<template>
  <default-layout>
    <template v-slot:header>
      <div class="bl_ttl">서비스 유형</div>
      <div class="bl_right is_moClose" @click="back"></div>
    </template>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_type">
            <div class="bl_type_table">
              <table>
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>셰어</th>
                    <th>리모</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="is_moShare">
                      <div class="bl_table_img"></div>
                    </td>
                    <td class="is_moLimo">
                      <div class="bl_table_img"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>동승 서비스</td>
                    <td>프라이빗 전세 서비스</td>
                  </tr>
                  <tr>
                    <td>
                      도착 시간<br />
                      늘어날 수 있음<br />
                      (동승자 있을 경우)
                    </td>
                    <td>빠르고 편안한 운행</td>
                  </tr>
                  <tr>
                    <td>
                      1~2인 예약 시<br />
                      저렴한 가격
                    </td>
                    <td>
                      3인 이상 예약 시<br />
                      저렴한 가격
                    </td>
                  </tr>
                  <tr>
                    <td class="is_moShare">공항 의전 서비스 불가</td>
                    <td class="is_moLimo">공항 의전 서비스 가능</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="bl_airport">
            <div class="bl_airport_ttl">공항 의전 서비스</div>
            <div class="bl_airport_service">
              <ul class="el_reset bl_service_list">
                <li class="bl_service_item">
                  <div class="bl_service_obj is_moWelcome">
                    <div class="bl_obj_txt">공항 웰컴보드</div>
                  </div>
                </li>
                <li class="bl_service_item">
                  <div class="bl_service_obj is_moBag">
                    <div class="bl_obj_txt">차량 안내 및 수하물 차량 적재</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { closeWebviewLayer } from "@/util/webview";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
export default {
  name: "AirportServiceInfoView",
  components: {
    DefaultLayout,
  },
  methods: {
    back() {
      closeWebviewLayer();
    }
  }
};
</script>