import http from "@/util/http";

function getRestaurantList({ ccode, page, size, text }) {
  return http.get(`/trend/club/food/list`, {
    params: {
      ccode,
      page,
      size,
      text,
    },
  });
}

export default {
  getRestaurantList,
};
