<template>
  <div class="ly_wrapper" ref="scrollList">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="ly_container">
              <div class="bl_record_wrapper">
                <div class="bl_record">
                  <div
                    class="bl_record_cont"
                    v-for="record in recordList"
                    :key="record.title"
                  >
                    <div class="bl_record_cont_inner">
                      <div class="bl_record_cont_cc">
                        <div class="bl_record_cont_cc_ttl">
                          {{ record.clubName }}
                        </div>
                        <div class="bl_record_cont_cc_info">
                          {{ record.courseName }}/H{{ record.hlnum }}
                        </div>
                        <div
                          class="bl_record_cont_cc_state"
                          :class="{ is_moApply: !record.rewardType }"
                        >
                          {{ record.rewardType ? "지급완료" : "신청완료" }}
                        </div>
                      </div>
                      <ul class="el_reset bl_record_cont_infoList">
                        <li class="bl_record_cont_infoList_item">
                          <div class="bl_record_cont_info">
                            <div class="bl_record_cont_info_ttl">
                              라운드 일자
                            </div>
                            <div class="bl_record_cont_info_cont">
                              {{ convertDate(record.holeInOneDt) }}
                            </div>
                          </div>
                        </li>
                        <li class="bl_record_cont_infoList_item">
                          <div class="bl_record_cont_info">
                            <div class="bl_record_cont_info_ttl">
                              상금 신청일
                            </div>
                            <div class="bl_record_cont_info_cont">
                              {{ convertDate(record.reqDt) }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { membershipApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dayjs from "dayjs";

export default {
  name: "VoicecaddieMembershipRecord",
  mixins: [scrollMixin],
  async created() {
    this.searchParams = {
      size: this.pageSize,
    };
    await this.setData();
  },
  data() {
    return {
      searchParams: {},
      recordList: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
    };
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, total },
        } = await membershipApi.getMembershipInsuranceBilling({
          ...this.searchParams,
          page: this.pageIndex,
        });
        this.recordList = content;
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    convertDate(timeStamp) {
      if (!timeStamp) {
        return "";
      }
      return dayjs.unix(timeStamp).format("YYYY.MM.DD");
    },
  },
};
</script>

<style scoped>
.bl_record_cont_cc_ttl {
  text-overflow: ellipsis;
  width: calc(100% - 75px);
  white-space: nowrap;
  overflow: hidden;
}
</style>
