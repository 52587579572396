<template>
  <div class="container"></div>
</template>

<script>
import { membershipApi } from "@/api";
import { closeWebviewLayer, setDataTopToBottomWebLayer } from "@/util/webview";

export default {
  name: "Success",
  async created() {
    const passJoin = sessionStorage.getItem("passJoin");
    const {
      customerKey,
      authKey,
      couponNumber,
      email,
      productCouponId,
      productId,
    } = this.$route.query;
    try {
      if (sessionStorage.getItem("isCalledJoinRequest")) {
        throw new Error("가입이 진행중입니다.");
      }
      await membershipApi.createMembershipTossBilling({ authKey, customerKey });
      if (!passJoin) {
        sessionStorage.setItem("isCalledJoinRequest", "1");
        await membershipApi.joinMembership({
          agreeTermsIdList: [11],
          couponNumber,
          email,
          productCouponId,
          productId,
        });
      }
      setDataTopToBottomWebLayer({
        success: true,
      });
    } catch (e) {
      alert(e.message);
      closeWebviewLayer();
      return;
    }
  },
};
</script>
