<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_header">
            <div class="bl_header_ttl">예약자 정보</div>
          </div>
          <div class="bl_input">
            <ul class="el_reset bl_input_list">
              <li class="bl_input_item">
                <nm-byte-input
                  v-model="koreanName"
                  placeholder="(한글) 이름"
                  type="text"
                  pattern="[^가-힣ㆍᆞᆢㄱ-ㅎㅏ-ㅣ]"
                  :max-byte="18"
                />
              </li>
              <li class="bl_input_item is_moHalf">
                <nm-byte-input
                  v-model="inputEnglishFirstName"
                  placeholder="(영문) 이름"
                  type="text"
                  pattern="[^a-zA-Z]"
                  :max-byte="30"
                />
              </li>
              <li class="bl_input_item is_moHalf">
                <nm-byte-input
                  v-model="inputEnglishLastName"
                  placeholder="(영문) 성"
                  type="text"
                  pattern="[^a-zA-Z]"
                  :max-byte="30"
                />
              </li>
              <li class="bl_input_item is_moThreeQuarters">
                <nm-input
                  v-model="inputPhoneNumber"
                  placeholder="휴대폰"
                  type="text"
                  maxlength="13"
                  pattern="[^0-9]"
                />
              </li>
              <li class="bl_input_item is_moQuarter">
                <nm-button :disabled="!inputPhoneNumber.trim() || isConfirmCertNumber" @click="requestPNIdentification">
                  인증번호 발송
                </nm-button>
              </li>
              <li v-if="isRequestCertNumber" class="bl_input_item is_moThreeQuarters">
                <nm-input
                    v-model="certNumber"
                    placeholder="인증번호 입력"
                    type="text"
                    maxlength="6"
                    pattern="[^0-9]"
                    :disabled="isConfirmCertNumber"
                >
                  <template v-slot:timer>
                    <div class="cp_obj_time">{{ formatTime(time) }}</div>
                  </template>
                </nm-input>
              </li>
              <li v-if="isRequestCertNumber" class="bl_input_item is_moQuarter">
                <nm-button :disabled="isConfirmCertNumber || !certNumber.trim()" @click=confirmPNIdentification>
                  인증
                </nm-button>
              </li>
              <li class="bl_input_item">
                <nm-input
                  v-model="inputEmail"
                  placeholder="이메일"
                  type="text"
                  maxlength="50"
                  :message="emailErrorMessage"
                  :validate="emailValidate"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ly_body_footer">
        <div class="ly_container">
          <div class="bl_btn">
            <nm-button
              class="is_moPrim"
              :disabled="missingRequirements"
              @click="editBookerInfo"
              >확인</nm-button
            >
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { japanVehicleApi } from "@/api";
import {formatTime, phoneNumberFormatter} from "@/util/string";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmInput from "@/components/nearme/NmInput.vue";
import NmByteInput from "@/components/nearme/NmByteInput.vue";
import NmButton from "@/components/nearme/NmButton.vue";
export default {
  name: "JapaneseVehicleEditBookerInfo",
  components: {
    DefaultLayout,
    NmInput,
    NmByteInput,
    NmButton,
  },
  data() {
    return {
      reservationId: null,
      reservationCd: null,
      koreanName: "",
      englishFirstName: "",
      englishLastName: "",
      phoneNumber: "",
      email: "",
      emailErrorMessage: "",
      emailValidate: true,
      emailPattern: /^[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\.[a-z]{2,}$/,
      isFetching: false,
      isRequestCertNumber: false,
      isConfirmCertNumber: false,
      certNumber: "",
      time: 0,
    };
  },
  async created() {
    this.reservationId = this.$route.params.reservationId;
    if (!this.reservationId) {
      alert("잘못된 접근입니다.");
      this.$router.back();
      return;
    }

    await this.setData();
  },
  computed: {
    missingRequirements() {
      return (
        !this.koreanName?.trim() ||
        this.koreanName?.trim()?.length < 2 ||
        !this.inputEnglishFirstName?.trim() ||
        !this.inputEnglishLastName?.trim() ||
        !this.inputPhoneNumber?.trim() ||
        !this.inputEmail.trim() ||
        !this.isConfirmCertNumber
      );
    },
    inputEnglishFirstName: {
      get() {
        return this.englishFirstName?.toUpperCase();
      },
      set(value) {
        this.englishFirstName = value;
      },
    },
    inputEnglishLastName: {
      get() {
        return this.englishLastName?.toUpperCase();
      },
      set(value) {
        this.englishLastName = value;
      },
    },
    inputPhoneNumber: {
      get() {
        return phoneNumberFormatter(this.phoneNumber);
      },
      set(value) {
        this.phoneNumber = String(value).replace(/\D/g, "");
      },
    },
    inputEmail: {
      set(value) {
        if (value.length === 0) {
          this.emailValidate = true;
          this.emailErrorMessage = "";
        }
        this.email = value;
      },
      get() {
        return this.email;
      },
    },
  },
  methods: {
    formatTime,
    async setData() {
      const {
        data: { engFirstName, engLastName, reservationName, reservationPhoneNumber, email },
      } = await japanVehicleApi.getReservationsDetail(this.reservationId);
      this.englishFirstName = engFirstName;
      this.englishLastName = engLastName;
      this.koreanName = reservationName;
      this.phoneNumber = reservationPhoneNumber;
      this.inputEmail = email || "";
    },
    async editBookerInfo() {
      if (this.isFetching) {
        return;
      }
      try {
        this.isFetching = true;
        await japanVehicleApi.updatePassengerInfo(this.reservationId, {
          engFirstName: this.englishFirstName,
          engLastName: this.englishLastName,
          korName: this.koreanName,
          phoneNumber: this.inputPhoneNumber,
          email: this.inputEmail,
        });

        alert("수정되었습니다.");

        await this.$router.replace({
          name: "JapaneseVehicleUsageDetailView",
          params: {
            reservationId: this.reservationId,
          },
          query: {
            service_type: this.serviceType,
          },
        });
      } finally {
        this.isFetching = false;
      }
    },
    async requestPNIdentification() {
      try {
        this.stopTimer();
        await japanVehicleApi.requestPhoneNumberIdentification({
          hpNationNo: 82,
          hpNo: this.inputPhoneNumber.replaceAll('-', ''),
        });
        this.startTimer();
        alert('인증번호가 발송되었습니다.\n발송된 인증번호를 입력해주세요.');
        this.isRequestCertNumber = true;
      } catch (error) {
        this.isRequestCertNumber = false;
      } finally {
        this.isConfirmCertNumber = false;
      }
    },
    async confirmPNIdentification() {
      try {
        await japanVehicleApi.confirmPhoneNumberIdentification({
          hpNationNo: 82,
          hpNo: this.inputPhoneNumber.replaceAll('-', ''),
          certNo: this.certNumber,
        });
        this.stopTimer();
        alert('인증이 완료되었습니다.');
        this.time = 0;
        this.isConfirmCertNumber = true;
      } catch (error) {
        this.isConfirmCertNumber = false;
      }
    },
    startTimer() {
      this.time = 180;
      this.intervalId = setInterval(() => {
        this.time -= 1;

        if (this.time === 0) {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.time = 0;
    },
  },
};
</script>
<style scoped>
@media (max-width: 1179px) {
  .id_person .ly_page .ly_body .bl_input_item.is_moThreeQuarters {
    width: 60%;
  }
  .id_person .ly_page .ly_body .bl_input_item.is_moQuarter {
    width: 40%;
  }
}
</style>