<template>
  <div class="lyPage">
    <main class="lyBody">
      <section class="stText">
        <p class="text">
          <slot name="text" />
        </p>
      </section>
    </main>
    <footer class="lyFooter">
      <section class="stButton">
        <div class="cmButton">
          <ul class="cmButton-list">
            <slot name="btn" />
          </ul>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
export default {
  name: "CommunityNoneResult",
  beforeCreate() {
    document.querySelector("body").classList.add("id-communityNonePost");
    document.querySelector("html").style.height = "100%";
    document.querySelector("body").style.height = "100%";
    document.querySelector("#app").style.height = "100%";
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("id-communityNonePost");
    document.querySelector("html").style.height = "auto";
    document.querySelector("body").style.height = "auto";
    document.querySelector("#app").style.height = "auto";
  },
};
</script>
