<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_ttl">어디로 떠나시나요?</div>
          <!-- journey-form -->
          <journey-form v-model="computedJourneyType" @reverse="reverse">
            <template v-slot:departure-form>
              <div class="bl_place_obj">
                <div class="bl_obj_ico" :class="departureIconClass"></div>
                <input
                  v-model="departureName"
                  class="el_form bl_obj_input"
                  :placeholder="departurePlaceholderTxt"
                  type="text"
                  readonly
                  @click="goToDepartureSearch"
                />
              </div>
            </template>
            <template v-slot:arrival-form>
              <div class="bl_place_obj">
                <div class="bl_obj_ico" :class="arrivalIconClass"></div>
                <input
                  v-model="arrivalName"
                  class="el_form bl_obj_input"
                  :placeholder="arrivalPlaceholderTxt"
                  type="text"
                  readonly
                  @click="goToArrivalSearch"
                />
              </div>
            </template>
          </journey-form>
          <!-- 추천 경로 -->
          <div class="bl_best" v-show="!activeRouteMap && pathList">
            <div class="bl_best_inner">
              <div class="bl_best_header">
                <div class="bl_header_ttl">{{ title }}</div>
                <div class="bl_header_txt">
                  <strong class="el_reset">{{ explanation1 }}</strong
                  ><br />
                  {{ explanation2 }}
                </div>
              </div>
              <div class="bl_best_body">
                <ul class="el_reset bl_body_list">
                  <li
                    v-for="(path, index) in pathList"
                    :key="index"
                    class="bl_body_item"
                    @click="setRouteOnJourneyForm(path)"
                  >
                    <div class="bl_body_obj">
                      <div
                        class="bl_obj_img"
                        :style="{
                          'background-image': 'url(' + path.imagePath + ')',
                        }"
                      >
                        <div class="bl_img_txt">{{ index + 1 }}</div>
                      </div>
                      <div class="bl_obj_cont">
                        <div class="bl_cont_inner">
                          <div class="bl_cont_ttl">{{ path.clubName }}</div>
                          <div class="bl_cont_txt">
                            <div class="bl_txt_price">
                              {{ path.amount | comma }}원~
                            </div>
                            <div class="bl_txt_person">1인</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 출발지/도착지 경로 -->
          <nm-route-map
            v-model="activeRouteMap"
            :origin="departureLocation"
            :destination="arrivalLocation"
          />

          <!-- 출발지 / 도착지 모두 입력 완료 시 -->
          <div v-if="activeRouteMap" class="ly_body_footer">
            <div class="bl_btn">
              <nm-button class="is_moPrim" @click="goToSelectSchedule"
                >다음</nm-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { nearmeApi, clubApi } from "@/api";
import { comma } from "@/util/string";
import {
  recentSearchClub,
  newWebviewLayerV2,
  closeWebviewLayer,
} from "@/util/webview";
import { isJson } from "@/util/string";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import JourneyForm from "@/views/nearme/reservation/components/JourneyForm.vue";
import NmRouteMap from "@/components/modules/nearme/NmRouteMap.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import reservationMixin from "@/mixins/reservationMixin";

export default {
  name: "ClubReservation",
  components: { DefaultLayout, JourneyForm, NmRouteMap, NmButton },
  mixins: [reservationMixin],
  data() {
    return {
      journeyType: "one-way",
      isReversed: false,
      departureName: "",
      departureAddress: "",
      departureEngName: null,
      departureLat: 0,
      departureLng: 0,
      arrivalName: "",
      arrivalAddress: null,
      arrivalEngName: null,
      arrivalLat: 0,
      arrivalLng: 0,
      searchName: null,
      recommendationPath: null,
      isBack: false,
    };
  },
  computed: {
    ...mapGetters(["userUid"]),
    computedJourneyType: {
      get() {
        return this.journeyType;
      },
      set(value) {
        this.setJourneyInfoType(value);
        this.journeyType = value;
      },
    },
    title() {
      return this.recommendationPath?.recommendation?.title;
    },
    pathList() {
      return this.recommendationPath?.pathList;
    },
    explanation1() {
      return this.recommendationPath?.recommendation?.explanation1;
    },
    explanation2() {
      return this.recommendationPath?.recommendation?.explanation2;
    },
    departureIconClass() {
      return this.isReversed ? "is_moGolf" : "is_moLocation";
    },
    departurePlaceholderTxt() {
      return this.isReversed ? "골프장" : "출발지";
    },
    arrivalIconClass() {
      return this.isReversed ? "is_moLocation" : "is_moGolf";
    },
    arrivalPlaceholderTxt() {
      return this.isReversed ? "도착지" : "골프장";
    },
    activeRouteMap() {
      return (
        this.departureName?.trim().length > 0 &&
        this.arrivalName?.trim().length > 0
      );
    },
    departureLocation() {
      return { lat: this.departureLat, lng: this.departureLng };
    },
    arrivalLocation() {
      return { lat: this.arrivalLat, lng: this.arrivalLng };
    },
  },
  async created() {
    try {
      const { ccode } = this.$route.query;
      if (ccode) {
        this.resetReservationInfo();
        const { data } = await clubApi.getClubListSearch([ccode]);

        this.setArrivalInfo({
          name: data[0].clubName,
          eng_name: data[0].clubNameEng,
          address: data[0].address,
          lat: data[0].clubLat,
          lng: data[0].clubLon,
          terminal_cd: null,
          search_name: null,
        });
      }

      const { data } = await nearmeApi.getRecommendationPathList({
        partnershipCd: "5801",
      });
      this.recommendationPath = data;

      await this.setData();
    } catch (e) {
      console.log(e);
    }

    window.preventWebviewFinish = this.preventWebviewFinish;

    window.getDataTopToBottomWebLayer = (data) => {
      let parsedData = data;
      const isJsonFormat = isJson(parsedData);
      if (isJsonFormat) {
        parsedData = JSON.parse(data);
      }
      if (parsedData.route === "departure") {
        this.setDepartureInfo({
          name: parsedData.name,
          eng_name: parsedData.eng_name,
          address: parsedData.address,
          lat: parsedData.lat,
          lng: parsedData.lng,
          search_name: parsedData.search_name,
          terminal_cd: parsedData.terminal_cd ? parsedData.terminal_cd : null,
        });
      } else {
        this.setArrivalInfo({
          name: parsedData.name,
          eng_name: parsedData.eng_name,
          address: parsedData.address,
          lat: parsedData.lat,
          lng: parsedData.lng,
          search_name: parsedData.search_name,
          terminal_cd: parsedData.terminal_cd ? parsedData.terminal_cd : null,
        });
      }

      this.setJourneyFormData();
    };
  },
  beforeDestroy() {
    delete window.preventWebviewFinish;
  },
  methods: {
    async setData() {
      await this.getTemporaryData();
      this.setJourneyFormData();
    },
    async getTemporaryData() {
      // 임시 저장 정보 조회
      const { data } = await nearmeApi.getReservationTemporary("Golf");
      const isObject = data instanceof Object;
      if (isObject) {
        if (
          confirm(
            "임시 저장된 예약 정보가 있습니다.\n예약 정보를 불러오시겠습니까?"
          )
        ) {
          // load temporary data
          this.setReservationInfo({
            reverse_yn: data.reserveYn,
            departure_name: data.departureName,
            departure_eng_name: data.departureEngName,
            departure_address: data.departureAddress,
            departure_lat: data.departureLat,
            departure_lng: data.departureLng,
            arrival_name: data.arriveName,
            arrival_eng_name: data.arriveEngName,
            arrival_lat: data.arriveLat,
            arrival_lng: data.arriveLng,
            arrival_address: data.arriveAddress,
            one_way_date: data.oneWayDate,
            one_way_time: data.oneWayTime,
            round_trip_date: data.roundTripDate,
            round_trip_time: data.roundTripTime,
            passengers: data.passengers,
            carrier_small: data.carrierSmall,
            carrier_medium: data.carrierMedium,
            carrier_large: data.carrierLarge,
            tour_bag: data.tourBag,
            stand_bag: data.standBag,
            half_bag: data.halfBag,
            other_with_in_100: data.otherWithin100,
            other_with_in_150: data.otherWithin150,
            other_with_in_200: data.otherWithin200,
            num_flight: data.numFlight,
            time_flight: data.timeFlight,
            name: data.name,
            eng_first_name: data.engFirstName,
            eng_last_name: data.engLastName,
            phone_number: data.phoneNumber,
            recommendation_path_id: data.recommendationPathId,
            search_name: data.searchName,
            email: data.email,
          });
        } else {
          this.resetJourneyInfo();
        }
      }
    },
    back() {
      this.$router.back();
    },
    async reverse() {
      const tempDepartureName = this.departureName;
      const tempDepartureAddress = this.departureAddress;
      const tempDepartureEngName = this.departureEngName;
      const tempDepartureLat = this.departureLat;
      const tempDepartureLng = this.departureLng;
      this.departureName = this.arrivalName;
      this.arrivalName = tempDepartureName;
      this.departureAddress = this.arrivalAddress;
      this.arrivalAddress = tempDepartureAddress;
      this.departureEngName = this.arrivalEngName;
      this.arrivalEngName = tempDepartureEngName;
      this.departureLat = this.arrivalLat;
      this.departureLng = this.arrivalLng;
      this.arrivalLat = tempDepartureLat;
      this.arrivalLng = tempDepartureLng;
      this.isReversed = !this.isReversed;

      this.setJourneyInfo({
        journey_type: this.computedJourneyType,
        reverse_yn: this.isReversed ? "Y" : "N",
        departure_name: this.departureName,
        departure_eng_name: this.departureEngName || null,
        departure_address: this.departureAddress || null,
        arrival_name: this.arrivalName,
        arrival_eng_name: this.arrivalEngName || null,
        arrival_address: this.arrivalAddress || null,
        departure_lat: this.departureLat,
        departure_lng: this.departureLng,
        arrival_lat: this.arrivalLat,
        arrival_lng: this.arrivalLng,
        recommendation_path_id: this.recommendationPathId,
        search_name: this.searchName,
      });

      this.setJourneyFormData();
    },
    async setJourneyFormData() {
      const {
        journey_type,
        reverse_yn,
        departure_name,
        departure_eng_name,
        departure_address,
        departure_lat,
        departure_lng,
        arrival_name,
        arrival_eng_name,
        arrival_address,
        arrival_lat,
        arrival_lng,
        search_name,
      } = this.getJourneyInfo();

      this.computedJourneyType = journey_type;
      this.isReversed = reverse_yn === "Y" ? true : false;
      this.departureName = departure_name;
      this.departureEngName = departure_eng_name;
      this.departureAddress = departure_address;
      this.departureLat = departure_lat;
      this.departureLng = departure_lng;
      this.arrivalName = arrival_name;
      this.arrivalEngName = arrival_eng_name;
      this.arrivalAddress = arrival_address;
      this.arrivalLat = arrival_lat;
      this.arrivalLng = arrival_lng;
      this.searchName = search_name;

      const params = {
        journey_type: this.computedJourneyType,
        reverse_yn: this.isReversed,
        departure_name: this.arrivalName,
        departure_lat: this.arrivalLat,
        departure_lng: this.arrivalLng,
        arrival_name: this.departureName,
        arrival_lat: this.departureLat,
        arrival_lng: this.departureLng,
        search_name: this.searchName,
      };

      await this.$router.replace({ query: { ...params } }).catch(() => {});
    },
    async setRouteOnJourneyForm(path) {
      this.isReversed = false;
      this.departureName = path.departureName;
      this.departureAddress = path.departureAddress;
      this.departureEngName = null;
      this.departureLat = path.departureLat;
      this.departureLng = path.departureLon;
      this.arrivalName = path.clubName;
      this.arrivalAddress = path.clubAddress;
      this.arrivalEngName = path.clubNameEng;
      this.arrivalLat = path.clubLat;
      this.arrivalLng = path.clubLon;

      await recentSearchClub({
        ccodes: [path.ccode],
      });

      this.recommendationPathId = path.pathId;
      this.setJourneyInfo({
        journey_type: this.computedJourneyType,
        reverse_yn: this.isReversed ? "Y" : "N",
        departure_name: this.departureName,
        departure_eng_name: this.departureEngName,
        departure_address: this.departureAddress,
        arrival_name: this.arrivalName,
        arrival_eng_name: this.arrivalEngName,
        arrival_address: this.arrivalAddress,
        departure_lat: this.departureLat,
        departure_lng: this.departureLng,
        arrival_lat: this.arrivalLat,
        arrival_lng: this.arrivalLng,
        recommendation_path_id: this.recommendationPathId,
        search_name: this.searchName,
      });

      const params = {
        journey_type: this.computedJourneyType,
        reverse_yn: this.isReversed ? "Y" : "N",
        departure_name: this.departureName,
        departure_lat: this.departureLat,
        departure_lng: this.departureLng,
        arrival_name: this.arrivalName,
        arrival_lat: this.arrivalLat,
        arrival_lng: this.arrivalLng,
        search_name: this.searchName,
      };

      await this.$router.replace({ query: { ...params } }).catch(() => {});
    },
    preventWebviewFinish() {
      setTimeout(async () => {
        if (this.isBack) {
          return;
        } else {
          this.isBack = true;
          if (await this.reservationAbort()) {
            this.resetReservationInfo();
            closeWebviewLayer();
          } else {
            this.isBack = false;
          }
        }
      }, 100);
      return JSON.stringify({ isPreventFinishing: true });
    },
    goToDepartureSearch() {
      newWebviewLayerV2({
        name: "DepartureSearch",
        query: {
          service_type: "club",
          search_type: this.isReversed ? "club" : "location",
        },
        hideAppBar: true,
      });
    },
    goToArrivalSearch() {
      newWebviewLayerV2({
        name: "ArrivalSearch",
        query: {
          service_type: "club",
          search_type: this.isReversed ? "location" : "club",
        },
        hideAppBar: true,
      });
    },
    goToSelectSchedule() {
      const reservationInfo = this.getReservationInfo();
      this.setJourneyInfo({
        ...reservationInfo,
        reverse_yn: this.isReversed ? "Y" : "N",
      });
      this.$router.push({
        name: "SelectSchedule",
        query: {
          service_type: "club",
          journey_type: this.journeyType,
        },
      });
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>