<template>
  <community-none-result v-if="boardList && !boardList.length">
    <template #text>
      등록한 게시물이 없어요!<br />게시물을 작성해볼까요?
    </template>
    <template #btn>
      <li
        @click="
          logEvent('more_board_posting', () => {
            newWebviewLayerV2({ name: 'CommunityRegistration' });
          })
        "
        class="cmButton-item is-colorKey"
      >
        <div class="cmButton-object">게시물 작성</div>
      </li>
    </template>
  </community-none-result>
  <div class="lyPage" ref="scrollList" v-else>
    <main class="lyBody">
      <section class="stPost">
        <div class="cmPost">
          <ul class="cmPost-list">
            <community-post
              v-for="board in boardList"
              :key="board.boardId"
              :board="board"
              @deletePost="onDeletePost"
              @openPostLayer="onOpenPostLayer"
            />
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import CommunityNoneResult from "@/views/community/components/CommunityNoneResult";
import CommunityPost from "@/views/community/components/CommunityPost";
import { newWebviewLayerV2 } from "@/util/webview";
import { boardApi, userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";

export default {
  name: "CommunityMyPost",
  components: {
    CommunityNoneResult,
    CommunityPost,
  },
  mixins: [scrollMixin],
  async created() {
    await this.setData();
    window.communityTabActivated = () => {
      this.updateBoard();
    };
  },
  mounted() {
    this.addScrollEvent();
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      lastId: undefined,
      totalCount: 0,
      boardList: null,
      lastOpenBoardId: undefined,
    };
  },
  methods: {
    newWebviewLayerV2,
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, last },
        } = await userApi.getMyBoards({
          page: this.pageIndex,
          lastId: this.lastId,
          size: this.pageSize,
        });
        this.boardList = this.boardList || [];
        this.boardList = [...this.boardList, ...content];
        this.lastId = content[content.length - 1]?.boardId;
        this.last = last;
      } finally {
        this.isFetching = false;
      }
    },
    onDeletePost() {
      this.pageIndex = 1;
      this.lastId = undefined;
      this.last = false;
      this.boardList = null;
      this.setData();
    },
    onOpenPostLayer(boardId) {
      this.lastOpenBoardId = boardId;
    },
    async updateBoard() {
      if (!this.lastOpenBoardId) {
        this.setData();
        return;
      }
      const {
        data: { content },
      } = await boardApi.getBoards({
        size: 1,
        orderBy: "date",
        lastId: this.lastOpenBoardId + 1,
      });
      const [board] = content;
      if (board) {
        this.boardList = this.boardList.map((item) =>
          item.boardId === board.boardId ? board : item
        );
      }
    },
  },
};
</script>
