<template>
  <div class="policy">
    <h1>개인정보 제3자 제공 동의</h1>
    <p>브이씨소프트㈜에서 주식회사 NearMe(으)로 차량 서비스 예약 및 이용 목적으로 아래와 같이 정보를 제공합니다.</p>
    <table>
      <thead>
      <tr>
        <th>제공받는 자</th>
        <th>제공 목적</th>
        <th>제공 정보</th>
        <th>보유 및 이용기간</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>주식회사 NearMe</td>
        <td>차량 서비스 예약 및 이용</td>
        <td>예약자 이름 (한글, 영문), 휴대폰 번호, 출발/도착지 정보, 항공편 정보</td>
        <td>제공받는 자의 이용 목적 달성 시까지. 단, 관계법령의 규정에 의하여<br>보존할 필요가 있는 경우에는 해당 법률에서 규정하는 기간 동안 필요한 정보를
          보관합니다.
        </td>
      </tr>
      </tbody>
    </table>
    <h2>동의를 거부할 권리 및 동의 거부에 따른 불이익</h2>
    <p>
      개인정보 주체자는 제3자 제공에 대한 동의를 거부할 권리가 있습니다. 동의를 거부할 경우 차량 예약 서비스 이용이 불가함을 알려드립니다.
    </p>
  </div>
</template>
<script>
export default {
  name: "ThirdPartyInfoShare",
};
</script>
<style scoped>
@import "~@/assets/css/nearme/reset.css";
</style>