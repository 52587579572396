<template>
  <div class="ly_page">
    <div class="ly_page_inner">
      <header class="ly_header">
        <div class="bl_tabUnit_wrapper">
          <ul class="bl_tabUnit">
            <li class="bl_tab">
              <div class="bl_tab_item is_ac">쿠폰 등록</div>
            </li>
            <li
              class="bl_tab"
              @click="
                $router.replace({ name: 'NmCouponList', query: $route.query })
              "
            >
              <div class="bl_tab_item">쿠폰 내역</div>
            </li>
          </ul>
        </div>
      </header>
      <main class="ly_body">
        <div class="bl_coupon_wrapper">
          <div class="bl_coupon">
            <h4 class="bl_coupon_ttl">쿠폰 번호 입력</h4>
            <div class="bl_coupon_input">
              <!-- 유효성 체크 활성: is_acValid -->
              <div class="el_inputDefault" :class="{ is_acValid: onValidate }">
                <input
                  :value="couponCodeInput"
                  @input="onCouponCodeInput"
                  class="el_inputDefault_input"
                  placeholder="쿠폰 번호를 입력해주세요."
                  type="text"
                  :maxlength="couponCodeMaxLength"
                />
                <div class="el_inputDefault_valid">{{ validateMsg }}</div>
              </div>
            </div>
            <div class="bl_coupon_btn">
              <button
                class="el_btnDefault is_moConfirm"
                :disabled="missingRequirements"
                @click="submitCouponCode"
              >
                <div class="el_btnDefault_txt">쿠폰 등록</div>
              </button>
            </div>
          </div>
        </div>
      </main>
       <footer class="ly_footer">
        <div class="bl_caution_wrapper">
          <div class="bl_caution">
            <h4 class="bl_caution_ttl">꼭 읽어주세요!</h4>
            <ul class="bl_caution_descUnit">
              <li class="bl_caution_desc">
                쿠폰 정상 등록 시, 상품이 지급됩니다.
              </li>
              <li class="bl_caution_desc">
                쿠폰 번호는 중복 사용이 불가능합니다.
              </li>
              <li class="bl_caution_desc">
                유효 기간 만료 이후 쿠폰 사용은 불가능합니다.
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import { userApi } from "@/api";
import { setDataTopToBottomWebLayer, showToast } from "@/util/webview";
const COUPON_CODE_MAX_LENGTH = 16;
export default {
  name: "NmCoupon",
  data() {
    return {
      onValidate: false,
      couponCodeInput: "",
      couponCodeMaxLength: COUPON_CODE_MAX_LENGTH,
      validateMsg: "",
    };
  },
  watch: {
    couponCodeInput(val) {
      this.couponCodeInput = val.toUpperCase().replace(/[^A-Z0-9]/g, "");
    },
  },
  computed: {
    missingRequirements() {
      return !this.couponCodeInput.length || this.onValidate === true;
    },
    isLayer() {
      return this.$route.query?.isLayer;
    },
  },
  methods: {
    onCouponCodeInput(e) {
      this.couponCodeInput = e.target.value.slice(0, this.couponCodeMaxLength);
      e.target.value = this.couponCodeInput;
      this.onValidate = false;
    },
    async submitCouponCode() {
      const data = await userApi.submitCouponNumber(this.couponCodeInput);
      if (data?.code !== "200") {
        this.onValidate = true;
        this.validateMsg = data.message;
        return;
      }
      this.couponCodeInput = "";
      if (this.isLayer) {
        if (data?.data?.productTypeCd === "3503") { // 해외예약 바우처
          showToast({ message: "쿠폰이 등록되었습니다." });
          setDataTopToBottomWebLayer({
            ...data.data,
            from: "register"
          });
        }
        else {
          showToast({ message: "쿠폰이 등록되었습니다." });
        }
        return;
      }
    },
  },
};
</script>