<template>
  <bottom-modal-layout class="is_moService" :close="close">
    <template v-slot:header>
      <div class="bl_ttl">부가 서비스</div>
      <div class="bl_right is_moClose" @click="hide(null)"></div>
    </template>
    <template v-slot:main>
      <div class="bl_cont">
        <div class="bl_cont_ttl">공항 의전 서비스</div>
        <div class="bl_cont_service">
          <ul class="el_reset bl_service_list">
            <li class="bl_service_item">
              <div class="bl_service_obj is_moWelcome">
                <div class="bl_obj_txt">공항 웰컴보드</div>
              </div>
            </li>
            <li class="bl_service_item">
              <div class="bl_service_obj is_moBag">
                <div class="bl_obj_txt">차량 안내 및 수하물 차량 적재</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </bottom-modal-layout>
</template>
<script>
import { preventBackButton } from "@/util/webview";
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";

export default {
  name: "ExtraServiceModal",
  components: {
    BottomModalLayout,
  },
  data() {
    return {
      resolve: () => {},
      content: null,
      close: false,
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
  },
};
</script>