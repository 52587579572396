<template>
  <li class="cmComment-item">
    <div class="cmComment-object" :class="{ 'is-focus': isTarget }">
      <div class="cmReply">
        <div class="cmReply-profile">
          <div class="cmReply-profileImage" @click="onProfileClick">
            <div
              class="cmReply-profileImageObject"
              :style="{
                'background-image': `url('${profileImgUrl}')`,
              }"
            ></div>
          </div>
          <div class="cmReply-profileName" @click="onProfileClick">
            {{ comment.nickname || "닉네임없음" }}
          </div>
          <div class="cmReply-profileWrite">
            {{ regDt }}
          </div>
        </div>
        <div class="cmReply-content" v-html="commentContent"></div>
        <div class="cmReply-feed">
          <div class="cmReply-feedReply">
            <ul class="cmReply-feedReplyList">
              <li
                class="cmReply-feedReplyItem"
                v-if="!isComment"
                @click="
                  logEvent('community_board_recomment_click', onReplyClick)
                "
              >
                답글달기
              </li>
              <li
                v-if="isOwnComment"
                @click="
                  logEvent(
                    'community_board_comment_delete_click',
                    deleteComment
                  )
                "
                class="cmReply-feedReplyItem is-delete"
              >
                삭제
              </li>
            </ul>
          </div>
          <!-- 온 상태 : is-stateOn -->
          <div
            class="cmReply-feedLike"
            :class="{ 'is-stateOn': comment.likeYn === 'Y' }"
            @click="
              logEvent('community_board_comment_like_click', toggleCommentLike)
            "
          >
            {{ comment.likeCnt }}
          </div>
        </div>
      </div>
    </div>
    <div class="cmComment-sub">
      <ul class="cmComment-subList">
        <community-comment-reply
          v-for="reply in replyList"
          :key="reply.replyId"
          :reply="reply"
          @deleteReply="onDeleteReply"
          @setReplyInfo="onSetReplyInfo"
        />
      </ul>
      <div class="more-reply" v-if="isRemainReply" @click="getReply">
        + {{ remainReplyCount }}개의 답글 더보기
      </div>
    </div>
  </li>
</template>

<script>
import { debounce } from "lodash";
import { boardApi } from "@/api";
import dateMixin from "@/mixins/dateMixin";
import { mapGetters } from "vuex";
import { newWebviewLayerV2, showToast } from "@/util/webview";
import { convertNewLine } from "@/util/string";
import CommunityCommentReply from "@/views/community/components/CommunityCommentReply";
import dayjs from "dayjs";

export default {
  name: "CommunityComment",
  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
    isComment: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dateMixin],
  components: {
    CommunityCommentReply,
  },
  data() {
    return {
      lastLikeYn: this.comment.likeYn,
      likeUpdateCnt: 0,
      replyList: null,
      lastId: undefined,
      last: false,
      isFetching: false,
      pageIndex: 1,
      pageSize: 50,
    };
  },
  created() {
    this.updateCommentLike = debounce(this.updateCommentLike, 500);
  },
  methods: {
    newWebviewLayerV2,
    async getReply() {
      this.logEvent("community_board_comment_more_click");
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content: replyList, last },
        } = await boardApi.getBoardCommentReply(this.comment.commentId, {
          page: this.pageIndex,
          lastId: this.lastId,
          size: this.pageSize,
        });

        this.replyList = this.replyList || [];
        this.replyList = [...this.replyList, ...replyList];
        this.lastId = replyList[replyList.length - 1]?.replyId;
        this.last = last;
        this.pageIndex += 1;
      } finally {
        this.isFetching = false;
        await this.$nextTick();
        this.$emit("updateHighlight");
      }
    },
    async onReplyClick() {
      if (this.isComment) {
        return;
      }
      this.onSetReplyInfo({
        isComment: true,
        ...this.comment,
      });
    },
    onSetReplyInfo(info) {
      this.$emit("setReplyInfo", {
        commentId: this.comment.commentId,
        ...info,
      });
    },
    onDeleteReply(replyId) {
      this.replyList = this.replyList.filter(
        (reply) => reply.replyId !== replyId
      );
    },
    onProfileClick() {
      if (this.comment.nickname === undefined) {
        return;
      }
      newWebviewLayerV2({
        name: "CommunityProfile",
        params: { uid: this.comment.uid },
      });
    },
    async toggleCommentLike() {
      this.likeUpdateCnt += 1;
      if (this.likeUpdateCnt >= 10) {
        showToast({ message: "커뮤니티 보호를 위해 반복할 수 없습니다" });
      }
      if (this.comment.likeYn === "Y") {
        this.comment.likeYn = "N";
        this.comment.likeCnt -= 1;
      } else if (this.comment.likeYn === "N") {
        this.comment.likeYn = "Y";
        this.comment.likeCnt += 1;
      }
      await this.updateCommentLike();
    },
    async updateCommentLike() {
      if (this.lastLikeYn === this.comment.likeYn) {
        return;
      }
      this.lastLikeYn = this.comment.likeYn;
      return await boardApi.updateBoardCommentLike(this.comment.commentId, {
        likeYn: this.comment.likeYn,
      });
    },
    async deleteComment() {
      if (!confirm("댓글을 삭제 하시겠어요?")) {
        return;
      }
      await boardApi.deleteBoardComment(this.comment.commentId);
      this.$emit("deleteComment", this.comment.commentId);
    },
    updateReply(reply) {
      if (this.isRemainReply) {
        return;
      }
      this.lastId = reply.replyId;
      if (this.replyList) {
        this.replyList.push(reply);
      } else {
        this.replyList = [reply];
      }
    },
  },
  computed: {
    ...mapGetters(["userUid"]),
    profileImgUrl() {
      return this.comment?.profileImgUrl || "";
    },
    regDt() {
      if (dayjs().diff(dayjs.unix(this.comment?.regDt), "days", true) > 30.5) {
        return dayjs.unix(this.comment?.regDt).format("YYYY/MM/DD");
      }
      return this.convertTimeFromNow(this.comment?.regDt);
    },
    isOwnComment() {
      return this.userUid === this.comment.uid;
    },
    commentContent() {
      return convertNewLine(this.comment?.comment);
    },
    isRemainReply() {
      return this.comment?.replyCnt && !this.last;
    },
    remainReplyCount() {
      return this.comment?.replyCnt - this.pageSize * (this.pageIndex - 1);
    },
    isTarget() {
      return (
        this.$route.query.target_type === "COMMENT" &&
        this.$route.query.target_id === String(this.comment.commentId)
      );
    },
  },
};
</script>

<style>
.more-reply {
  color: #4f84ff;
  font: normal normal 500 12px / 1.43 "Noto Sans KR";
  padding: 0 0 0 50px;
}
</style>
