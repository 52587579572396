<template>
  <div :ref="ref"></div>
</template>

<script>
import IosSelector from "@/util/iosDatepicker";

export default {
  name: "IosDatepicker",
  props: {
    ref: {
      type: String,
      default: "iosSelector",
    },
    type: {
      type: String,
      // normal | infinite
      default: "infinite",
    },
    source: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 16,
    },
    value: {
      type: [Number, String, Object],
      default: null,
    },
  },
  data() {
    return {
      selector: null,
    };
  },
  mounted() {
    this.selector = new IosSelector({
      el: this.$refs[this.ref],
      type: this.type,
      source: this.source,
      count: 16,
      value: this.value,
      onChange: (selected) => {
        this.$emit("input", selected?.value);
      },
    });
  },
  computed: {
    moving() {
      return this.selector?.moving;
    },
  },
  watch: {
    moving(value) {
      this.$emit("changeMoving", value);
    },
  },
};
</script>

<style>
@import "~@/assets/css/promotion/jeomsin/datePicker.css";
</style>
