<template>
  <div class="policy">
    <h1>개인정보 제 3자 제공 동의</h1>
    <h2>[SKT 이용약관]</h2>
    <p>
      "알뜰폰(MVNO)"사업자는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    </p>
    <h2>제1조 (알뜰폰(MVNO) 사업자)</h2>
    <p>
      SK텔레콤의 알뜰폰(MVNO) 사업자는
      SK텔링크(SK세븐모바일),아이즈비전(아이즈모바일),이마트(이마트알뜰폰),유니컴즈(모빙),LG헬로비전(헬로모바일),토스모바일(주),에스원(안심모바일),큰사람(이야기모바일),스마텔(스마텔),프리텔레콤(프리티),조이텔(조이텔),KCT(티플러스),
      KB국민은행(Liiv M)입니다.
    </p>
    <h2>제2조 (제공목적)</h2>
    <p>
      본 동의는 본인확인서비스를 제공하기 위하여 본인확인기관인 SK텔레콤과
      본인확인서비스 이용자간 본인확인서비스 이용에 필요한 고객정보를 위탁하여
      본인확인서비스를 제공 하는 것에 대해 동의합니다.
    </p>
    <h2>제3조 (제공정보)</h2>
    <p>
      휴대폰 본인확인서비스 제공에 필요한 개인정보로 성명, 휴대폰번호, 생년월일,
      내·외국인구분, 성별 정보입니다.
    </p>
    <h2>제4조 (제공받는자)</h2>
    <p>SK텔레콤</p>
    <h2>제5조 (제공기간)</h2>
    <p>이동통신사에서 보유중인 정보로서 별도 이용기간은 없습니다.</p>
    <p>
      "본인"은 정보제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 휴대폰
      본인확인서비스를 이용 하실 수 없습니다.
    </p>
    <h2>[KT 이용약관]</h2>
    <p>
      "알뜰폰(MVNO)"사업자는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    </p>
    <h2>제1조 (알뜰폰(MVNO) 사업자)</h2>
    <p>
      ㈜케이티의 알뜰폰(MVNO) 사업자 (주)LG헬로비전(헬로모바일),
      (주)니즈텔레콤(니즈모바일), (주)더원플랫폼(아이플러스유),
      (주)미니게이트(M2모바일), (주)스테이지파이브(핀플레이),
      (주)와이엘랜드(여유알뜰폰), (주)위너스텔(웰), (주)파인디지털,
      (주)한국케이블텔레콤(티플러스), ACN코리아(플래시모바일),
      KT스카이라이프(스카이라이프모바일), KT엠모바일(KtM 모바일), KT텔레캅,
      드림라인(주)(드림모바일), 로카모빌리티(주), 토스모바일(주),
      아이디스파워텔㈜, 앤알커뮤니케이션(앤텔레콤), 에스원(안심모바일),
      장성모바일, ㈜세종텔레콤(스노우맨), ㈜씨엔커뮤니케이션(씨앤컴),
      ㈜아이즈비전(아이즈모바일), ㈜에넥스텔레콤(A모바일),
      ㈜에이프러스(아시아모바일), ㈜유니컴즈(모빙), ㈜제이씨티,
      ㈜코드모바일(이지모바일), ㈜큰사람커넥트(이야기모바일),
      ㈜프리텔레콤(프리티), 한국피엠오(주)(밸류컴), (주)스마텔,
      KB국민은행(리브엠), (주)포인트파크 은 본인확인서비스의 제공을 위해 아래와
      같이 이용자의 개인정보를 제3자에게 제공합니다
    </p>
    <h2>제2조 (제공목적)</h2>
    <p>본인확인서비스 제공</p>
    <h2>제3조 (제공정보)</h2>
    <p>성명, 휴대폰번호, 생년월일, 내 외국인구분, 성별</p>
    <h2>제4조 (제공받는자)</h2>
    <p>주식회사 케이티(KT)</p>
    <h2>제5조 (제공기간)</h2>
    <p>서비스 제공 완료시까지</p>
    <p>
      '본인'은 정보제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 휴대폰
      본인확인서비스를 이용하실 수 없습니다.
    </p>
    <h2>[LGU+ 이용약관]</h2>
    <p>
      "알뜰폰(MVNO)"사업자는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
    </p>
    <h2>제1조 (알뜰폰(MVNO) 사업자)</h2>
    <p>
      엘지유플러스의 알뜰폰(MVNO) 사업자는 인스코비(프리티), 토스모바일(주),
      (주)엠티티텔레콤(메리큐), (주)미디어로그(U+유모바일), 이마트, 서경방송,
      울산방송, 푸른방송, 남인천방송, 금강방송, 제주방송,
      (주)와이엘랜드(여유텔레콤), ACN코리아(플래시모바일), 이지모바일,
      유니컴즈(모빙), 큰사람(이야기), 스마텔, 에넥스텔레콤(A모바일),
      레그원(온국민폰), 드림에이치앤비(셀모바일), 조이텔(조이텔),
      에스원(안심모바일), 원텔레콤, (주)국민은행(Liiv M),
      LG헬로비전(헬로모바일), 엔티온텔레콤, 인스모바일, 아시아모바일,
      슈가모바일, 코나아이, KG모빌리언스(KG모바일), 마블프로듀스(마블링) 입니다.
    </p>
    <h2>제2조 (제공목적)</h2>
    <p>
      본 동의는 본인확인 서비스를 제공하기 위하여 본인확인기관인
      (주)엘지유플러스와 본인확인서비스 이용자간 본인확인서비스 이용에 필요한
      고객정보를 위탁하여 본인확인서비스를 제공 하는 것에 대해 동의합니다.
    </p>
    <h2>제3조 (제공정보)</h2>
    <p>
      휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명, 휴대폰번호,
      생년월일, 내·외국인구분, 성별 정보입니다.
    </p>
    <h2>제4조 (제공받는자)</h2>
    <p>(주)엘지유플러스</p>
    <h2>제5조 (제공기간)</h2>
    <p>이동통신사에서 보유중인 정보로서 별도 이용기간은 없습니다.</p>
    <p>
      "본인"은 정보제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 휴대폰
      본인 확인 서비스를 이용 하실 수 없습니다.
    </p>
  </div>
</template>

<script>
export default {
  name: "ThirdParties",
};
</script>

<style scoped>
table {
  margin: 0 20px;
  margin-bottom: 14px;
}
table,
td,
th {
  border: 1px solid black;
  border-collapse: collapse;
}
td,
th {
  padding: 6px;
}
th {
  background-color: #f3f3f3;
}
</style>
