<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div
            :class="{ is_ac: !usageHistoryList || usageHistoryList.length }"
            class="bl_history"
          >
            <ul
              class="el_reset bl_history_list"
              v-for="usageHistory in usageHistoryList"
              :key="usageHistory.reservationCd"
            >
              <japanese-vehicle-usage-history-list-item
                :reservation="usageHistory"
                @click="onClickUsageHistory(usageHistory.reservationId)"
              />
            </ul>
            <div class="no_result">이용 내역이 없습니다.</div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { japanVehicleApi } from "@/api";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import JapaneseVehicleUsageHistoryListItem from "@/views/japan-vehicle/components/JapaneseVehicleUsageHistoryListItem.vue";

export default {
  components: { DefaultLayout, JapaneseVehicleUsageHistoryListItem },
  name: "JapaneseVehicleUsageHistoryList",
  data() {
    return {
      usageHistoryList: null,
    };
  },
  async created() {
    await this.setData();
  },
  methods: {
    async setData() {
      const { data } = await japanVehicleApi.getUsageHistoryList();
      console.log('history data: ', data);
      this.usageHistoryList = data;
    },
    onClickUsageHistory(reservationId) {
      this.$router.push({
        name: "JapaneseVehicleUsageDetailView",
        params: {
          reservationId: reservationId,
        },
      });
    },
  },
};
</script>
