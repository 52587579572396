import nearmeHttp from "@/util/nearmeHttp";

function getUsageHistoryList() {
  return nearmeHttp.get("/v1/vehicle/reservations");
}

function getReservationsDetail(reservationId) {
  return nearmeHttp.get(`/v1/vehicle/reservations/${reservationId}`);
}

function updatePassengerInfo(reservationId, { engFirstName, engLastName, korName, phoneNumber, email }) {
  return nearmeHttp.put(`/v1/vehicle/passenger/${reservationId}`, {
    engFirstName,
    engLastName,
    korName,
    phoneNumber,
    email,
  });
}

function cancelVehicleReservation(reservationId) {
  return nearmeHttp.delete(`/v1/vehicle/reservations/${reservationId}`);
}

function requestPhoneNumberIdentification({
    hpNationNo,
    hpNo
}) {
  return nearmeHttp.post(`/auth/hp/verification`, {
    hpNationNo,
    hpNo
  });
}

function confirmPhoneNumberIdentification({
   certNo,
   hpNationNo,
   hpNo
}) {
  return nearmeHttp.post(`/auth/hp/identification/confirm`, {
    certNo,
    hpNationNo,
    hpNo
  });
}

export default { getUsageHistoryList, getReservationsDetail, updatePassengerInfo, cancelVehicleReservation, requestPhoneNumberIdentification, confirmPhoneNumberIdentification };
