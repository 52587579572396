<template>
  <div v-if="isLoading" class="spinner">
    <spinner size="55" :message="message" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from 'vue-simple-spinner'

export default {
  name: "NmSpinner",
  components: {
    Spinner,
  },
  computed: {
    ...mapGetters(["isLoading"]),
    ...mapGetters(["message"]),
  },
};
</script>

<style scoped>
.spinner {
  top: 0rem;
  left: 0rem;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1011;
}

>>> .vue-simple-spinner {
  border-color: #4f84ff white white !important;
}

>>> .vue-simple-spinner-text {
  margin-top: 1.4rem !important;
  color: white !important;
  font-size: 1.6rem !important;
  font-weight: 700;
}
</style>