import Vue from "vue";
import { logEvent } from "firebase/analytics";
import analytics from "@/util/analytics";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
Vue.config.productionTip = false;

// Font
import "@/assets/fonts/notoSansKr.css";
import "@/assets/fonts/gmarketSans.css";

Vue.prototype.$EventBus = new Vue();
Vue.use(VueMeta);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
  methods: {
    logEvent(eventName, callback) {
      if (process.env.VUE_APP_FIREBASE_ENABLE !== "true") {
        if (callback && typeof callback === "function") {
          callback?.();
        }
        return;
      }
      if (process.env.VUE_APP_MODE === "qa") {
        eventName = `QA_${eventName}`;
      }
      logEvent(analytics, eventName);
      callback?.();
    },
    consultLayer(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("consultLayer", {
          content,
          props,
          resolve,
        });
      });
    },
    alert(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("alert", {
          content,
          props,
          resolve,
        });
      });
    },
    confirm(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("confirm", {
          content,
          props,
          resolve,
        });
      });
    },
    terminalInfo(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("terminalInfo", {
          content,
          props,
          resolve,
        });
      });
    },
    luggageNotice(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("luggageNotice", {
          content,
          props,
          resolve,
        });
      });
    },
    extraService(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("extraService", {
          content,
          props,
          resolve,
        });
      });
    },
    reservationCaution(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("reservationCaution", {
          content,
          props,
          resolve,
        });
      });
    },
    reservationSuccess(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("reservationSuccess", {
          content,
          props,
          resolve,
        });
      });
    },
    reservationAbort(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("reservationAbort", {
          content,
          props,
          resolve,
        });
      });
    },
    timePicker(title, content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("timePicker", {
          title,
          content,
          props,
          resolve,
        });
      });
    },
    paymentFail(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("paymentFail", {
          content,
          props,
          resolve,
        });
      });
    },
    videoPlayer(content, props) {
      return new Promise((resolve) => {
        this.$EventBus.$emit("videoPlayer", {
          content,
          props,
          resolve,
        });
      });
    },
  },
});
