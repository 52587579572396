var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ly_page"},[_c('div',{staticClass:"ly_page_inner"},[_c('header',{staticClass:"ly_header"},[_c('div',{staticClass:"bl_tabUnit_wrapper"},[_c('ul',{staticClass:"bl_tabUnit"},[_vm._m(0),_c('li',{staticClass:"bl_tab",on:{"click":function($event){_vm.logEvent(
                _vm.isLayer
                  ? 'more_membership_join_coupon_menu_list'
                  : 'coupon_menu_list',
                _vm.$router.replace({ name: 'CouponList', query: { isLayer: _vm.isLayer } })
              )}}},[_c('div',{staticClass:"bl_tab_item"},[_vm._v("쿠폰 내역")])])])])]),_c('main',{staticClass:"ly_body"},[_c('div',{staticClass:"bl_coupon_wrapper"},[_c('div',{staticClass:"bl_coupon"},[_c('h4',{staticClass:"bl_coupon_ttl"},[_vm._v("쿠폰 번호 입력")]),_c('div',{staticClass:"bl_coupon_input"},[_c('div',{staticClass:"el_inputDefault",class:{ is_acValid: _vm.onValidate }},[_c('input',{staticClass:"el_inputDefault_input",attrs:{"placeholder":"쿠폰 번호를 입력해주세요.","type":"text","maxlength":_vm.couponCodeMaxLength},domProps:{"value":_vm.couponCodeInput},on:{"click":function($event){return _vm.logEvent(
                    _vm.isLayer
                      ? 'more_membership_coupon_code_input'
                      : 'coupon_register_code_input'
                  )},"input":_vm.onCouponCodeInput}}),_c('div',{staticClass:"el_inputDefault_valid"},[_vm._v(_vm._s(_vm.validateMsg))])])]),_c('div',{staticClass:"bl_coupon_btn"},[_c('button',{staticClass:"el_btnDefault is_moConfirm",attrs:{"disabled":_vm.missingRequirements},on:{"click":function($event){return _vm.logEvent(
                  _vm.isLayer
                    ? 'more_membership_coupon_register_code_register'
                    : 'coupon_register_code_register',
                  _vm.submitCouponCode
                )}}},[_c('div',{staticClass:"el_btnDefault_txt"},[_vm._v("쿠폰 등록")])])])])])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"bl_tab"},[_c('div',{staticClass:"bl_tab_item is_ac"},[_vm._v("쿠폰 등록")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"ly_footer"},[_c('div',{staticClass:"bl_caution_wrapper"},[_c('div',{staticClass:"bl_caution"},[_c('h4',{staticClass:"bl_caution_ttl"},[_vm._v("꼭 읽어주세요!")]),_c('ul',{staticClass:"bl_caution_descUnit"},[_c('li',{staticClass:"bl_caution_desc"},[_vm._v(" 쿠폰 정상 등록 시, 라운드 이용권이 지급됩니다. ")]),_c('li',{staticClass:"bl_caution_desc"},[_vm._v(" 쿠폰 번호를 입력하여 교환 받은 라운드 이용권은 "),_c('em',{staticClass:"bl_caution_desc_nowrap"},[_vm._v("[더보기 – 내 이용권]")]),_vm._v(" 메뉴에서 확인 가능합니다. ")]),_c('li',{staticClass:"bl_caution_desc"},[_vm._v(" 쿠폰 번호는 중복 사용이 불가능합니다. ")]),_c('li',{staticClass:"bl_caution_desc"},[_vm._v(" 유효 기간 만료 이후 쿠폰 사용은 불가능합니다. ")])])])])])}]

export { render, staticRenderFns }