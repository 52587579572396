<template>
  <div class="cp_btn" :class="disabled ? 'is_acDisabled' : ''" @click="click">
    <div class="cp_btn_obj">
      <div class="cp_obj_txt">
        <slot />
      </div>
      <div class="cp_obj_box"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NmButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(event) {
      if (this.disabled) return;
      this.$emit("click", event);
    },
  },
};
</script>