<template>
  <li class="bl_join_info">
    <div class="bl_join_info_profile">
      <button class="bl_join_info_profile_btn">
        <div class="bl_join_info_profile_btn_photo">
          <div
            class="bl_join_info_profile_btn_photo_photo"
            :style="{ 'background-image': `url('${user.profileImageUrl}')` }"
          ></div>
        </div>
        <div class="bl_join_info_profile_btn_nick">
          {{ user.nickname || "닉네임없음" }}
        </div>
      </button>
    </div>
    <div class="bl_join_info_add">
      <!-- MY 단골 활성: is_acMy -->
      <button
        :class="{ is_acMy: user.followYn === 'Y' }"
        @click.stop="toggleFollow(user)"
        class="bl_join_info_add_btn"
      >
        MY 단골
      </button>
    </div>
  </li>
</template>

<script>
import { boardApi } from "@/api";
import { showToast } from "@/util/webview";

export default {
  name: "InviteProfile",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async toggleFollow() {
      if (this.user.followYn === "Y") {
        this.user.followYn = "N";
      } else if (this.user.followYn === "N") {
        this.user.followYn = "Y";
      }
      await this.updateFollow();
    },
    async updateFollow() {
      if (this.user.followYn === "Y") {
        await boardApi.createFollow(this.user.uid);
        showToast({ message: "MY 단골에 추가했습니다" });
        return;
      } else if (this.user.followYn === "N") {
        await boardApi.deleteFollow(this.user.uid);
        showToast({ message: "MY 단골에서 삭제했습니다" });
        return;
      }
    },
  },
};
</script>
