<template>
  <div class="lyPage">
    <main class="lyBody" :style="{ paddingBottom: mainPaddingBottom }">
      <section class="stPost">
        <div class="cmPost">
          <ul class="cmPost-list">
            <community-post
              v-if="board"
              :key="board.boardId"
              :board="board"
              :is-post="true"
              @deletePost="onDeletePost"
            />
          </ul>
        </div>
      </section>
      <section class="stComment">
        <div class="cmComment">
          <ul class="cmComment-list" ref="scrollList">
            <community-comment
              v-for="comment in comments"
              :key="comment.commentId"
              :comment="comment"
              :ref="`commentRef_${comment.commentId}`"
              @deleteComment="onDeleteComment"
              @setReplyInfo="onSetReplyInfo"
              @updateHighlight="targetHighlightEvent"
            />
          </ul>
        </div>
      </section>
    </main>
    <footer>
      <div class="comment-statusbar" ref="commentStatusbar" v-if="commentType">
        <strong>{{ targetNickname }}</strong
        >님에게 답글을 작성 중이에요
        <i class="cmSearch-inputClear" @click="clearReplyInfo"> 지우기 </i>
      </div>
      <div class="comment">
        <div class="cmCommentInput" :class="{ on: passRequirement }">
          <textarea
            class="cmInput-input"
            ref="commentInput"
            placeholder="댓글을 입력해주세요"
            @input="onCommentInput"
            :value="commentInputValue"
            @keyup="textAreaAdjust"
            @keydown="textAreaAdjust"
            @scroll="textAreaScroll"
            @click="logEvent('community_board_comment_input_click')"
            :style="{
              height: `${textareaHeight}px`,
              textIndent: `${
                commentType === 'reply' && targetNickname
                  ? mentionIndent + 10
                  : 0
              }px`,
            }"
          />
          <div
            class="comment-mention"
            ref="mention"
            :style="{ top: `${-mentionTop + 10}px` }"
            v-if="commentType === 'reply'"
          >
            {{ targetNickname }}
          </div>
          <i class="cmComment-inputSubmit" @click="onCommentSubmit">댓글달기</i>
        </div>
        <!-- '0'이 아닐때 상태 : is-stateNonzero -->
        <div
          v-if="commentInputValue.length"
          class="cmTextarea-limit"
          :class="{ 'is-stateNonzero': !commentInputValue.length }"
        >
          <strong class="cmTextarea-limitStrong">{{
            commentInputValue.length | comma
          }}</strong
          >/500
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import CommunityPost from "@/views/community/components/CommunityPost";
import CommunityComment from "@/views/community/components/CommunityComment";
import { boardApi, pointApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dateMixin from "@/mixins/dateMixin";
import { comma } from "@/util/string";
import { closeWebviewLayer } from "@/util/webview";

const COMMENT_MAX_LENGTH = 500;

export default {
  name: "CommunityView",
  components: {
    CommunityPost,
    CommunityComment,
  },
  mixins: [scrollMixin, dateMixin],
  async created() {
    this.boardId = this.$route.params.boardId;
    await this.getBoard();
    await this.setData();
    window.communityTabActivated = () => {
      this.reInitCommentList();
    };
  },
  mounted() {
    this.addScrollEvent();
    this.textAreaAdjust();
    if (this.$route.query.target_type && this.$route.query.target_id) {
      window.addEventListener("scroll", this.targetHighlightEvent);
    }
  },
  data() {
    return {
      boardId: "",
      board: null,
      comments: null,
      commentInputValue: "",
      lastId: undefined,
      textareaHeight: 0,
      tempTextareaHeight: 0,
      commentStatusbarHeight: 0,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      targetInfo: null,
      mentionIndent: 0,
      mentionTop: 0,
      commentType: "",
    };
  },
  methods: {
    closeWebviewLayer,
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content: comments, last },
        } = await boardApi.getBoardCommentList(this.boardId, {
          page: this.pageIndex,
          lastId: this.lastId,
          size: this.pageSize,
        });
        this.comments = this.comments || [];
        this.comments = [...this.comments, ...comments];
        this.lastId = comments[comments.length - 1]?.commentId;
        this.last = last;
      } finally {
        this.isFetching = false;
        await this.$nextTick();
        this.targetHighlightEvent();
      }
    },
    async getBoard() {
      try {
        const { data: board } = await boardApi.getBoard(this.boardId);
        this.board = board;
      } catch (e) {
        if (!e?.code) {
          return;
        }
        switch (e.code) {
          case "5901":
            closeWebviewLayer();
            return;
          default:
            return;
        }
      }
    },
    onCommentInput(e) {
      this.commentInputValue = e.target.value.slice(0, COMMENT_MAX_LENGTH);
      e.target.value = this.commentInputValue;
    },
    async onCommentSubmit() {
      if (!this.passRequirement) {
        return;
      }
      if (!this.targetInfo) {
        await this.addComment();
      } else {
        await this.addReply();
      }
    },
    async addComment() {
      const comment = this.commentInputValue;
      this.commentInputValue = "";
      const result = await boardApi.createBoardComment(this.boardId, {
        comment,
      });
      if (result?.data?.firstRegisterYn === "Y") {
        const {
          data: { point },
        } = await pointApi.getEventTypeInfo("SNS_COMMENT");
        alert(`첫 댓글 작성 기념
${comma(point)} 포인트 지급 완료!!

소중한 첫 댓글 남겨 주셔서 감사합니다.
앞으로도 자주 이용해 주세요 ~ 😄`);
      }
      const content = result?.data?.content;
      if (this.comments && this.last && content) {
        this.comments.push(content);
      } else {
        await this.reInitCommentList();
      }
      await this.textAreaAdjust();
      this.clearReplyInfo();
    },
    async addReply() {
      const reply = this.commentInputValue;
      this.commentInputValue = "";
      const {
        data: { commentId, content },
      } = await boardApi.createBoardCommentReply(this.commentId, {
        targetUid: this.commentType === "reply" ? this.targetUid : undefined,
        reply,
      });
      const target = this.comments?.find?.(
        ({ commentId }) => commentId === this.commentId
      );
      if (target) {
        target.replyCnt += 1;
      }
      const [commentComponent] = this.$refs[`commentRef_${commentId}`] || [];
      if (commentComponent) {
        commentComponent.updateReply(content);
      }
      await this.textAreaAdjust();
      this.clearReplyInfo();
    },
    async onDeleteComment(commentId) {
      this.comments = this.comments.filter(
        (comment) => comment.commentId !== commentId
      );
    },
    async reInitCommentList() {
      this.removeScrollEvent();
      this.comments = null;
      this.lastId = undefined;
      this.pageIndex = 1;
      this.last = false;
      await this.getBoard();
      await this.setData();
      this.addScrollEvent();
    },
    async textAreaAdjust(e) {
      this.textareaHeight = 1;
      if (
        e?.type === "keydown" &&
        this.commentInputValue === "" &&
        e?.key === "Backspace"
      ) {
        this.clearReplyInfo();
      }
      await this.$nextTick();
      this.textareaHeight = this.$refs.commentInput?.scrollHeight || 0;
      this.commentStatusbarHeight =
        this.$refs.commentStatusbar?.scrollHeight || 0;
      this.tempTextareaHeight = `${
        Math.min(
          this.textareaHeight +
            this.commentStatusbarHeight +
            (this.commentInputValue.length ? 28 : 0),
          91
        ) + 21
      }px`;
    },
    async textAreaScroll(e) {
      this.mentionTop = e.target.scrollTop;
    },
    async onSetReplyInfo(info) {
      if (!info) {
        this.commentInputValue = "";
      }
      if (info.isComment) {
        this.commentType = "comment";
      } else {
        this.commentType = "reply";
      }
      this.targetInfo = info;
      await this.$nextTick();
      this.mentionIndent = this.$refs?.mention?.clientWidth || 0;
    },
    clearReplyInfo() {
      this.targetInfo = null;
      this.mentionIndent = 0;
      this.commentType = "";
    },
    onDeletePost() {
      closeWebviewLayer();
    },
    targetHighlightEvent() {
      const target = document.querySelector(".is-focus");
      if (!target) {
        return;
      }

      const { top: targetY } = target.getBoundingClientRect();
      if (window.innerHeight - 100 < targetY) {
        return;
      }

      target.classList.add("highlighted");
      window.removeEventListener("scroll", this.targetHighlightEvent);
    },
  },
  computed: {
    passRequirement() {
      return !!this.commentInputValue?.trim();
    },
    mainPaddingBottom() {
      if (!this.commentInputValue || this.textareaHeight === 1) {
        return this.tempTextareaHeight;
      }
      return `${
        Math.min(
          this.textareaHeight +
            this.commentStatusbarHeight +
            (this.commentInputValue.length ? 28 : 0),
          91
        ) + 21
      }px`;
    },
    targetUid() {
      return this.targetInfo?.uid;
    },
    targetNickname() {
      return this.targetInfo?.nickname && `@${this.targetInfo?.nickname}`;
    },
    commentId() {
      return this.targetInfo?.commentId;
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>

<style scoped>
>>> .cmPost-content.is-typeUsed .cmPost-contentObject {
  -webkit-line-clamp: unset;
}
>>> .cmPost-contentObject {
  -webkit-line-clamp: unset;
}
footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
}
.comment {
  display: flex;
  flex-direction: column;
  min-height: 65px;
  max-height: 112px;
  padding: 10px 20px;
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  background-color: #ffffff;
}
.cmCommentInput {
  position: relative;
  overflow: hidden;
}
.cmCommentInput >>> textarea {
  min-height: 44px;
  max-height: 64px;
  padding: 10px 46px 10px 20px;
}
.cmComment-inputSubmit {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIBAMAAACnw650AAAAIVBMVEVHcEypqamoqKipqampqampqamqqqqpqampqampqampqalFHDHIAAAACnRSTlMAktoc7LVYPHQND+tJ1QAAAU5JREFUSMftlTFPwzAQhQ2Bptkq1IkJRjxFFQNiqsSWidkTQqqEOqUDC1OF1J0JsRICLfcrcV2ltc/nuxVVfUuU6NPL+e7prNRBe6gbffkmINkrANwJNsYy8MkhxQKcWoZ5NyBBxQQ6fYs2VqsEM6l2DHyRSD4EXxcUM6sCBgaijVUd22jMRB3IzyASbvhHYNNu3n6QT1jxoOcevyF0jX/jOnofQuf45O7DPIQqZJS5DyUDWaPR+tmgw2lkpKnxTgkjWCLoiDCCcZTpKjIixtvT2AgeiAwYZEQGPBuGRnTAi0ffCI93q2fPKBFwq3JnhMcbVLZt/jwNjZiAx6FpmD1wmwq4r9MOWnLLq4PG3G4ycgeUeoHkeOOi2KWaC/vLz/uKh6b8eDc6Tu8vL8ip/RUXVSu5qFa6eU6kC2OtvoWuxEvMQFOKUPZUq4P+vf4Ae5RdLDOHVtUAAAAASUVORK5CYII=")
    no-repeat center / contain;
  display: block;
  width: 24px;
  height: 100%;
  right: 16px;
  overflow: hidden;
  position: absolute;
  text-indent: -1000rem;
  top: 0;
}
.cmCommentInput.on .cmComment-inputSubmit {
  background: transparent
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIBAMAAACnw650AAAAKlBMVEVHcExOg/9OhP9OhP9NhP9OhP9Og/9OhP9OhP9Og/9Kgv9OhP9Og/9PhP+QC3sVAAAADXRSTlMAHN6XPrOIWurNDXHzzmlc/wAAAWpJREFUSMdjYBgFwxBIz1VbQEAJ4867d+8qEjIGqObuZXxKuLbeBYMbeNQsj71LSBFX6V0YuIrTmBi4mru3cRjjehah5u5FrGpYbO4iAwVsapx7UdTcTcBijMVdNOCAocZNF10NRgiwJN3FAOgBvhrFmBsQ3nU0c1BdbMAOpm6hKpJBUXONgQEcMQGoimzRfQ4W2ICqqBfNIEZwuAvgUWTAwCAEom+ieW4umkG6UAYKqMVi0N0raIqYsRh0twE9wMV6MQwCsdAA+1x4VEANwhK9DCyxaAbdwZrTMlANwp7AuSqRDUKPXjhwRTIIPXoRQABh0N0JeMoAeMLagFuREJ4EjploLuEpB2RxJXBkwApTdAVf4QVTdABf2QQrdgLwKfKFKipgIMJReAtVFgLlF3J6v41fUS2e8gsOOHGXX0gJGVf5hQJ0CUQv3FE3CNU8HFhyLwbgBirSIliJzb17U4CgIkZvB4ZRMOgBAC1RxRGBvq3xAAAAAElFTkSuQmCC")
    no-repeat center / contain;
}

.comment-mention {
  position: absolute;
  top: 10px;
  left: 20px;
  color: #4f84ff;
  font: normal normal 400 14px / 1.43 "Noto Sans KR";
}

.comment-statusbar {
  color: #222;
  font: normal normal 400 14px / 1.43 "Noto Sans KR";
  padding: 10px 20px;
  background-color: #eeeeee;
}
.cmSearch-inputClear {
  display: block;
  height: 20px;
}
>>> .is-focus.highlighted {
  animation: background-fade-highlight 2.5s ease-out;
  background-color: #ffffff;
}

@-webkit-keyframes background-fade-highlight {
  0% {
    background-color: #f2f6f8;
  }
  100% {
    background-color: #ffffff;
  }
}

@keyframes background-fade-highlight {
  0% {
    background-color: #f2f6f8;
  }
  100% {
    background-color: #ffffff;
  }
}
</style>
