export const URL_EXPRESSION =
  /((?:http(?:s)?:\/\/.|www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_+.~#?&//=]*))/gi;

export const HTTP_EXPRESSION = /^http(s)?:\/\//;

/**
 * JSON parse 가능여부 확인.
 * @param str : 글자 stringify 된 JSON
 * @returns {boolean}
 */
export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * 글자 Byte 수 가져오기.
 * @param str : 글자 Byte 수 구할 데이터
 * @returns {number}
 */
export function getByteLength(str) {
  let b, i, c;
  for (
    b = i = 0;
    (c = str.charCodeAt(i++));
    b += c >> 16 ? 4 : c >> 11 ? 3 : c >> 7 ? 2 : 1
  );
  return b;
}

/**
 * 글자 Byte Index 가져오기.
 * @param str : 글자 Byte 수 구할 데이터
 * @param byte : 찾을 Index Byte
 * @returns {number}
 */
export function getByteIndexLength(str, byte = 0) {
  let b, i, c;
  for (
    b = i = 0;
    (c = b <= byte && str.charCodeAt(i++));
    b += c >> 16 ? 4 : c >> 11 ? 3 : c >> 7 ? 2 : 1
  );
  return i - 1;
}

/**
 * 3자리 마다 comma 넣기.
 * @param str : 문자
 * @returns {string}
 */
export function comma(str = "") {
  return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * \n -> <br> 변경
 * @param str : 문자
 * @returns {string}
 */
export function convertNewLine(str = "") {
  return String(str).replace(/\n/gi, "</br>");
}

/**
 * \n이 연속으로 중복되어 있는 경우 하나만 남기고 삭제
 * @param str : 문자
 * @returns {string}
 */
export function removeDuplicateNewLine(str = "") {
  return String(str).replace(/\n$/gm, "");
}

/**
 * 마지막 공백 이후 단어를 삭제
 * @param str : 문자
 * @returns {string}
 */
export function removeLastWord(str) {
  const lastIndexOfSpace = str.lastIndexOf(" ");
  if (lastIndexOfSpace === -1) {
    return str;
  }
  return str.substring(0, lastIndexOfSpace);
}

/**
 * 천,만단위 변환
 * @param count : count
 * @returns {string}
 */
export function formatCount(count) {
  if (count !== 0 && !count) {
    return "";
  }
  if (count < 1000) {
    return `${count}`;
  }
  if (count < 10000) {
    return `${Math.floor(count / 100) / 10}천`;
  }
  if (count < 10000000) {
    return `${Math.floor(count / 1000) / 10}만`;
  }
  return `${comma(Math.floor(count / 10000))}만`;
}

/**
 * 날짜 - 포맷
 * @param 날짜 : 시간
 * @return {string}
 */
export function dateFormatter(text) {
  if (!text) {
    return "";
  }

  if(text.length < 5) {
    return text.replace(/^([\d*]{4})$/, "$1");
  } else if(text.length < 7) {
    return text.replace(/^([\d*]{4})([\d*]*)$/, "$1-$2");
  } else {
    return text.replace(/^([\d*]{4})([\d*]{2})([\d*]*)$/, "$1-$2-$3");
  }
}

/**
 * 전화번호 - 포맷
 * @param text : 전화번호
 * @return {string}
 */
export function phoneNumberFormatter(text) {
  if (!text) {
    return "";
  }

  if (text.startsWith("050")) {
    if (4 < text.length) {
      if (text.length < 9) {
        return text.replace(/^([\d*]{4})([\d*]*)$/, "$1-$2");
      } else if (text.length < 13) {
        return text.replace(/^([\d*]{4})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else if (text.startsWith("02")) {
    if (2 < text.length) {
      if (text.length < 7) {
        return text.replace(/^([\d*]{2})([\d*]*)$/, "$1-$2");
      } else if (text.length < 11) {
        return text.replace(/^([\d*]{2})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
      }
    }
  } else if (3 < text.length) {
    if (text.length < 8) {
      return text.replace(/^([\d*]{3})([\d*]*)$/, "$1-$2");
    } else if (text.length < 12) {
      return text.replace(/^([\d*]{3})([\d*]*)([\d*]{4})$/, "$1-$2-$3");
    }
  }
  switch (text.length) {
    case 9:
      return text.replace(/^([\d*]{2})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 10:
      return text.replace(/^([\d*]{3})([\d*]{3})([\d*]{4})$/, "$1-$2-$3");
    case 11:
      return text.replace(/^([\d*]{3})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
    case 12:
      return text.replace(/^([\d*]{4})([\d*]{4})([\d*]{4})$/, "$1-$2-$3");
  }

  return text;
}

export function getUserRange(birthString) {
  if(!birthString) { 
    return "";
  }
  const year = parseInt(birthString.substring(0, 4), 10);
  const month = parseInt(birthString.substring(4, 6), 10) - 1;
  const day = parseInt(birthString.substring(6, 8), 10);

  // Date 객체 생성
  const birth = new Date(year, month, day);
  const birthYear = birth.getFullYear();
  const birthMonth = birth.getMonth();
  const birthDay = birth.getDate();

  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth();
  const currentDay = current.getDate();

  let age = currentYear - birthYear;

  if (currentMonth < birthMonth) {
    age--;
  } else if (currentMonth === birthMonth && currentDay < birthDay) {
    age--;
  }

  return Math.floor((age / 10)) * 10;
}

export const formatTime = (_seconds) => {
  const minutes = Math.floor(_seconds / 60);
  const seconds = _seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};