<template>
  <input
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="readonly"
    :value="computedValue"
    class="cpInput-input"
    type="number"
    @input="onInput"
    @keypress="onKeypress"
  />
</template>

<script>
import { comma } from "@/util/string";

export default {
  name: "NumberInput",
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    comma: {
      type: Boolean,
      default: () => false,
    },
    maximum: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    maxLength: {
      type: Number,
      default: Number.MAX_VALUE,
    },
    allowDot: {
      type: Boolean,
      default: () => false,
    },
    maxUnderDotLength: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      numberValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.formatGetValue(this.numberValue);
      },
      set(value) {
        this.numberValue = value;
      },
    },
  },
  watch: {
    numberValue(value) {
      value = String(value).replace(/[^0-9.]/g, "");
      if (value === "") {
        this.$emit("input", "");
        return;
      }
      value = this.allowDot ? Number.parseFloat(value) : Number.parseInt(value);
      this.$emit("input", value);
    },
    value(value) {
      this.numberValue =
        value !== ""
          ? Math.min(String(value).substring(0, this.maxLength), this.maximum)
          : "";
    },
  },
  methods: {
    onInput(e) {
      this.computedValue = e.target.value;
      e.target.value = this.computedValue;
    },
    onKeypress(e) {
      const charCode = e.which;
      const selection = e.srcElement.selectionStart;
      if (this.allowDot) {
        if (
          this.numberValue &&
          this.numberValue.toString().includes(".") &&
          charCode === 46
        ) {
          e.preventDefault(); // "." 중복 막기
        }
        const strValue = e.target.value;
        const dotIndex = strValue.indexOf(".");
        const preValueArr = strValue.split(".");
        if (
          dotIndex >= 0 &&
          selection > dotIndex &&
          preValueArr[1].length >= this.maxUnderDotLength
        ) {
          e.preventDefault(); //소수점 이하 자리수 체크해서
        }
      }
    },
    formatComma(str) {
      const strArr = str.split(".");
      if (strArr.length > 1) {
        //소숫점 형식은 소숫점으로 나눠서 정수 부분만 Comma 입력

        const firstNum = comma(strArr[0]);
        const secondNum = strArr[1]
          .replace(/[^\d]+/g, "")
          .slice(0, this.maxUnderDotLength);
        return `${firstNum}.${secondNum}`;
      } else {
        return comma(str);
      }
    },
    formatGetValue(value) {
      value = String(Number.parseInt(value)).replace(/[^0-9.]/g, "");
      return this.comma ? this.formatComma(String(value)) : String(value);
    },
  },
};
</script>
