<template>
  <div class="policy">
    <h1>취소 및 환불 규정</h1>

    <p>
      취소 및 환불 규정은 브이씨소프트㈜의 플랫폼인 APL골프 앱 및 웹 서비스를 통해
      ‘예약 취소’가 접수된 시간을 기준으로 적용합니다.
    </p>
    <h2>예약취소</h2>
    <ol>
      <li>예약 변경을 원하시는 경우, 예약 취소 후 다시 예약을 진행하셔야 합니다.</li>
      <li>결제 실패 건에 대해 최대 3회 재결제를 요청하며, 결제하지 않았을 경우 해당 예약은 자동 취소됩니다.</li>
      <li>예약 취소 시, 탑승시간 48시간 전부터 취소 수수료가 발생합니다.</li>
    </ol>

    <h2>취소 수수료 안내</h2>
    <p>취소 수수료는 쿠폰 할인금액을 제외하지 않은 전체 예약 금액을 기준으로 계산됩니다.</p>
    <ol>
      <li>탑승 예정 시각 48시간 전까지: 취소 수수료 무료</li>
      <li>탑승 예정 시각의 48시간 전을 지나고 24시간 전까지: 전체 예약 금액의 30%</li>
      <li>탑승 예정 시각의 24시간 전을 지나고 탑승 시각까지: 전체 예약 금액</li>
      <li>탑승 당일 취소 및 No-show : 전체 예약 금액</li>
    </ol>

    <h2>환불 규정</h2>
    <p>결제 이후, 예약 취소 일자를 기준으로 환불 규정이 적용됩니다.</p>
    <p>‘취소 수수료 안내’ 기준에 따라, 취소 수수료를 제외한 나머지 금액을 환불합니다.</p>

    <h3>천재지변 또는 기상악화에 의한 예외 규정</h3>
    <p>천재지변 또는 기상악화 등 불가항력적인 원인으로 서비스 이용이 불가한 경우 고객센터를 통해 예외적 취소가 가능합니다.</p>
    <p>단, 천재지변의 영향정도(시설의 현장상황, 교통수단 및 이동경로 등)를 증명하는 자료의 제출을 요청하거나, 재난의 정도에 따라 예약취소 및 환불이 불가할 수 있습니다.</p>

    <h3>항공기 지연 또는 결항에 의한 예외 규정</h3>
    <p>항공기 지연 또는 결항을 증명하는 자료를 고객센터를 통해 제출한 경우에 한하여, 전액 환불해드립니다.</p>

    <h3>사용한 쿠폰의 반환</h3>
    <p>예약 시 쿠폰을 사용한 경우, 취소 수수료 발생 여부와 무관하게 예약취소 시점에 계정으로 반환됩니다.</p>
  </div>
</template>
<script>
export default {
  name: "CancelAndRefund",
};
</script>
<style scoped>
@import "~@/assets/css/nearme/reset.css";
</style>