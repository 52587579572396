<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_intro">
            <div class="bl_intro_ttl">
              <div class="bl_ttl_img"></div>
              <div class="bl_ttl_txt">소개</div>
            </div>
            <ul class="el_reset bl_intro_list">
              <li class="bl_intro_item">
                <div class="bl_intro_obj">
                  <div class="bl_obj_img is_moImg"></div>
                  <div class="bl_obj_txt">
                    합리적인 가격! 편리한 Door to door 서비스
                  </div>
                </div>
              </li>
              <li class="bl_intro_item">
                <div class="bl_intro_obj">
                  <div class="bl_obj_img is_moImg2"></div>
                  <div class="bl_obj_txt">
                    안심하고 목적지까지! 최적의 경로로 편안한 운행
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="bl_info">
            <ul class="el_reset bl_info_list">
              <li class="bl_info_item">
                <div class="bl_info_obj">
                  <div class="bl_obj_ttl is_moAirport"></div>
                  <div class="bl_obj_cont">
                    <div class="bl_cont_img is_moAirport"></div>
                    <div class="bl_cont_ttl">공항 픽업 서비스</div>
                    <div class="bl_cont_desc">
                      도어 to 공항 : 스마트 셔틀, 최대 9인까지<br />
                      공항부터 도심까지 편안하게!
                    </div>
                    <div class="bl_cont_desc2">
                      합리적인 가격으로 원하는 목적지까지<br />
                      이동하고자 하는 고객님께 추천드려요!
                    </div>
                    <div class="bl_cont_btn">
                      <nm-button
                        class="is_moSeco"
                        @click="goToAirportReservation"
                        >공항 픽업 예약하기</nm-button
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li class="bl_info_item">
                <div class="bl_info_obj">
                  <div class="bl_obj_ttl is_moGolf"></div>
                  <div class="bl_obj_cont">
                    <div class="bl_cont_img is_moGolf"></div>
                    <div class="bl_cont_ttl">골프장 픽업 서비스</div>
                    <div class="bl_cont_desc">
                      도어 to 골프장 : 전세 택시, 최대 4인까지<br />
                      골프백, 캐리어도 OK
                    </div>
                    <div class="bl_cont_desc2">
                      라운드 동반자와 함께 골프장까지<br />
                      이동하고자 하는 고객님께 추천드려요!
                    </div>
                    <div class="bl_cont_btn">
                      <nm-button class="is_moPrim" @click="goToClubReservation"
                        >골프장 픽업 예약하기</nm-button
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="bl_btn">
          <ul class="el_reset bl_btn_list">
            <li class="bl_btn_item">
              <nm-button class="is_moSeco" @click="goToAirportReservation"
                >공항 픽업 예약</nm-button
              >
            </li>
            <li class="bl_btn_item">
              <nm-button class="is_moPrim" @click="goToClubReservation"
                >골프장 픽업 예약</nm-button
              >
            </li>
          </ul>
        </div>
        <div class="bl_consult" @click="openConsultLayer"></div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { nearmeApi } from "@/api";
import { preventBackButton, newWebviewLayerV2 } from "@/util/webview";
import NmButton from "@/components/nearme/NmButton.vue";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import reservationMixin from "@/mixins/reservationMixin";
const MAX_RESERVATION_COUNT = 5;
export default {
  components: { DefaultLayout, NmButton },
  name: "MainView",
  mixins: [reservationMixin],
  data() {
    return {
      airportReservationCount: 0,
      clubReservationCount: 0,
    };
  },
  async created() {
    window.tabActivated = () => {
      this.resetReservationInfo();
    };

    await this.setData();
    this.resetReservationInfo();
  },
  methods: {
    async setData() {
      const {
        data: { airportReservationCount },
      } = await nearmeApi.getReservationCount();
      this.airportReservationCount = airportReservationCount;

      const {
        data: { golfReservationCount },
      } = await nearmeApi.getReservationCount();
      this.clubReservationCount = golfReservationCount;
    },
    goToAirportReservation() {
      if (this.airportReservationCount >= MAX_RESERVATION_COUNT) {
        alert("예약은 최대 5회까지만 가능해요");
        return;
      }
      // 공항 픽업 예약
      newWebviewLayerV2({ name: "AirportReservation" });
    },
    goToClubReservation() {
      if (this.clubReservationCount >= MAX_RESERVATION_COUNT) {
        alert("예약은 최대 5회까지만 가능해요");
        return;
      }
      // 골프장 픽업 예약
      newWebviewLayerV2({ name: "ClubReservation" });
    },
    async openConsultLayer() {
      await preventBackButton({
        isBackPrevent: true,
      });
      // 고객 문의 레이어 활성
      this.consultLayer();
    },
  },
};
</script>