<template>
  <div class="bl_map" v-show="visible && renderedMap">
    <div class="bl_map_inner">
      <!-- 지도 API -->
      <div ref="routeMap" id="route-map"></div>
      <div class="bl_map_info">
        <div class="bl_info_ico"></div>
        <div class="bl_info_time">{{ durationTime }}</div>
        <div class="bl_info_dot"></div>
        <div class="bl_info_dst">{{ distance }}km</div>
      </div>
      <div class="layover" />
    </div>
  </div>
</template>
<script>
import loader from "@/util/googleMaps";

export default {
  name: "NmRouteMap",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: Object,
    },
    destination: {
      type: Object,
    },
    reverse: {
      type: Boolean,
    },
  },
  data() {
    return {
      routeMap: null,
      directionsService: null,
      directionsRenderer: null,
      distance: "",
      durationTime: "",
      google: null,
      departureImageBase64:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAACECAYAAABPjbMUAAAAAXNSR0IArs4c6QAABKhJREFUeF7t3LFKHEEcx/GZtVALNSB62t0JogZ8hgQLS0llZZHIPYDgcwg+gBgLK6tgaSHJI4iNEvCuineKELVQC3fCrM6xXvbkp9h48xVCzN5fyXzv48zeFVpT8DG1fLroL9vELtjUzNrEjBTNca07CrjUnLvEHLrU7foVHW+O77SvzOYvVKrNUq9xa4kxc92RgFW8pkBqzP6dsau1jVIzfH0LikfSl7o9do/XpO2+r/G7zG1i5wOWDEoRktHhwWz1QwP93VeBFf1X4PL6Jrt2dnHVeiyPJYMyXW1s548bjwQgcWryYPJY/DF0tDG2ZP2Na09i10MWkMQJJL/qdiz3qVt5AgUkIAkF8lgyKPljByhAKYLijx87s9w4CK90JsslSlGgVeB3/eHVsb+ptR+rjT/hEaCgJF8gQPHXgIKNjgWAAg6pAFCkTAwBBQNSAaBImRgCCgakAkCRMjEEFAxIBYAiZWIIKBiQCgBFysQQUDAgFQCKlIkhoGBAKgAUKRNDQMGAVAAoUiaGgIIBqQBQpEwMAQUDUgGgSJkYAgoGpAJAkTIxBBQMSAWAImViCCgYkAoARcrEEFAwIBUAipSJIaBgQCoAFCkTQ0DBgFQAKFImhoCCAakAUKRMDAEFA1IBoEiZGAIKBqQCQJEyMQQUDEgFgCJlYggoGJAKAEXKxBBQMCAVAIqUiSGgYEAqABQpE0NAwYBUAChSJoaAggGpAFCkTAwBBQNSAaBImRgCCgakAkCRMjEEFAxIBYAiZWIIKBiQCgBFysQQUDAgFQCKlIkhoGBAKgAUKRNDQMGAVAAoUiaGgIIBqQBQpEwMAQUDUgGgSJkYAgoGpAJAkTIx9ATKzHLjwCZmxGeZLJeoQ4FWgQDFpebcTlcb24kxc/7R0eFBMzTQTyoKmMvrG3N2cZWVSI3Zt1PLp4s9iV0HCjryBfJQ7lO38gQKWMDiC+SR+H9nUPwn+eMHLHFjaUfij52jjbGlDEql2iz1pW4v3NQGLP5v7lnigOOB+I9wX+I/9zext4mdr22UmhmUTljiSMQqiwrkkfjHW1ACll7j1sKrIBLGWcAfN3fGrvqdJBR4AiVc9K+EMkWJXbCpmc0fSXGm6+5V+93DJebQpW7Xr/R4c3ynfcWFULo7S/HqJiYmXNEjJycnNGo/emIEEtYMlOeffX5aHvsABSjSRgkUoABFKgAUKRM7ClCAIhUAipSJHQUoQJEKAEXKxI4CFKBIBYAiZWJHAQpQpAJAkTKxowAFKFIBoEiZ2FGAAhSpAFCkTOwoQAGKVAAoUiZ2FKAARSoAFCkTOwpQgCIVAIqUiR0FKECRCgBFysSOAhSgSAWAImViRwEKUKQCQJEysaMABShSAaA8W6BcLn+w1v6w1n4qGnTO/XLOfanX63/foPe7/RbR/zaDSqXysxOS8Kx6LLVa7fO7fZbf4D8eNZRyufw1SZLvSsc0Tb/V6/UtZbYbZ6KGouwm7CoPBaKG0umVTqcdIeZf0wWUF5wTQHlBrG4a5ejRn82odxRuZoEiF1B2FV4eR34z6zXxhpv2MxX10ZNP5I8ha63/k71D+/iO7FbM753k+/wD2m8sYsIqyaYAAAAASUVORK5CYII=",
      arrivalImageBase64:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIoAAACEBAMAAAC9o64nAAAAHlBMVEUzMzMRERFHcEwkJCQZGRkNDQ0PDw8YGBgQEBAiIiIJliYoAAAACXRSTlPm5gDosxXRSnkaRDKwAAAA4klEQVRo3u3ZvQ3CMBAF4ItYgBORSW0SpQUyQSIhoI0QvQsGIGIBECPQeFucLBCJewXFe7X16fxTPYv39Tvo73GnwXvxVau25H1SrmrNzUsVtBFLGnW9lLoSW3bayV23RmWha3laR0nDFNIClFzC0qxkThSgKBUqVKhQoUKFChUqVKhQoUKFChUqVCBKACgO1EBi2lBMM2tviTN9gRprTHtub/KLscnH/CrMJcY4u4YKFSpUqFChQgWiXJJyMCvnpHysSh3HDEalnJTOqGwmZf8XCmZHmNPF3DTo1flHc5xd8wUmfP2LdaSveQAAAABJRU5ErkJggg==",
      markers: null,
    };
  },
  created() {
    this.markers = [];
  },
  async mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.routeMap = null;
    this.directionsService = null;
    this.directionsRenderer = null;
    this.initData();
    window.initMap = null;
  },
  computed: {
    visible() {
      this.initData();
      return this.value;
    },
    renderedMap() {
      if (this.routeMap) {
        this.calculateAndDisplayRoute();
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    reverse() {
      if (this.routeMap) {
        this.calculateAndDisplayRoute();
      }
    },
  },
  methods: {
    async initMap() {
      const { Map } = await loader.importLibrary("maps");
      const { DirectionsService, DirectionsRenderer } = await loader.importLibrary("routes");
      const position = { lat: 35.6585805, lng: 139.7454329 }; // 지도 임시 위치 지정
      this.directionsService = new DirectionsService();
      this.directionsRenderer = new DirectionsRenderer({
        suppressMarkers: true,
      });

      this.routeMap = new Map(this.$refs.routeMap, {
        center: position,
        disableDefaultUI: true,
        clickableIcons: false,
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        clickable: false,
        mapId: "cfdf8f80bd0cfc97",
      });
      this.createMarker(
        { lat: 35.6585848, lng: 139.742858 }, // 마커 임시 위치 지정
        this.departureImageBase64,
        "출발"
      );
      this.createMarker(
        { lat: 35.7701823, lng: 140.3817466 }, // 마커 임시 위치 지정
        this.arrivalImageBase64,
        "도착"
      );
      this.directionsRenderer.setMap(this.routeMap);
    },
    initData() {
      this.distance = "";
      this.durationTime = "";
    },
    async calculateAndDisplayRoute() {
      if(!this.origin?.lat || !this.origin?.lng || !this.destination?.lat || !this.destination?.lng) return;

      const { TravelMode } = await loader.importLibrary("routes");
      this.directionsService
        ?.route({
          origin: this.origin,
          destination: this.destination,
          travelMode: TravelMode.DRIVING,
        })
        .then((response) => {
          this.durationTime = "";
          this.markers[0].setPosition(response.routes[0]?.legs[0]?.start_location);
          this.markers[1].setPosition(response.routes[0]?.legs[0]?.end_location);
          this.directionsRenderer?.setDirections(response);
          const distance = this.getTotalDistance();
          const duration = this.getTotalDuration();
          const hours = Math.floor(duration / 3600);
          const minutes = Math.floor((duration % 3600) / 60);

          this.distance = (distance / 1000).toFixed(1);
          if (hours > 0) {
            this.durationTime += `${hours}시간`;
          }
          if (minutes > 0) {
            if (this.durationTime !== "") {
              this.durationTime += ", ";
            }
            this.durationTime += `${minutes}분`;
          }
        })
        .catch((e) => {
          alert("Directions request failed due to " + e.status);
        });
    },
    getTotalDuration() {
      const directionsResult = this.directionsRenderer?.getDirections();
      const route = directionsResult?.routes[0];
      let totalDuration = 0;
      const legs = route.legs;
      for (let i = 0; i < legs.length; ++i) {
        totalDuration += legs[i].duration.value;
      }
      return totalDuration;
    },
    getTotalDistance() {
      const directionsResult = this.directionsRenderer?.getDirections();
      const route = directionsResult?.routes[0];
      let totalDistance = 0;
      const legs = route.legs;
      for (let i = 0; i < legs.length; ++i) {
        totalDistance += legs[i].distance.value;
      }
      return totalDistance;
    },
    async createMarker(latLng, imageBase64, label) {
      const { Marker } = await loader.importLibrary("marker");
      const { Size, Point } = await loader.importLibrary("core");
      this.markers?.push(
        new Marker({
          icon: {
            url: imageBase64,
            scaledSize: new Size(46, 44),
            origin: new Point(0, 0),
            labelOrigin: new Point(23, 15),
          },
          label: {
            color: "#fff",
            fontSize: "14px",
            text: label,
          },
          position: latLng,
          map: this.routeMap,
        })
      );
    },
  },
};
</script>
<style scoped>
#route-map {
  width: 100%;
  height: 100%;
}

.layover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.label-position {
  position: absolute;
  left: calc(50% - 2.3rem);
  top: calc((100% - 23.8rem) / 2 - 4.4rem);
}
</style>