<template>
  <div id="googleMap" ref="map"></div>
</template>

<script>
import { initGoogleMap } from "@/util/googleMap";
import {
  Cluster,
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { updateClubList } from "@/util/webview";
import { clubApi } from "@/api";
import { mapGetters } from "vuex";
import Hangul from "hangul-js";

const SOUTH_KOREA_BOUNDS = {
  north: 38.86,
  south: 32.46,
  west: 125.46,
  east: 129.84,
};
const DEFAULT_ZOOM = 7;

const CLUSTER_BACKGROUND_COLOR = "#ffffff";
const CLUSTER_COLOR = "#222222";
const CLUSTER_FONT_WEIGHT = "700";
const SELECTED_CLUSTER_BACKGROUND_COLOR = "#000000";
const SELECTED_CLUSTER_COLOR = "#ffffff";
const SELECTED_CLUSTER_FONT_WEIGHT = "500";

export default {
  name: "ClubMap",
  created() {
    window.setMapFilter = this.setMapFilter;
  },
  async mounted() {
    this.setUserLocation();
    this.intervalId = window.setInterval(this.setUserLocation, 5000);
    initGoogleMap();
    await Promise.all([
      new Promise((resolve) => {
        window.onInitGoogleMap = resolve;
      }),
      this.setGolfClubList(),
    ]);
    this.setMap();
  },
  beforeDestroy() {
    window.clearInterval(this.intervalId);
  },
  data() {
    return {
      map: null,
      mapCenter: { lat: 36.0081479, lng: 127.7896811 },
      userLocation: null,
      clubList: null,
      superCluster: null,
      customCluster: null,
      boundsClubList: [],
      positionList: {
        82031: {
          lat: 37.2892903,
          lng: 127.0534814,
        },
        82033: {
          lat: 37.3707415,
          lng: 128.390338,
        },
        82041: {
          lat: 36.66018,
          lng: 126.6733654,
        },
        82043: {
          lat: 36.6358093,
          lng: 127.4913338,
        },
        82054: {
          lat: 36.5760207,
          lng: 128.5055956,
        },
        82055: {
          lat: 35.2382905,
          lng: 128.692398,
        },
        82061: {
          lat: 34.8162186,
          lng: 126.4629242,
        },
        82063: {
          lat: 35.8197638,
          lng: 127.1081298,
        },
        82064: {
          lat: 33.4888341,
          lng: 126.4980797,
        },
      },
      keyword: null,
      stateCodes: null,
      hashtags: null,
      clubInfos: null,
      selectedClusterClubIdxList: null,
      intervalId: null,
      isAllClubShow: true,
    };
  },
  methods: {
    setUserLocation() {
      try {
        const { lat, lon: lng } = JSON.parse(
          localStorage.getItem("geolocation")
        );
        if (!this.userLocation || this.getDistance({ lat, lng }) > 300) {
          this.userLocation = { lat, lng };
          this.setClusterMakers();
        }
      } catch {
        this.userLocation = null;
        console.log("geolocation parse Error.");
      }
    },
    updateClubList(clubList) {
      updateClubList({
        clubList: clubList.map(
          ({ ccode, clubLat: lat, clubLon: lon, reviewCount, roundCount }) => ({
            ccode,
            lat,
            lon,
            reviewCount,
            roundCount,
          })
        ),
      });
    },
    async setMapFilter(filters) {
      if (filters) {
        await this.$store.dispatch("setFilters", filters);
      }
      try {
        const { keyword, stateCodes, clubInfos, hashtags } =
          this.mapFilters || "{}";

        this.keyword = keyword;
        this.stateCodes = stateCodes || [];
        this.clubInfos = clubInfos || [];
        this.hashtags = hashtags || [];
        this.map?.setCenter(this.mapCenter);
        this.map?.setZoom(DEFAULT_ZOOM);
        this.isAllClubShow = true;

        this.updateClubList(this.filteredBoundsClubList);
        this.setClusterMakers();
      } catch (e) {
        console.log(e);
        console.log("filters parse Error.");
      }
    },
    async setGolfClubList() {
      const storageClubList = this.getStorage("mapClubList");
      if (storageClubList) {
        this.clubList = storageClubList;
        return;
      }
      const { data } = await clubApi.getMapClubList();
      this.clubList = data
        .filter((club) => club.address && club.ccode.startsWith("82"))
        .map((club) => ({
          ...club,
          stateCode: this.getClubState(club.address),
        }));
      this.setStorage("mapClubList", this.clubList, 1 / 48);
    },
    setMap() {
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: this.mapCenter, //center로 할 위도, 경도를 지정한다.
        zoom: DEFAULT_ZOOM, //zoom size를 지정.
        maxZoom: 20,
        minZoom: 3,
        keyboardShortcuts: false,
        disableDefaultUI: true,
        clickableIcons: false,
        restriction: {
          latLngBounds: SOUTH_KOREA_BOUNDS,
          strictBounds: false,
        },
      });
      this.map.addListener("idle", () => {
        const bounds = this.map.getBounds();
        this.boundsClubList = this.clubList.filter(
          (club) =>
            bounds.contains({
              lat: club.clubLat,
              lng: club.clubLon,
            }) === true
        );
        this.selectedClusterClubIdxList = null;

        this.updateClubList(this.filteredBoundsClubList);
        this.setClusterMakers();
        this.isAllClubShow = false;
      });
      this.clubPins();
      this.setMapFilter();
    },
    clusterMakerRenderer({ count, position, markers }) {
      const isSelected = this.selectedClusterClubIdxList?.includes(
        this.clubMarkerList.findIndex((_marker) => _marker === markers[0])
      );

      const backgroundColor = isSelected
        ? SELECTED_CLUSTER_BACKGROUND_COLOR
        : CLUSTER_BACKGROUND_COLOR;
      const color = isSelected ? SELECTED_CLUSTER_COLOR : CLUSTER_COLOR;
      const fontWeight = isSelected
        ? SELECTED_CLUSTER_FONT_WEIGHT
        : CLUSTER_FONT_WEIGHT;
      const borderColor = "#999999";

      const size = count < 100 ? 90 : 120;
      const svg = window.btoa(`
            <svg fill="${backgroundColor}" stroke="${borderColor}" stroke-width="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
              <circle cx="120" cy="120" opacity=".8" r="${size}" />
            </svg>`);
      return new window.google.maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new window.google.maps.Size(80, 80),
          anchor: new window.google.maps.Point(40, 40),
        },
        label: {
          text: String(count),
          fontFamily: "Gmarket Sans",
          fontSize: "14px",
          fontWeight,
          color,
        },
        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
    getClubState(address) {
      if (
        address.includes("경기") ||
        address.includes("서울") ||
        address.includes("인천")
      ) {
        return "82031";
      }
      if (address.includes("강원")) {
        return "82033";
      }
      if (address.includes("충남") || address.includes("대전")) {
        return "82041";
      }
      if (address.includes("충북") || address.includes("세종")) {
        return "82043";
      }
      if (address.includes("경북") || address.includes("대구")) {
        return "82054";
      }
      if (
        address.includes("경남") ||
        address.includes("울산") ||
        address.includes("부산")
      ) {
        return "82055";
      }
      if (address.includes("전남") || address.includes("광주")) {
        return "82061";
      }
      if (address.includes("전북")) {
        return "82063";
      }
      if (address.includes("제주")) {
        return "82064";
      }
      return "0";
    },
    clubPins() {
      this.customCluster = new MarkerClusterer({
        map: this.map,
        markers: this.clubMarkerList,
        renderer: {
          render: this.clusterMakerRenderer,
        },
        algorithm: {
          calculate: ({ markers }) => {
            const clusters = markers.reduce((acc, marker, index) => {
              const stateCode = this.filteredBoundsClubList[index]?.stateCode;
              if (acc[stateCode]) {
                acc[stateCode].push(marker);
              } else {
                acc[stateCode] = new Cluster({
                  markers: [marker],
                  position: this.positionList[stateCode],
                });
              }
              return acc;
            }, {});
            return {
              clusters: Object.values(clusters),
            };
          },
        },
        onClusterClick: this.onClusterClick,
      });
      this.superCluster = new MarkerClusterer({
        map: this.map,
        markers: this.clubMarkerList,
        renderer: {
          render: this.clusterMakerRenderer,
        },
        algorithm: new SuperClusterAlgorithm({
          radius: 200,
        }),
        onClusterClick: this.onClusterClick,
      });

      this.setClusterMakers();
    },
    onClusterClick(event, cluster) {
      this.selectedClusterClubIdxList = [...cluster.markers].map((marker) =>
        this.clubMarkerList.findIndex((_marker) => _marker === marker)
      );
      this.setClusterMakers();

      this.updateClubList(
        this.selectedClusterClubIdxList.map(
          (index) => this.filteredBoundsClubList[index]
        )
      );
    },
    setClusterMakers() {
      this.superCluster?.clearMarkers?.();
      this.customCluster?.clearMarkers?.();
      if (this.map?.zoom > DEFAULT_ZOOM) {
        this.superCluster?.addMarkers?.(this.clubMarkerList);
      } else {
        this.customCluster?.addMarkers?.(this.clubMarkerList);
      }
    },
    setStorage(key, value, days) {
      localStorage.setItem(key, JSON.stringify(value));
      if (days) {
        localStorage.setItem(
          `${key}_expiresIn`,
          Date.now() + 24 * 60 * 60 * 1000 * days
        );
      }
    },
    getStorage(key) {
      try {
        const expiresIn = localStorage.getItem(`${key}_expiresIn`);
        if (expiresIn < Date.now()) {
          return null;
        }
        return JSON.parse(localStorage.getItem(key));
      } catch {
        return null;
      }
    },
    parseMeter(distance) {
      if (distance === "-") {
        return "-km";
      }
      if (distance < 1000) {
        return `${Math.floor(distance)}m`;
      }
      if (distance < 10000) {
        return `${Math.floor(distance / 100) / 10}km`;
      }
      return `${Math.floor(distance / 1000)}km`;
    },
    rad(x) {
      return (x * Math.PI) / 180;
    },
    getDistance({ lat, lng }) {
      if (!this.userLocation) {
        return "-";
      }
      const { lat: uLat, lng: uLng } = this.userLocation;
      const R = 6378137; // Earth’s mean radius in meter
      const dLat = this.rad(lat - uLat);
      const dLong = this.rad(lng - uLng);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(uLat)) *
          Math.cos(this.rad(lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    copyTwoPossibilities(array) {
      const wordIndices = array.reduce((indices, element, index) => {
        return element === "ㅔ" || element === "ㅐ"
          ? [...indices, index]
          : indices;
      }, []);

      return Array.from({ length: Math.pow(2, wordIndices.length) }, (_, i) => {
        const copy = array.slice();
        const binary = i.toString(2).padStart(wordIndices.length, "0");
        wordIndices.forEach((idx, j) => {
          copy[idx] = binary[j] === "0" ? "ㅔ" : "ㅐ";
        });
        return copy;
      });
    },
    keywordFilter(club) {
      const keyword = this.keyword?.replace(/ /g, "") || "";
      const clubName =
        (/^[A-Za-z]/.test(keyword) ? club.clubNameEng : club.clubName) || "";
      return this.copyTwoPossibilities(
        Hangul.disassemble(keyword.toLowerCase())
      ).find((possibility) =>
        clubName
          .replace(/ /g, "")
          .toLowerCase()
          .includes(Hangul.assemble(possibility))
      );
    },
  },
  computed: {
    ...mapGetters({
      mapFilters: "filters",
    }),
    clubListFilters() {
      const filters = [];
      if (this.keyword) {
        filters.push(this.keywordFilter);
      }
      if (this.stateCodes?.length) {
        filters.push((club) =>
          this.stateCodes.find((s) => s === club.stateCode.slice(2, 5))
        );
      }
      if (this.clubInfos?.length) {
        filters.push((club) =>
          this.clubInfos.find((info) => info === club.clubInfo)
        );
      }
      if (this.hashtags?.length) {
        this.hashtags.forEach((code) => {
          filters.push((club) => club.hashtagCodes.includes(code));
        });
      }
      return filters;
    },
    filteredBoundsClubList() {
      return this.clubListFilters.reduce(
        (acc, filter) => acc.filter(filter),
        this.isAllClubShow ? this.clubList : this.boundsClubList
      );
    },
    clubMarkerList() {
      return (
        this.filteredBoundsClubList?.map((club, i) => {
          const isSelected = this.selectedClusterClubIdxList?.includes(i);

          const backgroundColor = isSelected
            ? SELECTED_CLUSTER_BACKGROUND_COLOR
            : CLUSTER_BACKGROUND_COLOR;
          const color = isSelected ? SELECTED_CLUSTER_COLOR : CLUSTER_COLOR;
          const fontWeight = isSelected
            ? SELECTED_CLUSTER_FONT_WEIGHT
            : CLUSTER_FONT_WEIGHT;

          const svg = window.btoa(`
            <svg fill="${backgroundColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
              <rect x="0" y="35" width="120" height="50" opacity=".8" rx="25" />
            </svg>`);

          const marker = new window.google.maps.Marker({
            position: {
              lat: club.clubLat,
              lng: club.clubLon,
            },
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(80, 80),
              anchor: new window.google.maps.Point(40, 40),
            },
            label: {
              text: this.parseMeter(
                this.getDistance({
                  lat: club.clubLat,
                  lng: club.clubLon,
                })
              ),
              fontFamily: "Gmarket Sans",
              fontSize: "14px",
              fontWeight,
              color,
            },
          });
          marker.addListener("click", () => {
            this.selectedClusterClubIdxList = [i];
            this.setClusterMakers();
            this.updateClubList([club]);
          });
          return marker;
        }) || []
      );
    },
  },
};
</script>

<style scoped>
#googleMap {
  width: 100%;
  height: 100%;
}
>>> a[href^="http://maps.google.com/maps"]
{
  display: block !important;
  margin-bottom: 27px;
}
>>> a[href^="https://maps.google.com/maps"]
{
  display: block !important;
  margin-bottom: 27px;
}

>>> .gmnoprint a,
>>> .gmnoprint span,
>>> .gm-style-cc {
  margin-bottom: 27px;
}
</style>
