var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ly_wrapper"},[_c('div',{staticClass:"ly_page"},[_c('div',{staticClass:"ly_page_inner"},[_c('div',{staticClass:"ly_page_inner2"},[_c('main',{staticClass:"ly_body"},[_c('div',{staticClass:"bl_header_wrapper"},[_c('div',{staticClass:"bl_header"},[_c('div',{staticClass:"bl_header_ttl"},[_vm._v("Q"+_vm._s(_vm.questionNo)+".")]),(_vm.questionLength > 1)?_c('div',{staticClass:"bl_header_state"},[_c('strong',{staticClass:"el_reset bl_header_state_accent"},[_vm._v(_vm._s(_vm.questionNo))]),_vm._v(" / "+_vm._s(_vm.questionLength)+" ")]):_vm._e()])]),_c('div',{staticClass:"bl_ttl_wrapper"},[_c('div',{staticClass:"bl_ttl"},[_c('div',{staticClass:"bl_ttl_txt"},[_vm._v(" "+_vm._s(_vm.currentQuestion.title)+" ")]),(_vm.currentQuestion.correctCount > 1)?_c('div',{staticClass:"bl_ttl_multi"},[_vm._v(" 답변 "+_vm._s(_vm.currentQuestion.correctCount)+"개 ")]):_vm._e(),(_vm.currentQuestion.imageUrl)?_c('div',{staticClass:"bl_ttl_img"},[_c('div',{staticClass:"bl_ttl_img_val",style:({
                    'background-image': ("url('" + (_vm.currentQuestion.imageUrl) + "')"),
                  })})]):_vm._e()])]),(_vm.isShortAnswerQuestion)?_c('div',{staticClass:"bl_problemList_wrapper"},[_c('ul',{staticClass:"el_reset bl_problemList"},[_c('li',{staticClass:"bl_problemList_item is_moFull"},[_c('div',{staticClass:"bl_problem"},[_c('div',{staticClass:"cp_area"},[_c('byte-textarea',{staticClass:"el_form cp_area_inp",attrs:{"max-byte":1000,"placeholder":"내용을 입력하세요."},model:{value:(_vm.questionAnswer),callback:function ($$v) {_vm.questionAnswer=$$v},expression:"questionAnswer"}}),_c('div',{staticClass:"cp_area_box"})],1),_c('div',{staticClass:"bl_problem_state"},[_c('strong',{staticClass:"el_reset bl_problem_state_accent",class:{ is_acIng: _vm.answerByte }},[_vm._v(_vm._s(_vm._f("comma")(_vm.answerByte)))]),_vm._v("/1,000 ")])])])])]):_c('div',{staticClass:"bl_problemList_wrapper"},[_c('ul',{staticClass:"el_reset bl_problemList"},_vm._l((_vm.currentQuestion.exampleList),function(example){return _c('li',{key:example.exampleNumber,staticClass:"bl_problemList_item",class:{
                  is_moFull: _vm.currentQuestion.questionTypeCd === '4602',
                }},[_c('div',{staticClass:"bl_problem"},[_c('div',{staticClass:"cp_chk",class:{
                      is_moImg: _vm.currentQuestion.questionTypeCd === '4601',
                      is_moTxt: _vm.currentQuestion.questionTypeCd === '4602',
                    }},[((_vm.currentQuestion.correctCount > 1
                          ? 'checkbox'
                          : 'radio')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionMultipleAnswer),expression:"questionMultipleAnswer"}],staticClass:"el_form cp_chk_inp",attrs:{"type":"checkbox"},domProps:{"value":example.exampleNumber,"checked":Array.isArray(_vm.questionMultipleAnswer)?_vm._i(_vm.questionMultipleAnswer,example.exampleNumber)>-1:(_vm.questionMultipleAnswer)},on:{"change":[function($event){var $$a=_vm.questionMultipleAnswer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=example.exampleNumber,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.questionMultipleAnswer=$$a.concat([$$v]))}else{$$i>-1&&(_vm.questionMultipleAnswer=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.questionMultipleAnswer=$$c}},_vm.preventCheckGreaterThanCorrectCount]}}):((_vm.currentQuestion.correctCount > 1
                          ? 'checkbox'
                          : 'radio')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionMultipleAnswer),expression:"questionMultipleAnswer"}],staticClass:"el_form cp_chk_inp",attrs:{"type":"radio"},domProps:{"value":example.exampleNumber,"checked":_vm._q(_vm.questionMultipleAnswer,example.exampleNumber)},on:{"change":[function($event){_vm.questionMultipleAnswer=example.exampleNumber},_vm.preventCheckGreaterThanCorrectCount]}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionMultipleAnswer),expression:"questionMultipleAnswer"}],staticClass:"el_form cp_chk_inp",attrs:{"type":_vm.currentQuestion.correctCount > 1
                          ? 'checkbox'
                          : 'radio'},domProps:{"value":example.exampleNumber,"value":(_vm.questionMultipleAnswer)},on:{"change":_vm.preventCheckGreaterThanCorrectCount,"input":function($event){if($event.target.composing){ return; }_vm.questionMultipleAnswer=$event.target.value}}}),_c('div',{staticClass:"cp_chk_bg"}),_c('div',{staticClass:"cp_chk_box"}),_c('div',{staticClass:"cp_chk_area"},[_vm._m(0,true),_c('div',{staticClass:"cp_chk_area_img",style:({
                          'background-image': ("url('" + (example.imageUrl) + "')"),
                        })}),_c('div',{staticClass:"cp_chk_area_cont"},[_c('div',{staticClass:"cp_chk_area_cont_txt"},[_vm._v(" "+_vm._s(example.info)+" ")]),_c('div',{staticClass:"cp_chk_area_cont_chk"})])])])])])}),0)])]),_c('footer',{staticClass:"ly_footer"},[_c('div',{staticClass:"bl_btnList_wrapper"},[_c('ul',{staticClass:"el_reset bl_btnList"},[(_vm.questionNo > 1)?_c('li',{staticClass:"bl_btnList_item is_moPrev"},[_c('div',{staticClass:"bl_btn"},[_c('div',{staticClass:"cp_btn"},[_c('button',{staticClass:"el_form cp_btn_btn",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 이전 ")])])])]):_vm._e(),(_vm.questionNo < _vm.questionLength)?_c('li',{staticClass:"bl_btnList_item"},[_c('div',{staticClass:"bl_btn"},[_c('div',{staticClass:"cp_btn is_moPrim"},[_c('button',{staticClass:"el_form cp_btn_btn",attrs:{"disabled":_vm.disabled},on:{"click":_vm.chooseAnswer}},[_vm._v(" 다음 ")])])])]):_c('li',{staticClass:"bl_btnList_item"},[_c('div',{staticClass:"bl_btn"},[_c('div',{staticClass:"cp_btn is_moPrim"},[_c('button',{staticClass:"el_form cp_btn_btn",attrs:{"disabled":_vm.disabled},on:{"click":_vm.submit}},[_vm._v(" 답변 제출 ")])])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cp_chk_area_pro"},[_c('div',{staticClass:"cp_chk_area_pro_val",staticStyle:{"width":"60%"}})])}]

export { render, staticRenderFns }