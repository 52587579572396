<template>
  <router-view
    :key="questionNo"
    v-if="questionNo"
    :answer-list="answerList"
    :is-fetching="isFetching"
    @chooseAnswer="chooseAnswer"
    @submit="submit"
  />
  <div v-else>
    <div class="ly_wrapper">
      <div class="ly_page">
        <div class="ly_page_inner">
          <div class="ly_page_inner2">
            <main class="ly_body" v-if="promotion">
              <div class="bl_ttl_wrapper">
                <div class="bl_ttl">
                  [{{ usePoint }}]
                  {{ promotion.subject }}
                </div>
              </div>
              <div class="bl_cont_wrapper">
                <div class="bl_cont">
                  문항 : {{ promotion.questionCount }}문항<br />
                  기간 : {{ convertDate(promotion.startDt) }}~{{
                    convertDate(promotion.endDt)
                  }}<br />
                  참가 : {{ usePoint }}<br />
                  경품 : {{ gift }}<br />
                  발표 : {{ convertDate(promotion.resultDt) }}
                </div>
              </div>
              <div class="bl_noti_wrapper" v-if="noticeList.length">
                <div class="bl_noti">
                  <div class="bl_noti_ttl">유의사항</div>
                  <div class="bl_noti_cont">
                    <ul class="el_reset bl_noti_cont_txtList">
                      <li
                        class="bl_noti_cont_txtList_item"
                        v-for="(notice, i) in noticeList"
                        :key="i"
                      >
                        <div class="bl_noti_cont_txt">
                          {{ notice }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </main>
            <footer class="ly_footer">
              <div class="bl_point_wrapper">
                <div class="bl_point">
                  <div class="bl_point_label">
                    <div class="bl_point_label_ico"></div>
                    <div class="bl_point_label_txt">보유 포인트</div>
                  </div>
                  <div class="bl_point_val">{{ comma(point) }}P</div>
                </div>
              </div>
              <div class="bl_btn_wrapper">
                <div class="bl_btn">
                  <div class="cp_btn is_moPrim">
                    <!-- 비활성(A): disabled -->
                    <button
                      class="el_form cp_btn_btn"
                      @click="onJoinButtonClick"
                    >
                      {{ promotionType }}참여
                    </button>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  closeWebviewLayer,
  newWebviewLayerV2,
  pointAccumulationComplete,
  showJsAlert,
  showJsConfirm,
  showPointCharging,
} from "@/util/webview";
import { boardApi, introApi } from "@/api";
import { comma } from "@/util/string";
import dateMixin from "@/mixins/dateMixin";

export default {
  name: "PromotionView",
  mixins: [dateMixin],
  async created() {
    if (!this.promotionId) {
      this.$router.push({ name: "PromotionList" });
    }
    window.tabActivated = () => {
      this.getUserInfo();
    };
    window.preventWebviewFinish = this.preventWebviewFinish;
    window.setPopUpResult = (popupResult) => {
      const result = JSON.parse(popupResult);
      if (result.id === "chargePointConfirm") {
        if (result.result) {
          showPointCharging();
        }
      }
      if (result.id === "quizResultAlert") {
        closeWebviewLayer();
      }
      if (result.id === "quizRetryConfirm") {
        if (result.result) {
          this.joinPromotion();
        } else {
          closeWebviewLayer();
        }
      }
      if (result.id === "surveyResultConfirm") {
        if (result.result) {
          this.showSurveyResult();
        } else {
          closeWebviewLayer();
        }
      }
    };
    await this.setData();
    await this.getUserInfo();
    this.setTitle();
  },
  beforeDestroy() {
    delete window.preventWebviewFinish;
  },
  data() {
    return {
      promotion: null,
      point: 0,
      answerList: [],
      answerJoinNumber: "",
      isFetching: false,
    };
  },
  methods: {
    comma,
    async setData() {
      const { data: promotion } = await boardApi.getPromotion(this.promotionId);
      this.promotion = promotion;
    },
    async getUserInfo() {
      const {
        data: { point },
      } = await introApi.getUserInfo();
      this.point = point;
    },
    setTitle() {
      if (!this.questionNo) {
        return;
      }
      document.title = this.promotionType;
    },
    async joinPromotion() {
      try {
        const { data: questionList } = await boardApi.createPromotionJoin(
          this.promotionId
        );
        if (this.promotion?.pointUseYn === "Y") {
          pointAccumulationComplete();
        }
        localStorage.setItem("questionList", JSON.stringify(questionList));
        if (this.questionNo) {
          this.answerList = [];
          if (questionList.length > 1) {
            this.$router.go(-(questionList.length - 1));
          }
        } else {
          newWebviewLayerV2({
            name: "PromotionQuestion",
            params: {
              promotionId: this.promotionId,
              questionNo: 1,
            },
          });
        }
      } catch (e) {
        switch (e.code) {
          case "6301":
          case "6305":
            closeWebviewLayer();
            return;
          default:
            return;
        }
      }
    },
    async onJoinButtonClick() {
      if (this.promotion?.pointUseYn !== "Y") {
        if (confirm(`이벤트에 참여 하시겠어요?`)) {
          await this.joinPromotion();
          this.$router.back();
        }
        return;
      }
      if (this.point >= this.promotion?.pointAmount) {
        if (
          confirm(
            `${comma(
              this.promotion?.pointAmount
            )}포인트를 사용해\n이벤트에 참여 하시겠어요?`
          )
        ) {
          await this.joinPromotion();
          this.$router.back();
        }
        return;
      }
      this.showChargePointConfirm();
    },
    showChargePointConfirm() {
      showJsConfirm({
        id: "chargePointConfirm",
        msg: "포인트가 부족해요.\n포인트를 충전 하시겠어요?",
        positiveText: "충전하기",
      });
    },
    chooseAnswer(answer) {
      this.answerList[this.questionNo - 1] = answer;
    },
    async submit() {
      try {
        this.isFetching = true;
        const { data } = await boardApi.createPromotionAnswer(
          this.promotionId,
          this.answerList
        );
        this.answerJoinNumber = data.joinNumber;
        //   퀴즈
        if (this.promotion?.promotionTypeCd === "4401") {
          // 정답
          if (data.joinCorrectYn === "Y") {
            showJsAlert({
              id: "quizResultAlert",
              msg: `정답입니다!\n\n축하합니다. 정답을 맞혀 [${this.usePoint}]${
                this.promotion?.subject
              }에 응모되었어요.\n당첨자 발표는 ${this.convertDate(
                this.promotion?.resultDt
              )}에 공지사항 게시판에서 확인하실 수 있어요!`,
              positiveText: "목록으로",
            });
          } else {
            const incorrectQuestionNumberList = data.incorrectQuestionNumberList
              ?.map((number) => `Q${number}`)
              ?.join(", ");
            showJsConfirm({
              id: "quizRetryConfirm",
              msg: `정답이 아닙니다!\n\n아쉽게도 정답을 맞추지 못했어요.\n${
                this.promotion?.pointUseYn === "Y"
                  ? `${this.usePoint}를 사용해서 `
                  : ""
              }재도전 하시겠어요?${
                this.answerList.length > 1 && incorrectQuestionNumberList
                  ? `\n\n틀린 문제 : ${incorrectQuestionNumberList}`
                  : ""
              }`,
              negativeText: "목록으로",
              positiveText: "재도전",
            });
          }
        }
        //   투표,설문
        if (
          this.promotion?.promotionTypeCd === "4402" ||
          this.promotion?.promotionTypeCd === "4403"
        ) {
          showJsConfirm({
            id: "surveyResultConfirm",
            msg: `감사합니다!\n\n투표에 참여하여\n[${this.usePoint}] ${
              this.promotion?.subject
            }에 응모되었어요.\n당첨자 발표는 ${this.convertDate(
              this.promotion?.resultDt
            )}에 공지사항 게시판에서 확인하실 수 있어요!`,
            negativeText: "목록으로",
            positiveText: "결과보기",
          });
        }
      } catch (e) {
        switch (e.code) {
          case "6301":
          case "6305":
            closeWebviewLayer();
            return;
          default:
            return;
        }
      } finally {
        this.isFetching = false;
      }
    },
    preventWebviewFinish() {
      if (this.joinNumber || this.questionNo !== 1) {
        return JSON.stringify({ isPreventFinishing: false });
      }
      setTimeout(() => {
        if (
          confirm(
            `퀴즈를 마치지 않아도 사용된 포인트는 복구되지 않아요. 정말 그만 하시겠어요?`
          )
        ) {
          closeWebviewLayer();
        }
      }, 100);
      return JSON.stringify({ isPreventFinishing: true });
    },
    async showSurveyResult() {
      const { data: answerList } = await boardApi.getPromotionAnswer(
        this.promotionId,
        this.answerJoinNumber
      );
      localStorage.setItem("answerList", JSON.stringify(answerList));

      if (answerList?.questionList?.length > 1) {
        this.$router.go(-(answerList?.questionList?.length - 1));
      }
      window.setTimeout(() => {
        this.$router.replace({
          name: "PromotionResult",
          params: {
            promotionId: this.promotionId,
            joinNumber: this.answerJoinNumber,
            questionNo: 1,
          },
        });
      }, 10);
    },
  },
  computed: {
    promotionId() {
      return Number(this.$route.params.promotionId);
    },
    questionNo() {
      return Number(this.$route.params.questionNo);
    },
    joinNumber() {
      return Number(this.$route.params.joinNumber);
    },
    noticeList() {
      if (!this.promotion?.noticeInfo) {
        return [];
      }
      return this.promotion?.noticeInfo.split("\n");
    },
    gift() {
      return this.promotion?.freeGiftYn === "Y"
        ? this.promotion?.freeGiftInfo
        : "없음";
    },
    usePoint() {
      return this.promotion?.pointUseYn === "Y"
        ? `${comma(this.promotion?.pointAmount)} 포인트`
        : "포인트 미사용";
    },
    promotionType() {
      switch (this.promotion?.promotionTypeCd) {
        case "4401":
          return "퀴즈";
        case "4402":
          return "투표";
        case "4403":
          return "설문";
        default:
          return "";
      }
    },
  },
  watch: {
    $route() {
      this.setTitle();
    },
    promotionType() {
      this.setTitle();
    },
  },
};
</script>

<style scoped>
.bl_ttl {
  word-break: break-all;
}
.bl_noti_cont_txt {
  word-break: break-all;
}
</style>
