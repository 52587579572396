<template>
  <div id="app">
    <router-view v-if="appReady" />
    <nm-spinner></nm-spinner>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NmSpinner from "./components/nearme/NmSpinner.vue";

export default {
  components: {
    NmSpinner,
  },
  async created() {
    window.setMapFilter = (filters) =>
      this.$store.dispatch("setFilters", filters);
    await this.setData();
  },
  data() {
    return {
      appReady: false,
    };
  },
  methods: {
    ...mapActions(["setHeader"]),
    async setData() {
      try {
        const json = await new Promise((resolve) => {
          window.setAppData = (json) => {
            delete window.setAppData;
            resolve(json);
          };
        });
        localStorage.setItem("h", json);
        this.setHeader(json);
      } catch (e) {
        console.log(e);
      } finally {
        this.appReady = true;
      }
    },
  },
  watch: {
    $route(route) {
      if (
        route.path.includes("/policy") ||
        route.path.includes("/promotion/pincrux")
      ) {
        this.appReady = true;
      }
    },
  },
};
</script>

<style>

#app {
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}
</style>
