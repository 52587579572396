<template>
  <div class="wrapper" v-if="isReady">
    <div class="page">
      <main class="main">
        <div class="main-main">
          <img
            :src="require('@/assets/images/voicecaddie-membership.png')"
            alt=""
          />
          <p>
            <a href="/policy/leisure" target="_blank"
              >레저활동중 상해 약관보기</a
            >
          </p>
        </div>
        <div class="main-footer">
          <button
            disabled
            class="membership-btn"
            @click="
              logEvent('more_membership_join_click', onClickJoinMembership)
            "
          >
            멤버십 가입하기
          </button>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { membershipApi, userApi } from "@/api";
import { showMyInfo, showJsAlert } from "@/util/webview";

export default {
  name: "VoicecaddieMembership",
  async created() {
    await this.setData();
  },
  data() {
    return {
      isReady: false,
      isRegistered: false,
    };
  },
  watch: {
    async isReady(value) {
      if (value && !this.isRegistered) {
        await this.$nextTick();
        showJsAlert({
          id: "membershipAlert",
          title: "멤버십 가입 제한 안내",
          msg: `\n서비스 운영상 지속적인 서비스 제공이 어려워 부득이하게 신규 및 재가입이 제한되었습니다.\n자세한 사항은 추가 공지드릴 예정이오니 이점 양해 부탁드립니다.\n\n감사합니다.`,
          positiveText: "확인",
        });
      }
    },
  },
  methods: {
    async setData() {
      try {
        const { data } = await membershipApi.getMembershipInfo();
        const status = data?.membershipStatusCd;
        switch (status) {
          case "12002":
          case "12003":
          case "12005":
            this.isRegistered = true;
            await this.$router.replace({ name: "VoicecaddieMembershipInfo" });
            break;
          case "12001":
          case "12004":
          case "12006":
            this.isRegistered = false;
            break;
        }
      } finally {
        await this.$nextTick();
        this.isReady = true;
      }
    },
    async onClickJoinMembership() {
      const { data } = await userApi.getUserInfo();
      if (data?.niceCertDt) {
        this.$router.push({
          name: "VoicecaddieMembershipPay",
        });
      } else {
        if (
          !confirm(
            "보이스캐디 APL 멤버십 가입을 위해\n본인 인증이 필요합니다.\n인증화면으로 이동 하시겠어요?"
          )
        ) {
          return;
        }
        showMyInfo();
      }
    },
  },
};
</script>

<style scoped>
.main {
  height: 100%;
}

.main-main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;
}

.main-main img {
  width: 100%;
}

.main-footer {
  flex: 0 0 auto;
}

.main-footer .membership-btn {
  width: 100%;
  height: 100%;
  padding: 16px 20px 15px 20px;
  background-color: #4f84ff;
  border: none;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  text-align: center;
}

.main-footer .membership-btn:disabled {
  background-color: #ddd;
}

.main-main p {
  background: #e2e1de;
  padding: 3rem 2.4rem;
}

.main-main p a {
  display: block;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 20px;
  color: #222;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
  padding: 8px;
}
</style>
