import { render, staticRenderFns } from "./PromotionQuestion.vue?vue&type=template&id=012107dc&scoped=true&"
import script from "./PromotionQuestion.vue?vue&type=script&lang=js&"
export * from "./PromotionQuestion.vue?vue&type=script&lang=js&"
import style0 from "./PromotionQuestion.vue?vue&type=style&index=0&id=012107dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012107dc",
  null
  
)

export default component.exports