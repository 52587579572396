<template>
  <div class="lyPage">
    <main class="lyBody">
      <section class="stText">
        <div class="text">
          <p class="text-object">
            <strong class="text-strong">단골 유저를 추가하고</strong><br />
            소통해보세요!
          </p>
        </div>
      </section>
    </main>
    <footer class="lyFooter">
      <section class="stButton">
        <div class="cmButton">
          <ul class="cmButton-list">
            <li class="cmButton-item is-colorKey">
              <div
                class="cmButton-object"
                @click="
                  logEvent('more_my_community_move', () => {
                    closeWebviewLayer({ toCommunity: true });
                  })
                "
              >
                게시물 이동
              </div>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  </div>
</template>

<script>
import { closeWebviewLayer } from "@/util/webview";

export default {
  name: "CommunityNoneDangol",
  beforeCreate() {
    document.querySelector("body").classList.add("id-communityNoneDangol");
    document.querySelector("html").style.height = "100%";
    document.querySelector("body").style.height = "100%";
    document.querySelector("#app").style.height = "100%";
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("id-communityNoneDangol");
    document.querySelector("html").style.height = "auto";
    document.querySelector("body").style.height = "auto";
    document.querySelector("#app").style.height = "auto";
  },
  methods: {
    closeWebviewLayer,
  },
};
</script>

<style scoped>
.id-communityNoneDangol .lyBody::before {
  background: transparent url("~@/assets/images/communityNoneDangol.png")
    no-repeat center top / cover;
}
</style>
