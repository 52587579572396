<template>
  <div class="ly_wrapper">
    <div class="ly_page" v-if="ready">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="bl_fieldList_wrapper">
              <ul class="el_reset bl_fieldList">
                <li class="bl_fieldList_item">
                  <div class="bl_field">
                    <div class="bl_field_ttl is_moReq">이름</div>
                    <div class="bl_field_cont">
                      <div class="cp_inp">
                        <!-- 비활성(A): disabled -->
                        <input
                          v-model="userName"
                          class="el_form cp_inp_inp"
                          placeholder="이름(한글 최대 6자)"
                          type="text"
                        />
                        <div class="cp_inp_box"></div>
                      </div>
                      <p class="cp_inp-validation" v-if="invalidUserName">
                        이름을 정확하게 입력해 주세요.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="bl_fieldList_item">
                  <div class="bl_field">
                    <div class="bl_field_ttl is_moReq">생년월일</div>
                    <div class="bl_field_cont">
                      <div class="cp_inp">
                        <!-- 비활성(A): disabled -->
                        <input
                          @input="onBirthDateInput"
                          :value="birthDate"
                          class="el_form cp_inp_inp"
                          placeholder="8자리 입력 (YYYYMMDD)"
                          type="number"
                        />
                        <div class="cp_inp_box"></div>
                      </div>
                      <p class="cp_inp-validation" v-if="invalidBirthDate">
                        생년월일을 정확하게 입력해 주세요.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="bl_fieldList_item">
                  <div class="bl_field">
                    <!-- 양력:S | 음력(평달):F | 음력(윤달):L -->
                    <div class="bl_field_ttl is_moReq">양/음력</div>
                    <div class="bl_field_cont">
                      <ul class="el_reset bl_field_cont_checkList">
                        <li class="bl_field_cont_checkList_item">
                          <div class="cp_cusChk">
                            <!-- 체크(A): checked / 비활성(A): disabled -->
                            <input
                              value="S"
                              v-model="isSolar"
                              class="el_form cp_cusChk_inp"
                              type="radio"
                            />
                            <div class="cp_cusChk_chk"></div>
                            <div class="cp_cusChk_txt">양력</div>
                          </div>
                        </li>
                        <li class="bl_field_cont_checkList_item">
                          <div class="cp_cusChk">
                            <!-- 체크(A): checked / 비활성(A): disabled -->
                            <input
                              value="F"
                              v-model="isSolar"
                              class="el_form cp_cusChk_inp"
                              type="radio"
                            />
                            <div class="cp_cusChk_chk"></div>
                            <div class="cp_cusChk_txt">음력(평달)</div>
                          </div>
                        </li>
                        <li class="bl_field_cont_checkList_item">
                          <div class="cp_cusChk">
                            <!-- 체크(A): checked / 비활성(A): disabled -->
                            <input
                              value="L"
                              v-model="isSolar"
                              class="el_form cp_cusChk_inp"
                              type="radio"
                            />
                            <div class="cp_cusChk_chk"></div>
                            <div class="cp_cusChk_txt">음력(윤달)</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="bl_fieldList_item">
                  <div class="bl_field">
                    <div class="bl_field_ttl">태어난 시간</div>
                    <div class="bl_field_cont">
                      <!-- 포커스(C): is_acFocus / 비활성(C): is_acDisabled -->
                      <div
                        class="cp_cusTime"
                        :class="{ is_acFocus: isActiveDatepicker }"
                      >
                        <div
                          class="cp_cusTime_obj"
                          @click.self="openDatepicker"
                        >
                          <input
                            class="el_form cp_cusTime_obj_inp"
                            placeholder="태어난 시간"
                            type="text"
                            :value="birthTime"
                          />
                          <div class="cp_cusTime_obj_box"></div>
                          <div class="cp_cusTime_obj_ico"></div>
                        </div>
                        <div class="cp_cusTime_lay">
                          <jeomsin-datepicker
                            v-if="isActiveDatepicker"
                            :default-time-type="timeType"
                            :default-hour="birthHour"
                            :default-minute="birthMinute"
                            @change="onTimeValueChange"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bl_fieldList_item">
                  <div class="bl_field">
                    <div class="bl_field_ttl is_moReq">성별</div>
                    <div class="bl_field_cont">
                      <ul class="el_reset bl_field_cont_checkList">
                        <li class="bl_field_cont_checkList_item">
                          <div class="cp_cusChk">
                            <!-- 체크(A): checked / 비활성(A): disabled -->
                            <input
                              value="M"
                              v-model="gender"
                              class="el_form cp_cusChk_inp"
                              type="radio"
                            />
                            <div class="cp_cusChk_chk"></div>
                            <div class="cp_cusChk_txt">남성</div>
                          </div>
                        </li>
                        <li class="bl_field_cont_checkList_item">
                          <div class="cp_cusChk">
                            <!-- 체크(A): checked / 비활성(A): disabled -->
                            <input
                              value="F"
                              v-model="gender"
                              class="el_form cp_cusChk_inp"
                              type="radio"
                            />
                            <div class="cp_cusChk_chk"></div>
                            <div class="cp_cusChk_txt">여성</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="bl_termsList_wrapper">
              <ul class="el_reset bl_termsList">
                <li class="bl_termsList_item">
                  <div class="bl_terms">
                    <div class="bl_terms_chk">
                      <div class="cp_chk">
                        <!-- 체크(A): checked / 비활성(A): disabled -->
                        <input
                          v-model="additionalAgree"
                          class="el_form cp_chk_inp"
                          type="checkbox"
                        />
                        <div class="cp_chk_chk"></div>
                        <div class="cp_chk_txt">
                          <strong class="cp_chk_txt_req">[필수]</strong>
                          개인정보 추가 수집에 대한 동의
                        </div>
                      </div>
                    </div>
                    <div
                      class="bl_terms_ico"
                      @click="openTerm('JeomsinPrivacy')"
                    ></div>
                  </div>
                </li>
                <li class="bl_termsList_item">
                  <div class="bl_terms">
                    <div class="bl_terms_chk">
                      <div class="cp_chk">
                        <!-- 체크(A): checked / 비활성(A): disabled -->
                        <input
                          v-model="thirdPartiesAgree"
                          class="el_form cp_chk_inp"
                          type="checkbox"
                        />
                        <div class="cp_chk_chk"></div>
                        <div class="cp_chk_txt">
                          <strong class="cp_chk_txt_req">[필수]</strong>
                          개인정보 제3자 제공 동의
                        </div>
                      </div>
                    </div>
                    <div
                      class="bl_terms_ico"
                      @click="openTerm('JeomsinThirdParties')"
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="bl_confirm_wrapper">
              <div class="bl_confirm">
                <div class="cp_btn">
                  <!-- 비활성(A): disabled -->
                  <button
                    class="el_form cp_btn_btn"
                    :disabled="!passRequirement"
                    @click="submit"
                  >
                    2023 신년운세 확인
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    <ul class="el_reset ly_layerList">
      <li class="ly_layer is_moDimmer"></li>
      <li class="ly_layer is_moLoading">
        <div class="ly_layer_inner">
          <div class="ly_layer_inner2">
            <div class="ly_layer_inner3">
              <div class="ly_layer_inner4">
                <div class="ly_body">
                  <div class="bl_ad_wrapper">
                    <div class="bl_ad"></div>
                  </div>
                </div>
                <div class="ly_footer">
                  <div class="bl_load_wrapper">
                    <div class="bl_load">
                      <div class="bl_load_img">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 400 150"
                          width="400"
                          height="150"
                          preserveAspectRatio="xMidYMid meet"
                          style="
                            width: 100%;
                            height: 100%;
                            transform: translate3d(0px, 0px, 0px);
                          "
                        >
                          <defs>
                            <clipPath id="__lottie_element_2">
                              <rect width="400" height="150" x="0" y="0"></rect>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#__lottie_element_2)"></g>
                        </svg>
                      </div>
                      <div class="bl_load_txt">
                        홍길동님이 요청하신 사주 운세를<br />
                        분석하고 있어요! 잠시만 기다려주세요.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { userApi } from "@/api";
import dayjs from "dayjs";
import {
  handleOpenWeb,
  showJsConfirm,
  showPointCharging,
} from "@/util/webview";
import JeomsinDatepicker from "@/views/promotion/components/JeomsinDatepicker.vue";

export default {
  name: "JeomsinForm",
  components: {
    JeomsinDatepicker,
  },
  async created() {
    await this.setData();
    if (this.additionalAgree && this.thirdPartiesAgree) {
      this.$router.replace({ name: "JeomsinInfo" });
    }

    this.ready = true;
    window.setPopUpResult = (popupResult) => {
      const result = JSON.parse(popupResult);
      if (result.id === "chargePointConfirm") {
        if (result.result) {
          showPointCharging();
        }
      }
    };
  },
  data() {
    return {
      ready: false,
      userName: "",
      invalidUserName: false,
      birthDate: "",
      invalidBirthDate: false,
      isSolar: "S",
      birthHour: null,
      birthMinute: null,
      gender: "M",
      additionalAgree: false,
      thirdPartiesAgree: false,
      isActiveDatepicker: false,
      timeType: null,
    };
  },
  methods: {
    async setData() {
      const { data } = await userApi.getPromotionJeomsinUserInfo();
      this.userName = data.userName;
      this.birthDate = data.birthDate;
      this.isSolar = data.isSolar;
      this.birthHour = data.birthHour;
      this.birthMinute = data.birthMinute;
      this.gender = data.gender;
      this.additionalAgree = data.additionalAgreeYn === "Y";
      this.thirdPartiesAgree = data.thirdPartiesAgreeYn === "Y";
    },
    async submit() {
      if (!this.passRequirement) {
        return;
      }
      if (!this.isValidName) {
        this.invalidUserName = true;
        return;
      }
      if (!this.isValidDate) {
        this.invalidBirthDate = true;
        return;
      }
      if (
        !confirm("신년운세 확인 시 1,000p가 차감됩니다.\n진행하시겠습니까?")
      ) {
        return;
      }
      try {
        await userApi.createPromotionJeomsinUserInfo({
          userName: this.userName,
          birthDate: this.birthDate,
          isSolar: this.isSolar,
          birthHour: this.birthHour + (this.timeType === "PM" ? 12 : 0),
          birthMinute: this.birthMinute,
          gender: this.gender,
          additionalAgreeYn: this.additionalAgree ? "Y" : "F",
          thirdPartiesAgreeYn: this.thirdPartiesAgree ? "Y" : "F",
        });

        this.$router.replace({ name: "JeomsinInfo" });
      } catch (e) {
        if (!e?.code) {
          return;
        }
        switch (e.code) {
          case "5201":
            showJsConfirm({
              id: "chargePointConfirm",
              msg: "포인트가 부족해요.\n포인트를 충전하시겠어요?",
              positiveText: "충전하기",
            });
            return;
          default:
            return;
        }
      }
    },
    onBirthDateInput(e) {
      this.birthDate = e.target.value.slice(0, 8);
      e.target.value = this.birthDate;
    },
    onTimeValueChange(timeValue) {
      const { timeType, hour, minute } = timeValue;
      this.timeType = timeType;
      this.birthHour = hour;
      this.birthMinute = minute;
      this.closeDatepicker();
    },
    openDatepicker() {
      this.isActiveDatepicker = true;
      document.querySelector("body").classList.add("is_acDimmer");
    },
    closeDatepicker() {
      this.isActiveDatepicker = false;
      document.querySelector("body").classList.remove("is_acDimmer");
    },
    openTerm(componentName) {
      const { href } = this.$router.resolve({ name: componentName });
      handleOpenWeb({ url: `${location.origin}${href}` });
    },
  },
  computed: {
    isValidDate() {
      return (
        this.birthDate &&
        dayjs(this.birthDate, "YYYYMMDD", true).isValid() &&
        dayjs(this.birthDate).isBefore(dayjs()) &&
        dayjs(this.birthDate).isAfter(dayjs("1899-12-31"))
      );
    },
    isValidName() {
      // 한글검사
      return /^[가-힣]*$/.test(this.userName)
    },
    passRequirement() {
      return !!(
        this.userName?.trim() &&
        this.birthDate &&
        this.isSolar &&
        this.gender &&
        this.additionalAgree &&
        this.thirdPartiesAgree
      );
    },
    hour() {
      if (this.birthHour === null) {
        return null;
      }
      return this.birthHour.toLocaleString("en-Us", {
        minimumIntegerDigits: 2,
      });
    },
    minute() {
      if (this.birthMinute === null) {
        return null;
      }

      return this.birthMinute.toLocaleString("en-Us", {
        minimumIntegerDigits: 2,
      });
    },
    birthTime() {
      if (!this.timeType && this.timeType === null) {
        return "";
      }
      if (this.timeType === "AM" && !this.birthHour && !this.birthMinute) {
        return "시간 모름";
      }
      return `${this.timeType === "AM" ? "오전" : "오후"} ${this.hour}:${
        this.minute
      }`;
    },
  },
};
</script>

<style scoped>
.bl_terms_ico {
  z-index: 10;
}

.cp_inp-validation {
  color: #ef3131;
  font: normal normal 400 12px / 1.43 "Noto Sans KR";
  margin: 0;
  padding-top: 10px;
}
</style>
