<template>
  <div class="ly_body">
    <div class="ly_body_header">
      <div class="bl_input">
        <div class="bl_input_inner">
          <nm-search-input
            v-model="keyword"
            clear
            :placeholder="placeholder"
            @keypress:enter="search"
            autofocus
          />
        </div>
      </div>
    </div>
    <div class="ly_body_body">
      <div class="ly_container" :class="{ is_acOpen: isOpen }">
        <div v-if="serviceType === 'club'" class="bl_obj_header">
          <div class="bl_header_ttl">
            <div class="bl_ttl_ico"></div>
            <div class="bl_ttl_ttl">공항 리스트</div>
          </div>
          <div class="bl_header_arrow" @click="openList"></div>
        </div>
        <div class="bl_place bl_airport_item">
          <ul class="el_reset bl_place_list">
            <place-list-item
              v-for="terminal in terminalList"
              :key="terminal.id"
              :item="terminal"
            >
              <template v-slot:button>
                <div class="bl_obj_btn" @click="selectPlace(terminal)">
                  {{ buttonTxt }}
                </div>
              </template>
            </place-list-item>
          </ul>
        </div>
        <div
          class="bl_place"
          :class="{ is_ac: !placeList || placeList.length }"
        >
          <ul class="el_reset bl_place_list">
            <place-list-item
              v-for="place in placeList"
              :key="place.id"
              :item="place"
              @click="setLocationOnMap(place)"
            >
              <template v-slot:button>
                <div class="bl_obj_btn" @click="selectPlace(place)">
                  {{ buttonTxt }}
                </div>
              </template>
            </place-list-item>
          </ul>
          <div v-if="!isSearch" class="no_result">
            검색된 내역이 없어요.<br />
            다시 검색 해주세요.
          </div>
        </div>
      </div>
      <div class="bl_map">
        <div ref="map" id="map"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { nearmeApi } from "@/api";
import NmSearchInput from "@/components/nearme/NmSearchInput.vue";
import loader from "@/util/googleMaps";
import PlaceListItem from "@/views/nearme/search/components/PlaceListItem.vue";
import {
  NEARME_AIRPORT_SERVICE_CITY_LIST,
  NEARME_GOLF_SERVICE_CITY_LIST,
} from "@/util/googleMaps.js";
export default {
  components: { NmSearchInput, PlaceListItem },
  name: "PlaceList",
  props: {
    placeholder: {
      type: String,
    },
    route: {
      type: String,
      default: "departure",
    },
  },
  data() {
    return {
      keyword: "",
      placeList: null,
      terminalList: null,
      isOpen: true,
      map: null,
      places: null,
      serviceType: null,
      searchType: null,
      mapsLibrary: null,
      placesLibrary: null,
      coreLibrary: null,
      latLngBounds: null,
      isSearch: false,
      autocompleteService: null,
      geocoder: null,
    };
  },
  computed: {
    buttonTxt() {
      return this.route === "departure" ? "출발" : "도착";
    },
  },
  async created() {
    const { service_type, search_type, keyword } = this.$route.query;
    this.serviceType = service_type;
    this.searchType = search_type;
    this.keyword = keyword || "";

    if (this.serviceType === "club") {
      await this.setTerminalList();
    }
  },
  async mounted() {
    this.mapsLibrary = await loader.importLibrary("maps");
    this.placesLibrary = await loader.importLibrary("places");
    this.coreLibrary = await loader.importLibrary("core");
    this.geocoder = await loader.importLibrary("geocoding");

    this.initMap();

    if (this.keyword.trim()) {
      this.isOpen = false;
      this.search();
    }
  },
  watch: {
    keyword(value) {
      if (this.isOpen && value.trim()) {
        this.isOpen = false;
      }
    },
  },
  methods: {
    async setTerminalList() {
      const { data } = await nearmeApi.getAirportInfoList({
        partnershipCd: "5801",
      });
      this.terminalList = data.map((el) => {
        return {
          terminal_cd: el.airportCd,
          name: el.airportName,
          eng_name: null,
          address: el.airportAddress,
          lat: el.airportLat,
          lng: el.airportLon,
        };
      });
    },
    async initMap() {
      const { Map } = this.mapsLibrary;
      const { PlacesService, AutocompleteService } = this.placesLibrary;
      const { LatLngBounds, LatLng } = this.coreLibrary;
      const { Geocoder } = this.geocoder;

      const position = { lat: 35.6585805, lng: 139.7454329 };

      const JAPAN_BOUNDS = {
        north: 45.5514834662,
        south: 31.0295791692,
        west: 129.408463169,
        east: 145.543137242,
      };

      this.latLngBounds = new LatLngBounds(
        new LatLng(JAPAN_BOUNDS.south, JAPAN_BOUNDS.west),
        new LatLng(JAPAN_BOUNDS.north, JAPAN_BOUNDS.east)
      );

      // map
      this.map = new Map(this.$refs.map, {
        center: position,
        zoom: 18,
        disableDefaultUI: true,
        clickableIcons: false,
      });
      // place
      this.places = new PlacesService(this.map);
      // geocoder
      this.geocoder = new Geocoder();
      // autocomplete service
      this.autocompleteService = new AutocompleteService();
    },
    async search() {
      this.isSearch = true;
      this.isOpen = false;
      this.placeList = [];

      if (!this.keyword?.trim()) {
        alert("검색할 장소명을 입력해주세요.");
        return;
      }
      const request = {
        query: this.keyword.trim(),
        location: this.map.getCenter(),
      };
      this.places.textSearch(request, this.searchPlaceCallback);
    },
    async searchPlaceCallback(results, status) {
      const { PlacesServiceStatus } = this.placesLibrary;
      let temp = [];
      if (status === PlacesServiceStatus.OK) {
        if (this.serviceType === "airport") {
          temp = results.filter((el) => {
            const isServiceArea = NEARME_AIRPORT_SERVICE_CITY_LIST.some(
              (area) =>
                el.plus_code?.compound_code?.includes(area) ||
                el.vicinity?.includes(area)
            );
            if (isServiceArea) {
              return this.latLngBounds.contains(el.geometry?.location);
            }
          });
        } else {
          temp = results.filter((el) => {
            const isServiceArea = NEARME_GOLF_SERVICE_CITY_LIST.some(
              (area) =>
                el.plus_code?.compound_code?.includes(area) ||
                el.vicinity?.includes(area)
            );
            if (isServiceArea) {
              return this.latLngBounds.contains(el.geometry?.location);
            }
          });
        }

        this.placeList = temp?.map((el) => {
          return {
            name: el.name,
            address: el.formatted_address,
            lat: el.geometry.location.lat(),
            lng: el.geometry.location.lng(),
          };
        });
      } else if (status === PlacesServiceStatus.INVALID_REQUEST) {
        alert("잘못된 요청입니다.");
        this.placeList = [];
      } else if (status === PlacesServiceStatus.OVER_QUERY_LIMIT) {
        alert("웹페이지가 요청 할당량을 초과했습니다.");
        this.placeList = [];
      } else if (status === PlacesServiceStatus.REQUEST_DENIED) {
        alert("웹페이지에서 PlacesService를 사용할 수 없습니다.");
        this.placeList = [];
      } else if (status === PlacesServiceStatus.ZERO_RESULTS) {
        this.placeList = [];
      }

      const request = {
        input: this.keyword.trim(),
        componentRestriction: { country: "jp" },
        locationRestriction: this.latLngBounds,
      };

      if (!this.placeList.length) {
        this.autocompleteService.getPlacePredictions(
          request,
          this.getPlacePredictionsCallback
        );
      } else {
        this.isSearch = false;
      }
    },
    async getPlacePredictionsCallback(results, status) {
      const { PlacesServiceStatus } = this.placesLibrary;
      let temp = [];
      if (status === PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          if (this.serviceType === "airport") {
            const isServiceArea = NEARME_AIRPORT_SERVICE_CITY_LIST.some(
              (area) => results[i].description?.indexOf(area) >= 0
            );

            if (!isServiceArea) break;
          } else {
            const isServiceArea = NEARME_GOLF_SERVICE_CITY_LIST.some(
              (area) => results[i].description?.indexOf(area) >= 0
            );

            if (!isServiceArea) break;
          }
          if (!results[i].geometry) {
            const data = await this.geocoder.geocode({
              placeId: results[i].place_id,
            });

            temp.push({
              name: results[i].structured_formatting?.main_text,
              address: results[i].description,
              lat: data.results[0].geometry.location.lat(),
              lng: data.results[0].geometry.location.lng(),
            });
          }
        }

        this.placeList = temp;
      } else if (status === PlacesServiceStatus.INVALID_REQUEST) {
        this.alert("잘못된 요청입니다.");
        this.placeList = [];
      } else if (status === PlacesServiceStatus.OVER_QUERY_LIMIT) {
        this.alert("웹페이지가 요청 할당량을 초과했습니다.");
        this.placeList = [];
      } else if (status === PlacesServiceStatus.REQUEST_DENIED) {
        this.alert("웹페이지에서 PlacesService를 사용할 수 없습니다.");
        this.placeList = [];
      } else if (status === PlacesServiceStatus.ZERO_RESULTS) {
        this.placeList = [];
      }

      this.isSearch = false;
    },
    setLocationOnMap(place) {
      this.$router.push({
        name: "PlaceOnMap",
        query: {
          service_type: this.serviceType,
          name: place.name,
          lat: place.lat,
          lng: place.lng,
          route: this.route,
          search_name: this.keyword,
        },
      });
    },
    selectPlace(place) {
      this.$emit("select", place, this.keyword);
    },
    openList() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style scoped>
.bl_obj_header {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1rem 2.4rem;
}
.bl_obj_header .bl_header_ttl {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
}
.bl_obj_header .bl_header_ttl .bl_ttl_ico::before {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAAAXNSR0IArs4c6QAAA75JREFUeF7tmr9rFEEUx79v9xSs1MLCPyEWWlmk0BOsAklUtEgE+5ygoJVITmMSxEpBi9jbWAhKktYigqSw0uIs9C8wIMZG0OyNzDEL63p38+PN3h2Xd00gs/tm5rPf92PeLkF+fQmQ8OlPQABZFCKABBAviIiCREGiIB4BURCPn8QgUZAoiEdAFMTjJzFIFCQK4hEQBfH47ZsYdHFJHckO4pTGpdqYSBTqRDgJ4ETnfwpbO98xtf2EfhWRjj2gmYeqThkWiDBn01IGzG4u0sa+AGTAPCBC3QYmH98XgC6tqIkMWPMBkwOiPRx9s0Q/xlJBOsa0U9wmQtNVMcXrdAxab9K58r1jEYOmV9VCCjwCcDgEDoDdRGHydZM+jxUgjjsVQWRAY3ORnneDG6wgI+k1AMeN4XeddJnirf67cZe2Ap+m022zK2qZCDcZqunMoxRerjdpvtekQYA0HFXD+7yG6Lcj7dsxAWrVtAmvXOa2kbbB0fd7A/KBY1ugGW8phR0AHQW2CR8owc/0Nz6WM4pRTVAQLq/FBY43oArguDDsACTCsRiqcXGroDQ/JDguAL2uUQor602653qTs4uNAaDdDLhWPkrYQDkDyg3pIPmHUDeHvSluFrEtMNJ4K1G40q3Osdn3BlQ2WAA2F1Le2xbIHfd1qfJ8bEBlg+aQeB7A2WEC0+VFCjRCVBMUpEOf5BCA6Vhzp1dl7LuP6ArqtwAd6PdqOJMonCbC5VhpO59T1zZJhka5fvKFMlAF9VpcflRxaWTZNhjLnbrNM1AFFTNhpONCVHcaCUDTq2omBV5wywOdnZIMj2O609ABxThLVelOQwMUKd60THb6p6lui0/c8cpjUIT2xK5SeOpzfuJCGVgW48YbnbZTYJlb7HGAVaYg0yfWHceQX6ud4HrVXUmXhUUHxIw3ladtFyhOLpa/RtEX6z5zLcM3m9Q5LZFBpe0ogCwb1R2+TwC+6PboAYWvGpwJxtu+9c2g0zYbUOhGfScGoNP2s1iHyoD5nW75LwZdWFX61WvoCzinSUfVnZwKxQhvKXtCGoW07fQECxf1zGK6hkkUrhIhRlt1ZNJ2NEC5IdPDmTM9aOs3NqUFDLUK9oXh5GL9jOb95xS4YWt2VdG8irFhXxvBhaL5cGC+3BnUaVuluD8KVbAvDLaCigYmb6lD+fd8OSxdF/m+d4qxiSptBCuoykWNkm0BZHkaAkgA8RxWFCQKEgXxCIiCePwkBomCREE8AqIgHj+JQaIgURCPgCiIx09ikCiIp6C/5nF5WBPUttwAAAAASUVORK5CYII=")
    no-repeat center/contain;
  content: "";
  display: block;
  height: 2.4rem;
  width: 2.4rem;
}
.bl_obj_header .bl_header_ttl .bl_ttl_ttl {
  flex: 0 0 auto;
  font-size: 1.6rem;
  font-weight: 500;
  max-width: calc(100% - 6.2rem);
  padding-left: 0.5rem;
}
.bl_obj_header .bl_header_arrow {
  flex: 0 0 auto;
  padding-left: 1rem;
  transition: transform 0.1s ease-out;
}
.bl_obj_header .bl_header_arrow::before {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAVdJREFUaEPtmNENgzAMRJsVuiOMUFZoOwJDdgWqSCAFSLCduxRRub/U9r07iwDhdvFfuLj+mwOcnaAn4AmADvgKgQbC5Z4AbCHY4D8T6Pv+EY0Zx/ENGkQp77ruGUL45PTsEpjFv+bJw9kQs/ihpGcFsBG/uHcaxEZ8Vs8KoFAQC38OodWyWyFtIWW5C00sGrJ3IUsDNoh1dvE2am3EAKmZeXgO1DSsBamdJR5ktY0tIMgMESAKQQZIIGhvFUArCFR81KUGYEMwxJsBWBAs8VUAKARTfDVALQRbPARghWghHgbQQrQSTwGQIKZpuocQluf59FigPOGabqNHh9KBy7kyinhaAotCJQRNPB1AWKcmL0a0FUr3RPMqKD0jaa83AcgkQV2bFK4ZQAKR/RyidVj6X1MAaTjjugMwXER6eAKIe4xaT4DhItLDE0DcY9R6AgwXkR6XT+ALj6HQMfhXI7cAAAAASUVORK5CYII=")
    no-repeat center/contain;
  content: "";
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  transform: rotateX(0deg);
}
.bl_place.bl_airport_item {
  border-bottom: 0.1rem solid #f0f0f0;
}
.ly_container.is_acOpen .bl_obj_header .bl_header_arrow {
  transform: rotateX(180deg);
}
.ly_container .bl_place.bl_airport_item {
  display: none;
}
.ly_container.is_acOpen .bl_place.bl_airport_item {
  display: block;
}
</style>