<template>
  <community-none-result v-if="boardList && !boardList.length">
    <template #text>
      좋아요한 게시물이 없어요!<br />게시판을 구경해볼까요?
    </template>
    <template #btn>
      <li
        @click="
          logEvent('more_likes_community_move', () => {
            closeWebviewLayer({ toCommunity: true });
          })
        "
        class="cmButton-item is-colorKey"
      >
        <div class="cmButton-object">게시판 이동</div>
      </li>
    </template>
  </community-none-result>
  <div class="lyPage" ref="scrollList" v-else>
    <main class="lyBody">
      <section class="stPost">
        <div class="cmPost">
          <ul class="cmPost-list">
            <community-post
              v-for="board in boardList"
              :key="board.boardId"
              :board="board"
            />
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import CommunityNoneResult from "@/views/community/components/CommunityNoneResult";
import CommunityPost from "@/views/community/components/CommunityPost";
import { closeWebviewLayer } from "@/util/webview";
import { userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";

export default {
  name: "CommunityMyPost",
  components: {
    CommunityNoneResult,
    CommunityPost,
  },
  mixins: [scrollMixin],
  async created() {
    await this.setData();
  },
  mounted() {
    this.addScrollEvent();
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      lastId: undefined,
      totalCount: 0,
      boardList: null,
    };
  },
  methods: {
    closeWebviewLayer,
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, last },
        } = await userApi.getLikeBoards({
          page: this.pageIndex,
          lastId: this.lastId,
          size: this.pageSize,
        });
        this.boardList = this.boardList || [];
        this.boardList = [...this.boardList, ...content];
        this.lastId = content[content.length - 1]?.boardId;
        this.last = last;
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>
