<template>
  <li class="cmComment-subItem">
    <div class="cmComment-subObject" :class="{ 'is-focus': isTarget }">
      <div class="cmReply">
        <div class="cmReply-profile">
          <div class="cmReply-profileImage" @click="onProfileClick">
            <div
              class="cmReply-profileImageObject"
              :style="{
                'background-image': `url('${profileImgUrl}')`,
              }"
            ></div>
          </div>
          <div class="cmReply-profileName" @click="onProfileClick">
            {{ reply.nickname || "닉네임없음" }}
          </div>
          <div class="cmReply-profileWrite">
            {{ regDt }}
          </div>
        </div>
        <div class="cmReply-content" v-html="replyContent"></div>
        <div class="cmReply-feed">
          <div class="cmReply-feedReply">
            <ul class="cmReply-feedReplyList">
              <li
                class="cmReply-feedReplyItem"
                @click="
                  logEvent('community_board_recomment_click', setReplyInfo)
                "
              >
                답글달기
              </li>
              <li
                v-if="isOwnReply"
                @click="
                  logEvent('community_board_comment_delete_click', deleteReply)
                "
                class="cmReply-feedReplyItem is-delete"
              >
                삭제
              </li>
            </ul>
          </div>
          <!-- 온 상태 : is-stateOn -->
          <div
            class="cmReply-feedLike"
            :class="{ 'is-stateOn': reply.likeYn === 'Y' }"
            @click="
              logEvent('community_board_comment_like_click', toggleReplyLike)
            "
          >
            {{ reply.likeCnt }}
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { boardApi } from "@/api";
import { debounce } from "lodash";
import dateMixin from "@/mixins/dateMixin";
import { mapGetters } from "vuex";
import { convertNewLine } from "@/util/string";
import { newWebviewLayerV2, showToast } from "@/util/webview";
import dayjs from "dayjs";

export default {
  name: "CommunityCommentReply",
  props: ["reply"],
  mixins: [dateMixin],
  data() {
    return {
      lastLikeYn: this.reply.likeYn,
      likeUpdateCnt: 0,
    };
  },
  created() {
    this.updateReplyLike = debounce(this.updateReplyLike, 500);
  },
  methods: {
    async toggleReplyLike() {
      this.likeUpdateCnt += 1;
      if (this.likeUpdateCnt >= 10) {
        showToast({ message: "커뮤니티 보호를 위해 반복할 수 없습니다" });
      }
      if (this.reply.likeYn === "Y") {
        this.reply.likeYn = "N";
        this.reply.likeCnt -= 1;
      } else if (this.reply.likeYn === "N") {
        this.reply.likeYn = "Y";
        this.reply.likeCnt += 1;
      }
      await this.updateReplyLike();
    },
    async updateReplyLike() {
      if (this.lastLikeYn === this.reply.likeYn) {
        return;
      }
      this.lastLikeYn = this.reply.likeYn;
      return await boardApi.updateBoardCommentReplyLike(this.reply.replyId, {
        likeYn: this.reply.likeYn,
      });
    },
    async deleteReply() {
      if (!confirm("댓글을 삭제 하시겠어요?")) {
        return;
      }
      await boardApi.deleteBoardCommentReply(this.reply.replyId);
      this.$emit("deleteReply", this.reply.replyId);
    },
    onProfileClick() {
      if (this.reply.nickname === undefined) {
        return;
      }
      newWebviewLayerV2({
        name: "CommunityProfile",
        params: { uid: this.reply.uid },
      });
    },
    setReplyInfo() {
      if (this.reply.nickname === undefined) {
        return;
      }
      this.$emit("setReplyInfo", this.reply);
    },
  },
  computed: {
    ...mapGetters(["userUid"]),
    profileImgUrl() {
      return this.reply?.profileImgUrl || "";
    },
    regDt() {
      if (dayjs().diff(dayjs.unix(this.reply?.regDt), "days", true) > 30.5) {
        return dayjs.unix(this.reply?.regDt).format("YYYY/MM/DD");
      }
      return this.convertTimeFromNow(this.reply?.regDt);
    },
    isOwnReply() {
      return this.userUid === this.reply.uid;
    },
    replyContent() {
      let replyContent = "";
      if (this.reply?.targetNickname) {
        replyContent += `<strong class="cmReply-contentStrong">@${this.reply.targetNickname}</strong> `;
      }
      replyContent += convertNewLine(this.reply?.reply);
      return replyContent;
    },
    isTarget() {
      return (
        this.$route.query.target_type === "REPLY" &&
        this.$route.query.target_id === String(this.reply.replyId)
      );
    },
  },
};
</script>
