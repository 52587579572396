<template>
  <div class="lyPage">
    <main class="lyBody">
      <section class="stContent">
        <div class="profile">
          <ul class="profile-list">
            <community-mini-profile
              class="profile-item"
              v-for="user in users"
              :key="user.uid"
              :user="user"
            />
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import scrollMixin from "@/mixins/scrollMixin";
import { boardApi } from "@/api";
import CommunityMiniProfile from "@/views/community/components/CommunityMiniProfile";

export default {
  name: "CommunitySearchProfile",
  mixins: [scrollMixin],
  components: {
    CommunityMiniProfile,
  },
  created() {
    const { keyword } = this.$route.query;
    this.keyword = keyword;
    this.setData();
  },
  data() {
    return {
      keyword: "",
      users: null,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
    };
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      this.isFetching = true;
      try {
        const {
          data: { content, total },
        } = await boardApi.getBoardSearchUser({
          keyword: this.keyword,
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.users = this.users || [];
        this.users = [...this.users, ...content];
        this.totalCount = total;
      } finally {
        this.isFetching = true;
      }
    },
  },
};
</script>
