const state = () => ({
  filters: {},
});

const mutations = {};

const actions = {
  setFilters({ state }, payload) {
    state.filters = payload;
  },
};

const getters = {
  filters: ({ filters }) => {
    try {
      return JSON.parse(filters);
    } catch {
      return "";
    }
  },
};

export default {
  namespace: true,
  state,
  mutations,
  actions,
  getters,
};
