<template>
  <div>
    <modal-container
      v-for="modalItem in modalItems"
      :key="`modal-${modalItem.id}`"
      :ref="modalItem.id"
      :modal-component="modalItem.component"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ConsultLayer from "@/components/modal/nearme/ConsultLayer";
import ModalContainer from "@/components/modal/nearme/ModalContainer.vue";
import AlertModal from "@/components/modal/nearme/AlertModal.vue";
import ConfirmModal from "@/components/modal/nearme/ConfirmModal.vue";
import TerminalInfoModal from "@/components/modal/nearme/TerminalInfoModal.vue";
import LuggageNoticeModal from "@/components/modal/nearme/LuggageNoticeModal.vue";
import ReservationCautionModal from "@/components/modal/nearme/ReservationCautionModal.vue";
import TimePickerModal from "@/components/modal/nearme/TimePickerModal.vue";
import PaymentFailModal from "@/components/modal/nearme/PaymentFailModal.vue";
import ReservationSuccessModal from "@/components/modal/nearme/ReservationSuccessModal.vue";
import ReservationAbortModal from "@/components/modal/nearme/ReservationAbortModal.vue";
import ExtraServiceModal from "@/components/modal/nearme/ExtraServiceModal.vue";
import VideoPlayer from "@/components/modal/nearme/VideoPlayer.vue";

export default {
  name: "Modal",
  components: {
    ModalContainer,
  },
  data() {
    return {
      modalItems: [],
    };
  },
  created() {
    this.$EventBus.$on("consultLayer", this.$consultLayer);
    this.$EventBus.$on("alert", this.$alert);
    this.$EventBus.$on("confirm", this.$confirm);
    this.$EventBus.$on("terminalInfo", this.$terminalInfo);
    this.$EventBus.$on("luggageNotice", this.$luggageNotice);
    this.$EventBus.$on("reservationCaution", this.$reservationCaution);
    this.$EventBus.$on("timePicker", this.$timePicker);
    this.$EventBus.$on("extraService", this.$extraService);
    this.$EventBus.$on("paymentFail", this.$paymentFail);
    this.$EventBus.$on("reservationSuccess", this.$reservationSuccess);
    this.$EventBus.$on("reservationAbort", this.$reservationAbort);
    this.$EventBus.$on("videoPlayer", this.$videoPlayer);
  },
  methods: {
    $consultLayer(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, ConsultLayer);
    },
    $alert(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, AlertModal);
    },
    $confirm(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, ConfirmModal);
    },
    $terminalInfo(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, TerminalInfoModal);
    },
    $luggageNotice(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, LuggageNoticeModal);
    },
    $reservationCaution(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, ReservationCautionModal);
    },
    $timePicker(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, TimePickerModal);
    },
    $extraService(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, ExtraServiceModal);
    },
    $reservationSuccess(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, ReservationSuccessModal);
    },
    $reservationAbort(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, ReservationAbortModal);
    },
    $paymentFail(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, PaymentFailModal);
    },
    $videoPlayer(modalInfo) {
      const modalId = uuidv4();
      this.pushModalItem(modalId, modalInfo, VideoPlayer);
    },
    pushModalItem(modalId, modalInfo, modalComponent) {
      const { resolve } = modalInfo;
      this.modalItems.push({
        id: modalId,
        component: modalComponent,
      });
      this.modalItems = [...this.modalItems];
      this.$nextTick(() => {
        this.$refs[modalId]?.[0]?.show?.({
          ...modalInfo,
          resolve: (payload) => {
            this.modalItems.splice(
              this.modalItems.findIndex(({ id }) => id === modalId),
              1
            );
            resolve(payload);
          },
        });
      });
    },
  },
};
</script>
<style scoped>
</style>