import axios from "axios";
import { setInterceptors } from "@/common/interceptor";

function createAxiosService() {
  const axiosService = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/v1.1`,
    timeout: Number(process.env.VUE_APP_API_TIMEOUT),
  });
  return setInterceptors(axiosService);
}

const httpV2 = createAxiosService();

export default httpV2;
