<template>
  <modal-layout :close="close">
    <template v-slot:message>
      <span v-html="content" />
    </template>
    <template v-slot:footer>
      <div class="bl_btn">
        <ul class="el_reset bl_btn_list">
          <li class="bl_btn_item">
            <nm-button @click="hide(false)"> 취소 </nm-button>
          </li>
          <li class="bl_btn_item">
            <nm-button class="is_moPrim" @click="hide(true)"> 확인 </nm-button>
          </li>
        </ul>
      </div>
    </template>
  </modal-layout>
</template>
<script>
import { preventBackButton } from "@/util/webview";
import NmButton from "@/components/nearme/NmButton.vue";
import ModalLayout from "@/components/layout/nearme/ModalLayout.vue";

export default {
  components: { ModalLayout, NmButton },
  name: "ConfirmModal",
  data() {
    return {
      resolve: () => {},
      content: null,
      close: false,
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
  },
};
</script>
