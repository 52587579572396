<template>
  <div class="lyPage">
    <main class="lyBody">
      <section class="stProfile" :class="{ 'is-ownProfile': isOwnProfile }">
        <!-- 카메라 활성 : is-activeCamera -->
        <div class="image">
          <div
            class="image-object"
            :style="{ 'background-image': `url('${userInfo.profileImgUrl}')` }"
          ></div>
          <i class="image-camera"></i>
        </div>
        <div class="information">
          <div class="information-name">{{ userInfo.nickname }}</div>
          <!-- 남성 : is-genderMale / 여성 : is-genderFemale -->
          <div
            class="information-age"
            :class="userGenderClass"
            v-if="userGenderClass || userInfo.age"
          >
            <div class="information-ageObject">{{ userInfo.age }}</div>
          </div>
        </div>
        <div class="location">{{ addrText }}</div>
        <!-- 온 상태 : is-stateOn -->
        <div
          class="my"
          :class="{ 'is-stateOn': userInfo.followYn === 'Y' }"
          @click.stop="toggleFollow"
          v-if="!isOwnProfile"
        >
          {{ userInfo.followYn === "Y" ? "골프친구" : "골프친구 추가" }}
        </div>
      </section>
      <!-- 프로필 활성 : is-activeProfile / 통계 활성 : is-activeStatistics -->
      <section class="stInformation" :class="infoTab">
        <div class="wrapper">
          <nav class="tab">
            <ul class="tab-list">
              <li
                class="tab-item is-profile"
                @click="
                  logEvent('community_profile_tap_profile_click', () => {
                    activeTab = 'profile';
                  })
                "
              >
                <div class="tab-object">프로필</div>
              </li>
              <li
                class="tab-item is-statistics"
                @click="
                  logEvent('community_profile_tap_statistics_click', () => {
                    activeTab = 'stat';
                  })
                "
              >
                <div class="tab-object">통계</div>
              </li>
            </ul>
          </nav>
          <div class="content is-profile">
            <div class="content-information">
              <ul class="content-informationList">
                <li class="content-informationItem">
                  <div class="content-informationTitle">골프친구</div>
                  <div class="content-informationValue">
                    <div class="content-informationValueMain">
                      {{ profile.followerCnt | formatCount }}
                    </div>
                  </div>
                </li>
                <li class="content-informationItem">
                  <div class="content-informationTitle">게시물</div>
                  <div class="content-informationValue">
                    <div class="content-informationValueMain">
                      {{ profile.boardCnt | formatCount }}
                    </div>
                  </div>
                </li>
                <li class="content-informationItem">
                  <div class="content-informationTitle">총 라운드</div>
                  <div class="content-informationValue">
                    <div class="content-informationValueMain">
                      {{ profile.roundCnt | formatCount }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="content-most">
              <div class="content-mostTitle">Most 골프장</div>
              <div class="content-mostContent">{{ mostClubName }}</div>
            </div>
          </div>
          <div class="content is-statistics">
            <div class="content-information">
              <ul class="content-informationList">
                <li class="content-informationItem">
                  <div class="content-informationTitle">베스트 스코어</div>
                  <div class="content-informationValue">
                    <div class="content-informationValueMain">
                      {{ profile.bestStroke }}
                    </div>
                    <div class="content-informationValueSub">
                      ({{
                        profile.bestScore > 0
                          ? `+${profile.bestScore}`
                          : profile.bestScore
                      }})
                    </div>
                  </div>
                  <div class="content-informationCaption">
                    {{ bestRank  }}위
                  </div>
                </li>
                <li class="content-informationItem">
                  <div class="content-informationTitle">평균 타수</div>
                  <div class="content-informationValue">
                    <div class="content-informationValueMain">
                      {{ profile.avgStroke }}
                    </div>
                  </div>
                  <div class="content-informationCaption">
                    {{ avgRank }}위
                  </div>
                </li>
                <li class="content-informationItem is-recent">
                  <i class="content-informationInformation" @click="showInfo">
                    정보
                  </i>
                  <div class="content-informationTitle">
                    최근 3경기<br />
                    평균 스코어
                  </div>
                  <div class="content-informationValue">
                    <div class="content-informationValueMain">
                      {{ profile.recentStroke }}
                    </div>
                    <div class="content-informationValueSub">
                      ({{
                        profile.recentScore > 0
                          ? `+${profile.recentScore}`
                          : profile.recentScore
                      }})
                    </div>
                  </div>
                  <div class="content-informationCaption">
                    {{ recentRank }}위
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="stPost">
        <div class="cmPost">
          <ul class="cmPost-list" ref="scrollList">
            <community-post
              v-for="board in boardList"
              :key="board.boardId"
              :board="board"
              @deletePost="onDeletePost"
              @openPostLayer="onOpenPostLayer"
            />
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import CommunityPost from "@/views/community/components/CommunityPost";
import { boardApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import { formatCount } from "@/util/string";
import { debounce } from "lodash";
import { showToast } from "@/util/webview";
import { mapGetters } from "vuex";

export default {
  name: "CommunityProfile",
  mixins: [scrollMixin],
  components: {
    CommunityPost,
  },
  async created() {
    this.uid = this.$route.params.uid;
    this.updateFollow = debounce(this.updateFollow, 500);
    this.getProfile();
    await this.setData();
    window.communityTabActivated = () => {
      this.updateBoard();
    };
  },
  mounted() {
    this.addScrollEvent();
  },
  data() {
    return {
      uid: "",
      lastId: undefined,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      profile: {},
      boardList: null,
      activeTab: "profile",
      lastFollowYn: "",
      lastOpenBoardId: undefined,
      bestRank: "+999",
      avgRank: "+999",
      recentRank: "+999"
    };
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, last },
        } = await boardApi.getFollowProfileBoardList(this.uid, {
          page: this.pageIndex,
          lastId: this.lastId,
          size: this.pageSize,
        });
        this.boardList = this.boardList || [];
        this.boardList = [...this.boardList, ...content];
        this.lastId = content[content.length - 1]?.boardId;
        this.last = last;
      } finally {
        this.isFetching = false;
      }
    },
    onOpenPostLayer(boardId) {
      this.lastOpenBoardId = boardId;
    },
    onDeletePost() {
      this.pageIndex = 1;
      this.lastId = undefined;
      this.last = false;
      this.boardList = null;
      this.setData();
    },
    async updateBoard() {
      if (!this.lastOpenBoardId) {
        return;
      }
      const {
        data: { content },
      } = await boardApi.getBoards({
        size: 1,
        orderBy: "date",
        lastId: this.lastOpenBoardId + 1,
      });
      const [board] = content;
      if (board) {
        this.boardList = this.boardList.map((item) =>
          item.boardId === board.boardId ? board : item
        );
      }
    },
    async getProfile() {
      const { data: profile } = await boardApi.getFollowProfile(this.uid);
      this.profile = profile;
      this.bestRank = this.profile.bestRank > 0 ? this.profile.bestRank : this.bestRank;
      this.avgRank = this.profile.avgRank > 0 ? this.profile.avgRank : this.avgRank;
      this.recentRank = this.profile.recentRank > 0 ? this.profile.recentRank : this.recentRank;
      this.lastFollowYn = profile.userInfo.followYn;
    },
    async toggleFollow() {
      let eventName = "";
      if (this.userInfo.followYn === "Y") {
        eventName = "community_profile_my_off";
        this.userInfo.followYn = "N";
      } else if (this.userInfo.followYn === "N") {
        eventName = "community_profile_my_on";
        this.userInfo.followYn = "Y";
      }
      this.logEvent(eventName);
      await this.updateFollow();
    },
    async updateFollow() {
      if (this.lastFollowYn === this.userInfo.followYn) {
        return;
      }
      this.lastFollowYn = this.userInfo.followYn;
      if (this.userInfo.followYn === "Y") {
        await boardApi.createFollow(this.userInfo.uid);
        showToast({ message: "MY 단골에 추가했습니다" });
        return;
      } else if (this.userInfo.followYn === "N") {
        showToast({ message: "MY 단골에서 삭제했습니다" });
        return await boardApi.deleteFollow(this.userInfo.uid);
      }
    },
    showInfo() {
      showToast({ message: `18홀 스코어가 모두 입력된 최근 3개 라운드 평균` });
    },
  },
  computed: {
    ...mapGetters(["userUid"]),
    userInfo() {
      return this.profile.userInfo || {};
    },
    isOwnProfile() {
      return this.userUid === this.uid;
    },
    mostClubName() {
      return this.profile.mostClub?.clubName || "-";
    },
    userGenderClass() {
      switch (this.userInfo.gender) {
        case "M":
          return "is-genderMale";
        case "F":
          return "is-genderFemale";
        default:
          return "";
      }
    },
    infoTab() {
      switch (this.activeTab) {
        case "profile":
          return "is-activeProfile";
        case "stat":
          return "is-activeStatistics";
        default:
          return "";
      }
    },
    addrText() {
      if (!this.userInfo?.addr) {
        return "지역 정보 없음";
      }
      const [si, gu] = this.userInfo.addr.split(" ");
      return `${si} ${gu}`;
    },
  },
  filters: {
    formatCount,
  },
};
</script>

<style scoped>
.id-communityProfile .lyBody .stProfile.is-ownProfile {
  height: 170px;
}
.information-ageObject {
  height: 100%;
}
</style>
