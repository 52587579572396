import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/ko";

const relativeConfig = {
  thresholds: [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 30, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y" },
    { l: "yy", d: "year" },
  ],
};

dayjs.extend(relativeTime, relativeConfig);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.locale("ko");
dayjs.updateLocale("ko", {
  relativeTime: {
    future: "%s 전",
    past: "%s 전",
    s: "방금",
    m: "1분",
    mm: "%d분",
    h: "1시간",
    hh: "%d시간",
    d: "1일",
    dd: "%d일",
    M: "1달",
    MM: "%d달",
    y: "1년",
    yy: "%d년",
  },
});

export default {
  methods: {
    convertDate(timeStamp) {
      return dayjs.unix(timeStamp).format("YYYY-MM-DD");
    },
    convertTime(timeStamp, format="HH:mm") {
      if (!timeStamp) {
        return "";
      }

      const date = dayjs.unix(timeStamp).format(format);
      return date !== "Invalid Date"
        ? date
        : dayjs().format(format);
    },
    convertDateTime(timeStamp, format="YYYY-MM-DD HH:mm") {
      return dayjs.unix(timeStamp).format(format);
    },
    convertTimeFromNow(timeStamp) {
      return dayjs.unix(timeStamp).fromNow();
    },
    convertTimestamp(dateString) {
      if (!dateString) {
        return "";
      }

      const date = dayjs(dateString).unix();
      return date !== "Invalid Date"
        ? date
        : dayjs().unix();
    },
    getDateTime(dateString, format = "YYYY-MM-DD HH:mm") {
      if (!dateString) {
        return "";
      }

      const date = dayjs(dateString).format(format);
      return date !== "Invalid Date"
        ? date
        : dayjs().format(format);
    },
  },
};
