<template>
  <div class="policy">
    <h1>마케팅 정보 활용 동의</h1>
    <p>
      브이씨소프트(주)(이하 “회사”)는 개인정보보호법 등에 관한 법률 등
      관계법령에 따라 마케팅 정보를 전송하기 위해 수신자의 사전 수신동의를 받고
      있습니다. 마케팅 정보 수신 동의는 거부하실 수 있으며 동의 이후에 라도
      고객의 의사에 따라 동의를 철회할 수 있습니다. 동의를 거부하시더라도
      개인정보보호법 제22조 제5항에 의거하여 회사가 제공하는 서비스의 이용에
      제한이 되지 않습니다. 단, 할인, 이벤트 및 이용자 맞춤형 상품 추천 등의
      마케팅 정보 안내 서비스가 제한됩니다.
    </p>
    <h2>전송 방법</h2>
    <p>
      발송하는 마케팅 정보는 수신자에게 회사가 운영하는 서비스에서 제공하는 혜택
      정보, 각종 이벤트 정보, 신규 서비스 안내 등 광고성 정보로 관련 법의 규정을
      준수하여 발송됩니다. 단, 광고성 정보 외에 의무적으로 안내되어야 하는
      정보성 내용은 수신동의 여부와 무관하게 제공됩니다.
    </p>
    <h2>철회 안내</h2>
    <p>
      고객님은 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을
      동의하지 않아도 회사가 제공하는 기본적인 서비스를 이용할 수 있습니다. 단,
      회사의 마케팅 정보를 수신하지 못할 수 있습니다.
    </p>
    <h2>개인정보 이용 상세 내용</h2>
    <table>
      <colgroup>
        <col style="width: 25%" />
        <col style="width: 75%" />
      </colgroup>
      <tbody>
        <tr>
          <th>수집 항목</th>
          <td>휴대폰 번호, 이메일 주소</td>
        </tr>
        <tr>
          <th>이용 목적</th>
          <td>혜택 및 각종 이벤트 정보, 신규 서비스 안내</td>
        </tr>
        <tr>
          <th>제공 기간</th>
          <td>마케팅 동의 철회 및 회원 탈퇴시 까지</td>
        </tr>
      </tbody>
    </table>
    <h2>약관의 변경</h2>
    <p>
      회사는 약관 변경 시 최소 7일 이전, 중대한 변경이 있는 경우 30일 이전에
      APL골프 앱 등에 게시하고 이용자에게 통지합니다. 해당 변경 사항은 변경된
      약관이 적용되는 날짜에 다시 한 번 이용자에게 통지됩니다.
    </p>
    <h2>부 칙</h2>
    <ol>
      <li>제1조 (시행일) 이 약관은 2022년 10월 28일부터 시행한다.</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Marketing",
};
</script>

<style scoped>
table {
  margin: 0 20px;
  margin-bottom: 14px;
}
table,
td,
th {
  border: 1px solid black;
  border-collapse: collapse;
}
td,
th {
  padding: 6px;
}
th {
  background-color: #f3f3f3;
}
</style>
