<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="ly_container">
              <div class="bl_product_wrapper">
                <div class="bl_product">
                  <div class="bl_product_box">
                    <div
                      class="bl_product_box_img"
                      :style="{
                        'background-image': `url('${thumbnailImg}')`,
                      }"
                    ></div>
                  </div>
                  <div class="bl_product_label">
                    <div class="bl_product_label_inner">
                      <div class="bl_product_label_txt">{{ brand }}</div>
                      <div class="bl_product_label_pay">
                        총
                        <span class="bl_product_label_pay_num">{{
                          saleCnt | comma
                        }}</span
                        >개 판매
                      </div>
                    </div>
                  </div>
                  <div class="bl_product_ttl">
                    {{ productName }}
                  </div>
                  <div class="bl_product_info">
                    <div class="bl_product_info_sale">
                      <div class="bl_product_info_sale_num">
                        {{ discountRatio }}
                      </div>
                      <div class="bl_product_info_sale_unit">%</div>
                    </div>
                    <div class="bl_product_info_price">
                      <div class="bl_product_info_price_num">
                        {{ price | comma }}
                      </div>
                      <div class="bl_product_info_price_unit">원</div>
                    </div>
                  </div>
                  <div class="bl_product_share">
                    <button
                      class="el_form bl_product_share_btn"
                      @click="shareProduct"
                    ></button>
                  </div>
                </div>
              </div>
              <div class="bl_detail_wrapper">
                <div class="bl_detail" v-html="content"></div>
              </div>
            </div>
          </main>
          <footer class="ly_footer">
            <div class="ly_container">
              <div class="bl_purchase_wrapper">
                <div class="bl_purchase">
                  <button class="el_form bl_purchase_btn">
                    <a :href="pageUrl"> AK골프에서 구매하기</a>
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partnershipApi } from "@/api";
import { comma } from "@/util/string";
import { sharedPost } from "@/util/webview";

export default {
  name: "ProductView",
  async created() {
    document.title = "akgolf_logo/ 인기 상품";
    const { categoryCd, productId } = this.$route.params;
    this.categoryCd = categoryCd;
    this.productId = productId;
    await this.setData();
  },
  data() {
    return {
      categoryCd: "",
      productId: "",
      product: null,
    };
  },
  methods: {
    async setData() {
      const { data } = await partnershipApi.getCategoryProduct(
        this.categoryCd,
        this.productId
      );
      this.product = data;
    },
    async shareProduct() {
      const bridgeUrl = process.env.VUE_APP_APP_AK_BRIDGE_URL;
      await sharedPost({
        bridgeUrl,
        url: `/ak-golf/${this.categoryCd}/${this.productId}`,
      });
    },
  },
  computed: {
    thumbnailImg() {
      return this.product?.thumbImgUrl;
    },
    brand() {
      return this.product?.brand;
    },
    saleCnt() {
      return this.product?.saleCnt;
    },
    productName() {
      return this.product?.productName;
    },
    discountRatio() {
      return this.product?.discountRatio;
    },
    price() {
      return this.product?.price;
    },
    content() {
      return this.product?.content;
    },
    pageUrl() {
      return this.product?.pageUrl;
    },
  },
  filters: {
    comma,
  },
};
</script>

<style>
.bl_purchase_btn a {
  text-decoration: none;
  color: #fff;
}
.bl_detail img {
  width: 100%;
}
</style>
