<template>
  <div class="ly_page" ref="scrollList">
    <div class="ly_page_inner">
      <main class="ly_body">
        <div class="bl_status_wrapper">
          <div class="bl_status">
            <h4 class="bl_status_ttl">친구 가입 현황</h4>
            <div class="bl_status_desc">
              내 초대 코드를 입력한 친구가 표기됩니다.
            </div>
            <div class="bl_status_info">
              <ul class="bl_status_info_infoUnit">
                <li class="bl_status_info_info">
                  <div class="bl_status_info_info_txt">
                    {{ totalCount | comma }}명
                  </div>
                  <div class="bl_status_info_info_ttl">가입한 친구</div>
                </li>
                <li class="bl_status_info_info">
                  <div class="bl_status_info_info_txt">
                    {{ point | comma }}P
                  </div>
                  <div class="bl_status_info_info_ttl">누적 혜택 현황</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bl_join_wrapper">
          <div class="bl_join">
            <div v-if="users && !users.length" class="bl_join_none">
              <div class="bl_join_none_txt">
                내 추천 코드를 통해<br />
                회원가입 한 친구가 없습니다.
              </div>
            </div>
            <ul class="bl_join_infoUnit" v-else>
              <invite-profile
                v-for="user in users"
                :key="user.uid"
                :user="user"
              />
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import InviteProfile from "@/views/invite/component/InviteProfile";
import { userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import { comma } from "@/util/string";

export default {
  name: "InviteStatus",
  mixins: [scrollMixin],
  components: {
    InviteProfile,
  },
  data() {
    return {
      users: null,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      point: 0,
    };
  },
  async created() {
    await this.setData();
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      this.isFetching = true;
      try {
        const {
          data: { point, list, total },
        } = await userApi.getInviteUsers({
          page: this.pageIndex,
          size: this.pageSize,
        });

        this.users = this.users || [];
        this.users = [...this.users, ...list];
        this.totalCount = total;
        this.point = point;
      } finally {
        this.isFetching = false;
      }
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>
