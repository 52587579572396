<template>
  <div></div>
</template>

<script>
import { closeWebviewLayer } from "@/util/webview";

export default {
  name: "Fail",
  created() {
    const { message } = this.$route.query;
    alert(`멤버십 가입에 실패했습니다.\n${message}`);
    closeWebviewLayer();
  },
};
</script>
