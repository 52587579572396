/* Minimum Size Responsive */
rem();
window.addEventListener("resize", rem);

/* Iphone Check */
if (navigator.userAgent.match(/iP(hone|od|ad)/)) {
  document
    .getElementsByName("viewport")[0]
    .setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
} else {
  document
    .getElementsByName("viewport")[0]
    .setAttribute("content", "width=device-width, initial-scale=1.0");
}

/* Minimum Size Responsive */
function rem() {
  let clientWidth = document.documentElement.clientWidth;
  let breakPoint = 720;
  let rem = document.documentElement.clientWidth / breakPoint;
  if (clientWidth < breakPoint) {
    document
      .querySelector(":root")
      .setAttribute("style", "font-size:" + rem * 10 + "px;");
  } else {
    document.querySelector(":root").setAttribute("style", "font-size: 10px;");
  }
}
