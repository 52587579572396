import { Loader } from "@googlemaps/js-api-loader";
export const NEARME_AIRPORT_SERVICE_CITY_LIST = [
  "아다치구",
  "아라카와구",
  "이타바시구",
  "에도가와구",
  "오타구",
  "카츠시카구",
  "가쓰시카구",
  "키타구",
  "기타구",
  "고토구",
  "시나가와구",
  "시부야구",
  "신주쿠구",
  "스기나미구",
  "스미다구",
  "세타가야구",
  "다이토구",
  "주오구",
  "치요다구",
  "지요다구",
  "도시마구",
  "나카노구",
  "네리마구",
  "분쿄구",
  "미나토구",
  "메구로구",
  "우라야스시",
  "도쿄도",
];
export const NEARME_GOLF_SERVICE_CITY_LIST = [
  "아다치구",
  "아라카와구",
  "이타바시구",
  "에도가와구",
  "오타구",
  "카츠시카구",
  "가쓰시카구",
  "키타구",
  "기타구",
  "고토구",
  "시나가와구",
  "시부야구",
  "신주쿠구",
  "스기나미구",
  "스미다구",
  "세타가야구",
  "다이토구",
  "주오구",
  "치요다구",
  "지요다구",
  "도시마구",
  "나카노구",
  "네리마구",
  "분쿄구",
  "미나토구",
  "메구로구",
  "나리타시",
];
const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  version: "weekly",
  libraries: ["places"],
  language: "ko",
  region: "JP"
});

export default loader;
