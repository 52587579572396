<template>
  <input
    type="text"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    :value="computedValue"
    @input="onInput"
    :autofocus="autoFocus"
  />
</template>

<script>
import { getByteIndexLength, getByteLength } from "@/util/string";
import { keyboardUp } from "@/util/webview";

export default {
  name: "ByteInput",
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    maxByte: {
      type: Number,
      default: 0,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autoFocus) {
      keyboardUp();
    }
  },
  methods: {
    onInput(e) {
      this.computedValue = e.target.value;
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isMaxByte() {
      return this.maxByte && this.maxByte <= getByteLength(this.value);
    },
    maxByteLength() {
      return getByteIndexLength(this.value, this.maxByte);
    },
  },
  watch: {
    value(val) {
      this.computedValue = val.substring(0, this.maxByteLength);
    },
  },
};
</script>
