<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_progress">
            <ul class="el_reset bl_progress_list">
              <li class="bl_progress_item">
                <div class="bl_progress_txt">1</div>
              </li>
              <li class="bl_progress_item">
                <div class="bl_progress_txt is_moPrim">2</div>
              </li>
            </ul>
          </div>
          <div class="bl_header">
            <div class="bl_header_ttl">예약자 정보</div>
            <div class="bl_header_txt">벌써 마지막 단계에요!</div>
          </div>
          <div class="bl_input">
            <ul class="el_reset bl_input_list">
              <li class="bl_input_item">
                <nm-byte-input
                  v-model="koreanName"
                  placeholder="(한글) 이름"
                  type="text"
                  pattern="[^ㄱ-힣]"
                  :max-byte="18"
                />
              </li>
              <li class="bl_input_item is_moHalf">
                <nm-byte-input
                  v-model="inputEnglishFirstName"
                  placeholder="(영문) 이름"
                  type="text"
                  pattern="[^a-zA-Z]"
                  :max-byte="30"
                />
              </li>
              <li class="bl_input_item is_moHalf">
                <nm-byte-input
                  v-model="inputEnglishLastName"
                  placeholder="(영문) 성"
                  type="text"
                  pattern="[^a-zA-Z]"
                  :max-byte="30"
                />
              </li>
              <li class="bl_input_item">
                <nm-input
                  v-model="inputPhoneNumber"
                  placeholder="휴대폰"
                  type="text"
                  maxlength="13"
                  pattern="[^0-9]"
                />
              </li>
              <li class="bl_input_item">
                <nm-input
                  v-model="inputEmail"
                  placeholder="이메일"
                  type="text"
                  maxlength="50"
                  :message="emailErrorMessage"
                  :validate="emailValidate"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ly_body_footer">
        <div class="ly_container">
          <div class="bl_btn">
            <ul class="el_reset bl_btn_list">
              <li class="bl_btn_item is_moMin">
                <nm-button @click="saveTemporary">임시 저장</nm-button>
              </li>
              <li class="bl_btn_item">
                <nm-button
                  class="is_moPrim"
                  :disabled="missingRequirements"
                  @click="goToPayment"
                  >다음</nm-button
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { nearmeApi, userApi } from "@/api";
import { phoneNumberFormatter } from "@/util/string";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmInput from "@/components/nearme/NmInput.vue";
import NmByteInput from "@/components/nearme/NmByteInput.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import reservationMixin from "@/mixins/reservationMixin";
export default {
  name: "BookerInfo",
  components: {
    DefaultLayout,
    NmInput,
    NmByteInput,
    NmButton,
  },
  mixins: [reservationMixin],
  data() {
    return {
      serviceType: null,
      userInfo: null,
      koreanName: "",
      englishFirstName: "",
      englishLastName: "",
      phoneNumber: "",
      email: "",
      emailErrorMessage: "",
      emailValidate: true,
      emailPattern: /^[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\.[a-z]{2,}$/,
      isFetching: false,
    };
  },
  async created() {
    const { service_type } = this.$route.query;
    this.serviceType = service_type;
    this.title =
      this.serviceType === "airport" ? "공항 픽업 예약" : "골프장 픽업 예약";
    this.$nextTick(() => {
      document.title = this.title;
    });

    await this.setData();
  },
  computed: {
    missingRequirements() {
      return (
        !this.koreanName?.trim() ||
        this.koreanName?.trim()?.length < 2 ||
        !this.inputEnglishFirstName?.trim() ||
        !this.inputEnglishLastName?.trim() ||
        !this.inputPhoneNumber?.trim() ||
        !this.inputEmail.trim()
      );
    },
    inputEnglishFirstName: {
      get() {
        return this.englishFirstName?.toUpperCase();
      },
      set(value) {
        this.englishFirstName = value;
      },
    },
    inputEnglishLastName: {
      get() {
        return this.englishLastName?.toUpperCase();
      },
      set(value) {
        this.englishLastName = value;
      },
    },
    inputPhoneNumber: {
      get() {
        return phoneNumberFormatter(this.phoneNumber);
      },
      set(value) {
        this.phoneNumber = value;
      },
    },
    inputEmail: {
      set(value) {
        if (value.length === 0) {
          this.emailValidate = true;
          this.emailErrorMessage = "";
        }
        this.email = value;
      },
      get() {
        return this.email;
      },
    },
  },
  methods: {
    async setData() {
        await this.getUserInfo();
        if (this.hasBookerInfo()) {
          // 저장된 데이터 로드
          const { name, eng_first_name, eng_last_name, phone_number, email } =
            this.getBookerInfo();
          this.koreanName = name;
          this.inputEnglishFirstName = eng_first_name;
          this.inputEnglishLastName = eng_last_name;
          this.inputPhoneNumber = phone_number;
          this.inputEmail = email || "";
        } else {
          // 이전 예약 기록 있는 경우 예약자 정보 불러오기(단 기존에 입력 혹은 임시저장 데이터 불러오기 했을 경우 무시)
          const {
            data: { name, engFirstName, engLastName, phoneNumber, email },
          } = await nearmeApi.getCurrentlyBookerInfo();

          if (name && engFirstName && engLastName && phoneNumber) {
            if (
              confirm("이전 예약 때 입력했던 예약자 정보를 불러오시겠습니까?")
            ) {
              this.koreanName = name;
              this.inputEnglishFirstName = engFirstName;
              this.inputEnglishLastName = engLastName;
              this.inputPhoneNumber = phoneNumber;
              this.inputEmail = email || "";
            }
          }
        }
    },
    async getUserInfo() {
      const { data } = await userApi.getUserInfo();
      if (data.niceCertDt !== 0 && data.niceCertDt !== null) {
        this.koreanName = data.userName;
        this.inputPhoneNumber = data.hpNo;
      }
    },
    initData() {
      this.koreanName = "";
      this.inputEnglishFirstName = "";
      this.inputEnglishLastName = "";
      this.inputPhoneNumber = "";
    },
    async saveTemporary() {
      this.setBookerInfo({
        name: this.koreanName,
        eng_first_name: this.inputEnglishFirstName,
        eng_last_name: this.inputEnglishLastName,
        phone_number: this.inputPhoneNumber,
        email: this.inputEmail,
      });

      if (this.isFetching) return;
      const reservationInfo = this.getReservationInfo();

      try {
        this.isFetching = true;
        await nearmeApi.createReservationTemporary({
          arriveAddress: reservationInfo.arrival_address,
          arriveLat: reservationInfo.arrival_lat,
          arriveLng: reservationInfo.arrival_lng,
          arriveName: reservationInfo.arrival_name,
          arriveEngName: reservationInfo.arrival_eng_name || null,
          carrierLarge: reservationInfo.carrier_large || null,
          carrierMedium: reservationInfo.carrier_medium || null,
          carrierSmall: reservationInfo.carrier_small || null,
          departureAddress: reservationInfo.departure_address,
          departureLat: reservationInfo.departure_lat,
          departureLng: reservationInfo.departure_lng,
          departureName: reservationInfo.departure_name,
          departureEngName: reservationInfo.departure_eng_name || null,
          engFirstName: reservationInfo.eng_first_name,
          engLastName: reservationInfo.eng_last_name,
          halfBag: reservationInfo.half_bag || null,
          name: reservationInfo.name,
          oneWayDate: reservationInfo.one_way_date,
          oneWayTime: reservationInfo.one_way_time,
          otherWithin100: reservationInfo.other_with_in_100 || null,
          otherWithin150: reservationInfo.other_with_in_150 || null,
          otherWithin200: reservationInfo.other_with_in_200 || null,
          passengers: reservationInfo.passengers || null,
          phoneNumber: reservationInfo.phone_number || null,
          recommendationPathId: reservationInfo.recommendation_path_id || null,
          reserve: reservationInfo.reverse_yn || "N",
          roundTripDate: reservationInfo.round_trip_date || null,
          roundTripTime: reservationInfo.round_trip_time || null,
          serviceName: this.serviceType === "airport" ? "Airport" : "Golf",
          standBag: reservationInfo.stand_bag || null,
          tourBag: reservationInfo.tour_bag || null,
          searchName: reservationInfo.search_name,
          email: reservationInfo.email,
        });
        alert("임시저장이 완료되었습니다.");
      } finally {
        this.isFetching = false;
      }
    },
    goToPayment() {
      if (!this.emailPattern.test(this.inputEmail)) {
        this.emailValidate = false;
        this.emailErrorMessage = "이메일 형식에 맞춰 입력해주세요.";

        return;
      }

      this.emailValidate = true;
      this.emailErrorMessage = "";

      this.setBookerInfo({
        name: this.koreanName,
        eng_first_name: this.inputEnglishFirstName,
        eng_last_name: this.inputEnglishLastName,
        phone_number: this.inputPhoneNumber,
        email: this.email,
      });
      this.$router.push({
        name: "PaymentView",
        query: {
          service_type: this.serviceType,
        },
      });
    },
  },
};
</script>