<template>
  <default-layout>
    <template v-slot:header>
      <div class="bl_ttl">{{ title }}</div>
      <div class="bl_right is_moClose" @click="back"></div>
    </template>
    <template v-slot:main>
      <!-- 공항 터미널 목록 -->
      <template v-if="searchType === 'airport'">
        <terminal-list @select="selectedTerminal" />
      </template>
      <!-- 골프장 선택 -->
      <template v-else-if="searchType === 'club'">
        <!-- 골프장 목록 -->
        <club-list @select="selectedClub" route="departure" />
      </template>
      <!-- 장소 검색  -->
      <template v-else>
        <place-list
          :placeholder="placeholderTxt"
          route="departure"
          @select="selectedPlace"
        />
      </template>
    </template>
  </default-layout>
</template>
<script>
import { setDataTopToBottomWebLayer, closeWebviewLayer } from "@/util/webview";
import TerminalList from "@/views/nearme/search/components/TerminalList.vue";
import PlaceList from "@/views/nearme/search/components/PlaceList.vue";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import ClubList from "@/views/nearme/search/components/ClubList.vue";
import reservationMixin from "@/mixins/reservationMixin";
export default {
  components: { DefaultLayout, TerminalList, PlaceList, ClubList },
  name: "DepartureSearch",
  mixins: [reservationMixin],
  data() {
    return {
      title: "",
      serviceType: "",
      searchType: "",
    };
  },
  created() {
    const { service_type, search_type } = this.$route.query;
    this.serviceType = service_type;
    this.searchType = search_type;
    this.title = this.searchType === "club" ? "골프장 선택" : "출발지";

    this.$nextTick(() => {
      document.title = this.title;
    });

    if (this.searchType === "airport") {
      // 공항 선택
      document.querySelector("body").classList.add("id_airport");
    } else {
      // 골프장 선택 / 장소 검색
      document.querySelector("body").classList.add("id_place");
    }
  },
  computed: {
    placeholderTxt() {
      return this.searchType === "club" ? "골프장" : "출발지";
    },
  },
  methods: {
    back() {
      closeWebviewLayer();
    },
    async selectedTerminal(terminal) {
      setDataTopToBottomWebLayer({
        route: "departure",
        name: terminal.airportName,
        eng_name: null,
        address: terminal.airportAddress,
        lat: terminal.airportLat,
        lng: terminal.airportLon,
        terminal_cd: terminal.airportCd,
        search_name: null,
      });
    },
    async selectedClub(club) {
      setDataTopToBottomWebLayer({
        route: "departure",
        name: club.name,
        eng_name: club.eng_name,
        address: club.address,
        lat: club.lat,
        lng: club.lng,
        search_name: null,
      });
    },
    async selectedPlace(place, searchName) {
      setDataTopToBottomWebLayer({
        route: "departure",
        name: place.name,
        eng_name: null,
        address: place.address,
        lat: place.lat,
        lng: place.lng,
        search_name: searchName,
        terminal_cd: place.terminal_cd ? place.terminal_cd : null,
      });
    },
  },
};
</script>