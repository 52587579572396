export async function initGoogleMap() {
  return new Promise((resolve) => {
    const jsScript = document.createElement("script");
    jsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&callback=onInitGoogleMap`;
    jsScript.addEventListener("load", () => {
      resolve(true);
    });
    document.body.appendChild(jsScript);
});
}
