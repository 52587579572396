export default {
  mounted() {
    this.addScrollEvent();
  },
  beforeDestroy() {
    this.removeScrollEvent();
  },
  data() {
    return {
      isFetching: false,
      last: false,
    };
  },
  methods: {
    async scrollEvtHandler() {
      if (this.isFetching) return;
      if (this.last) return;
      if (
        this.totalCount &&
        Math.ceil(this.totalCount / this.pageSize) <= this.pageIndex
      ) {
        return;
      }
      const isWindow = !this.$refs.scrollContainer;
      const scrollY = isWindow
        ? window.scrollY + window.innerHeight
        : this.$refs.scrollContainer.scrollTop +
          this.$refs.scrollContainer.offsetHeight;
      const listHeight = isWindow
        ? document.querySelector("body").scrollHeight
        : this.$refs.scrollList.offsetHeight;
      try {
        if (scrollY >= listHeight - 100) {
          this.pageIndex += 1;
          await this.setData();
        }
      } finally {
        this.isFetching = false;
      }
    },
    addScrollEvent() {
      if (!this.$refs.scrollContainer && !this.$refs.scrollList) {
        return;
      }
      if (!this.$refs.scrollContainer && this.$refs.scrollList) {
        window?.addEventListener("scroll", this.scrollEvtHandler);
        return;
      }
      this.$refs.scrollContainer?.addEventListener(
        "scroll",
        this.scrollEvtHandler
      );
    },
    removeScrollEvent() {
      window.removeEventListener("scroll", this.scrollEvtHandler);
      this.$refs.scrollContainer?.removeEventListener?.(
        "scroll",
        this.scrollEvtHandler
      );
    },
    saveScrollPosition(key, content) {
      const scrollY = this.$refs.scrollContainer
        ? this.$refs.scrollContainer.scrollTop
        : window.scrollY;
      const scrollInfo = {
        scrollY,
        content,
        pageIndex: this.pageIndex,
        totalCount: this.totalCount,
      };
      sessionStorage.setItem(key, JSON.stringify(scrollInfo));
    },
    loadScrollPosition(key) {
      const scrollInfo = sessionStorage.getItem(key);
      if (!scrollInfo) {
        return null;
      }
      const { scrollY, content, pageIndex, totalCount } =
        JSON.parse(scrollInfo);
      this.pageIndex = pageIndex;
      this.totalCount = totalCount;
      this.$nextTick(() => {
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scroll(0, scrollY);
        } else {
          window.scroll(0, scrollY);
        }
        sessionStorage.removeItem(key);
      });
      return content;
    },
  },
  watch: {},
};
