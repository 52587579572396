var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lyPage"},[_c('header',{staticClass:"lyHeader"},[_c('section',{staticClass:"stSearch"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"cmSearch",class:{ 'is-activeClear': _vm.searchValue }},[_c('div',{staticClass:"cmSearch-input"},[_c('byte-input',{staticClass:"cmSearch-inputInput",attrs:{"max-byte":100,"placeholder":"검색어를 입력하세요.","auto-focus":true},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click":function($event){return _vm.logEvent('community_search_input_click')}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('i',{staticClass:"cmSearch-inputSearch",on:{"click":_vm.search}},[_vm._v(" 검색 ")]),_c('i',{staticClass:"cmSearch-inputClear",on:{"click":function($event){_vm.logEvent(
                  'community_search_input_remove',
                  function () { return (_vm.searchValue = ''); }
                )}}},[_vm._v(" 지우기 ")])],1)])]),_c('div',{staticClass:"recent"},[_c('div',{staticClass:"cmRecent"},[_c('div',{staticClass:"cmRecent-header"},[_c('div',{staticClass:"cmHeader"},[_c('div',{staticClass:"cmHeader-title"},[_vm._v("최근 검색어")]),_c('div',{staticClass:"cmHeader-button",on:{"click":function($event){return _vm.logEvent(
                    'community_recentsearch_allremove',
                    _vm.removeAllSearchWords
                  )}}},[_vm._v(" 전체삭제 ")])])]),_c('div',{staticClass:"cmRecent-body"},[(!_vm.searchWords.length)?_c('p',{staticClass:"cmRecent-bodyNone"},[_vm._v(" 최근 검색어가 없습니다. ")]):_c('div',{staticClass:"cmRecent-bodyWord"},[_c('ul',{staticClass:"cmRecent-bodyWordList"},_vm._l((_vm.searchWords),function(searchWord){return _c('li',{key:searchWord,staticClass:"cmRecent-bodyWordItem",on:{"click":function($event){_vm.logEvent('community_search_input_remove', function () { return _vm.setSearchValue(searchWord); }
                    )}}},[_c('div',{staticClass:"cmRecent-bodyWordObject"},[_c('div',{staticClass:"cmRecent-bodyWordText"},[_vm._v(_vm._s(searchWord))]),_c('i',{staticClass:"cmRecent-bodyWordDelete",on:{"click":function($event){$event.stopPropagation();_vm.logEvent('community_recentsearch_remove', function () { return _vm.removeSearchWord(searchWord); }
                        )}}},[_vm._v(" 삭제 ")])])])}),0)])])])])])]),(_vm.users || _vm.clubs || _vm.tags)?_c('main',{staticClass:"lyBody"},[_c('section',{staticClass:"stContent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cmHeader"},[_c('div',{staticClass:"cmHeader-title"},[_vm._v("프로필")]),(_vm.users && _vm.users.length >= 5)?_c('div',{staticClass:"cmHeader-button",on:{"click":function($event){_vm.logEvent('community_search_profile_all_click', function () {
                _vm.newWebviewLayerV2({
                  name: 'CommunitySearchProfile',
                  query: { keyword: _vm.currentSearchValue },
                });
              })}}},[_vm._v(" 더보기 ")]):_vm._e()])]),_c('div',{staticClass:"body"},[(!_vm.users || !_vm.users.length)?_c('div',{staticClass:"search-noResult"},[_c('div',{staticClass:"search-caption"},[_vm._v("검색결과가 없어요.")])]):_vm._e(),_c('div',{staticClass:"body-profile"},[_c('ul',{staticClass:"body-profileList"},_vm._l((_vm.users),function(user){return _c('li',{key:user.uid,staticClass:"body-profileItem",on:{"click":function($event){return _vm.onProfileClick(user.uid)}}},[_c('div',{staticClass:"body-profileImage"},[_c('div',{staticClass:"body-profileImageObject",style:({
                    'background-image': ("url('" + (user.profileImgUrl) + "')"),
                  })})]),_c('div',{staticClass:"body-profileText"},[_vm._v(_vm._s(user.nickname))])])}),0)])])]),_c('section',{staticClass:"stContent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cmHeader"},[_c('div',{staticClass:"cmHeader-title"},[_vm._v("장소")]),(_vm.clubs && _vm.clubs.length >= 5)?_c('div',{staticClass:"cmHeader-button",on:{"click":function($event){_vm.logEvent('community_search_place_all_click', function () {
                _vm.newWebviewLayerV2({
                  name: 'CommunitySearchKeyword',
                  query: { keyword: _vm.currentSearchValue, type: 'club' },
                });
              })}}},[_vm._v(" 더보기 ")]):_vm._e()])]),_c('div',{staticClass:"body"},[(!_vm.clubs || !_vm.clubs.length)?_c('div',{staticClass:"search-noResult"},[_c('div',{staticClass:"search-caption"},[_vm._v("검색결과가 없어요.")])]):_vm._e(),_c('div',{staticClass:"cmMatching"},[_c('ul',{staticClass:"cmMatching-list"},_vm._l((_vm.clubs),function(club){return _c('li',{key:club.ccode,staticClass:"cmMatching-item",domProps:{"innerHTML":_vm._s(club.clubNameHtml)},on:{"click":function($event){return _vm.newWebviewLayerV2({
                  name: 'CommunityListBySearch',
                  query: {
                    keyword: club.ccode,
                    location: club.clubName,
                    type: 'club',
                  },
                })}}})}),0)])])]),_c('section',{staticClass:"stContent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cmHeader"},[_c('div',{staticClass:"cmHeader-title"},[_vm._v("해시태그")]),(_vm.tags && _vm.tags.length >= 5)?_c('div',{staticClass:"cmHeader-button",on:{"click":function($event){_vm.logEvent('community_search_hashtag_all_click', function () {
                _vm.newWebviewLayerV2({
                  name: 'CommunitySearchKeyword',
                  query: { keyword: _vm.currentSearchValue, type: 'tag' },
                });
              })}}},[_vm._v(" 더보기 ")]):_vm._e()])]),_c('div',{staticClass:"body"},[(!_vm.tags || !_vm.tags.length)?_c('div',{staticClass:"search-noResult"},[_c('div',{staticClass:"search-caption"},[_vm._v("검색결과가 없어요.")])]):_vm._e(),_c('div',{staticClass:"cmMatching"},[_c('ul',{staticClass:"cmMatching-list"},_vm._l((_vm.tags),function(tag){return _c('li',{key:tag.tagText,staticClass:"cmMatching-item",domProps:{"innerHTML":_vm._s(tag.tagTextHtml)},on:{"click":function($event){return _vm.newWebviewLayerV2({
                  name: 'CommunityListBySearch',
                  query: { keyword: tag.tagText, type: 'tag' },
                })}}})}),0)])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }