<template>
  <default-layout>
    <template v-slot:main>
      <div ref="boarding" class="ly_body_body">
        <div class="ly_container">
          <div class="bl_progress">
            <ul class="el_reset bl_progress_list">
              <li class="bl_progress_item">
                <div class="bl_progress_txt is_moPrim">1</div>
              </li>
              <li class="bl_progress_item">
                <div class="bl_progress_txt">2</div>
              </li>
            </ul>
          </div>
          <div class="bl_header">
            <div class="bl_header_ttl">탑승 정보</div>
            <div class="bl_header_txt">
              택시 배차를 위해 정확히 입력해주세요.
            </div>
          </div>
          <div class="bl_passenger">
            <div class="bl_passenger_ttl">
              <div class="bl_ttl_ttl">탑승 인원</div>
              <div class="bl_ttl_txt">최대 {{ passengersMax }}명 탑승</div>
            </div>
            <div class="bl_passenger_num">
              <!-- 1 일때: is_acOne -->
              <nm-number-quantity
                v-model="passengers"
                :min="1"
                :max="passengersMax"
              />
            </div>
          </div>
          <!-- 없음(C): is_acNone / 추가(C): is_acAdd -->
          <div
            class="bl_bag"
            :class="isAddedLuggage ? 'is_acAdd' : 'is_acNone'"
          >
            <div class="bl_bag_ttl">
              <div class="bl_ttl_ttl">수하물</div>
              <div class="bl_ttl_info" @click="openLuggageNoticeModal"></div>
            </div>
            <div class="bl_bag_navi">
              <ul class="el_reset bl_navi_list">
                <li
                  class="bl_navi_item is_moNone"
                  @click="changeAddedLuggageState('none')"
                >
                  <div class="bl_navi_btn">없음</div>
                </li>
                <li
                  class="bl_navi_item is_moAdd"
                  @click="changeAddedLuggageState('add')"
                >
                  <div class="bl_navi_btn">추가</div>
                </li>
              </ul>
            </div>
            <div v-if="isAddedLuggage" class="bl_bag_cont">
              <ul class="el_reset bl_cont_list">
                <li class="bl_cont_item is_moCarrier">
                  <div class="bl_cont_obj">
                    <div class="bl_obj_ttl">캐리어</div>
                    <div class="bl_obj_type">
                      <div class="bl_type_inner">
                        <ul class="el_reset bl_type_list">
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_img is_moCarrierS"></div>
                                <div class="bl_body_txt">기내용</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="carrierSmall"
                                    :min="0"
                                  />
                                </div>
                              </div>
                              <div class="bl_obj_footer">
                                <ul class="el_reset bl_footer_list">
                                  <li class="bl_footer_item">가로 : 34~38cm</li>
                                  <li class="bl_footer_item">세로 : 48~53cm</li>
                                  <li class="bl_footer_item">폭 : 20~24cm</li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_img is_moCarrierM"></div>
                                <div class="bl_body_txt">중형</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="carrierMedium"
                                    :min="0"
                                  />
                                </div>
                              </div>
                              <div class="bl_obj_footer">
                                <ul class="el_reset bl_footer_list">
                                  <li class="bl_footer_item">
                                    가로 : 38~ 44cm
                                  </li>
                                  <li class="bl_footer_item">세로 : 58~63cm</li>
                                  <li class="bl_footer_item">폭 : 22~26cm</li>
                                </ul>
                              </div>
                            </div>
                          </li>
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_img is_moCarrierL"></div>
                                <div class="bl_body_txt">대형</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="carrierLarge"
                                    :min="0"
                                  />
                                </div>
                              </div>
                              <div class="bl_obj_footer">
                                <ul class="el_reset bl_footer_list">
                                  <li class="bl_footer_item">가로 : 46~52cm</li>
                                  <li class="bl_footer_item">세로 : 68~73cm</li>
                                  <li class="bl_footer_item">폭 : 28~34cm</li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bl_cont_item">
                  <div class="bl_cont_obj">
                    <div class="bl_obj_ttl">골프백</div>
                    <div class="bl_obj_type">
                      <div class="bl_type_inner">
                        <ul class="el_reset bl_type_list">
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_img is_moBagT"></div>
                                <div class="bl_body_txt">투어백</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="tourBag"
                                    :min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_img is_moBagS"></div>
                                <div class="bl_body_txt">스탠드백</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="standBag"
                                    :min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_img is_moBagH"></div>
                                <div class="bl_body_txt">하프백</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="halfBag"
                                    :min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bl_cont_item is_moEtc">
                  <div class="bl_cont_obj">
                    <div class="bl_obj_ttl">기타 (캐리어, 골프백 제외)</div>
                    <div class="bl_obj_type">
                      <div class="bl_type_inner">
                        <ul class="el_reset bl_type_list">
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_txt">세 변 100cm 이내</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="otherWithIn100"
                                    :min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_txt">세 변 150cm 이내</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="otherWithIn150"
                                    :min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl_type_item">
                            <div class="bl_type_obj">
                              <div class="bl_obj_body">
                                <div class="bl_body_txt">세 변 200cm 이내</div>
                                <div class="bl_body_num">
                                  <!-- 0 일때: is_acZero -->
                                  <nm-number-quantity
                                    v-model="otherWithIn200"
                                    :min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="ly_body_footer">
        <div class="ly_container">
          <div class="bl_btn">
            <!-- 비활성화(C) : is_acDisabled -->
            <nm-button
              class="is_moPrim"
              :disabled="missingRequirements"
              @click="goToBookerInfo"
              >다음</nm-button
            >
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import NmNumberQuantity from "@/components/nearme/NmNumberQuantity.vue";
import reservationMixin from "@/mixins/reservationMixin";
export default {
  name: "BoardingInfo",
  components: {
    DefaultLayout,
    NmButton,
    NmNumberQuantity,
  },
  mixins: [reservationMixin],
  data() {
    return {
      luggageMax: 0,
      passengersMax: 0,
      serviceType: null,
      passengers: 1, // default
      carrierSmall: 0,
      carrierMedium: 0,
      carrierLarge: 0,
      halfBag: 0,
      tourBag: 0,
      standBag: 0,
      otherWithIn100: 0,
      otherWithIn150: 0,
      otherWithIn200: 0,
      isAddedLuggage: true,
      reservationInfo: {},
    };
  },
  async created() {
    const { service_type } = this.$route.query;
    this.serviceType = service_type;
    this.title =
      this.serviceType === "airport" ? "공항 픽업 예약" : "골프장 픽업 예약";

    this.$nextTick(() => {
      document.title = this.title;
    });

    await this.setData();
  },
  computed: {
    missingRequirements() {
      return this.isAddedLuggage
        ? !(
            this.carrierSmall ||
            this.carrierMedium ||
            this.carrierLarge ||
            this.halfBag ||
            this.tourBag ||
            this.standBag ||
            this.otherWithIn100 ||
            this.otherWithIn150 ||
            this.otherWithIn200
          )
        : false;
    },
  },
  methods: {
    setData() {
      this.initData();
      if (this.hasBoardingInfo()) {
        const {
          passengers,
          carrier_small,
          carrier_medium,
          carrier_large,
          half_bag,
          tour_bag,
          stand_bag,
          other_with_in_100,
          other_with_in_150,
          other_with_in_200,
        } = this.getBoardingInfo();
        this.passengers = passengers;
        this.carrierSmall = carrier_small;
        this.carrierMedium = carrier_medium;
        this.carrierLarge = carrier_large;
        this.halfBag = half_bag;
        this.tourBag = tour_bag;
        this.standBag = stand_bag;
        this.otherWithIn100 = other_with_in_100;
        this.otherWithIn150 = other_with_in_150;
        this.otherWithIn200 = other_with_in_200;
        this.isAddedLuggage = this.getLuggageTotalCount() ? true : false;
      }
    },
    initData() {
      this.initLuggageData();

      if (this.serviceType === "airport") {
        this.luggageMax = 10;
        this.passengersMax = 9;
        this.carrierMedium = 1;
      } else {
        this.luggageMax = 12;
        this.passengersMax = 4;
        this.carrierSmall = 1;
        this.tourBag = 1;
      }

      this.isAddedLuggage = true;
    },
    initLuggageData() {
      // init luggage info data
      this.carrierSmall = 0;
      this.carrierMedium = 0;
      this.carrierLarge = 0;
      this.halfBag = 0;
      this.tourBag = 0;
      this.standBag = 0;
      this.otherWithIn100 = 0;
      this.otherWithIn150 = 0;
      this.otherWithIn200 = 0;
    },
    openLuggageNoticeModal() {
      this.luggageNotice();
    },
    changeAddedLuggageState(state) {
      this.initLuggageData();

      if (state === "none") {
        this.isAddedLuggage = false;
      } else {
        if (this.serviceType === "airport") {
          this.carrierMedium = 1;
        } else {
          this.carrierSmall = 1;
          this.tourBag = 1;
        }
        this.isAddedLuggage = true;
      }
    },
    goToBookerInfo() {
      setTimeout(() => {
        this.setBoardingInfo({
          passengers: this.passengers,
          carrier_small: this.carrierSmall,
          carrier_medium: this.carrierMedium,
          carrier_large: this.carrierLarge,
          half_bag: this.halfBag,
          tour_bag: this.tourBag,
          stand_bag: this.standBag,
          other_with_in_100: this.otherWithIn100,
          other_with_in_150: this.otherWithIn150,
          other_with_in_200: this.otherWithIn200,
        });

        if (this.getLuggageTotalCount() > this.luggageMax) {
          alert(`수하물은 최대 ${this.luggageMax}개까지 가능합니다.`);
          return;
        }

        this.$router.push({
          name: "BookerInfo",
          query: {
            service_type: this.serviceType,
          },
        });
      }, 300);
    },
  },
};
</script>