<template>
  <div class="wrapper">
    <div class="page">
      <main class="main">
        <div class="main-header">
          <div class="commonSearch">
            <!-- 삭제버튼 활성 : is-activeDelete -->
            <div class="search is-activeDelete">
              <div
                class="search-input"
                @click="logEvent('customercenter_search_click')"
              >
                <byte-input
                  placeholder="궁금하신 내용을 입력해주세요."
                  :max-byte="50"
                  v-model="searchValue"
                  @keydown.native.enter="search"
                />
                <i class="search-icon" @click="search"> 검색 </i>
                <i
                  class="search-delete"
                  v-if="searchValue"
                  @click.stop="
                    logEvent(
                      'customercenter_search_remove',
                      () => (searchValue = '')
                    )
                  "
                >
                  삭제
                </i>
              </div>
            </div>
          </div>
          <div class="result" v-if="searchParams.search">
            '{{ searchParams.search }}' 검색결과
            <strong>{{ totalCount }}</strong
            >건
          </div>
        </div>
        <div class="main-main" ref="scrollContainer">
          <div class="board" ref="scrollList">
            <div class="board-noResult" v-if="faqList && !faqList.length">
              <div class="board-notice">검색결과가 없어요.</div>
              <div class="board-caption">다시 검색 해주세요.</div>
            </div>
            <ul class="board-list" v-else>
              <li
                v-for="faq in faqList"
                :key="faq.faqId"
                @click="
                  newWebviewLayerV2({
                    name: 'FaqView',
                    params: { faqId: faq.faqId },
                  })
                "
              >
                <div class="info">
                  <div class="info-title">
                    {{ faq.subject }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="main-footer" v-if="searchParams.search">
          <div
            class="allList"
            @click="logEvent('customercenter_search_full_list_click', reset)"
          >
            전체목록
          </div>
        </div>
        <div class="kakao-chat" @click="openKakaoChat" v-else>
          <div class="kakao-title">채팅 문의</div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import ByteInput from "@/components/ByteInput";
import { keyboardDown, newWebviewLayerV2 } from "@/util/webview";

export default {
  name: "FaqList",
  mixins: [scrollMixin],
  components: {
    ByteInput,
  },
  data() {
    return {
      faqList: null,
      activeFaqIndex: -1,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      searchValue: "",
      currentSearchValue: "",
      searchParams: {},
      categoryCd: "",
    };
  },
  created() {
    this.search();
  },
  methods: {
    newWebviewLayerV2,
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { list, total },
        } = await userApi.getFaqList({
          ...this.searchParams,
          page: this.pageIndex,
        });
        this.faqList = this.faqList || [];
        this.faqList = [...this.faqList, ...list];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    search() {
      keyboardDown();
      if (
        this.faqList !== null &&
        this.currentSearchValue === this.searchValue
      ) {
        return;
      }
      this.activeFaqIndex = -1;
      this.pageIndex = 1;
      this.faqList = null;
      this.searchParams = {
        size: this.pageSize,
        search: this.searchValue,
        categoryCd: this.searchValue ? "" : this.categoryCd,
      };
      this.currentSearchValue = this.searchValue;
      this.setData();
    },
    reset() {
      this.searchValue = "";
      this.search();
    },
    openKakaoChat() {
      if (navigator.userAgent.match(/iP(hone|od|ad)/)) {
        // ios
        window.webkit.messageHandlers.aplGolf?.postMessage?.(
          JSON.stringify({
            action: "openKakaoChat",
          })
        );
      } else if (
        navigator.userAgent.match(
          /Android|Mobile|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
        )
      ) {
        // android
        window.aplGolf?.openKakaoChat?.();
      }
    },
  },
};
</script>

<style scoped>
.type-faq .main-header {
  padding-bottom: 16px;
}
.id-faq .main-main .board-list > li {
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  padding: 14px 20px;
}
.id-faq .main-main .info {
  background: transparent
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAqCAYAAACpxZteAAAAAXNSR0IArs4c6QAAATNJREFUWEft1rtRw0AQBuDdVQUkZKQeZ+RkBFACVUCkk1SDZk4RNEEJkFKBMw8lkLiDXUaBZyx8uufeDIEdS//nu71bLULlH1bOhwsQ3GHnFg3DcDuO4y74dsQDC6Dv+wcReUfEKwD4RsSnUmgBdF33gYiPxz8mIgciui9BFoAx5pWInk9XXor8Ba6J6AsANlrIWZGNMaqI8xRpIqs3WQvxtgoNJNiLSpEgMJ8mH8LMd9M07dcudRRQgkQDuUgSkIMkAyFERLbW2p9jTbIAH8LMb9bal/8N+I5t8Ral3omkGqSGz3WIBnLCo4Hc8CigarMrDfeuQCN8FdAKdwKa4WeAdrgLqDt4VR8d5+EXAD7npZWOjKvtum3bbdM0N8y8O/1wREzqzkeie9EFyN2B4HvVa/ALj7k2OhWiiWgAAAAASUVORK5CYII=)
    no-repeat right center / 8px 14px;
  color: #222;
  font: normal normal 400 14px / 1.43 "Noto Sans KR";
  padding-right: 28px;
}
.id-faq .main-main .info-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

figure {
  margin: 0;
}
img {
  width: 100%;
}
.type-faq .main-header .result {
  display: block;
  padding-bottom: 0;
}
.type-faq .main-main .board {
  overflow: unset;
}
.id-faq .main-main .board-noResult {
  display: flex;
}
.type-faq .main-footer {
  display: block;
}

.kakao-chat {
  position: fixed;
  right: 20px;
  bottom: 30px;
  width: 136px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffd900;
  text-align: center;
}
.kakao-chat .kakao-title {
  display: inline-block;
  font-family: "Noto Sans KR";
  padding: 11px 0 11px 34px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  color: #222;
  background: url("~@/assets/images/btn_chat_kakao.png") left center no-repeat;
  background-size: auto;
}
</style>
