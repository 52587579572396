<template>
  <div class="bl_load_img">
    <lottie
      :options="options"
      class="lottieLoading"
      @animCreated="handleAnim"
    ></lottie>
  </div>
</template>

<script>
import Lottie from "vue-lottie";
import * as animationData from "@/assets/js/promotion/jeomsin/lottie.json";

export default {
  name: "JeomsinLoading",
  beforeDestroy() {
    this.destroy();
  },
  components: { Lottie },
  data() {
    return {
      anim: null,
      options: {
        animationData: animationData.default,
        renderer: "svg",
        loop: true,
        autoplay: true,
      },
    };
  },
  methods: {
    handleAnim(anim) {
      this.anim = anim;
    },
    destroy() {
      this.anim?.destroy?.();
    },
  },
};
</script>
