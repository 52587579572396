<template>
  <div
    class="cp_input"
    :class="[focusClass, deleteClass, validateClass, errorClass]"
    @click="onFocus"
  >
    <div class="cp_input_obj">
      <div class="cp_obj_search"></div>
      <input
        ref="input"
        :value="computedValue"
        class="el_form cp_obj_input"
        :placeholder="placeholder"
        :type="type"
        :maxlength="maxlength"
        :autofocus="autofocus"
        :disabled="disabled"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keypress.enter="onKeypressEnter"
      />
      <slot name="timer" />
      <div
        v-if="computedValue.length > 0"
        class="cp_obj_del"
        @click="onRemove"
        @mousedown.prevent
      ></div>
      <div class="cp_obj_box"></div>
    </div>
    <!-- 에러(C) : is_acError -->
    <div class="cp_input_val" :class="errorClass">
      {{ message }}
    </div>
  </div>
</template>
<script>
export default {
  name: "NmSearchInput",
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      value: "검색어를 입력해주세요.",
    },
    type: {
      type: String,
      default: "text",
    },
    clear: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [String, Number],
    },
    message: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
      visible: false,
      patternRegex: null,
    };
  },
  created() {
    this.patternRegex = new RegExp(this.pattern, "g");
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    focusClass() {
      return this.focus && !this.disabled ? "is_acFocus" : "";
    },
    deleteClass() {
      return this.clear ? "is_acDel" : "";
    },
    errorClass() {
      return !this.validate && this.message?.trim()?.length > 0
        ? "is_acError"
        : "";
    },
    validateClass() {
      return this.message?.trim().length > 0 ? "is_acVal" : "";
    },
  },
  watch: {
    value(value) {
      if (value.replace(this.patternRegex, "") === "") {
        this.$emit("input", "");
        return;
      }
      this.$emit("input", value.replace(this.patternRegex, ""));
    },
  },
  methods: {
    onInput(event) {
      if(this.maxlength) {
        this.computedValue = event.target.value.substring(0, Number(this.maxlength));
        event.target.value = this.computedValue;
      }
      else {
        this.computedValue = event.target.value
      }
    },
    onFocus() {
      this.$refs.input?.focus();
      this.focus = true;
    },
    onBlur() {
      this.$refs.input?.blur();
      this.focus = false;
    },
    onRemove() {
      this.computedValue = "";
    },
    onKeypressEnter(event) {
      this.$emit("keypress:enter", event);
    },
  },
};
</script>