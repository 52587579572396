<template>
  <li class="profile-item" @click="onProfileClick(user.uid)">
    <div class="profile-image">
      <div
        class="profile-imageObject"
        :style="{
          'background-image': `url('${user.profileImgUrl}')`,
        }"
      ></div>
    </div>
    <div class="profile-information">
      <div class="profile-informationName">
        {{ user.nickname }}
      </div>
      <!-- 남성 : is-genderMale / 여성 : is-genderFemale -->
      <div
        v-if="userGenderClass(user.gender) || user.age"
        :class="userGenderClass(user.gender)"
        class="profile-informationAge"
      >
        <div class="profile-informationAgeObject">
          {{ user.age }}
        </div>
      </div>
    </div>
    <div class="profile-location" v-if="user.addr">
      {{ user.addr }}
    </div>
    <!-- 온 상태 : is-stateOn -->
    <div
      v-if="!isOwnProfile"
      class="profile-my"
      :class="{ 'is-stateOn': user.followYn === 'Y' }"
      @click.stop="toggleFollow(user)"
    >
      MY 단골
    </div>
  </li>
</template>

<script>
import { newWebviewLayerV2, showToast } from "@/util/webview";
import { boardApi } from "@/api";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "CommunityMiniProfile",
  created() {
    this.updateFollow = debounce(this.updateFollow, 500);
  },
  methods: {
    newWebviewLayerV2,
    onProfileClick(uid) {
      this.logEvent("community_profile_click");
      newWebviewLayerV2({
        name: "CommunityProfile",
        params: { uid },
      });
    },
    userGenderClass(gender) {
      switch (gender) {
        case "M":
          return "is-genderMale";
        case "F":
          return "is-genderFemale";
        default:
          return "";
      }
    },
    async toggleFollow() {
      let eventName = "";
      if (this.user.followYn === "Y") {
        eventName = "community_profile_my_off";
        this.user.followYn = "N";
      } else if (this.user.followYn === "N") {
        eventName = "community_profile_my_on";
        this.user.followYn = "Y";
      }
      this.logEvent(eventName);
      await this.updateFollow();
    },
    async updateFollow() {
      if (this.user.followYn === "Y") {
        await boardApi.createFollow(this.user.uid);
        showToast({ message: "MY 단골에 추가했습니다" });
        return;
      } else if (this.user.followYn === "N") {
        await boardApi.deleteFollow(this.user.uid);
        showToast({ message: "MY 단골에서 삭제했습니다" });
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["userUid"]),
    isOwnProfile() {
      return this.userUid === this.user?.uid;
    },
  },
};
</script>

<style scoped>
.profile-informationAgeObject {
  height: 100%;
}
</style>
