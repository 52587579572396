<template>
  <bottom-modal-layout class="is_moTime">
    <template v-slot:header>
      <div class="bl_ttl">{{ title }}</div>
      <div
        class="bl_right is_moClose"
        @click="hide({ hour: hour, minute: minute })"
      ></div>
    </template>
    <template v-slot:main>
      <div class="bl_time">
        <div
          v-if="content === 'reservation' && airportCd.includes('NRT')"
          class="bl_time_txt"
        >
          예약 시간은 여유있게 설정해주세요.<br />
          도착 시간으로부터
          <strong class="el_reset">최소 1시간 30분 이후</strong>를 권장 드려요.
        </div>
        <div
          v-else-if="content === 'reservation' && airportCd.includes('HND')"
          class="bl_time_txt"
        >
          예약 시간은 여유있게 설정해주세요.<br />
          도착 시간으로부터
          <strong class="el_reset">최소 1시간 이후</strong>를 권장 드려요.
        </div>
        <div
          v-else-if="content === 'reservation' && !airportCd"
          class="bl_time_txt"
        >
          예약 시간은 여유있게 설정해주세요.<br />
          도착 시간으로부터
          <strong class="el_reset">최소 1시간 30분 이후</strong>를 권장 드려요.
        </div>
        <div v-if="content === 'payment'" class="bl_time_txt">
          항공편 도착 시간은
          <strong class="el_reset">정확하게</strong> 설정해주세요.
        </div>
        <div class="bl_time_cont">
          <scroll-picker v-model="hour" :options="hourList"></scroll-picker>
          <scroll-picker
            v-if="unit === 1"
            :value="minute"
            :options="everyMinuteList"
            @input="onInputMinute"
          ></scroll-picker>
          <scroll-picker
            v-else
            v-model="minute"
            :options="every10MinutesList"
          ></scroll-picker>
          <div class="highlight"></div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <footer class="ly_footer">
        <div class="bl_btn">
          <ul class="el_reset bl_btn_list">
            <li class="bl_btn_item">
              <!-- 비활성화(C) : is_acDisabled -->
              <nm-button class="is_moPrim" @click="selectTime">
                선택 완료
              </nm-button>
            </li>
          </ul>
        </div>
      </footer>
    </template>
  </bottom-modal-layout>
</template>
<script>
import "vue-scroll-picker/dist/style.css";
import { ScrollPicker } from "vue-scroll-picker";
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import { preventBackButton } from "@/util/webview";

export default {
  components: { BottomModalLayout, ScrollPicker, NmButton },
  name: "TimePickerModal",
  data() {
    return {
      title: null,
      content: null,
      resolve: null,
      hour: null,
      minute: null,
      unit: null,
      airportCd: null,
    };
  },
  computed: {
    hourList() {
      return Array.from({ length: 24 }, (_, index) =>
        index < 10 ? "0" + index : index.toString()
      );
    },
    every10MinutesList() {
      return Array.from({ length: 6 }, (_, index) =>
        index * 10 < 10 ? "0" + index : (index * 10).toString()
      );
    },
    everyMinuteList() {
      return Array.from({ length: 60 }, (_, index) => {
        return index < 10 ? "0" + index : index.toString();
      });
    },
  },
  methods: {
    show({ title, content, props, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.title = title;
      this.content = content ? content : "reservation";
      this.unit = props?.unit ? props.unit : 10;
      this.airportCd = props?.airportCd ? props.airportCd : "";

      this.resolve = resolve;
      this.$nextTick(() => {
        this.hour = props?.hour ? props.hour : "06";
        this.minute = props?.minute ? props.minute : "00";
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
    selectTime() {
      this.hide({ hour: this.hour, minute: this.minute });
    },
    onInputMinute(value) {
      this.minute = value;
    },
  },
};
</script>
<style scoped>
.bl_time_cont {
  padding-top: 0 !important;
  display: flex !important;
  position: relative !important;
  left: 0 !important;
  right: 0 !important;
}

::v-deep.vue-scroll-picker {
  height: calc(4.4rem + 5.7rem + 5.5rem);
}

::v-deep.vue-scroll-picker .vue-scroll-picker-list .vue-scroll-picker-item {
  height: 4.4rem;
  transform: scale(70%, 56%);
  line-height: 4.4rem;
  color: #aaa;
  font-size: 2.4rem;
}

::v-deep.vue-scroll-picker
  .vue-scroll-picker-list
  .vue-scroll-picker-item:has(+ .-selected) {
  transform: scale(70%);
}

/* 현재 선택 시간 */
::v-deep.vue-scroll-picker
  .vue-scroll-picker-list
  .vue-scroll-picker-item.-selected {
  transform: scale(100%);
  color: #4f84ff;
  font-weight: 700;
}

::v-deep.vue-scroll-picker
  .vue-scroll-picker-list
  .vue-scroll-picker-item.-selected
  + .vue-scroll-picker-item {
  transform: scale(70%);
}

::v-deep.vue-scroll-picker .vue-scroll-picker-layer .top,
::v-deep.vue-scroll-picker .vue-scroll-picker-layer .bottom {
  border: none;
  background: none;
  height: calc(50% - 2.2rem);
  line-height: 4.4rem;
}

::v-deep.vue-scroll-picker .vue-scroll-picker-layer .middle {
  top: calc(50% - 2.2rem);
  bottom: calc(50% - 2.2rem);
  border: none;
  background: none;
  height: 4.4rem;
  line-height: 4.4rem;
}

.highlight {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 4.4rem;
  background-color: #eff5ff;
  border-radius: 0.8rem;
  padding: 0 2.8rem;
  z-index: -1;
  box-sizing: border-box;
}
</style>