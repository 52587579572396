<template>
  <div class="cp_chk">
    <div class="cp_chk_obj">
      <input
        class="el_form cp_obj_input"
        type="checkbox"
        :value="modelValue"
        :checked="checked"
        @input="updateValue"
      />
      <div class="cp_obj_chk"></div>
      <div class="cp_obj_txt">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NmCheckbox",
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    modelValue: {
      type: [Boolean, Array],
    },
    value: {
      type: [Number, String, Array],
    },
  },
  computed: {
    checked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      } else {
        return this.modelValue;
      }
    },
  },
  methods: {
    updateValue($event) {
      if (Array.isArray(this.modelValue)) {
        if (this.modelValue.includes(this.value)) {
          const index = this.modelValue.findIndex((el) => el === this.value);
          this.modelValue.splice(index, 1);
        } else {
          this.modelValue.push(this.value);
        }
        this.$emit("change", this.modelValue);
      } else {
        this.$emit("change", $event.target.checked);
      }
    },
  },
};
</script>