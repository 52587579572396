<template>
  <bottom-modal-layout class="is_moComplete" :close="close">
    <template v-slot:main>
      <div class="bl_alert">
        <div class="bl_alert_ico is_moComplete"></div>
        <div class="bl_alert_ttl">예약 완료되었습니다.</div>
        <div class="bl_alert_txt">
          예약 확정 시, 결제가 진행됩니다.<br />
          예약 정보는 이용 내역 페이지에서<br />
          확인할 수 있습니다.
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <footer class="ly_footer">
        <div class="bl_btn">
          <ul class="el_reset bl_btn_list">
            <li class="bl_btn_item">
              <nm-button class="is_moPrim" @click="goToUsageHistoryView"
                >확인</nm-button
              >
            </li>
          </ul>
        </div>
      </footer>
    </template>
  </bottom-modal-layout>
</template>
<script>
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import {
  newWebviewLayerV2,
  closeWebviewLayer,
  preventBackButton,
} from "@/util/webview";

export default {
  components: { BottomModalLayout, NmButton },
  name: "ReservationSuccessModal",
  data() {
    return {
      resolve: () => {},
      reservationCd: null,
      close: false,
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.reservationCd = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });

      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = userAgent.search("android") > -1;
      const isIOS = !isAndroid && /iphone|ipad|ipod/i.test(userAgent);

      if (isAndroid) {
        this.$router.go(-2);
      }
      if (isIOS) {
        this.$router.go(-1);
      }
    },
    async goToUsageHistoryView() {
      if(this.reservationCd === null) {
        this.hide(null);
        return;
      }
      await closeWebviewLayer();
      newWebviewLayerV2({
        name: "UsageHistoryView",
        params: {
          reservation_cd: this.reservationCd,
        },
      });
    },
  },
};
</script>