<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <header class="ly_header">
            <div class="ly_container">
              <div class="bl_cont"></div>
            </div>
          </header>
          <main class="ly_body">
            <div class="ly_container">
              <div class="bl_txt"></div>
              <div class="bl_coupon_wrapper">
                <div class="bl_coupon">
                  <div class="bl_coupon_price">
                    <div class="bl_price_value">20,000</div>
                    <div class="bl_price_unit">원</div>
                  </div>
                </div>
              </div>
              <div class="bl_btn_wrapper">
                <!-- 완료 : is_moComplete -->
                <div class="bl_btn" @click="getCouponNumber">
                  {{ buttonText }}
                </div>
              </div>
            </div>
          </main>
          <footer class="ly_footer">
            <div class="ly_container">
              <div class="bl_cont"></div>
            </div>
          </footer>
        </div>
      </div>
    </div>
    <ul class="el_reset ly_layerList">
      <li class="ly_layer is_moDimmer" :class="{ 'is-visible': visible }"></li>
      <li class="ly_layer is_moResult" :class="{ 'is-visible': visible }">
        <div class="ly_layer_inner">
          <div class="ly_layer_inner2">
            <div class="ly_layer_inner3">
              <div class="ly_layer_inner4">
                <div class="ly_header">
                  <div class="bl_cont_wrapper">
                    <div class="bl_cont"></div>
                  </div>
                  <div class="bl_close_wrapper" @click="closeResultModal">
                    <div class="bl_close"></div>
                  </div>
                </div>
                <div class="ly_body">
                  <div class="bl_coupon_wrapper">
                    <div class="bl_coupon">
                      <div class="bl_coupon_price">
                        <div class="bl_price_value">
                          {{ couponPrice | comma }}
                        </div>
                        <div class="bl_price_unit">원</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ly_footer">
                  <div
                    v-if="showClipboard"
                    class="bl_code_wrapper"
                    @click="clipboardCopy"
                  >
                    <div class="bl_code">
                      <div class="bl_code_num">
                        <div class="bl_num_copy"></div>
                        <div class="bl_num_value">
                          {{ couponNumber }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bl_btn_wrapper">
                    <div class="bl_btn" @click="openTMapOneLink">쿠폰 등록</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { boardApi } from "@/api";
import { comma } from "@/util/string";
import { saveText } from "@/util/webview";
export default {
  name: "TMapCoupon",
  async created() {
    await this.setData();
  },
  data() {
    return {
      couponPrice: null, // 쿠폰 금액
      couponNumber: null, // 쿠폰 번호
      visible: false,
      isFetching: false,
    };
  },
  computed: {
    buttonText() {
      return this.couponNumber ? "쿠폰 뽑기 완료" : "쿠폰 뽑기";
    },
    showClipboard() {
      return this.couponNumber && this.couponPrice === 20000;
    },
  },
  methods: {
    async setData() {
      const {
        data: { couponPrice, couponNumber },
      } = await boardApi.getUserEntryInfo();

      this.couponPrice = couponPrice;
      this.couponNumber = couponNumber;

      if (this.couponNumber) {
        this.visible = true;
      }
    },
    async getCouponNumber() {
      // 이미 쿠폰이 발행된 회원의 경우 쿠폰 재발급 막기
      if (this.couponNumber && this.couponPrice) {
        this.visible = true;
        return;
      }

      // 중복 호출 방지
      if (this.isFetching) {
        return;
      }
      
      try {
        this.isFetching = true;
        const {
          data: { couponPrice, couponNumber },
        } = await boardApi.getTMapCoupon();
        this.couponPrice = couponPrice;
        this.couponNumber = couponNumber;
      } finally {
        this.isFetching = false;
      }

      // 라운드 시작 체크
      if (!this.couponNumber && !this.couponPrice) {
        alert(`라운드를 진행하셨나요?
'라운드 시작' 후에
다시 참여해주세요.`);
      } else {
        this.visible = true;
      }
    },
    openTMapOneLink() {
      let link = "";
      if (this.couponPrice === 20000) {
        link = "https://surl.tmap.co.kr/7a00bb50";
      } else if (this.couponPrice === 3000) {
        link = "https://surl.tmobiapi.com/2424a6cc1";
      }

      if (link !== "") {
        const a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("target", "_blank");
        a.click();
      }
      this.closeResultModal();
    },
    clipboardCopy() {
      saveText({ textStr: this.couponNumber });
    },
    closeResultModal() {
      this.visible = false;
    },
  },
  filters: {
    comma,
  },
};
</script>
<style scoped>
.is-visible {
  visibility: visible;
  opacity: 1;
}
</style>