<template>
  <div class="lyPage">
    <main class="lyBody">
      <section class="stContent">
        <div class="cmMatching">
          <ul class="cmMatching-list" ref="scrollList">
            <li
              v-for="(item, i) in content"
              :key="i"
              @click="handleClick(item)"
              class="cmMatching-item"
              v-html="item.itemHtml"
            ></li>
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { boardApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import { newWebviewLayerV2, setCommunityBoardPlace } from "@/util/webview";

export default {
  name: "CommunitySearchKeyword",
  mixins: [scrollMixin],
  async created() {
    const { keyword, type } = this.$route.query;
    this.keyword = keyword;
    this.type = type;
    await this.setData();
  },
  mounted() {
    this.addScrollEvent();
  },
  data() {
    return {
      keyword: "",
      type: "",
      content: null,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
    };
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      if (!this.searchApi) {
        return;
      }
      try {
        this.isFetching = true;
        const {
          data: { content, total },
        } = await this.searchApi({
          keyword: this.keyword,
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.content = this.content || [];
        this.content = [
          ...this.content,
          ...content.map((item) => ({
            ...item,
            itemHtml: this.getHighlightHtml(
              item.address || item.clubName || item.tagText
            ),
          })),
        ];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    handleClick(location) {
      switch (this.type) {
        case "tag":
        case "club":
          newWebviewLayerV2({
            name: "CommunityListBySearch",
            query: {
              keyword: location.ccode || location.tagText,
              location: location.clubName,
              type: this.type,
            },
          });
          break;
        case "registClub":
        case "registAddr":
          setCommunityBoardPlace(location);
          break;
        default:
          return null;
      }
    },
    getHighlightHtml(text) {
      return text.replace(
        new RegExp(this.keyword, "g"),
        `<strong class="cmMatching-strong">${this.keyword}</strong>`
      );
    },
  },
  computed: {
    searchApi() {
      switch (this.type) {
        case "tag":
          return boardApi.getBoardSearchHashtag;
        case "club":
        case "registClub":
          return boardApi.getPlaceclubList;
        case "registAddr":
          return boardApi.getPlaceAddrList;
        default:
          return null;
      }
    },
  },
};
</script>
