<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="bl_info">
          <div class="bl_info_inner">
            <div class="bl_info_inner2">
              <div class="bl_info_header">
                <ul class="el_reset bl_header_list">
                  <li class="bl_header_item is_moPick">
                    <div class="bl_header_obj">
                      <div class="bl_obj_label">
                        <div class="bl_label_obj">픽업</div>
                      </div>
                      <div class="bl_obj_txt">
                        <div class="bl_txt_obj">{{ oneWayBoardingDate }}</div>
                        <div class="bl_txt_obj">{{ oneWayBoardingTime }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="bl_header_item">
                    <div class="bl_header_obj">
                      <div class="bl_obj_label">
                        <div class="bl_label_obj">출발</div>
                      </div>
                      <div class="bl_obj_txt">
                        <div class="bl_txt_obj name">{{ departureName }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="bl_header_item is_moDest">
                    <div class="bl_header_obj">
                      <div class="bl_obj_label">
                        <div class="bl_label_obj">도착</div>
                      </div>
                      <div class="bl_obj_txt">
                        <div class="bl_txt_obj name">{{ arriveName }}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="bl_info_body">
                <ul class="el_reset bl_body_list">
                  <li class="bl_body_item is_moPerson">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">인원</div>
                      <div class="bl_obj_txt">{{ passengers }}명</div>
                    </div>
                  </li>
                  <li class="bl_body_item">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">캐리어</div>
                      <div class="bl_obj_txt">{{ tripLuggageInfo }}</div>
                    </div>
                  </li>
                  <li class="bl_body_item">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">골프백</div>
                      <div class="bl_obj_txt">{{ golfLuggageInfo }}</div>
                    </div>
                  </li>
                  <li class="bl_body_item">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">기타</div>
                      <div class="bl_obj_txt">{{ etcLuggageInfo }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="journeyType === 'round-trip'" class="bl_info_inner">
            <div class="bl_info_inner2">
              <div class="bl_info_header">
                <ul class="el_reset bl_header_list">
                  <li class="bl_header_item is_moPick">
                    <div class="bl_header_obj">
                      <div class="bl_obj_label">
                        <div class="bl_label_obj">픽업</div>
                      </div>
                      <div class="bl_obj_txt">
                        <div class="bl_txt_obj">
                          {{ roundTripBoardingDate }}
                        </div>
                        <div class="bl_txt_obj">
                          {{ roundTripBoardingTime }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="bl_header_item">
                    <div class="bl_header_obj">
                      <div class="bl_obj_label">
                        <div class="bl_label_obj">출발</div>
                      </div>
                      <div class="bl_obj_txt">
                        <div class="bl_txt_obj name">{{ arriveName }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="bl_header_item is_moDest">
                    <div class="bl_header_obj">
                      <div class="bl_obj_label">
                        <div class="bl_label_obj">도착</div>
                      </div>
                      <div class="bl_obj_txt">
                        <div class="bl_txt_obj name">{{ departureName }}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="bl_info_body">
                <ul class="el_reset bl_body_list">
                  <li class="bl_body_item is_moPerson">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">인원</div>
                      <div class="bl_obj_txt">{{ passengers }}명</div>
                    </div>
                  </li>
                  <li class="bl_body_item">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">캐리어</div>
                      <div class="bl_obj_txt">{{ tripLuggageInfo }}</div>
                    </div>
                  </li>
                  <li class="bl_body_item">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">골프백</div>
                      <div class="bl_obj_txt">{{ golfLuggageInfo }}</div>
                    </div>
                  </li>
                  <li class="bl_body_item">
                    <div class="bl_body_obj">
                      <div class="bl_obj_ttl">기타</div>
                      <div class="bl_obj_txt">{{ etcLuggageInfo }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="ly_container">
          <div v-if="isDepartureAirport" class="bl_flight">
            <div class="bl_flight_ttl">가는 날 항공편 정보</div>
            <div class="bl_flight_info">
              <ul class="el_reset bl_info_list">
                <li class="bl_info_item">
                  <nm-byte-input
                    v-model="inputNumFlight"
                    placeholder="항공편 번호 (ex. KE719)"
                    type="text"
                    pattern="[^a-zA-Z0-9]"
                    :max-byte="12"
                  />
                </li>
                <li class="bl_info_item">
                  <nm-input
                    v-model="inputDateFlight"
                    placeholder="항공편 도착 날짜 (ex. 0000-00-00)"
                    type="text"
                    maxlength="10"
                    pattern="[^0-9]"
                  />
                </li>
                <li class="bl_info_item">
                  <nm-input
                    :value="timeFlight"
                    placeholder="항공편 도착 시간 (ex. 00:00)"
                    readonly
                    @click="openTimePickerModal"
                  />
                </li>
                <li class="bl_info_item">
                  <nm-button
                    class="is_moPrim"
                    :disabled="
                      !inputNumFlight.trim() ||
                      !inputDateFlight.trim() ||
                      !timeFlight.trim()
                    "
                    @click="onClickSearchPaymentAmount"
                    >결제 금액 조회하기</nm-button
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="bl_coupon">
            <div class="bl_coupon_ttl">
              <div class="bl_ttl_ttl">쿠폰</div>
              <div v-if="couponCountTotal" class="bl_ttl_txt">
                보유쿠폰 {{ couponCountTotal }}장
              </div>
            </div>
            <div v-if="couponCountTotal === 0" class="bl_coupon_reg">
              <nm-button @click="goToCouponRegister">쿠폰 등록하기</nm-button>
            </div>
            <div v-else>
              <div v-if="maxDiscountCoupon" class="bl_coupon_sale">
                <div class="bl_sale_inner" @click="goToCouponList">
                  <div class="bl_sale_ttl">{{ couponName }}</div>
                  <div class="bl_sale_txt">{{ discountAmount }}원</div>
                  <div class="bl_sale_ico"></div>
                </div>
              </div>
              <div v-else class="bl_coupon_reg">
                <nm-button @click="goToCouponList">쿠폰 적용하기</nm-button>
              </div>
            </div>
          </div>
          <div class="bl_agree">
            <div class="bl_agree_ttl">
              <div class="bl_ttl_ttl">약관 동의</div>
            </div>
            <div class="bl_agree_all">
              <nm-checkbox v-model="allAgree"> 모두 동의합니다. </nm-checkbox>
            </div>
            <div class="bl_agree_detail">
              <ul class="el_reset bl_detail_list">
                <li
                  class="bl_detail_item"
                  v-for="term in termList"
                  :key="term.termId"
                >
                  <div class="bl_detail_obj">
                    <div class="bl_obj_chk">
                      <nm-checkbox v-model="agreeList" :value="term.termId">
                        <strong v-if="term.requiredYn === 'Y'" class="el_reset"
                          >[필수]</strong
                        >
                        <strong v-else class="el_reset">[선택]</strong>
                        {{ term.termKind }}
                      </nm-checkbox>
                    </div>
                    <div
                      class="bl_obj_detail"
                      @click="openTermDetail(term.termUrl)"
                    ></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="ly_container">
        <div class="bl_pay is_acDiscount">
          <div class="bl_pay_inner">
            <div
              v-if="baseAmount === finalAmount"
              class="bl_pay_price is_moDiscount"
            >
              <div class="bl_price_txt">결제금액</div>
              <div class="bl_price_total">{{ finalAmount | comma }}원</div>
            </div>
            <div
              v-else-if="baseAmount > finalAmount"
              class="bl_pay_price is_moDiscount"
            >
              <div class="bl_price_discount">{{ baseAmount | comma }}원</div>
              <div class="bl_price_total">{{ finalAmount | comma }}원</div>
            </div>
            <div class="bl_pay_btn">
              <nm-button
                class="is_moPrim"
                @click="onClickPayment"
                :disabled="missingRequirements"
                >결제하기</nm-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  preventBackButton,
  handleOpenWeb,
  newWebviewLayerV2,
  hideBackButton,
  showToast,
} from "@/util/webview";
import { nearmeApi, userApi } from "@/api";
import { comma, isJson, dateFormatter } from "@/util/string";
import { mapGetters, mapMutations } from "vuex";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import dateMixin from "@/mixins/dateMixin";
import NmCheckbox from "@/components/nearme/NmCheckbox.vue";
import reservationMixin from "@/mixins/reservationMixin";
import NmInput from "@/components/nearme/NmInput.vue";
import NmByteInput from "@/components/nearme/NmByteInput.vue";
dayjs.extend(customParseFormat);

export default {
  name: "PaymentView",
  components: {
    DefaultLayout,
    NmButton,
    NmCheckbox,
    NmInput,
    NmByteInput,
  },
  mixins: [dateMixin, reservationMixin],
  data() {
    return {
      serviceType: null,
      meetService: false,
      partnerService: "limo",
      termList: null,
      agreeList: [],
      amountInfo: null,
      reservationInfo: {},
      journeyType: "",

      // 쿠폰
      maxDiscountCoupon: null,
      couponCountTotal: 0,

      // 항공편
      numFlight: "",
      timeFlight: "",
      dateFlight: "",
      isAllowInputFlightInfo: false,

      // 결제 관련 변수
      guestId: null,
      reservationId: 0,
      merchantUid: "",
      impUid: "",
      isFetching: false,
      isBack: false,
      isPaymentProcess: false,

      delayTime: null,
    };
  },
  async created() {
    const { service_type } = this.$route.query;
    this.serviceType = service_type;
    this.reservationInfo = this.getReservationInfo();
    this.journeyType = this.reservationInfo.journey_type;
    this.timeFlight = this.reservationInfo.time_flight || "";
    this.dateFlight = this.reservationInfo.date_flight || "";
    this.inputNumFlight = this.reservationInfo.num_flight || "";

    if (this.isFetching) {
      return;
    }
    try {
      this.isFetching = true;
      this.setIsLoading(true);
      this.setLoadingMessage("조회 중");
      await this.setData();
    } finally {
      this.isFetching = false;
      this.setIsLoading(false);
      this.setLoadingMessage("");
    }

    window.getDataTopToBottomWebLayer = async (data) => {
      let parsedData = data;
      const isJsonFormat = isJson(parsedData);

      if (isJsonFormat) {
        parsedData = JSON.parse(data);
      }

      if (parsedData.from === "list") {
        if (this.maxDiscountCoupon?.couponNumber === parsedData.couponNumber) {
          this.maxDiscountCoupon = null;
          showToast({ message: "쿠폰을 해제했습니다." });
        } else {
          this.maxDiscountCoupon = parsedData;
          showToast({ message: "쿠폰을 적용했습니다." });
        }
      } else {
        if (this.partnerService === "limo") {
          await this.getNearMeCouponList("NEARME_LIMO");
        } else {
          await this.getNearMeCouponList("NEARME_GOLF");
        }
      }
    };
  },
  async mounted() {
    const terminalCd = this.reservationInfo.terminal_cd;
    const isReverse = this.reservationInfo.reverse_yn === "Y" ? true : false;
    if (!isReverse && terminalCd) {
      if (terminalCd.includes("NRT")) {
        this.delayTime = 90;
      } else {
        this.delayTime = 60;
      }
    } else {
      this.delayTime = 90;
    }

    await preventBackButton({
      isBackPrevent: true,
    });
    await this.reservationCaution(this.delayTime);

    try {
      if (this.isDepartureAirport) {
        if (!this.timeFlight.trim() || !this.inputNumFlight.trim()) {
          this.isAllowInputFlightInfo = await this.confirm(
            "항공편 정보를 입력하시면 정확한 결제 금액을 알 수 있습니다.<br>항공편 정보를 입력하시겠습니까?"
          );
        } else {
          await this.onClickSearchPaymentAmount();
          return;
        }
      } else {
        this.isAllowInputFlightInfo = false;
      }

      if (!this.isAllowInputFlightInfo) {
        // 항공편 정보 미입력
        this.setIsLoading(true);
        this.setLoadingMessage("결제 금액 조회 중");
        await this.searchPaymentAmount();
      } else {
        this.inputDateFlight = this.reservationInfo.one_way_date;
        await this.getNearMeCouponList("NEARME_LIMO");
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setIsLoading(false);
      this.setLoadingMessage("");
    }
  },
  beforeDestroy() {
    this.$store.dispatch("removePortone");
  },
  computed: {
    ...mapGetters(["userUid"]),
    missingRequirements() {
      return (
        isNaN(this.finalAmount) ||
        this.finalAmount === null ||
        this.finalAmount === undefined
      );
    },
    allAgree: {
      get() {
        return this.agreeList.length === this.termList?.length;
      },
      set(value) {
        if (value) {
          this.agreeList = this.termList.map((el) => el.termId);
        } else {
          this.agreeList = [];
        }
      },
    },
    isDepartureAirport() {
      if(this.isAirportService) {
        if(this.reservationInfo.reverse_yn === "N" && this.reservationInfo.terminal_cd) {
          return true;
        } else {
          this.setFlightInfo({
            time_flight: null,
            num_flight: null,
            date_flight: null,
          });
          return false;
        }
      } else {
        if(this.reservationInfo.reverse_yn === "N" && this.reservationInfo.terminal_cd) {
          return true;
        } else {
          this.setFlightInfo({
            time_flight: null,
            num_flight: null,
            date_flight: null,
          });
          return false;
        }
      }
    },
    isAirportService() {
      return this.serviceType === "airport" ? true : false;
    },
    oneWayBoardingDate() {
      return this.getDateTime(this.reservationInfo.one_way_date, "YYYY.MM.DD");
    },
    oneWayBoardingTime() {
      return this.reservationInfo.one_way_time;
    },
    roundTripBoardingDate() {
      return this.getDateTime(
        this.reservationInfo.round_trip_date,
        "YYYY.MM.DD"
      );
    },
    roundTripBoardingTime() {
      return this.reservationInfo.round_trip_time;
    },
    departureName() {
      return this.reservationInfo.departure_name;
    },
    arriveName() {
      return this.reservationInfo.arrival_name;
    },
    passengers() {
      return this.reservationInfo.passengers;
    },
    tripLuggageInfo() {
      const carryOnSizeCount = this.reservationInfo.carrier_small;
      const mediumSizeCount = this.reservationInfo.carrier_medium;
      const largeSizeCount = this.reservationInfo.carrier_large;

      let result = "";

      if (carryOnSizeCount > 0) {
        result += `기내용 ${carryOnSizeCount}개`;
      }
      if (mediumSizeCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `중형 ${mediumSizeCount}개`;
      }
      if (largeSizeCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `대형 ${largeSizeCount}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    golfLuggageInfo() {
      const tourBagCount = this.reservationInfo.tour_bag;
      const standBagCount = this.reservationInfo.stand_bag;
      const halfBagCount = this.reservationInfo.half_bag;

      let result = "";

      if (tourBagCount > 0) {
        result += `투어백 ${tourBagCount}개`;
      }
      if (standBagCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `스탠드백 ${standBagCount}개`;
      }
      if (halfBagCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `하프백 ${halfBagCount}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    etcLuggageInfo() {
      const within100Count = this.reservationInfo.other_with_in_100;
      const within150Count = this.reservationInfo.other_with_in_150;
      const within200Count = this.reservationInfo.other_with_in_200;

      let result = "";

      if (within100Count > 0) {
        result += `세 변 100cm 이내 ${within100Count}개`;
      }
      if (within150Count > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `세 변 150cm 이내 ${within150Count}개`;
      }
      if (within200Count > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `세 변 200cm 이내 ${within200Count}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    inputNumFlight: {
      get() {
        return this.numFlight?.toUpperCase();
      },
      set(value) {
        this.numFlight = value;
      },
    },
    inputDateFlight: {
      get() {
        return dateFormatter(this.dateFlight);
      },
      set(value) {
        this.dateFlight = value;
      },
    },
    serviceFee() {
      let fee = 0;
      if (this.serviceType === "airport") {
        fee = this.amountInfo?.limo?.oneWayServiceFee;
      } else {
        fee = this.amountInfo?.golf?.oneWayServiceFee;
      }

      return fee;
    },
    baseAmount() {
      let amount = 0;
      if (this.serviceType === "airport") {
        if (this.journeyType === "one-way") {
          amount = this.amountInfo?.limo?.oneWayFinalAmount;
        } else {
          amount = this.amountInfo?.limo?.oneWayFinalAmount +
                this.amountInfo?.limo?.roundTripFinalAmount;
        }
        return amount;
      } else {
        if (this.journeyType === "one-way") {
          amount = this.amountInfo?.golf?.oneWayFinalAmount;
        } else {
          amount =
            this.amountInfo?.golf?.oneWayFinalAmount +
            this.amountInfo?.golf?.roundTripFinalAmount;
        }
        return amount;
      }
    },
    finalAmount() {
      return this.baseAmount - (this.discountAmount || 0);
    },
    couponName() {
      return this.maxDiscountCoupon?.couponName;
    },
    couponNumber() {
      return this.maxDiscountCoupon?.couponNumber;
    },
    discountAmount() {
      return this.maxDiscountCoupon?.discountAmount;
    },
  },
  methods: {
    ...mapMutations(["setIsLoading", "setLoadingMessage"]),
    async setData() {
      if (this.serviceType === "club") {
        this.partnerService = "club";
      } else {
        this.partnerService = "limo";
      }
      // 니어미 약관 목록 조회
      await this.getNearMeTermList();

      // 포트원 스크립트 추가
      this.initPortone();
    },

    async openTimePickerModal() {
      const splitedTime = this.timeFlight.split(":");
      const selectedHour = splitedTime.length === 2 ? splitedTime[0] : "06";
      const selectedMinute = splitedTime.length === 2 ? splitedTime[1] : "00";
      const { hour, minute } = await this.timePicker(
        "항공편 도착 시간 선택",
        "payment",
        {
          hour: selectedHour,
          minute: selectedMinute,
          unit: 1,
        }
      );
      this.timeFlight = `${hour}:${minute}`;
    },
    async onClickSearchPaymentAmount() {
      try {
        if (!dayjs(this.inputDateFlight, "YYYY-MM-DD", true).isValid()) {
          await this.alert(
            "입력한 날짜의 값이 잘못되었습니다.\n다시 입력해주세요."
          );
          return;
        }
        this.reservationInfo = this.getReservationInfo();

        this.setFlightInfo({
          time_flight: this.timeFlight,
          num_flight: this.inputNumFlight,
          date_flight: this.inputDateFlight,
        });

        const oneWayTimeFlightBoardingDt = dayjs(
          `${this.inputDateFlight} ${this.timeFlight}`
        ).add(this.delayTime, "m");

        const oneWayBoardingDt = dayjs(
          `${this.reservationInfo.one_way_date} ${this.reservationInfo.one_way_time}`
        );

        if (oneWayTimeFlightBoardingDt.diff(oneWayBoardingDt, "minutes") <= 0) {
          this.setScheduleInfo({
            ...this.getScheduleInfo(),
            one_way_date: this.getDateTime(oneWayBoardingDt, "YYYY-MM-DD"),
            one_way_time: this.getDateTime(oneWayBoardingDt, "HH:mm"),
          });
        } else {
          const prevTime = this.convertTime(
            oneWayBoardingDt.unix(),
            "HH시 mm분"
          );
          const currentTime = this.convertTime(
            oneWayTimeFlightBoardingDt.unix(),
            "HH시 mm분"
          );
          const delayTimeMsg = this.delayTime === 90 ? "1시간 30분" : "1시간";
          const alertMessage = `<b>픽업 시간 변경</b><br>기존 : ${prevTime}<br>변경 : <b>${currentTime}</b><br><br>입국 지연 가능성으로 인해<br>도착 시간 ${delayTimeMsg} 이후로<br>자동 변경 됩니다.`;
          await this.alert(alertMessage);

          this.setScheduleInfo({
            ...this.getScheduleInfo(),
            one_way_date: this.getDateTime(
              oneWayTimeFlightBoardingDt,
              "YYYY-MM-DD"
            ),
            one_way_time: this.getDateTime(oneWayTimeFlightBoardingDt, "HH:mm"),
          });
        }
        this.reservationInfo = this.getReservationInfo();
        this.setIsLoading(true);
        this.setLoadingMessage("결제 금액 조회 중");
        this.maxDiscountCoupon = null;
        await this.searchPaymentAmount();
      } catch (e) {
        console.log(e);
      } finally {
        this.setIsLoading(false);
        this.setLoadingMessage("");
      }
    },
    async searchPaymentAmount() {
      // 중복 예약 체크 api 호출
      await nearmeApi.checkOverlap({
        oneWayDate: this.reservationInfo.one_way_date,
        oneWayTime: this.reservationInfo.one_way_time,
        roundTripDate: this.reservationInfo.round_trip_date,
        roundTripTime: this.reservationInfo.round_trip_time,
      });

      if (this.serviceType === "airport") {
        // 공항 픽업 예약 계산서
        await this.getAirportReservationRequest();

        // 쿠폰 페이지 조회 api 호출
        await this.getNearMeCouponList("NEARME_LIMO");
      } else {
        // 골프장 픽업 예약 계산서
        await this.getClubReservationRequest();
        // 쿠폰 페이지 조회 api 호출
        await this.getNearMeCouponList("NEARME_GOLF");
      }
    },
    async getNearMeTermList() {
      const { data } = await nearmeApi.getTermList(["1304"]);
      this.termList = data.filter((el) => el.termUrl);
    },
    async getAirportReservationRequest() {
      const { data } = await nearmeApi.getAirportReservationRequest({
        arriveAddress:
          this.reservationInfo.reverse_yn === "Y"
            ? this.reservationInfo.departure_address
            : this.reservationInfo.arrival_address,
        arriveLat:
          this.reservationInfo.reverse_yn === "Y"
            ? this.reservationInfo.departure_lat
            : this.reservationInfo.arrival_lat,
        arriveLng:
          this.reservationInfo.reverse_yn === "Y"
            ? this.reservationInfo.departure_lng
            : this.reservationInfo.arrival_lng,
        arriveName:
          this.reservationInfo.reverse_yn === "Y"
            ? this.reservationInfo.departure_name
            : this.reservationInfo.arrival_name,
        bags: this.getLuggageTotalCount(),
        guestId: this.guestId,
        oneWayAt: this.convertTimestamp(
          `${this.reservationInfo.one_way_date} ${this.reservationInfo.one_way_time}`
        ),
        passengers: this.reservationInfo.passengers,
        roundTripAt:
          this.reservationInfo.round_trip_date !== null
            ? this.convertTimestamp(
                `${this.reservationInfo.round_trip_date} ${this.reservationInfo.round_trip_time}`
              )
            : null,
        roundTripYn: this.reservationInfo.round_trip_date !== null ? "Y" : "N",
        meetServiceYn: this.meetService ? "Y" : "N",
        terminalCd: this.reservationInfo.terminal_cd,
        uid: this.userUid || null,
        exchangeYn: this.reservationInfo.reverse_yn,
        engFirstName: this.reservationInfo.eng_first_name,
        engLastName: this.reservationInfo.eng_last_name,
        phoneNumber: this.reservationInfo.phone_number.replace(/-/g, ""),
        searchName: this.reservationInfo.search_name,
        email: this.reservationInfo.email,
      });
      this.amountInfo = data;
    },
    async getClubReservationRequest() {
      const { data } = await nearmeApi.getClubReservationRequest({
        arriveAddress: this.reservationInfo.arrival_address,
        arriveLat: this.reservationInfo.arrival_lat,
        arriveLng: this.reservationInfo.arrival_lng,
        arriveName:
          this.reservationInfo.arrival_eng_name ||
          this.reservationInfo.arrival_name,
        bags: this.getLuggageTotalCount(),
        guestId: this.guestId,
        departureAddress: this.reservationInfo.departure_address,
        departureLat: this.reservationInfo.departure_lat,
        departureLng: this.reservationInfo.departure_lng,
        departureName:
          this.reservationInfo.departure_eng_name ||
          this.reservationInfo.departure_name,
        oneWayAt: this.convertTimestamp(
          `${this.reservationInfo.one_way_date} ${this.reservationInfo.one_way_time}`
        ),
        passengers: this.reservationInfo.passengers,
        roundTripAt:
          this.reservationInfo.round_trip_date !== null
            ? this.convertTimestamp(
                `${this.reservationInfo.round_trip_date} ${this.reservationInfo.round_trip_time}`
              )
            : null,
        roundTripYn: this.reservationInfo.round_trip_date !== null ? "Y" : "N",
        meetServiceYn: this.meetService ? "Y" : "N",
        uid: this.userUid || null,
        engFirstName: this.reservationInfo.eng_first_name,
        engLastName: this.reservationInfo.eng_last_name,
        phoneNumber: this.reservationInfo.phone_number.replace(/-/g, ""),
        searchName: this.reservationInfo.search_name,
        email: this.reservationInfo.email,
      });
      this.amountInfo = data;
    },
    async createInitPaymentInfo() {
      const { data } = await nearmeApi.createInitPaymentInfo("5801", {
        amount: this.finalAmount,
        cpid: process.env.VUE_APP_PORTONE_DANAL_CPID,
        currency: "KRW",
        digital: "N",
        impUid: "",
        merchantUid: "",
        paidAt: "",
        pgText: process.env.VUE_APP_PORTONE_DANAL_PG_TEXT,
        recommendId: this.reservationInfo.recommendation_path_id || null,
        reservationId: this.reservationId,
        taxFree: 0,
        uid: this.userUid,
        userCode: process.env.VUE_APP_PORTONE_DANAL_USER_CODE,
      });

      this.merchantUid = data.merchantUid;
    },
    async createReservationId() {
      const { data } = await nearmeApi.createReservationId();
      this.reservationId = data.reservationId;
      this.reservationCd = data.reservationCd;
    },
    openTermDetail(url) {
      if (url) {
        handleOpenWeb({ url: url });
      }
    },
    initPortone() {
      this.script = document.createElement("script");
      this.script.addEventListener("load", async () => {
        window.IMP.init(process.env.VUE_APP_PORTONE_DANAL_USER_CODE);
      });
      this.script.setAttribute("src", "https://cdn.iamport.kr/v1/iamport.js");
      document.head.appendChild(this.script);
    },
    goToAirportServiceInfo() {
      newWebviewLayerV2({
        name: "AirportServiceInfo",
        hideAppBar: true,
      });
    },
    async goToCouponRegister() {
      const baseAmount = this.meetService
        ? this.baseAmount - this.meetServiceAmount
        : this.baseAmount;
      newWebviewLayerV2({
        name: "NmCoupon",
        query: {
          isLayer: true,
          partner_service: this.partnerService,
          passengers: this.reservationInfo.passengers,
          amount: baseAmount,
        },
      });
    },
    goToCouponList() {
      const baseAmount = this.meetService
        ? this.baseAmount - this.meetServiceAmount
        : this.baseAmount;
      newWebviewLayerV2({
        name: "NmCouponList",
        query: {
          isLayer: true,
          partner_service: this.partnerService,
          passengers: this.reservationInfo.passengers,
          amount: baseAmount,
        },
      });
    },
    openBottomModal() {
      this.extraService();
    },
    async onClickPayment() {
      if (!this.allAgree) {
        alert("약관을 모두 동의해주세요.");
        return;
      }
      if (this.isPaymentProcess) {
        return;
      }
      if (this.isFetching) {
        return;
      }
      try {
        await hideBackButton({
          isHide: true,
        });
        await preventBackButton({
          isBackPrevent: true,
        });
        this.isPaymentProcess = true;
        this.isFetching = true;

        // 예약 아이디 생성
        await this.createReservationId();

        // 빌링키 발급 전 결제 정보 생성
        await this.createInitPaymentInfo();

        this.requestPayment();
      } catch (error) {
        await hideBackButton({
          isHide: false,
        });
        await preventBackButton({
          isBackPrevent: false,
        });
        this.isPaymentProcess = false;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      } finally {
        this.isFetching = false;
      }
    },
    async getNearMeCouponList(partnerService) {
      const baseAmount = this.baseAmount;
      const {
        data: { usableTotal },
      } = await userApi.getCouponListPartner({
        page: 1,
        size: 5,
        amount: baseAmount,
        partnerService: [partnerService],
        passengers: this.reservationInfo.passengers,
      });
      this.couponCountTotal = usableTotal;
    },
    async updateReservation() {
      await nearmeApi.updateReservation(this.reservationId, {
        arriveAddress: this.reservationInfo.arrival_address,
        arriveLat: this.reservationInfo.arrival_lat,
        arriveLng: this.reservationInfo.arrival_lng,
        arriveName: this.reservationInfo.arrival_name,
        carrierLarge: this.reservationInfo.carrier_large,
        carrierMedium: this.reservationInfo.carrier_medium,
        carrierSmall: this.reservationInfo.carrier_small,
        couponDiscountAmount: this.couponNumber ? this.finalAmount : null,
        couponNumber: this.couponNumber ? this.couponNumber : null,
        discountAmount: this.couponNumber ? this.discountAmount : null,
        departureAddress: this.reservationInfo.departure_address,
        departureLat: this.reservationInfo.departure_lat,
        departureLng: this.reservationInfo.departure_lng,
        departureName: this.reservationInfo.departure_name,
        engFirstName: this.reservationInfo.eng_first_name,
        engLastName: this.reservationInfo.eng_last_name,
        fleetId: this.getServiceAmountInfo()?.fleetId,
        halfBag: this.reservationInfo.half_bag,
        meetServiceYn: this.meetService ? "Y" : "N",
        name: this.reservationInfo.name,
        numFlight: this.reservationInfo.num_flight || null,
        oneWayBaseAmount: this.getServiceAmountInfo()?.oneWayBaseAmount,
        oneWayDate: this.reservationInfo.one_way_date,
        oneWayEarlyDiscount: this.getServiceAmountInfo()?.oneWayEarlyDiscount,
        oneWayFinalAmount: this.getServiceAmountInfo()?.oneWayFinalAmount,
        oneWayGroupDiscount: this.getServiceAmountInfo()?.oneWayGroupDiscount,
        oneWayId: this.getServiceAmountInfo()?.oneWayId,
        oneWayShareDiscount: this.getServiceAmountInfo()?.oneWayShareDiscount,
        oneWayTime: this.reservationInfo.one_way_time,
        otherWithin100: this.reservationInfo.other_with_in_100,
        otherWithin150: this.reservationInfo.other_with_in_150,
        otherWithin200: this.reservationInfo.other_with_in_200,
        passengers: this.reservationInfo.passengers,
        phoneNumber: this.reservationInfo.phone_number,
        recommendationPathId: this.reservationInfo.recommendation_path_id,
        rideCartId: this.getServiceAmountInfo()?.rideCartId,
        roundTripBaseAmount: this.getServiceAmountInfo()?.roundTripBaseAmount,
        roundTripDate: this.reservationInfo.round_trip_date,
        roundTripEarlyDiscount:
          this.getServiceAmountInfo()?.roundTripEarlyDiscount,
        roundTripFinalAmount: this.getServiceAmountInfo()?.roundTripFinalAmount,
        roundTripGroupDiscount:
          this.getServiceAmountInfo()?.roundTripGroupDiscount,
        roundTripId: this.getServiceAmountInfo()?.roundTripId,
        roundTripShareDiscount:
          this.getServiceAmountInfo()?.roundTripShareDiscount,
        roundTripTime: this.reservationInfo.round_trip_time,
        standBag: this.reservationInfo.stand_bag,
        searchName: this.reservationInfo.search_name,
        serviceFee: this.serviceFee,
        timeFlight: this.reservationInfo.time_flight || null,
        dateFlight: this.convertTimestamp(this.reservationInfo.date_flight),
        tourBag: this.reservationInfo.tour_bag,
        userPaymentAmount: this.baseAmount,
        totalBags: this.getLuggageTotalCount(),
        webAppCd: "APP",
        email: this.reservationInfo.email,
      });
    },
    async requestPayment() {
      window.IMP.request_pay(
        {
          pg: `${process.env.VUE_APP_PORTONE_DANAL_PG_TEXT}.${process.env.VUE_APP_PORTONE_DANAL_CPID}`,
          pay_method: "card",
          merchant_uid: this.merchantUid,
          name: "NearMe 일본교통예약",
          amount: 0,
          customer_uid: this.userUid,
          buyer_email: "",
          buyer_name: this.reservationInfo.name,
          buyer_tel: this.reservationInfo.phone_number,
        },
        this.getBillingKeyCallback
      );
    },

    async getBillingKeyCallback(result) {
      try {
        if (result.success) {
          this.setIsLoading(true);
          this.setLoadingMessage("예약 확정 중 화면을 유지해주세요.");
          this.impUid = result.imp_uid;
          const { code } = await this.doPayments();

          if (code === "200") {
            await this.updateReservation();
            await nearmeApi.immediatelyPayments(
              this.reservationId,
              this.merchantUid
            );
            // 결제 완료 시 저장된 예약 정보 삭제
            this.resetReservationInfo();
            await hideBackButton({
              isHide: false,
            });
            await preventBackButton({
              isBackPrevent: true,
            });
            this.isPaymentProcess = false;
            this.setIsLoading(false);
            this.setLoadingMessage("");
            this.reservationSuccess(this.reservationCd);
          }
        } else {
          await hideBackButton({
            isHide: false,
          });
          await preventBackButton({
            isBackPrevent: true,
          });
          this.paymentFail(result.error_msg, result.error_code);
        }
      } catch (e) {
        await hideBackButton({
          isHide: false,
        });
        await preventBackButton({
          isBackPrevent: false,
        });
        console.log(e);
      } finally {
        this.isPaymentProcess = false;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      }
    },
    async doPayments() {
      return nearmeApi.doPayments("5801", {
        amount: this.finalAmount,
        cpid: process.env.VUE_APP_PORTONE_DANAL_CPID,
        currency: "KRW",
        digital: "N",
        impUid: this.impUid,
        merchantUid: this.merchantUid,
        payMethod: "card",
        pgText: process.env.VUE_APP_PORTONE_DANAL_PG_TEXT,
        receiptUrl: `https://service.iamport.kr/payments/${this.impUid}`,
        recommendId: this.reservationInfo.recommendation_path_id || null,
        reservationId: this.reservationId,
        taxFree: 0,
        uid: this.userUid,
        userCode: process.env.VUE_APP_PORTONE_DANAL_USER_CODE,
      });
    },
    getServiceAmountInfo() {
      if (this.serviceType === "airport") {
        return this.amountInfo?.limo;
      } else {
        return this.amountInfo?.golf;
      }
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>
