<template>
  <div class="bl_info" :class="journeyTypeClass">
    <div class="bl_info_inner">
      <div class="bl_info_navi">
        <ul class="el_reset bl_navi_list">
          <li class="bl_navi_item is_moWay" @click="setJourneyType('one-way')">
            <div class="bl_navi_btn">편도</div>
          </li>
          <li class="bl_navi_item is_moRound" @click="setJourneyType('round-trip')">
            <div class="bl_navi_btn">왕복</div>
          </li>
        </ul>
      </div>
      <div class="bl_info_cont" :class="modeClass">
        <div class="bl_cont_place">
          <div class="bl_place_change" @click="reverse"></div>
          <ul class="el_reset bl_place_list">
            <li class="bl_place_item">
              <slot name="departure-form" />
            </li>
            <li class="bl_place_item">
              <slot name="arrival-form" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "JourneyForm",
  props: {
    value: {
      type: String,
      default: "one-way"
    },
  },
  computed: {
    journeyTypeClass() {
      return this.value === "one-way" ? "is_acWay" : "is_acRound";
    },
    modeClass() {
      return this.value === "one-way" ? "is_moWay" : "is_moRound";
    },
  },
  methods: {
    setJourneyType(type) {
      this.$emit("input", type);
    },
    reverse() {
      this.$emit("reverse");
    }
  }
};
</script>