<template>
  <div class="cp_cusTime_lay_inner">
    <div class="cp_cusTime_lay_inner2">
      <div class="cp_cusTime_lay_inner3">
        <div class="cp_cusTime_lay_inner4">
          <div class="cp_cusTime_lay_body">
            <div class="cp_cusTime_lay_body_pick">
              <div class="date-selector">
                <IosDatepicker
                  :source="timeTypeList"
                  v-model="timeType"
                  type="normal"
                  ref="timeTypeComponent"
                  @changeMoving="onPickerMoveChange"
                ></IosDatepicker>
                <IosDatepicker
                  :source="hourList"
                  v-model="birthHour"
                  ref="hourComponent"
                  @changeMoving="onPickerMoveChange"
                ></IosDatepicker>
                <IosDatepicker
                  :source="minuteList"
                  v-model="birthMinute"
                  ref="minuteComponent"
                  @changeMoving="onPickerMoveChange"
                ></IosDatepicker>
              </div>
            </div>
            <div class="cp_cusTime_lay_body_non">
              <button
                class="el_form cp_cusTime_lay_body_non_btn"
                @click.stop="submitDefaultTime"
              >
                태어난 시간 모름
              </button>
            </div>
          </div>
          <div class="cp_cusTime_lay_footer">
            <div class="cp_cusTime_lay_footer_btn">
              <div class="cp_btn">
                <!-- 비활성(A): disabled -->
                <button
                  class="el_form cp_btn_btn"
                  :disabled="isMoving"
                  @click.stop="submit"
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IosDatepicker from "@/components/IosDatepicker.vue";

export default {
  name: "JeomsinDatepicker",
  props: ["defaultTimeType", "defaultHour", "defaultMinute"],
  components: {
    IosDatepicker,
  },
  data() {
    return {
      timeType: this.defaultTimeType,
      birthHour: this.defaultHour,
      birthMinute: this.defaultMinute,
      movingPickerCount: 0,
    };
  },
  methods: {
    submit() {
      this.$emit("change", {
        timeType: this.timeType,
        hour: this.birthHour,
        minute: this.birthMinute,
      });
    },
    submitDefaultTime() {
      this.$emit("change", {
        timeType: "AM",
        hour: 0,
        minute: 0,
      });
    },
    onPickerMoveChange(value) {
      if (value) {
        this.movingPickerCount += 1;
      } else {
        this.movingPickerCount -= 1;
      }
      if (this.movingPickerCount < 0) {
        this.movingPickerCount = 0;
      }
    },
  },
  computed: {
    timeTypeList() {
      return [
        {
          value: "AM",
          text: "오전",
        },
        {
          value: "PM",
          text: "오후",
        },
      ];
    },
    hourList() {
      return Array.from(Array(12), (_, i) => ({ value: i, text: i }));
    },
    minuteList() {
      return Array.from(Array(60), (_, i) => ({ value: i, text: i }));
    },
    isMoving() {
      return !!this.movingPickerCount;
    },
  },
};
</script>
