<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="ly_container">
              <div class="bl_info_wrapper">
                <div class="bl_info">
                  <div class="bl_info_ttl">
                    <div class="bl_info_ttl_txt">가입자 정보</div>
                    <button
                      @click="
                        logEvent(
                          'more_membership_join_modify_click',
                          showMyInfo
                        )
                      "
                      class="el_form bl_info_ttl_btn"
                    >
                      정보 수정
                    </button>
                  </div>
                  <div class="bl_info_cont">
                    <div class="bl_info_cont_inner">
                      <ul class="el_reset bl_info_cont_infoList">
                        <li class="bl_info_cont_infoList_item">
                          <div class="bl_info_cont_info">
                            <div class="bl_info_cont_info_cont">
                              {{ userName }}
                            </div>
                            <div class="bl_info_cont_info_ico is_moName"></div>
                          </div>
                        </li>
                        <li class="bl_info_cont_infoList_item">
                          <div class="bl_info_cont_info">
                            <div class="bl_info_cont_info_cont">
                              {{ hpNo }}
                            </div>
                            <div class="bl_info_cont_info_ico is_moPhone"></div>
                          </div>
                        </li>
                        <li class="bl_info_cont_infoList_item">
                          <div class="bl_info_cont_info">
                            <input
                              class="el_form bl_info_cont_info_inp"
                              placeholder="이메일"
                              type="text"
                              :value="email"
                              @click="
                                logEvent('more_membership_join_email_input')
                              "
                              @input="onEmailInput"
                              :maxlength="50"
                            />
                            <div class="bl_info_cont_info_ico is_moEmail"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bl_goods_wrapper">
                <div class="bl_goods">
                  <div class="bl_goods_ttl">
                    <div class="bl_goods_ttl_txt">결제 상품</div>
                  </div>
                  <div class="bl_goods_cont">
                    <div class="bl_goods_cont_inner">
                      <div class="bl_goods_cont_ttl">
                        <div class="bl_goods_cont_ttl_ttl is_moMember"></div>
                        <div class="bl_goods_cont_ttl_price">2,000원/월</div>
                      </div>
                      <div class="bl_goods_cont_cont">
                        필드 홀인원 : 100만원<br />
                        스크린 홀인원 : 20만원<br />
                        APL 이용권(1일) : 1개
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bl_coupon_wrapper">
                <div class="bl_coupon">
                  <div class="bl_coupon_ttl">
                    <div class="bl_coupon_ttl_txt">
                      쿠폰({{ validCouponCnt || 0 }})
                    </div>
                    <button
                      @click="
                        logEvent(
                          'more_membership_join_coupon_click',
                          openCoupon
                        )
                      "
                      class="el_form bl_coupon_ttl_btn"
                    >
                      쿠폰 등록
                    </button>
                  </div>
                  <!-- 활성(C): is_ac -->
                  <div
                    class="bl_coupon_cont"
                    :class="{ is_ac: validCouponCnt }"
                  >
                    <div class="bl_coupon_cont_inner">
                      <div class="bl_coupon_cont_none">
                        사용 가능한 쿠폰이 없습니다.
                      </div>
                      <div class="bl_coupon_cont_cont">
                        <div class="bl_coupon_cont_cont_ttl">
                          {{ couponName }}
                        </div>
                        <div class="bl_coupon_cont_cont_price">
                          {{ couponPrice }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bl_method_wrapper">
                <div class="bl_method">
                  <div class="bl_method_ttl">
                    <div class="bl_method_ttl_txt">결제 수단</div>
                  </div>
                  <!-- 활성(C): is_ac -->
                  <div class="bl_method_cont is_ac">
                    <div class="bl_method_cont_inner">
                      <div class="bl_method_cont_none">
                        사용 가능한 결제 수단이 없습니다.
                      </div>
                      <div class="bl_method_cont_cont">
                        신용/체크카드(정기결제)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <footer class="ly_footer">
            <div class="ly_container">
              <div class="bl_terms_wrapper">
                <div class="bl_terms">
                  <!-- 체크(A): checked / 비활성(A): disabled -->
                  <input
                    @click="logEvent('more_membership_join_policy_check')"
                    v-model="membershipTermAgree"
                    class="el_form bl_terms_inp"
                    type="checkbox"
                  />
                  <div class="bl_terms_chk"></div>
                  <div class="bl_terms_txt">
                    <strong class="el_reset bl_terms_txt_required"
                      >[필수]</strong
                    >
                    보이스캐디 APL 멤버십 이용약관
                  </div>
                  <div
                    class="bl_terms_ico"
                    @click="
                      logEvent(
                        'more_membership_join_policy_click',
                        openTerm('Membership')
                      )
                    "
                  ></div>
                </div>
              </div>
              <div class="bl_btn_wrapper">
                <div class="bl_btn">
                  <!-- 비활성(A): disabled -->
                  <button
                    class="el_form bl_btn_btn"
                    :disabled="disabled"
                    @click="logEvent('more_membership_join_pay_click', submit)"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  handleOpenWeb,
  newWebviewLayerV2,
  showMyInfo,
  showToast,
} from "@/util/webview";
import { membershipApi } from "@/api";
import { isJson } from "@/util/string";
import dayjs from "dayjs";

export default {
  name: "VoicecaddieMembershipPay",
  data() {
    return {
      userInfo: null,
      membershipTermAgree: false,
      email: "",
      isFetching: false,
    };
  },
  async created() {
    await this.setData();
    window.getDataTopToBottomWebLayer = (data) => {
      if (!this.userInfo) {
        return;
      }
      try {
        let parsedData = data;
        const isJsonFormat = isJson(parsedData);
        if (isJsonFormat) {
          parsedData = JSON.parse(data);
        }
        if (parsedData?.success) {
          this.goMembershipInfo();
          return;
        }
        this.userInfo = {
          ...this.userInfo,
          ...parsedData,
          couponItemEndDt: parsedData.couponEndDt,
        };
      } catch (e) {
        console.log(e);
      }
    };
  },
  computed: {
    userName() {
      return this.userInfo?.userName;
    },
    hpNo() {
      return this.userInfo?.hpNo;
    },
    validCouponCnt() {
      return (
        this.userInfo?.validCouponCnt || (this.userInfo?.couponNumber ? 1 : 0)
      );
    },
    couponItemPeriodUnit() {
      return this.userInfo?.couponItemPeriodUnit;
    },
    isCouponItemAutoPayment() {
      return this.userInfo?.autoPaymentYn === "Y";
    },
    couponName() {
      return this.userInfo?.couponName;
    },
    couponPrice() {
      return this.couponItemPeriodUnit === "Y" ? "-24,000원" : "첫 달 0원";
    },
    disabled() {
      return !this.membershipTermAgree;
    },
    buttonText() {
      return this.userInfo?.couponNumber
        ? this.couponItemPeriodUnit === "Y"
          ? "0원 결제하기"
          : "첫 달 무료 가입하기"
        : "2,000원 결제하기";
    },
  },
  methods: {
    showMyInfo,
    async setData() {
      const { data } = await membershipApi.getMembershipJoin();
      this.userInfo = data;
      this.email = data?.email || "";
      if(this.userInfo !== null && this.userInfo.couponItemProductId !== null){
        this.userInfo.productId = this.userInfo.couponItemProductId;
      }
    },
    openTerm(componentName) {
      const { href } = this.$router.resolve({ name: componentName });
      handleOpenWeb({ url: `${location.origin}${href}` });
    },
    onEmailInput(e) {
      this.email = e.target.value.slice(0, 50);
      e.target.value = this.email;
    },
    openCoupon() {
      if (this.userInfo?.newMembershipYn !== "Y") {
        showToast({ message: "멤버십 최초 가입에만 적용할 수 있습니다." });
        return;
      }
      newWebviewLayerV2({ name: "Coupon", query: { isLayer: 1 } });
    },
    async submit() {
      if (
        this.userInfo.couponItemEndDt &&
        this.userInfo.couponItemEndDt < dayjs().unix()
      ) {
        alert("만료된 쿠폰입니다.");
        this.userInfo = {
          ...this.userInfo,
          validCouponCnt: null,
          productCouponId: null,
          couponNumber: null,
          couponName: null,
          couponItemProductId: null,
          autoPaymentYn: null,
          couponItemName: null,
          couponItemMembershipType: null,
          couponItemPeriodUnit: null,
          couponItemPeriodValue: null,
          couponItemEndDt: null,
        };
        return;
      }
      if (this.userInfo.autoPaymentYn === "N") {
        try {
          if (this.isFetching) {
            return;
          }
          this.isFetching = true;
          await membershipApi.joinMembership({
            agreeTermsIdList: [11],
            couponNumber: this.userInfo.couponNumber,
            email: this.email,
            productCouponId: this.userInfo.productCouponId,
            productId: this.userInfo.productId,
          });
          this.goMembershipInfo();
        } catch (e) {
          alert(e.message);
        } finally {
          this.isFetching = false;
        }
      } else {
        newWebviewLayerV2({
          name: "JoinMembership",
          query: {
            couponNumber: this.userInfo.couponNumber,
            email: this.email,
            productCouponId: this.userInfo.productCouponId,
            productId: this.userInfo.productId,
          },
        });
      }
    },
    goMembershipInfo() {
      this.$router.back();
      window.setTimeout(() => {
        this.$router.replace({ name: "VoicecaddieMembershipInfo" });
      }, 10);
    },
  },
};
</script>
