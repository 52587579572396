var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lyPage"},[_c('header',{staticClass:"lyHeader"},[_c('section',{staticClass:"stSearch"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"cmSearch"},[_c('div',{staticClass:"cmSearch-input"},[_c('byte-input',{staticClass:"cmSearch-inputInput",attrs:{"max-byte":100,"placeholder":"검색어를 입력하세요.","auto-focus":true},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"click":function($event){return _vm.logEvent('community_posting_place_search_click')}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('i',{staticClass:"cmSearch-inputSearch",on:{"click":_vm.search}},[_vm._v(" 검색 ")]),_c('i',{staticClass:"cmSearch-inputClear",on:{"click":function($event){_vm.searchValue = ''}}},[_vm._v(" 지우기 ")])],1)])])])]),(_vm.addrs || _vm.clubs)?_c('main',{staticClass:"lyBody"},[_c('section',{staticClass:"stContent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cmHeader"},[_c('div',{staticClass:"cmHeader-title"},[_vm._v("지역")]),(_vm.addrs && _vm.addrs.length >= 5)?_c('div',{staticClass:"cmHeader-button",on:{"click":function($event){_vm.logEvent('community_posting_place_location_all_click', function () {
                _vm.newWebviewLayerV2({
                  name: 'CommunitySearchKeyword',
                  query: { keyword: _vm.currentSearchValue, type: 'registAddr' },
                });
              })}}},[_vm._v(" 더보기 ")]):_vm._e()])]),_c('div',{staticClass:"body"},[(!_vm.addrs || !_vm.addrs.length)?_c('div',{staticClass:"search-noResult"},[_c('div',{staticClass:"search-caption"},[_vm._v("검색결과가 없어요.")])]):_vm._e(),_c('div',{staticClass:"cmMatching"},[_c('ul',{staticClass:"cmMatching-list"},_vm._l((_vm.addrs),function(addr){return _c('li',{key:addr.addrId,staticClass:"cmMatching-item",domProps:{"innerHTML":_vm._s(addr.addrHtml)},on:{"click":function($event){_vm.logEvent('community_posting_place_location_click', function () {
                  _vm.submit(addr);
                })}}})}),0)])])]),_c('section',{staticClass:"stContent"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cmHeader"},[_c('div',{staticClass:"cmHeader-title"},[_vm._v("골프장")]),(_vm.clubs && _vm.clubs.length >= 5)?_c('div',{staticClass:"cmHeader-button",on:{"click":function($event){_vm.logEvent('community_posting_place_golfcc_all_click', function () {
                _vm.newWebviewLayerV2({
                  name: 'CommunitySearchKeyword',
                  query: { keyword: _vm.currentSearchValue, type: 'registClub' },
                });
              })}}},[_vm._v(" 더보기 ")]):_vm._e()])]),_c('div',{staticClass:"body"},[(!_vm.clubs || !_vm.clubs.length)?_c('div',{staticClass:"search-noResult"},[_c('div',{staticClass:"search-caption"},[_vm._v("검색결과가 없어요.")])]):_vm._e(),_c('div',{staticClass:"cmMatching"},[_c('ul',{staticClass:"cmMatching-list"},_vm._l((_vm.clubs),function(club){return _c('li',{key:club.ccode,staticClass:"cmMatching-item",domProps:{"innerHTML":_vm._s(club.clubNameHtml)},on:{"click":function($event){_vm.logEvent('community_posting_place_golfcc_click', function () {
                  _vm.submit(club);
                })}}})}),0)])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }