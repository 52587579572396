<template>
  <div></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "JoinMembership",
  created() {
    const { passJoin } = this.$route.query;
    if (passJoin) {
      sessionStorage.setItem("passJoin", true);
    }
    this.initToss();
  },
  data() {
    return {
      script: null,
      toss: null,
    };
  },
  beforeDestroy() {
    document.head.removeChild(this.script);
  },
  methods: {
    initToss() {
      this.script = document.createElement("script");
      this.script.addEventListener("load", async () => {
        this.toss = window.TossPayments(process.env.VUE_APP_TOSS_CLIENT_KEY);
        this.requestBillingAuth();
      });
      this.script.setAttribute("src", "https://js.tosspayments.com/v1/payment");
      document.head.appendChild(this.script);
    },
    requestBillingAuth() {
      this.toss
        .requestBillingAuth("카드", {
          customerKey: this.userUid,
          successUrl: `${location.origin}/voicecaddie-membership/join-success?${this.routeQueryString}`,
          failUrl: `${location.origin}/voicecaddie-membership/join-fail?${this.routeQueryString}`,
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["userUid"]),
    routeQueryString() {
      return Object.entries(this.$route.query)
        .map((e) => e.join("="))
        .join("&");
    },
  },
};
</script>
