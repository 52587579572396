<template>
  <div class="bl_map">
    <div ref="map" id="map"></div>
    <div class="bl_map_point" :class="markerIconClass">{{ markerLabel }}</div>
  </div>
</template>
<script>
import loader from "@/util/googleMaps";
import {
  NEARME_AIRPORT_SERVICE_CITY_LIST,
  NEARME_GOLF_SERVICE_CITY_LIST,
} from "@/util/googleMaps.js";

export default {
  name: "NmMap",
  props: {
    lat: {
      type: [String, Number],
      required: true,
    },
    lng: {
      type: [String, Number],
      required: true,
    },
    markerLabel: {
      type: String,
      default: "",
    },
    serviceType: {
      type: String,
      default: "airport",
    },
  },
  data() {
    return {
      map: null,
      geocoder: null,
      places: null,
      addressTypes: ["political", "sublocality", "sublocality_level_4"],
      areaTypes: ["locality", "political"],
      location: {},
      isFetching: false,
      placeName: "",
      searchName: "",
    };
  },
  created() {
    const { name, search_name } = this.$route.query;
    this.placeName = name;
    this.location.lat = Number(this.lat);
    this.location.lng = Number(this.lng);
    this.searchName = search_name;
  },
  async mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.map = null;
  },
  computed: {
    markerIconClass() {
      return this.markerLabel === "출발" ? "is_moStart" : "is_moDest";
    },
  },
  methods: {
    async initMap() {
      const { Map } = await loader.importLibrary("maps");
      const { Geocoder } = await loader.importLibrary("geocoding");
      const { PlacesService, PlacesServiceStatus } = await loader.importLibrary("places");
      const position = this.location;

      const JAPAN_BOUNDS = {
        north: 45.5514834662,
        south: 31.0295791692,
        west: 129.408463169,
        east: 145.543137242,
      };

      // map
      this.map = new Map(this.$refs.map, {
        mapId: "cfdf8f80bd0cfc97",
        center: position,
        zoom: 18,
        disableDefaultUI: true,
        clickableIcons: false,
        restriction: {
          latLngBounds: JAPAN_BOUNDS,
          strictBounds: false,
        },
      });

      this.map.addListener("dragend", this.getCurrentLocation);
      this.map.addListener("zoom_changed", this.getCurrentLocation);

      // geocoder
      this.geocoder = new Geocoder();

      const searchNameLastWord = this.searchName?.slice(-1);
      const placeNameLastWord = this.placeName?.slice(-1);
      let searchQuery = this.placeName;
      if(searchNameLastWord === "역" && placeNameLastWord !== "역") {
        searchQuery = this.searchName;
      }

      // place
      this.places = new PlacesService(this.map);
      const request = {
        query: searchQuery,
        locationBias: position,
        fields: ['name', 'geometry', 'formatted_address'],
      };

      this.places.findPlaceFromQuery(request, (results, status) => {
        if (status === PlacesServiceStatus.OK) {
          this.$emit("location-info", {
            address: results[0].formatted_address,
            name: results[0].name,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          this.map.setCenter(results[0].geometry.location);
        }
      });
    },
    async getCurrentLocation() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const request = {
          location: this.map.getCenter(),
          language: "ko",
        };

        const { results } = await this.geocoder?.geocode(request);

        // 서비스 지역 체크
        const cityName = results?.find((el) => {
          return this.areaTypes.every((type) => el.types.includes(type));
        })?.address_components[0]?.long_name;

        if (this.serviceType === "airport") {
          if (!NEARME_AIRPORT_SERVICE_CITY_LIST.includes(cityName)) {
            alert(`서비스 지역을 벗어났어요.\n${this.markerLabel}지를 다시 설정해주세요.\n(도쿄 23구 내에서 가능)`);
            this.$emit("out-of-area", true);
          } else {
            this.$emit("out-of-area", false);
          }
        } else {
          if (!NEARME_GOLF_SERVICE_CITY_LIST.includes(cityName)) {
            alert(`서비스 지역을 벗어났어요.\n${this.markerLabel}지를 다시 설정해주세요.\n(도쿄 23구, 나리타 시 내에서 가능)`);
            this.$emit("out-of-area", true);
          } else {
            this.$emit("out-of-area", false);
          }
        }

        const place =
          results?.find((el) => {
            return this.addressTypes.every((type) => el.types.includes(type));
          }) || results[0];

        const placeAddress = place.formatted_address;
        const {
          results: { name },
        } = await this.getPlaceName(results[0]?.place_id);

        this.$emit("location-info", {
          address: placeAddress,
          name: name,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      } finally {
        this.isFetching = false;
      }
    },

    async getPlaceName(placeId) {
      const request = {
        placeId: placeId,
        fields: ["name", "geometry"],
      };

      return new Promise((resolve, reject) => {
        try {
          this.places?.getDetails(request, (results, status) => {
            resolve({ results, status });
          });
        } catch {
          reject();
        }
      });
    },
  },
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 100%;
}

.bl_map {
  height: calc(100% - 19.7rem) !important;
}

.bl_map_point {
  top: calc((100% - 23.8rem) / 2 - 4.4rem + 12.05rem) !important;
}
</style>