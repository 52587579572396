<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="bl_info">
          <div class="bl_info_inner">
            <div class="bl_info_ttl">날짜 선택</div>
            <div class="bl_info_txt">
              오늘 기준으로 <strong class="el_reset">3일 후</strong>부터 예약할
              수 있어요.
            </div>
          </div>
        </div>

        <nm-date-picker
          v-model="selectedDate"
          :range="isRoundTrip"
          :disabledDates="disabledDates"
          @select="selectDate"
        />
      </div>
    </template>
    <template v-slot:footer>
      <div
        class="bl_desc"
        :class="{ is_ac: isRoundTrip && departureDate && !arrivalDate }"
      >
        오는 날을 선택해주세요.
      </div>
      <div class="ly_container">
        <div class="bl_select" :class="{ is_acTime: activeTimeClass }">
          <ul class="el_reset bl_select_list">
            <li
              class="bl_select_item"
              @click="openTimePickerModal('departure')"
            >
              <div class="bl_select_obj">
                <div class="bl_obj_label">가는날</div>
                <div class="bl_obj_val">
                  <input
                    class="el_form bl_val_input"
                    placeholder="-"
                    readonly
                    type="text"
                    :value="departureDate"
                  />
                </div>
                <div class="bl_obj_time">
                  <div class="bl_time_inner">
                    <div class="bl_time_ico"></div>
                    <input
                      class="el_form bl_time_input"
                      placeholder="00:00"
                      readonly
                      type="text"
                      :value="departureTime"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul v-if="isRoundTrip" class="el_reset bl_select_list">
            <li class="bl_select_item" @click="openTimePickerModal('arrival')">
              <div class="bl_select_obj">
                <div class="bl_obj_label">오는날</div>
                <div class="bl_obj_val">
                  <input
                    class="el_form bl_val_input"
                    placeholder="-"
                    readonly
                    type="text"
                    :value="arrivalDate"
                  />
                </div>
                <div class="bl_obj_time">
                  <div class="bl_time_inner">
                    <div class="bl_time_ico"></div>
                    <input
                      class="el_form bl_time_input"
                      placeholder="00:00"
                      readonly
                      type="text"
                      :value="arrivalTime"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="bl_btn">
          <nm-button
            v-if="missingRequirements"
            class="is_moPrim"
            :disabled="checkSelectedDate"
            @click="openTimePickerModal()"
            >예약 시간 선택하기</nm-button
          >
          <nm-button v-else class="is_moPrim" @click="goToBoardingInfo"
            >다음</nm-button
          >
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { nearmeApi } from "@/api";
import { preventBackButton } from "@/util/webview";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import NmDatePicker from "@/components/nearme/NmDatePicker.vue";
import dayjs from "dayjs";
import DateMixin from "@/mixins/dateMixin";
import reservationMixin from "@/mixins/reservationMixin";
export default {
  name: "SelectScheduleView",
  components: { DefaultLayout, NmButton, NmDatePicker },
  mixins: [DateMixin, reservationMixin],
  data() {
    return {
      serviceType: null,
      journeyType: null,
      isRoundTrip: false,
      isReverse: false,
      departureHour: null,
      departureMinute: null,
      arrivalHour: null,
      arrivalMinute: null,
      selectedDate: null,
      disabledDates: [
        // required this format
        {
          start: null,
          end: dayjs().add(3, "day"),
        },
        {
          start: dayjs().add(3, "month").subtract(1, "day"),
          end: null,
        },
      ],
      isFetching: false,
    };
  },
  async created() {
    const { service_type, journey_type } = this.$route.query;
    this.serviceType = service_type;
    this.journeyType = journey_type;

    this.title =
      this.serviceType === "airport" ? "공항 픽업 예약" : "골프장 픽업 예약";
    this.$nextTick(() => {
      document.title = this.title;
    });

    this.setData();
  },
  computed: {
    missingRequirements() {
      if (this.isRoundTrip) {
        return (
          !this.departureDate?.trim() ||
          !this.arrivalDate?.trim() ||
          !this.departureTime?.trim() ||
          !this.arrivalTime?.trim()
        );
      } else {
        return !this.departureDate?.trim() || !this.departureTime?.trim();
      }
    },
    checkSelectedDate() {
      if (this.isRoundTrip) {
        return !this.departureDate || !this.arrivalDate;
      } else {
        return !this.departureDate;
      }
    },
    activeTimeClass() {
      if (this.isRoundTrip) {
        return this.departureDate && this.arrivalDate;
      } else {
        return this.departureDate;
      }
    },
    departureDate() {
      if (Array.isArray(this.selectedDate)) {
        return this.selectedDate[0]?.format("YY.MM.DD") || null;
      } else {
        return this.selectedDate?.format("YY.MM.DD") || null;
      }
    },
    departureTime() {
      if (!this.departureHour || !this.departureMinute) return null;
      return `${this.departureHour}:${this.departureMinute}`;
    },
    arrivalDate() {
      if (Array.isArray(this.selectedDate)) {
        return this.selectedDate[1]?.format("YY.MM.DD") || null;
      } else {
        return null;
      }
    },
    arrivalTime() {
      if (!this.arrivalHour || !this.arrivalMinute) return null;
      return `${this.arrivalHour}:${this.arrivalMinute}`;
    },
  },
  watch: {
    departureTime(value) {
      // 왕복일 경우 가는 날 시간 선택 후 오는 날 시간 선택 모달 활성화
      if (this.isRoundTrip && value && this.arrivalDate && !this.arrivalTime) {
        this.openTimePickerModal();
      }
    },
  },
  methods: {
    selectDate(value) {
      if (Array.isArray(value)) {
        // 왕복
        if (value[0] && value[1]) {
          this.departureHour = null;
          this.departureMinute = null;
          this.arrivalHour = null;
          this.arrivalMinute = null;
          this.openTimePickerModal("departure");
        }
      } else {
        // 편도
        if (value) {
          this.departureHour = null;
          this.departureMinute = null;
          this.openTimePickerModal("departure");
        }
      }
    },
    setData() {
      const reservationInfo = this.getReservationInfo();
      this.isRoundTrip = this.journeyType === "one-way" ? false : true;
      this.isReverse = reservationInfo.reverse_yn === "Y" ? true : false;

      if (this.hasScheduleInfo()) {
        const { one_way_date, one_way_time, round_trip_date, round_trip_time } =
          this.getScheduleInfo();
        if (this.journeyType === "round-trip") {
          // 왕복
          if (one_way_date && !round_trip_date) {
            // 편도 -> 왕복 변경 시
            this.selectedDate = [];
            this.departureHour = null;
            this.departureMinute = null;
            this.arrivalHour = null;
            this.arrivalMinute = null;
          } else {
            this.selectedDate = [
              one_way_date ? dayjs(one_way_date) : null,
              round_trip_date ? dayjs(round_trip_date) : null,
            ];

            this.departureHour =
              one_way_time !== null ? one_way_time.split(":")[0] : null;
            this.departureMinute =
              one_way_time !== null ? one_way_time.split(":")[1] : null;
            this.arrivalHour =
              round_trip_date !== null ? round_trip_time.split(":")[0] : null;
            this.arrivalMinute =
              round_trip_date !== null ? round_trip_time.split(":")[1] : null;
          }
        } else {
          // 편도
          if (one_way_date && round_trip_date) {
            // 왕복 -> 편도 변경 시
            this.selectedDate = null;
            this.departureHour = null;
            this.departureMinute = null;
          } else {
            this.selectedDate = one_way_date ? dayjs(one_way_date) : null;

            this.setScheduleInfo({
              one_way_date: one_way_date,
              one_way_time: one_way_time,
              round_trip_date: null,
              round_trip_time: null,
            });

            this.departureHour =
              one_way_time !== null ? one_way_time.split(":")[0] : null;
            this.departureMinute =
              one_way_time !== null ? one_way_time.split(":")[1] : null;
          }
        }
      }
    },
    async openTimePickerModal(type) {
      const reservationInfo = this.getReservationInfo();

      let route = "";
      if (!type) {
        route = this.departureTime ? "arrival" : "departure";
      } else {
        route = type;
      }

      await preventBackButton({
        isBackPrevent: true,
      });
      if (route === "departure") {
        const title = this.isRoundTrip ? "예약 시간 선택 - 가는 날" : "예약 시간 선택";
        const { hour, minute } = await this.timePicker(title, "reservation", {
          hour: this.departureHour,
          minute: this.departureMinute,
          airportCd: this.isReverse ? null : reservationInfo.terminal_cd,
        });
        this.departureHour = hour;
        this.departureMinute = minute;
      } else {
        const { hour, minute } = await this.timePicker("예약 시간 선택 - 오는 날", "reservation", {
          hour: this.arrivalHour,
          minute: this.arrivalMinute,
          airportCd: null,
        });
        this.arrivalHour = hour;
        this.arrivalMinute = minute;
      }
    },
    saveScheduleData() {
      if (this.isRoundTrip) {
        this.setScheduleInfo({
          one_way_date: this.getDateTime(this.selectedDate[0], "YYYY-MM-DD"),
          one_way_time: `${this.departureHour}:${this.departureMinute}`,
          round_trip_date: this.getDateTime(this.selectedDate[1], "YYYY-MM-DD"),
          round_trip_time: `${this.arrivalHour}:${this.arrivalMinute}`,
        });
      } else {
        this.setScheduleInfo({
          one_way_date: this.getDateTime(this.selectedDate, "YYYY-MM-DD"),
          one_way_time: `${this.departureHour}:${this.departureMinute}`,
        });
      }
    },
    async goToBoardingInfo() {
      this.saveScheduleData();

      const { one_way_date, one_way_time, round_trip_date, round_trip_time } =
        this.getScheduleInfo();
      if(this.isFetching) {
        return;
      }
      if(one_way_date === round_trip_date) {
        const oneWayDateTime = dayjs(`${one_way_date} ${one_way_time}`);
        const roundTripDateTime = dayjs(`${round_trip_date} ${round_trip_time}`);
        const diffMinutes = roundTripDateTime.diff(oneWayDateTime, "minute");

        if(diffMinutes / 60 <= 2) {
          alert("최소 2시간 이상의 간격으로 예약을 시도해 주세요.");
          return;
        }
      }
      try {
        this.isFetching = true;
        await nearmeApi.checkOverlap({
          oneWayDate: one_way_date,
          oneWayTime: one_way_time,
          roundTripDate: round_trip_date,
          roundTripTime: round_trip_time,
        });

        this.$router.push({
          name: "BoardingInfo",
          query: {
            service_type: this.serviceType,
          },
        });
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>
<style scoped>
</style>

