<template>
  <li :class="['bl_history_item', statusClass]" @click="click">
    <div class="bl_history_obj">
      <div class="bl_obj_header">
        <div class="bl_header_ttl">{{ type }}</div>
        <div class="bl_header_label">{{ status }}</div>
      </div>
      <div class="bl_obj_body">
        <div class="bl_body_ttl">{{ oneWayAt }}</div>
        <ul class="el_reset bl_body_list">
          <li class="bl_body_item">
            <div class="bl_body_txt">{{ departure }}</div>
          </li>
          <li class="bl_body_item">
            <div class="bl_body_txt">{{ arrive }}</div>
          </li>
        </ul>
      </div>
      <div v-if="isRoundTrip" class="bl_obj_body">
        <div class="bl_body_ttl">{{ roundTripAt }}</div>
        <ul class="el_reset bl_body_list">
          <li class="bl_body_item">
            <div class="bl_body_txt">{{ arrive }}</div>
          </li>
          <li class="bl_body_item">
            <div class="bl_body_txt">{{ departure }}</div>
          </li>
        </ul>
      </div>
      <div :class="['bl_obj_footer', paymentFailClass]">
        <div class="bl_footer_label">{{ paymentStatus }}</div>
        <div class="bl_footer_txt">{{ amount | comma }}원</div>
      </div>
    </div>
  </li>
</template>
<script>
import { comma } from "@/util/string";
import dateMixin from "@/mixins/dateMixin";
import dayjs from "dayjs";
export default {
  name: "UsageHistoryListItem",
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  mixins: [dateMixin],
  data() {
    return {
      dayList: ["일", "월", "화", "수", "목", "금", "토"],
    };
  },
  computed: {
    type() {
      return this.reservation.serviceName === "NearMe Airport" ||
        this.reservation.serviceName === "NearMe Limo"
        ? "공항 픽업 예약"
        : "골프장 픽업 예약";
    },
    status() {
      switch (this.reservation.reservationStateCd) {
        case "14002":
          return "예약 진행 중";
        case "14003":
        case "14004":
        case "14005":
          return "예약 완료";
        case "14006":
          return "예약 취소";
        case "14007":
          return "탑승 완료";
        default:
          return "";
      }
    },
    isRoundTrip() {
      return this.reservation.roundTripTime !== null  ? true : false;
    },
    oneWayAt() {
      const day = dayjs(
        this.convertDate(this.reservation.oneWayTime, "YYYY-MM-DD")
      ).get("day");
      return this.convertDateTime(
        this.reservation.oneWayTime,
        `YYYY.MM.DD(${this.dayList[day]}) HH:mm`
      );
    },
    roundTripAt() {
      const day = dayjs(
        this.convertDate(this.reservation.roundTripTime, "YYYY-MM-DD")
      ).get("day");

      return this.convertDateTime(
        this.reservation.roundTripTime,
        `YYYY.MM.DD(${this.dayList[day]}) HH:mm`
      );
    },
    departure() {
      return this.reservation.departureName;
    },
    arrive() {
      return this.reservation.arriveName;
    },
    paymentStatus() {
      return this.reservation.reservationCancelCd === "12701"
        ? "결제 실패"
        : "결제 금액";
    },
    amount() {
      return this.reservation.amount;
    },
    statusClass() {
      switch (this.reservation.reservationStateCd) {
        case "14002":
          return "is_moIng";
        case "14003":
        case "14004":
        case "14005":
          return "";
        case "14006":
          return "is_moCancel";
        case "14007":
          return "is_moBoarding";
        default:
          return "";
      }
    },
    paymentFailClass() {
      return this.reservation.reservationCancelCd === "12701"
        ? "is_moFail"
        : "";
    },
  },
  methods: {
    click() {
      this.$emit("click", this.reservation.reservationId);
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>