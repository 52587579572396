const state = () => ({
  isLoading: false,
  message: "",
});

const mutations = {
  setIsLoading(state, status) {
    state.isLoading = status;
  },
  setLoadingMessage(state, message) {
    state.message = message;
  },
};

const getters = {
  isLoading: ({ isLoading }) => isLoading,
  message: ({ message }) => message,
};

export default {
  namespace: true,
  state,
  mutations,
  getters,
};
