var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ly_page"},[_c('div',{staticClass:"ly_page_inner"},[_c('header',{staticClass:"ly_header"},[_c('div',{staticClass:"bl_tabUnit_wrapper"},[_c('ul',{staticClass:"bl_tabUnit"},[_c('li',{staticClass:"bl_tab",on:{"click":function($event){_vm.logEvent(
                _vm.isLayer
                  ? 'more_membership_join_coupon_menu_register'
                  : 'coupon_menu_register',
                _vm.$router.replace({ name: 'Coupon', query: { isLayer: _vm.isLayer } })
              )}}},[_c('div',{staticClass:"bl_tab_item"},[_vm._v("쿠폰 등록")])]),_vm._m(0)])])]),_c('main',{staticClass:"ly_body"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponList && _vm.couponList.length),expression:"couponList && couponList.length"}],ref:"scrollList",staticClass:"bl_historyUnit_wrapper"},[_c('ul',{staticClass:"bl_historyUnit"},_vm._l((_vm.couponList),function(coupon){return _c('li',{key:coupon.productCouponId,staticClass:"bl_history",on:{"click":function($event){return _vm.onCouponClick(coupon)}}},[_c('div',{staticClass:"bl_history_item",class:{
                is_ac: coupon.status === '미사용',
              }},[_c('div',{staticClass:"bl_history_item_cont"},[_c('div',{staticClass:"bl_history_item_cont_cont"},[_c('div',{staticClass:"bl_history_item_cont_cont_ttl"},[_vm._v(" "+_vm._s(coupon.couponName)+" ")]),_c('div',{staticClass:"bl_history_item_cont_cont_date"},[_vm._v(" "+_vm._s(_vm.getCouponDate(coupon))+" ")])])]),_c('div',{staticClass:"bl_history_item_use",domProps:{"innerHTML":_vm._s(_vm.getCouponStatus(coupon))}})])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.couponList && !_vm.couponList.length),expression:"couponList && !couponList.length"}],staticClass:"coupon-none"},[_vm._v(" 보유하신 쿠폰이 없습니다. ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"bl_tab"},[_c('div',{staticClass:"bl_tab_item is_ac"},[_vm._v("쿠폰 내역")])])}]

export { render, staticRenderFns }