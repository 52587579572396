<template>
  <div class="ly_page">
    <div class="ly_page_inner">
      <header class="ly_header">
        <div class="bl_tabUnit_wrapper">
          <ul class="bl_tabUnit">
            <li
              class="bl_tab"
              @click="$router.replace({ name: 'NmCoupon', query: $route.query })"
            >
              <div class="bl_tab_item">쿠폰 등록</div>
            </li>
            <li class="bl_tab">
              <div class="bl_tab_item is_ac">쿠폰 내역</div>
            </li>
          </ul>
        </div>
      </header>
      <main class="ly_body">
        <div
          v-show="couponList && couponList.length"
          class="bl_historyUnit_wrapper"
          ref="scrollList"
        >
          <ul class="bl_historyUnit">
            <li
              class="bl_history"
              v-for="coupon in couponList"
              :key="coupon.productCouponId"
              @click="onCouponClick(coupon)"
            >
              <!-- 활성(C): is_ac -->
              <div
                class="bl_history_item"
                :class="{
                  is_ac: coupon.status === '미사용' && coupon.errorMsg === null,
                }"
              >
                <div class="bl_history_item_cont">
                  <div class="bl_history_item_cont_cont">
                    <div class="bl_history_item_cont_cont_ttl">
                      {{ coupon.couponName }}
                    </div>
                    <div class="bl_history_item_cont_cont_date">
                      {{ getCouponDate(coupon) }}
                    </div>
                  </div>
                </div>
                <div
                  class="bl_history_item_use"
                  v-html="getCouponStatus(coupon)"
                ></div>
              </div>
            </li>
          </ul>
        </div>
        <div v-show="couponList && !couponList.length" class="coupon-none">
          보유하신 쿠폰이 없습니다.
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { userApi } from "@/api";
import { setDataTopToBottomWebLayer } from "@/util/webview";
import dayjs from "dayjs";
import scrollMixin from "@/mixins/scrollMixin";
import dateMixin from "@/mixins/dateMixin";
export default {
  name: "NmCouponList",
  data() {
    return {
      partnerService: null,
      serviceType: null,
      couponList: null,
      pageIndex: 1,
      pageSize: 5,
      totalCount: 0,
    };
  },
  mixins: [scrollMixin, dateMixin],
  async mounted() {
    await this.setData();
  },
  methods: {
    async setData() {
      const partnerService = this.getPartnerService();
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, total },
        } = await userApi.getCouponListPartner({
          page: this.pageIndex,
          size: this.pageSize,
          partnerService: this.$route.query?.partner_service
            ? [partnerService]
            : ["NEARME_AIRPORT", "NEARME_LIMO", "NEARME_GOLF"],
          ...this.$route.query,
        });
        this.couponList = this.couponList || [];
        this.couponList = [...this.couponList, ...content.couponList];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    getCouponStatus(coupon) {
      if(coupon.status === "미사용" && coupon.errorMsg !== null) {
        return `사용<br/>불가`;
      }
      switch (coupon.status) {
        case "미사용":
          return "미사용";
        case "사용완료":
          return `사용<br/>완료`;
        case "기간만료":
          return `기간<br/>만료`;
        default:
          return "";
      }
    },
    getCouponDate(coupon) {
      if (coupon.usedDt) {
        return `${this.convertDate(coupon.usedDt)} 사용`;
      } else if (coupon.couponEndDt < dayjs().unix()) {
        return `${this.convertDate(coupon.couponEndDt)} 만료`;
      }
      return `${this.convertDate(coupon.couponEndDt)} 까지`;
    },
    onCouponClick(coupon) {
      if (coupon.status === "미사용" && coupon.errorMsg !== null) {
        return;
      }
      setDataTopToBottomWebLayer({
        ...coupon,
        from: "list",
      });
    },
    getPartnerService() {
      if (this.$route.query?.partner_service === "share") {
        return "NEARME_AIRPORT";
      } else if (this.$route.query?.partner_service === "limo") {
        return "NEARME_LIMO";
      } else {
        return "NEARME_GOLF";
      }
    },
  },
  computed: {
    isLayer() {
      return this.$route.query?.isLayer;
    },
  },
};
</script>