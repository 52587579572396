import Vue from "vue";
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";
import EventList from "@/views/event/EventList.vue";
import EventView from "@/views/event/EventView.vue";
import NoticeList from "@/views/notice/NoticeList.vue";
import NoticeView from "@/views/notice/NoticeView.vue";
import FaqList from "@/views/faq/FaqList.vue";
import FaqView from "@/views/faq/FaqView.vue";
import RestaurantList from "@/views/board/RestaurantList.vue";
import Privacy from "@/views/policy/Privacy";
import Service from "@/views/policy/Service";
import GpsService from "@/views/policy/GpsService";
import Marketing from "@/views/policy/Marketing";
import BusinessInformation from "@/views/policy/BusinessInformation.vue";
import CommunityList from "@/views/community/CommunityList";
import CommunityView from "@/views/community/CommunityView";
import CommunityRegistration from "@/views/community/CommunityRegistration";
import CommunityProfile from "@/views/community/CommunityProfile";
import CommunitySearch from "@/views/community/CommunitySearch";
import CommunitySearchKeyword from "@/views/community/CommunitySearchKeyword";
import CommunitySearchProfile from "@/views/community/CommunitySearchProfile";
import CommunityRegistrationSearchLocation from "@/views/community/CommunityRegistrationSearchLocation";
import CommunityEdit from "@/views/community/CommunityEdit";
import CommunityMyPost from "@/views/community/CommunityMyPost";
import CommunityMyLikePost from "@/views/community/CommunityMyLikePost";
import CommunityDangolProfile from "@/views/community/CommunityDangolProfile";
import CommunityListBySearch from "@/views/community/CommunityListBySearch";
import Invite from "@/views/invite/Invite";
import InviteStatus from "@/views/invite/InviteStatus";
import Coupon from "@/views/coupon/Coupon";
import CommunityPostImage from "@/views/community/components/CommunityPostImage";
import JeomsinPrivacy from "@/views/policy/JeomsinPrivacy.vue";
import JeomsinThirdParties from "@/views/policy/JeomsinThirdParties.vue";
import RoundResolution from "@/views/promotion/RoundResolution.vue";
import JeomsinForm from "@/views/promotion/JeomsinForm.vue";
import JeomsinInfo from "@/views/promotion/JeomsinInfo.vue";
import JeomsinResult from "@/views/promotion/JeomsinResult.vue";
import VoicecaddieMembership from "@/views/membership/VoicecaddieMembership";
import VoicecaddieMembershipPay from "@/views/membership/VoicecaddieMembershipPay";
import Success from "@/views/membership/Success";
import Fail from "@/views/membership/Fail";
import JoinMembership from "@/views/membership/JoinMembership";
import NiceService from "@/views/policy/nice/NiceService.vue";
import NicePrivacy from "@/views/policy/nice/NicePrivacy.vue";
import NiceThirdParties from "@/views/policy/nice/NiceThirdParties.vue";
import NiceUniqueTag from "@/views/policy/nice/NiceUniqueTag.vue";
import NiceTelAgreement from "@/views/policy/nice/NiceTelAgreement.vue";
import ClubMap from "@/views/club-list/ClubMap";
import ProductList from "@/views/ak-golf/ProductList.vue";
import ProductView from "@/views/ak-golf/ProductView.vue";
import VoicecaddieMembershipInfo from "@/views/membership/VoicecaddieMembershipInfo.vue";
import VoicecaddieMembershipRecord from "@/views/membership/VoicecaddieMembershipRecord.vue";
import VoicecaddieMembershipPayment from "@/views/membership/VoicecaddieMembershipPayment.vue";
import Leisure from "@/views/policy/Leisure.vue";
import Pincrux from "@/views/promotion/Pincrux.vue";
import Membership from "@/views/policy/Membership.vue";
import PromotionList from "@/views/event/PromotionList.vue";
import PromotionView from "@/views/event/PromotionView.vue";
import PromotionResult from "@/views/event/PromotionResult.vue";
import PromotionQuestion from "@/views/event/PromotionQuestion.vue";
import CouponList from "@/views/coupon/CouponList.vue";
import TMapCoupon from "@/views/promotion/TMapCoupon.vue";

import Layout from "@/components/layout/nearme/Layout.vue";
import MainView from "@/views/nearme/MainView.vue";
import AirportReservation from "@/views/nearme/reservation/AirportReservation.vue";
import ClubReservation from "@/views/nearme/reservation/ClubReservation.vue";
import DepartureSearch from "@/views/nearme/search/DepartureSearch";
import ArrivalSearch from "@/views/nearme/search/ArrivalSearch";
import SelectSchedule from "@/views/nearme/reservation/SelectSchedule.vue";
import PlaceOnMap from "@/views/nearme/search/PlaceOnMap";
import BoardingInfo from "@/views/nearme/reservation/BoardingInfo.vue";
import BookerInfo from "@/views/nearme/reservation/BookerInfo.vue";
import EditBookerInfo from "@/views/nearme/reservation/EditBookerInfo.vue";
import UsageHistoryList from "@/views/nearme/history/UsageHistoryList.vue";
import UsageHistoryView from "@/views/nearme/history/UsageHistoryView.vue";
import PaymentView from "@/views/nearme/payment/PaymentView";
import AirportServiceInfo from "@/views/nearme/payment/AirportServiceInfo.vue";
import CancelAndRefund from "@/views/nearme/policy/CancelAndRefund.vue";
import GpsAndPrivacy from "@/views/nearme/policy/GpsAndPrivacy.vue";
import PartnerProduct from "@/views/nearme/policy/PartnerProduct.vue";
import ThirdPartyInfoShare from "@/views/nearme/policy/ThirdPartyInfoShare.vue";
import NmCoupon from "@/views/nearme/coupon/NmCoupon.vue";
import NmCouponList from "@/views/nearme/coupon/NmCouponList.vue";

import JapaneseVehicleUsageHistoryList from '@/views/japan-vehicle/JapaneseVehicleUsageHistoryList.vue';
import JapaneseVehicleUsageDetailView from '@/views/japan-vehicle/JapaneseVehicleUsageDetailView.vue';
import JapaneseVehicleEditBookerInfo from '@/views/japan-vehicle/JapaneseVehicleEditBookerInfo.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/event/promotion",
    component: () => import("@/components/layout/SurveyPromotionLayout.vue"),
    children: [
      {
        path: "",
        name: "PromotionList",
        component: PromotionList,
        meta: { title: "프로모션", bodyClass: "id_list" },
      },
      {
        path: ":promotionId",
        name: "PromotionView",
        component: PromotionView,
        meta: { title: "프로모션", bodyClass: "id_view" },
        children: [
          {
            path: "answer/:joinNumber/:questionNo",
            name: "PromotionResult",
            component: PromotionResult,
            meta: { title: "프로모션", bodyClass: "id_problem" },
          },
          {
            path: ":questionNo",
            name: "PromotionQuestion",
            component: PromotionQuestion,
            meta: { title: "프로모션", bodyClass: "id_problem" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: () => import("@/components/layout/DefaultLayout.vue"),
    children: [
      {
        path: "/event",
        name: "EventList",
        component: EventList,
        meta: { title: "이벤트", bodyClass: "type-event" },
      },
      {
        path: "/event/:eventId",
        name: "EventView",
        component: EventView,
        meta: { title: "이벤트", bodyClass: "id-eventView" },
      },
      {
        path: "/notice",
        name: "NoticeList",
        component: NoticeList,
        meta: { title: "공지사항", bodyClass: "id-notice" },
      },
      {
        path: "/notice/:noticeId",
        name: "NoticeView",
        component: NoticeView,
        props: true,
        meta: { title: "공지사항", bodyClass: "id-noticeView" },
      },
      {
        path: "/faq",
        name: "FaqList",
        component: FaqList,
        meta: { title: "고객센터", bodyClass: "id-faq type-faq" },
      },
      {
        path: "/faq/:faqId",
        name: "FaqView",
        component: FaqView,
        meta: { title: "고객센터", bodyClass: "id-faqView" },
      },
      {
        path: "/restaurant",
        name: "RestaurantList",
        component: RestaurantList,
        meta: { title: "맛집", bodyClass: "id-trendRestaurant" },
      },
      {
        path: "/policy/privacy",
        name: "Privacy",
        component: Privacy,
        meta: { title: "개인정보 처리방침", bodyClass: "id-privacy" },
      },
      {
        path: "/policy/service",
        name: "Service",
        component: Service,
        meta: { title: "APL골프 이용약관", bodyClass: "id-privacy" },
      },
      {
        path: "/policy/gps-service",
        name: "GpsService",
        component: GpsService,
        meta: { title: "위치기반 서비스 이용약관", bodyClass: "id-privacy" },
      },
      {
        path: "/policy/marketing",
        name: "Marketing",
        component: Marketing,
        meta: { title: "마케팅 정보 활용 동의", bodyClass: "id-privacy" },
      },
      {
        path: "/policy/business-information",
        name: "BusinessInformation",
        component: BusinessInformation,
        meta: { title: "APL골프 사업자 정보", bodyClass: "id-privacy" },
      },
      {
        path: "/policy/jeomsin-privacy",
        name: "JeomsinPrivacy",
        component: JeomsinPrivacy,
        meta: {
          title: "개인정보 추가 수집에 대한 동의",
          bodyClass: "id-privacy",
        },
      },
      {
        path: "/policy/jeomsin-third-parties",
        name: "JeomsinThirdParties",
        component: JeomsinThirdParties,
        meta: { title: "개인정보 제3자 제공 동의", bodyClass: "id-privacy" },
      },
      {
        path: "/policy/nice/service",
        name: "NiceService",
        component: NiceService,
        meta: {
          title: "휴대폰 본인확인 서비스 이용약관",
          bodyClass: "",
        },
      },
      {
        path: "/policy/nice/privacy",
        name: "NicePrivacy",
        component: NicePrivacy,
        meta: {
          title: "개인정보 수집/이용 동의",
          bodyClass: "",
        },
      },
      {
        path: "/policy/nice/third-parties",
        name: "NiceThirdParties",
        component: NiceThirdParties,
        meta: {
          title: "개인정보 제3자 제공 동의(알뜰폰)",
          bodyClass: "",
        },
      },
      {
        path: "/policy/nice/unique-tag",
        name: "NiceUniqueTag",
        component: NiceUniqueTag,
        meta: {
          title: "고유식별정보 처리 동의",
          bodyClass: "",
        },
      },
      {
        path: "/policy/nice/tel-agreement",
        name: "NiceTelAgreement",
        component: NiceTelAgreement,
        meta: {
          title: "통신사 이용약관 동의",
          bodyClass: "",
        },
      },
      {
        path: "/policy/leisure",
        name: "Leisure",
        component: Leisure,
        meta: {
          title: "레저활동중 상해 약관",
          bodyClass: "",
        },
      },
      {
        path: "/policy/membership",
        name: "Membership",
        component: Membership,
        meta: {
          title: "멤버십 이용약관",
          bodyClass: "",
        },
      },
      {
        path: "/club-map",
        name: "ClubMap",
        component: ClubMap,
        meta: {
          title: "클럽맵",
          bodyClass: "",
        },
      },
    ],
  },
  {
    path: "/community",
    component: () => import("@/components/layout/CommunityLayout.vue"),
    children: [
      {
        path: "",
        name: "CommunityList",
        component: CommunityList,
        meta: { title: "게시판", bodyClass: "id-communityList" },
      },
      {
        path: "post/:boardId",
        name: "CommunityView",
        component: CommunityView,
        props: true,
        meta: { title: "게시글", bodyClass: "id-communityView" },
      },
      {
        path: "registration",
        name: "CommunityRegistration",
        component: CommunityRegistration,
        meta: { title: "게시글 작성", bodyClass: "id-communityWrite" },
      },
      {
        path: "edit/:boardId",
        name: "CommunityEdit",
        component: CommunityEdit,
        props: true,
        meta: { title: "게시글 수정", bodyClass: "id-communityWrite" },
      },
      {
        path: "search-location",
        name: "CommunityRegistrationSearchLocation",
        component: CommunityRegistrationSearchLocation,
        meta: { title: "지역", bodyClass: "id-communitySearch" },
      },
      {
        path: "profile/:uid",
        name: "CommunityProfile",
        component: CommunityProfile,
        props: true,
        meta: { title: "프로필", bodyClass: "id-communityProfile" },
      },
      {
        path: "my-post",
        name: "CommunityMyPost",
        component: CommunityMyPost,
        meta: { title: "내 게시물", bodyClass: "id-communityList" },
      },
      {
        path: "like-post",
        name: "CommunityMyLikePost",
        component: CommunityMyLikePost,
        meta: { title: "좋아요한 게시물", bodyClass: "id-communityList" },
      },
      {
        path: "dangol-profile",
        name: "CommunityDangolProfile",
        component: CommunityDangolProfile,
        meta: { title: "MY 단골", bodyClass: "id-communitySearchProfile" },
      },
      {
        path: "search",
        name: "CommunitySearch",
        component: CommunitySearch,
        meta: { title: "검색", bodyClass: "id-communitySearch" },
      },
      {
        path: "search/profile",
        name: "CommunitySearchProfile",
        component: CommunitySearchProfile,
        meta: { title: "검색", bodyClass: "id-communitySearchProfile" },
      },
      {
        path: "search/keyword",
        name: "CommunitySearchKeyword",
        component: CommunitySearchKeyword,
        meta: { title: "검색", bodyClass: "id-communitySearch" },
      },
      {
        path: "keyword-post",
        name: "CommunityListBySearch",
        component: CommunityListBySearch,
        meta: { title: "검색", bodyClass: "id-communityList" },
      },
      {
        path: "image/:boardId",
        name: "CommunityPostImage",
        component: CommunityPostImage,
        meta: { title: "상세보기", bodyClass: "id-communityList" },
      },
    ],
  },
  {
    path: "/invite",
    component: () => import("@/components/layout/InviteLayout.vue"),
    children: [
      {
        path: "",
        name: "Invite",
        component: Invite,
        meta: { title: "친구초대", bodyClass: "id_invite is_tyDefault" },
      },
      {
        path: "/status",
        name: "InviteStatus",
        component: InviteStatus,
        meta: { title: "친구초대", bodyClass: "id_status is_tyDefault" },
      },
    ],
  },
  {
    path: "/coupon",
    component: () => import("@/components/layout/InviteLayout.vue"),
    children: [
      {
        path: "",
        name: "Coupon",
        component: Coupon,
        meta: { title: "쿠폰", bodyClass: "id_coupon is_tyDefault" },
      },
      {
        path: "list",
        name: "CouponList",
        component: CouponList,
        meta: { title: "쿠폰", bodyClass: "id_coupon is_tyDefault" },
      },
    ],
  },
  {
    path: "/promotion/jeomsin",
    component: () => import("@/components/layout/JeomsinLayout.vue"),
    children: [
      {
        path: "form",
        name: "JeomsinForm",
        component: JeomsinForm,
        meta: {
          title: "사주 정보 입력",
          bodyClass: "id_input ty_default",
        },
      },
      {
        path: "info",
        name: "JeomsinInfo",
        component: JeomsinInfo,
        meta: {
          title: "신년운세",
          bodyClass: " id_information ty_default",
        },
      },
      {
        path: ":title",
        name: "JeomsinResult",
        component: JeomsinResult,
        meta: {
          title: "신년운세",
          bodyClass: "id_result ty_default",
        },
      },
    ],
  },
  {
    path: "/promotion/pincrux",
    component: () => import("@/components/layout/PincruxLayout.vue"),
    children: [
      {
        path: "",
        name: "Pincrux",
        component: Pincrux,
        meta: {
          title: "VOICE CADDIE 이벤트",
          bodyClass: "id_event",
        },
      },
    ],
  },
  {
    path: "/promotion/tmap-coupon",
    component: () => import("@/components/layout/TMapLayout.vue"),
    children: [
      {
        path: "",
        name: "TMapCoupon",
        component: TMapCoupon,
        meta: {
          title: "이벤트",
          bodyClass: "id_event ty_default",
        },
      },
    ],
  },
  {
    path: "/promotion",
    component: () => import("@/components/layout/PromotionLayout.vue"),
    children: [
      {
        path: "round-resolution",
        name: "RoundResolution",
        component: RoundResolution,
        meta: {
          title: "라운드 목표",
          bodyClass: "id_event ty_default is_moComplete",
        },
      },
    ],
  },
  {
    path: "/ak-golf",
    component: () => import("@/components/layout/AkGolfLayout.vue"),
    children: [
      {
        path: "",
        name: "AKGolfProductList",
        component: ProductList,
        meta: {
          title: "인기 상품",
          bodyClass: "id_list ty_default",
        },
      },
      {
        path: ":categoryCd/:productId",
        name: "AKGolfProductView",
        component: ProductView,
        meta: {
          title: "인기 상품",
          bodyClass: "id_detail ty_default",
        },
      },
    ],
  },
  {
    path: "/voicecaddie-membership",
    component: () => import("@/components/layout/MembershipLayout.vue"),
    children: [
      {
        path: "",
        name: "VoicecaddieMembership",
        component: VoicecaddieMembership,
        meta: {
          title: "보이스캐디 APL 멤버십",
          bodyClass: "",
        },
      },
      {
        path: "pay",
        name: "VoicecaddieMembershipPay",
        component: VoicecaddieMembershipPay,
        meta: {
          title: "결제",
          bodyClass: "id_pay ty_default st_default",
        },
      },
      {
        path: "join",
        name: "JoinMembership",
        component: JoinMembership,
        meta: {
          title: "멤버십 이용약관",
          bodyClass: "",
        },
      },
      {
        path: "join-success",
        name: "Success",
        component: Success,
        meta: {
          title: "멤버십 가입 성공",
          bodyClass: "",
        },
      },
      {
        path: "join-fail",
        name: "Fail",
        component: Fail,
        meta: {
          title: "멤버십 가입 실패",
          bodyClass: "",
        },
      },
      {
        path: "info",
        name: "VoicecaddieMembershipInfo",
        component: VoicecaddieMembershipInfo,
        meta: {
          title: "보이스캐디 APL 멤버십",
          bodyClass: "id_membership ty_default st_default",
        },
      },
      {
        path: "payment",
        name: "VoicecaddieMembershipPayment",
        component: VoicecaddieMembershipPayment,
        meta: {
          title: "보이스캐디 APL 멤버십",
          bodyClass: "id_payment ty_default st_default",
        },
      },
      {
        path: "record",
        name: "VoicecaddieMembershipRecord",
        component: VoicecaddieMembershipRecord,
        meta: {
          title: "홀인원 기록",
          bodyClass: "id_record ty_default st_default",
        },
      },
    ],
  },
  {
    path: "/nearme",
    component: Layout,
    children: [
      {
        path: "/",
        name: "MainView",
        component: MainView,
        meta: { title: "일본 교통 예약", bodyClass: "id_index ty_default st_default" },
      },
      {
        path: "user/history",
        component: Layout,
        children: [
          {
            path: "",
            name: "UsageHistoryList",
            component: UsageHistoryList,
            meta: { title: "이용 내역", bodyClass: "id_history ty_default st_default" },
          },
          {
            path: ":reservation_cd",
            name: "UsageHistoryView",
            component: UsageHistoryView,
            meta: { title: "예약 상세 보기", bodyClass: "id_detail ty_default st_default" },
          }
        ]
      },
      {
        path: "reservation",
        component: Layout,
        children: [
          {
            path: "airport",
            name: "AirportReservation",
            component: AirportReservation,
            meta: { title: "공항 픽업 예약", bodyClass: "id_reservation ty_default st_default" },
          },
          {
            path: "club",
            name: "ClubReservation",
            component: ClubReservation,
            meta: { title: "골프장 픽업 예약", bodyClass: "id_reservation ty_default st_default" },
          },
          {
            path: "schedule",
            name: "SelectSchedule",
            component: SelectSchedule,
            meta: { bodyClass: "id_select ty_default st_default" },
          },
          {
            path: "boarding-info",
            name: "BoardingInfo",
            component: BoardingInfo,
            meta: { bodyClass: "id_boarding ty_default st_default" },
          },
          {
            path: "booker-info",
            name: "BookerInfo",
            component: BookerInfo,
            meta: { bodyClass: "id_person ty_default st_default" },
          },
          {
            path: "edit/:reservation_cd",
            name: "EditBookerInfo",
            component: EditBookerInfo,
            meta: { title: "예약자 수정", bodyClass: "id_person ty_default st_default" },
          },
        ]
      },
      {
        path: "search",
        component: Layout,
        children: [
          {
            path: "departure",
            name: "DepartureSearch",
            component: DepartureSearch,
            meta: { bodyClass: "ty_default st_default" },
          },
          {
            path: "arrival",
            name: "ArrivalSearch",
            component: ArrivalSearch,
            meta: { bodyClass: "ty_default st_default" },
          },
          {
            path: "map",
            name: "PlaceOnMap",
            component: PlaceOnMap,
            meta: { bodyClass: "id_location ty_default st_default" }
          },
        ]
      },
      {
        path: "payment",
        component: Layout,
        children: [
          {
            path: "",
            name: "PaymentView",
            component: PaymentView,
            meta: { title: "결제하기", bodyClass: "id_pay ty_default st_default" },
          },
          {
            path: "airport-service",
            name: "AirportServiceInfo",
            component: AirportServiceInfo,
            meta: { title: "서비스 유형", bodyClass: "id_service ty_default st_default" },
          }
        ],
      },

      {
        path: "policy/cancelandrefund",
        name: "CancelAndRefund",
        component: CancelAndRefund,
        meta: {
          title: "취소 및 환불 규정",
          bodyClass: "",
        },
      },
      {
        path: "policy/gpsandprivacy",
        name: "GpsAndPrivacy",
        component: GpsAndPrivacy,
        meta: {
          title: "개인정보 수집 및 이용 방침",
          bodyClass: "",
        },
      },
      {
        path: "policy/partnerproduct",
        name: "PartnerProduct",
        component: PartnerProduct,
        meta: {
          title: "파트너 상품 구매자 약관",
          bodyClass: "",
        },
      },
      {
        path: "policy/thirdpartyinfoshare",
        name: "ThirdPartyInfoShare",
        component: ThirdPartyInfoShare,
        meta: {
          title: "개인정보 제3자 제공 동의",
          bodyClass: "",
        },
      },
      {
        path: "coupon",
        component: () => import("@/components/layout/InviteLayout.vue"),
        children: [
          {
            path: "",
            name: "NmCoupon",
            component: NmCoupon,
            meta: { title: "쿠폰", bodyClass: "id_coupon is_tyDefault" },
          },
          {
            path: "list",
            name: "NmCouponList",
            component: NmCouponList,
            meta: { title: "쿠폰", bodyClass: "id_coupon is_tyDefault" },
          },
        ],
      },
    ],
  },
  {
    path: "/japan-vehicle",
    component: Layout,
    children: [
      {
        path: "usage-history",
        name: "JapaneseVehicleUsageHistoryList",
        meta: {title: "이용 내역", bodyClass: "id_history ty_default st_default"},
        component: JapaneseVehicleUsageHistoryList,
      },
      {
        path: "usage-history/:reservationId",
        name: "JapaneseVehicleUsageDetailView",
        component: JapaneseVehicleUsageDetailView,
        meta: { title: "예약 상세 보기", bodyClass: "id_detail ty_default st_default" },

      },
      {
        path: "edit/:reservationId",
        name: "JapaneseVehicleEditBookerInfo",
        component: JapaneseVehicleEditBookerInfo,
        meta: { title: "예약자 수정", bodyClass: "id_person ty_default st_default" },
      },
    ]
  }
];

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  vueBodyClass.guard(to, next);
});

router.afterEach(async (to) => {
  const title = to.meta.title || "APL GOLF";
  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
