<template>
  <div class="lyPage">
    <main class="lyBody">
      <section class="stWrite">
        <div class="class">
          <div
            class="cmSelect"
            @click="
              logEvent('community_posting_category_click', selectBoardCategory)
            "
          >
            <input
              type="text"
              class="cmSelect-input"
              placeholder="분류"
              :value="boardCategory"
              readonly
            />
          </div>
        </div>
        <div class="location">
          <div class="cmInput" :class="{ 'is-activeClear': location }">
            <input
              @click="logEvent('community_posting_place_click', selectLocation)"
              :value="location"
              type="text"
              class="cmInput-input"
              placeholder="장소를 추가하세요."
              readonly
            />
            <i class="cmSearch-inputClear" @click="placeInfo = null">
              지우기
            </i>
          </div>
        </div>
        <div class="file">
          <div class="cmFile">
            <div class="cmFile-input">
              <div class="cmFile-inputObject">
                <input
                  type="file"
                  ref="files"
                  @change="onChange"
                  @click="logEvent('community_posting_file_click')"
                  accept="image/*"
                  multiple
                  class="cmFile-inputInput"
                  :disabled="isImageFormatting"
                />
              </div>
            </div>
            <p class="cmFile-description">
              ※파일 크기는 최대 {{ maxFileSize }}MB입니다.
            </p>
            <div class="cmFile-attachContainer">
              <div class="indicator" v-if="isImageFormatting">
                <indicator-dot-falling />
              </div>
              <div class="cmFile-attach" ref="imageContainer">
                <ul class="cmFile-attachList">
                  <li
                    v-for="(image, i) in imageList"
                    :key="i"
                    class="cmFile-attachItem"
                  >
                    <div class="cmFile-attachObject">
                      <div
                        class="cmFile-attachImage"
                        :class="{ fileUnUploaded: !image.fileId }"
                        :style="{ 'background-image': `url('${image.url}')` }"
                      >
                        사진
                      </div>
                      <i
                        class="cmFile-attachDelete"
                        @click="
                          logEvent(
                            'community_posting_file_delete_click',
                            () => {
                              removeImage(i);
                            }
                          )
                        "
                      >
                        삭제
                      </i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="cmTextarea">
            <div class="cmTextarea-input">
              <textarea
                class="cmTextarea-inputTextarea"
                placeholder="내용을 입력하세요."
                @input="onContentInput"
                :value="content"
                @click="logEvent('community_posting_input_click')"
              />
            </div>
            <!-- '0'이 아닐때 상태 : is-stateNonzero -->
            <div class="cmTextarea-limit">
              <strong class="cmTextarea-limitStrong">{{
                content.length | comma
              }}</strong
              >/1000
            </div>
          </div>
        </div>
        <div class="hash">
          <div class="cmHash">
            <div class="cmHash-add" ref="hashContainer">
              <ul class="cmHash-addList">
                <li
                  class="cmHash-addItem"
                  v-for="(hashTag, i) in hashTags"
                  :key="hashTag"
                >
                  <div class="cmHash-addObject">
                    <div class="cmHash-addText">{{ hashTag }}</div>
                    <i
                      class="cmHash-addDelete"
                      @click="
                        logEvent(
                          'community_posting_hashtag_delete_click',
                          () => {
                            removeHashTag(i);
                          }
                        )
                      "
                    >
                      삭제
                    </i>
                  </div>
                </li>
              </ul>
            </div>
            <div class="cmHash-input">
              <!-- 추가 활성 : is-activeAdd -->
              <!-- 추가 온 상태 : is-stateAddOn -->
              <div
                class="cmInput is-activeAdd"
                :class="{ 'is-stateAddOn': hashTagInput.length }"
              >
                <input
                  type="text"
                  @keydown="addHashTag"
                  class="cmInput-input"
                  placeholder="태그를 추가하세요."
                  :value="hashTagInput"
                  @input="onInputHashTag"
                  maxlength="10"
                  @click="logEvent('community_posting_hashtag_input_click')"
                />
                <i
                  class="cmInput-add"
                  @click="
                    logEvent('community_posting_hashtag_add_click', () => {
                      addHashTag();
                    })
                  "
                >
                  추가
                </i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import imageCompression from "browser-image-compression";
import { $openSelect } from "@/util/modal";
import {
  comma,
  getByteLength,
  URL_EXPRESSION,
  isJson,
  HTTP_EXPRESSION,
} from "@/util/string";
import {
  closeWebviewLayer,
  newWebviewLayerV2,
  passRequirements,
} from "@/util/webview";
import { boardApi, pointApi } from "@/api";
import IndicatorDotFalling from "@/components/IndicatorDotFalling";

const CONTENT_MAX_LENGTH = 1000;
const KO_ENG_NUM_REG = /^[가-힣|a-z|A-Z|0-9|]+$/;

export default {
  name: "CommunityRegistration",
  components: { IndicatorDotFalling },
  created() {
    window.getCommunityBoardPlace = (placeInfo) => {
      this.placeInfo = JSON.parse(placeInfo);
    };
    window.submitCommunityBoard = () => {
      this.registrationBoard();
    };
  },
  mounted() {
    const data = sessionStorage.getItem("communityFormValues");
    if (data && isJson(data)) {
      const { boardCd, content, imageList, hashTags, placeInfo } =
        JSON.parse(data);
      this.boardCd = boardCd;
      this.imageList = imageList;
      this.hashTags = hashTags;
      this.placeInfo = placeInfo;
      this.$nextTick(() => {
        this.content = content;
      });
    }
  },
  data() {
    return {
      boardCd: 10001,
      boardCategories: [
        {
          text: "자유게시판",
          value: 10001,
        },
      ],
      content: "",
      maxFileCnt: 50,
      maxFileSize: 10,
      imageList: [],
      hashTagInput: "",
      hashTags: [],
      placeInfo: null,
      isFetching: false,
      isImageUploading: false,
      isImageFormatting: false,
    };
  },
  methods: {
    async registrationBoard() {
      if (!this.passRequirement) {
        return;
      }
      if (this.isFetching) {
        return;
      }
      this.isFetching = true;
      try {
        const fileIds = this.imageList
          .map(({ fileId }) => fileId)
          .filter((fileId) => fileId);
        const httpRegex = new RegExp(HTTP_EXPRESSION);
        const urlRegex = new RegExp(URL_EXPRESSION);
        const contentLinks = this.content
          .match(urlRegex)
          ?.map((url) => (httpRegex.test(url) ? url : `https://${url}`));
        const result = await boardApi.createBoard({
          addrId: this.placeInfo?.addrId,
          boardCd: this.boardCd,
          content: this.content,
          ccode: this.placeInfo?.ccode,
          fileIds,
          tags: this.hashTags.join(" ") || undefined,
          contentLinks,
        });
        if (result) {
          if (result?.data?.firstRegisterYn === "Y") {
            const {
              data: { point },
            } = await pointApi.getEventTypeInfo("SNS_BOARD");
            alert(`첫 게시물 축하 기념
${comma(point)} 포인트 지급 완료!!

소중한 첫 게시물 남겨 주셔서 감사합니다 .
앞으로도 자주 이용해 주세요~ 😄`);
          }
          await closeWebviewLayer();
        }
      } finally {
        this.isFetching = false;
      }
    },
    async selectBoardCategory() {
      const boardCd = await $openSelect({
        options: this.boardCategories,
      });
      if (!boardCd || this.boardCd === boardCd) {
        return;
      }
      this.boardCd = boardCd;
    },
    async uploadImage() {
      if (this.isImageUploading) {
        return;
      }
      const image = this.imageList.find(({ fileId }) => !fileId);
      if (!image) {
        return;
      }
      this.isImageUploading = true;
      try {
        const {
          data: { fileId },
        } = await boardApi.uploadBoardImage(image.file);
        this.imageList = this.imageList.map((_image) => {
          return _image !== image ? _image : { ..._image, fileId };
        });
      } catch (e) {
        this.imageList = this.imageList.filter((image) => image.fileId);
      } finally {
        this.isImageUploading = false;
        await this.uploadImage();
      }
    },
    async resizeImage(file) {
      return await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1440,
      });
    },
    selectLocation() {
      newWebviewLayerV2({ name: "CommunityRegistrationSearchLocation" });
    },
    async onChange() {
      try {
        this.isImageFormatting = true;
        const files = await this.validateFiles(
          [...this.$refs.files.files],
          this.imageList.length
        );
        this.imageList = [...this.imageList, ...files];
        this.$nextTick(() => {
          this.$refs.imageContainer.scrollLeft = 540;
        });
      } finally {
        this.isImageFormatting = false;
      }
      this.uploadImage();
    },
    async validateFiles(files, originFileLength) {
      const acceptTypes = ["jpg", "jpeg", "png", "gif", "bmp"];
      let resizedFiles;
      try {
        if (files.length + originFileLength > this.maxFileCnt) {
          throw new Error(
            `이미지는 최대 ${this.maxFileCnt}장만 첨부할 수 있습니다.`
          );
        }
        resizedFiles = files.map(async (file) => {
          const typeDot = file.name.lastIndexOf(".");
          const fileType = file.name
            .substring(typeDot + 1, file.name.length)
            .toLowerCase();
          const resizedFile = await this.resizeImage(file);
          const fileSize = resizedFile.size;

          if (!acceptTypes.find((type) => type === fileType)) {
            throw new Error(
              `업로드가 제한된 파일 형식입니다\n
              (JPEG, PNG, GIF, BMP)`
            );
          }

          if (fileSize > this.maxFileSize * 1024 * 1024) {
            throw new Error(
              `${this.maxFileSize}MB 미만의 파일만 등록 가능합니다.`
            );
          }
          return {
            file: resizedFile,
            url: URL.createObjectURL(resizedFile),
          };
        });
      } catch (e) {
        alert(e.message);
        return [];
      } finally {
        this.$refs.files.value = "";
      }
      return Promise.all(resizedFiles);
    },
    removeImage(i) {
      const newImageList = [...this.imageList];
      newImageList.splice(i, 1);
      this.imageList = newImageList;
    },
    onContentInput(e) {
      this.content = e.target.value.slice(0, CONTENT_MAX_LENGTH);
      e.target.value = this.content;
    },
    onInputHashTag(e) {
      this.hashTagInput = e.target.value;
    },
    async addHashTag(e) {
      if (e && e.key !== "Enter" && e.key !== " ") {
        return;
      }
      if (!this.hashTagInput) {
        return;
      }
      if (this.hashTags.length >= 10) {
        alert("태그는 최대 10개만 입력할 수 있습니다.");
        return;
      }
      if (!KO_ENG_NUM_REG.test(this.hashTagInput)) {
        alert("한글/영문/숫자만 입력할 수 있습니다.");
        return;
      }
      this.hashTags.push(`#${this.hashTagInput}`);
      this.hashTagInput = "";
      await this.$nextTick();
      this.$refs.hashContainer.scrollLeft =
        this.$refs.hashContainer.scrollWidth;
    },
    removeHashTag(i) {
      this.hashTags.splice(i, 1);
    },
    saveFormValue() {
      sessionStorage.setItem(
        "communityFormValues",
        JSON.stringify({
          boardCd: this.boardCd,
          content: this.content,
          imageList: this.imageList
            .filter(({ fileId }) => fileId)
            .map(({ url, fileId }) => ({ url, fileId })),
          hashTags: this.hashTags,
          placeInfo: this.placeInfo,
        })
      );
    },
  },
  computed: {
    location() {
      return this.placeInfo?.address || this.placeInfo?.clubName;
    },
    boardCategory() {
      return this.boardCategories.find(({ value }) => value === this.boardCd)
        ?.text;
    },
    contentByte() {
      return getByteLength(this.content);
    },
    passRequirement() {
      return !!(
        this.boardCd &&
        (this.content?.trim() ||
          (this.imageList.length &&
            !this.imageList.filter(({ fileId }) => !fileId).length)) &&
        !this.isImageFormatting
      );
    },
  },
  watch: {
    passRequirement: {
      handler(value) {
        passRequirements({
          isPass: value,
        });
      },
      immediate: true,
    },
    hashTagInput(val) {
      val = val.replace(/[^\w\sㄱ-힣·‥ㆍᆢ]|[_]/g, "");
      this.hashTagInput = val.substring(0, 10);
      if (val.slice(-1) === " ") {
        this.hashTagInput = this.hashTagInput.slice(0, -1);
        this.addHashTag();
      }
    },
    boardCd() {
      this.saveFormValue();
    },
    content() {
      this.saveFormValue();
    },
    imageList() {
      this.saveFormValue();
    },
    hashTags() {
      this.saveFormValue();
    },
    placeInfo() {
      this.saveFormValue();
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>

<style scoped>
.cmInput.is-activeClear .cmInput-input {
  padding-right: 46px;
}
.cmInput.is-activeClear .cmSearch-inputClear {
  display: block;
}
.cmFile-attachContainer {
  position: relative;
}
.cmFile-attachImage {
  position: relative;
}
.cmFile-attachImage::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  transition: 0.5s;
}
.fileUnUploaded::after {
  opacity: 0.7;
}
.indicator {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
