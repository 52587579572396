export async function initKakao() {
  return new Promise((resolve) => {
    const jsScript = document.createElement("script");
    jsScript.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.0.0/kakao.min.js";
    jsScript.addEventListener("load", () => {
      window.Kakao.init(process.env.VUE_APP_KAKAO_KEY);
      resolve(true);
    });
    document.body.appendChild(jsScript);
  });
}

export async function initShareKakao(btnId, templateArgs) {
  window.Kakao.Share.createScrapButton({
    container: btnId,
    requestUrl: location.href,
    templateId: Number(process.env.VUE_APP_KAKAO_INVITE_TEMPLATE_ID), // 메시지템플릿 번호
    templateArgs,
    installTalk: true,
  });
}

export async function initJeomsinKakao(btnId, templateArgs) {
  window.Kakao.Share.createScrapButton({
    container: btnId,
    requestUrl: location.href,
    templateId: Number(process.env.VUE_APP_KAKAO_JEOMSIN_TEMPLATE_ID), // 메시지템플릿 번호
    templateArgs,
    installTalk: true,
  });
}
