<template>
  <bottom-modal-layout class="is_moCaution" :close="close">
    <template v-slot:header>
      <div class="bl_ttl">예약 전 주의 사항</div>
    </template>
    <template v-slot:main>
      <div class="bl_desc">
        <ul class="el_reset bl_desc_list">
          <li class="bl_desc_item">
            <div class="bl_desc_obj">
              <div class="bl_obj_dot"></div>
              <div class="bl_obj_txt">
                <strong class="el_reset">
                  예약 확정 여부를 24시간 이내에 카카오톡 알림톡으로 알려드려요.
                </strong>
              </div>
            </div>
          </li>
          <li class="bl_desc_item">
            <div class="bl_desc_obj">
              <div class="bl_obj_dot"></div>
              <div class="bl_obj_txt">
                <strong class="el_reset"
                  >예약이 확정되었을 때, 등록된 카드 정보로 자동 결제돼요.
                </strong>
              </div>
            </div>
          </li>
          <li class="bl_desc_item">
            <div class="bl_desc_obj">
              <div class="bl_obj_dot"></div>
              <div class="bl_obj_txt">
                <strong class="el_reset">
                  탑승시간 48시간 전까지 무료로 취소할 수 있어요.
                </strong>
              </div>
            </div>
          </li>
          <li class="bl_desc_item">
            <div class="bl_desc_obj">
              <div class="bl_obj_dot"></div>
              <div class="bl_obj_txt">
                <strong class="el_reset">
                  <b>항공편 정보 입력 후 결제 금액 조회 시, 자동으로 픽업 시간이 항공편 도착 시간 {{ content }} 이후로 변경될 수 있어요.</b>
                </strong>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="bl_check">
        <div class="cp_chk">
          <div class="cp_chk_obj">
            <input
              v-model="checked"
              class="el_form cp_obj_input"
              type="checkbox"
            />
            <div class="cp_obj_chk"></div>
            <div class="cp_obj_txt">
              예약 전 주의사항 안내를 확인하였습니다.
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <footer class="ly_footer">
        <div class="bl_btn">
          <ul class="el_reset bl_btn_list">
            <li class="bl_btn_item">
              <!-- 비활성화(C) : is_acDisabled -->
              <nm-button class="is_moPrim" :disabled="missingRequirements" @click="checkCaution"
                >확인</nm-button
              >
            </li>
          </ul>
        </div>
      </footer>
    </template>
  </bottom-modal-layout>
</template>
<script>
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import { preventBackButton } from "@/util/webview";

export default {
  components: { BottomModalLayout, NmButton },
  name: "ReservationCautionModal",
  data() {
    return {
      resolve: () => {},
      close: false,
      checked: false,
      content: null,
    };
  },
  computed: {
    missingRequirements() {
      return !this.checked;
    },
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content === 90 ? "1시간 30분" : "1시간";
      this.resolve = resolve;
      
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
    checkCaution() {
      if (!this.checked) return;
      this.hide(true);
    },
  },
};
</script>