var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{ref:"scrollContainer",staticClass:"page"},[_c('header',{staticClass:"header",style:({
        height: _vm.headerHeight,
        position: _vm.isHeaderFixed ? 'fixed' : 'static',
        top: _vm.isDownScroll ? ("-" + _vm.headerHeight) : 0,
      })},[_c('div',{staticClass:"commonSearch"},[_c('div',{staticClass:"search is-activeDelete"},[_c('div',{staticClass:"search-input",on:{"click":function($event){return _vm.logEvent('restaurant_search_click')}}},[_c('byte-input',{attrs:{"placeholder":"골프장/식당","max-byte":50},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"input":function($event){return _vm.onSearchValueInput.apply(null, arguments)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('i',{staticClass:"search-icon"},[_vm._v(" 검색 ")]),(_vm.searchValue)?_c('i',{staticClass:"search-delete",on:{"click":function($event){$event.stopPropagation();return _vm.logEvent('restaurant_search_remove', _vm.resetSearch)}}},[_vm._v(" 삭제 ")]):_vm._e()],1)])]),_c('div',{staticClass:"recent"},[_c('div',{staticClass:"recent-header"},[_c('div',{staticClass:"recent-title"},[_vm._v("최근 검색어")]),_c('div',{staticClass:"recent-delete",on:{"click":function($event){return _vm.logEvent(
                'restaurant_recentsearch_allremove',
                _vm.removeAllSearchWords
              )}}},[_vm._v(" 전체삭제 ")])]),_c('div',{staticClass:"recent-main",class:{ 'is-active': _vm.searchWords.length }},[_c('div',{staticClass:"recent-noResult"},[_vm._v("최근 검색어가 없습니다.")]),_c('div',{staticClass:"recent-keyword"},[_c('div',{staticClass:"keyword"},[_c('ul',{staticClass:"keyword-list"},_vm._l((_vm.searchWords),function(word){return _c('li',{key:word,on:{"click":function($event){return _vm.setSearchValue(word)}}},[_c('div',{staticClass:"keyword-object"},[_c('div',{staticClass:"keyword-item"},[_vm._v(_vm._s(word))]),_c('i',{staticClass:"keyword-delete",on:{"click":function($event){$event.stopPropagation();_vm.logEvent(
                          'restaurant_recentsearch_remove',
                          _vm.removeSearchWord(word)
                        )}}},[_vm._v(" 삭제 ")])])])}),0)])])])])]),_c('main',{staticClass:"main"},[_c('div',{staticClass:"main-main",style:({
          height: _vm.searchWords.length
            ? ("calc(100% - " + _vm.headerPadding + ")")
            : ("calc(100% - " + _vm.headerPadding + ")"),
          'padding-top': _vm.headerPadding,
        })},[_c('div',{ref:"scrollList",staticClass:"board",class:{
            'is-active': _vm.restaurantList && _vm.restaurantList.length,
            'is-typeList': _vm.viewTypeList,
          }},[_c('div',{staticClass:"board-header"},[_c('div',{staticClass:"board-type"},[_vm._m(0),_c('i',{staticClass:"board-icon",on:{"click":_vm.toggleType}},[_vm._v(" 타입 ")])])]),_c('div',{staticClass:"board-main"},[_vm._m(1),_c('ul',{staticClass:"board-list"},_vm._l((_vm.restaurantList),function(restaurant){return _c('li',{key:restaurant.foodId,on:{"click":function($event){_vm.logEvent(
                    'restaurant_detail_open',
                    _vm.selectRestaurant(restaurant)
                  )}}},[_c('div',{staticClass:"place"},[_c('div',{staticClass:"place-header"},[_c('div',{staticClass:"thumb"},[_c('div',{staticClass:"thumb-img",style:({
                          'background-image': ("url('" + (restaurant.logoUrl) + "')"),
                        })})])]),_c('div',{staticClass:"place-info"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-name"},[_vm._v(_vm._s(restaurant.name))]),_c('div',{staticClass:"info-address"},[_vm._v(" "+_vm._s(restaurant.addr)+" ")])]),(restaurant.clubList && restaurant.clubList.length)?_c('div',{staticClass:"hash"},[_c('ul',{staticClass:"hash-list"},_vm._l((restaurant.clubList),function(tag){return _c('li',{key:tag},[_c('div',{staticClass:"hash-item"},[_vm._v("#"+_vm._s(tag))])])}),0)]):_vm._e()])])])}),0)])])])])]),(_vm.searchLayer && _vm.searchValue)?_c('div',{staticClass:"layer"},[_c('div',{staticClass:"layerAuto"},[_c('div',{staticClass:"outer"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-main"},[_c('div',{staticClass:"select"},[_c('ul',{staticClass:"select-list"},_vm._l((_vm.searchGolfClubList),function(club){return _c('li',{key:club.ccode,on:{"click":function($event){return _vm.setSearchValue(club.clubName)}}},[_c('div',{staticClass:"select-item",domProps:{"innerHTML":_vm._s(club.highlightHtml)}})])}),0)])])])])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board-info"},[_c('i',{staticClass:"info-icon"}),_vm._v(" TMAP 데이터 제공 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"board-noResult"},[_c('div',{staticClass:"board-notice"},[_vm._v("검색된 내역이 없어요.")]),_c('div',{staticClass:"board-caption"},[_vm._v("다시 검색 해주세요.")])])}]

export { render, staticRenderFns }