<template>
  <div class="bl_body_map">
    <div ref="staticMap" id="static-map"></div>
    <div class="bl_map_point" :class="markerIconClass">{{ markerLabel }}</div>
  </div>
</template>
<script>
import loader from "@/util/googleMaps";
export default {
  name: "NmStaticMap",
  props: {
    lat: {
      type: [String, Number],
    },
    lng: {
      type: [String, Number],
    },
    markerLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      map: null,
      staticMap: null,
    };
  },
  async mounted() {
    this.initMap();
  },
  beforeDestroy() {
    this.map = null;
    this.staticMap = null;
  },
  computed: {
    markerIconClass() {
      return this.markerLabel === "출발" ? "is_moStart" : "is_moDest";
    },
  },
  watch: {
    lat(value) {
      if (value) {
        this.map?.setCenter({ lat: value, lng: this.lng });
      }
    },
    lng(value) {
      if (value) {
        this.map?.setCenter({ lat: this.lat, lng: value });
      }
    },
  },
  methods: {
    async initMap() {
      const { Map } = await loader.importLibrary("maps");
      const position = {
        lat: this.lat || 35.658581,
        lng: this.lng || 139.745433,
      };

      // map
      this.map = new Map(this.$refs.staticMap, {
        mapId: "cfdf8f80bd0cfc97",
        center: position,
        disableDefaultUI: true,
        clickableIcons: false,
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        clickable: false,
        zoom: 14,
      });
    },
  },
};
</script>
<style>
#static-map {
  width: 100%;
  height: 100%;
}
</style>