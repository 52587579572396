<template>
  <li :class="['bl_history_item', statusClass]" @click="click">
    <div class="bl_history_obj">
      <div class="bl_obj_header">
        <div class="bl_header_ttl">{{ type }}</div>
        <div class="bl_header_label">{{ status }}</div>
      </div>
      <div class="bl_obj_body">
        <div class="bl_body_ttl">{{ pickUpDateTime }}</div>
        <ul class="el_reset bl_body_list">
          <li class="bl_body_item">
            <div class="bl_body_txt">{{ pickUpLocation }}</div>
          </li>
          <li class="bl_body_item">
            <div class="bl_body_txt">{{ dropOffLocation }}</div>
          </li>
        </ul>
      </div>
      <div :class="['bl_obj_footer']">
        <div class="bl_footer_label">{{ paymentStatus }}</div>
        <div class="bl_footer_txt">{{ amount | comma }}원</div>
      </div>
    </div>
  </li>
</template>
<script>
import { comma } from "@/util/string";
import dateMixin from "@/mixins/dateMixin";
import dayjs from "dayjs";
export default {
  name: "JapaneseVehicleUsageHistoryListItem",
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  mixins: [dateMixin],
  data() {
    return {
      dayList: ["일", "월", "화", "수", "목", "금", "토"],
    };
  },
  computed: {
    type() {
      switch (this.reservation.reservationTypeCd) {
        case "20001":
          return "공항 픽업 예약"
        case "20002":
          return "공항 샌딩 예약"
        case "20003":
          return "골프장 픽업 예약"
        case "20004":
          return "골프장 샌딩 예약"
        case "20005":
          return "일본 차량 예약"
        default:
          return ""
      }
    },
    status() {
      switch (this.reservation.reservationStateCd) {
        /*case "14002":
          return "예약 진행 중";*/
        case "14003":
        case "14004":
        case "14005":
          return "예약 완료";
        case "14006":
          return "예약 취소";
        case "14007":
          return "탑승 완료";
        default:
          return "";
      }
    },
    statusClass() {
      switch (this.reservation.reservationStateCd) {
        /*case "14002":
          return "is_moIng";*/
        case "14003":
        case "14004":
        case "14005":
          return "";
        case "14006":
          return "is_moCancel";
        case "14007":
          return "is_moBoarding";
        default:
          return "";
      }
    },
    pickUpDateTime() {
      const day = dayjs(
          this.convertDate(this.reservation.pickUpDateTime, "YYYY-MM-DD")
      ).get("day");
      return this.convertDateTime(
          this.reservation.pickUpDateTime,
          `YYYY.MM.DD(${this.dayList[day]}) HH:mm`
      );
    },
    pickUpLocation() {
      return this.reservation.pickUpLocation;
    },
    dropOffLocation() {
      return this.reservation.dropOffLocation;
    },
    paymentStatus() {
      return "결제 금액";
    },
    amount() {
      return this.reservation.finalAmount;
    },
  },
  methods: {
    click() {
      this.$emit("click", this.reservation.reservationId);
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>
