import http from "@/util/http";
import httpV2 from "@/util/httpV2";

function getMembershipInfo() {
  return http.get(`membership/info`);
}

function createCancelMembership() {
  return http.post(`membership/cancel`);
}
function deleteCancelMembership() {
  return http.delete(`membership/cancel`);
}

function getMembershipInsuranceBilling({ page, size }) {
  return http.get(`membership/insurance/billing`, {
    params: {
      page,
      size,
    },
  });
}

function joinMembership({
  agreeTermsIdList,
  couponNumber,
  email,
  productCouponId,
  productId,
}) {
  return httpV2.post(`membership/join`, {
    agreeTermsIdList,
    couponNumber,
    email,
    productCouponId,
    productId,
  });
}

function getMembershipPayment({ page, size }) {
  return http.get(`membership/payment`, {
    params: {
      page,
      size,
    },
  });
}

function createMembershipTossBilling({ authKey, customerKey }) {
  return http.post(`membership/payment/toss/billing`, {
    authKey,
    customerKey,
  });
}

function renewalMembership() {
  return http.put(`membership/renewal`);
}

function getMembershipJoin() {
  return http.get(`membership/join`);
}

export default {
  getMembershipInfo,
  createCancelMembership,
  deleteCancelMembership,
  getMembershipInsuranceBilling,
  joinMembership,
  getMembershipPayment,
  createMembershipTossBilling,
  renewalMembership,
  getMembershipJoin,
};
