<template>
  <div class="wrapper">
    <div class="page">
      <main class="main" v-if="subject">
        <div class="main-header">
          <div class="info">
            <div class="info-title">
              {{ subject }}
            </div>
            <div class="info-date">
              {{ convertDate(regDt) }}
            </div>
          </div>
        </div>
        <div class="main-main">
          <div class="content ck-content" v-html="content"></div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import { userApi } from "@/api";
import { closeWebviewLayer } from "@/util/webview";
import { HTTP_EXPRESSION } from "@/util/string";

export default {
  name: "NoticeView",
  props: ["notice"],
  mixins: [dateMixin],
  created() {
    this.noticeId = this.$route.params.noticeId;
    if (!this.noticeId) {
      this.$router.push("/notice");
    }
    this.setData();
  },
  data() {
    return {
      noticeId: "",
      subject: "",
      content: "",
      regDt: "",
    };
  },
  methods: {
    async setData() {
      try {
        const {
          data: { content, regDt, subject, externalUrl },
        } = await userApi.getNotice(this.noticeId);
        this.subject = subject;
        this.content = content;
        this.regDt = regDt;
        if (externalUrl) {
          const httpRegex = new RegExp(HTTP_EXPRESSION);
          if (httpRegex.test(externalUrl)) {
            const noticeLinkTag = document.createElement("a");
            noticeLinkTag.href = externalUrl;
            noticeLinkTag.click();
            closeWebviewLayer();
          } else {
            this.$router.replace({ path: externalUrl });
          }
          return;
        }
      } catch (e) {
        console.log(e);
        if (!e?.code) {
          return;
        }
        switch (e.code) {
          case "5231":
            closeWebviewLayer();
            return;
          default:
            return;
        }
      }
    },
  },
};
</script>

<style scoped>
.info-title,
.content {
  word-break: break-word;
}
figure {
  margin: 0;
}
img {
  width: 100%;
}
</style>
