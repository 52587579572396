<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="bl_header_wrapper">
              <div class="bl_header">
                <div class="bl_header_ttl">Q{{ questionNo }}.</div>
                <div v-if="questionLength > 1" class="bl_header_state">
                  <strong class="el_reset bl_header_state_accent">{{
                    questionNo
                  }}</strong>
                  / {{ questionLength }}
                </div>
              </div>
            </div>
            <div class="bl_ttl_wrapper">
              <div class="bl_ttl">
                <div class="bl_ttl_txt">
                  {{ currentQuestion.title }}
                </div>
                <div
                  class="bl_ttl_multi"
                  v-if="currentQuestion.correctCount > 1"
                >
                  답변 {{ currentQuestion.correctCount }}개
                </div>
                <div class="bl_ttl_img" v-if="currentQuestion.imageUrl">
                  <div
                    class="bl_ttl_img_val"
                    :style="{
                      'background-image': `url('${currentQuestion.imageUrl}')`,
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <!-- 타입 : 주관식 -->
            <div v-if="isShortAnswerQuestion" class="bl_problemList_wrapper">
              <ul class="el_reset bl_problemList">
                <li class="bl_problemList_item is_moFull">
                  <div class="bl_problem">
                    <div class="cp_area">
                      <byte-textarea
                        v-model="questionAnswer"
                        :max-byte="1000"
                        class="el_form cp_area_inp"
                        placeholder="내용을 입력하세요."
                      ></byte-textarea>
                      <div class="cp_area_box"></div>
                    </div>
                    <div class="bl_problem_state">
                      <!-- 입력중(C) : is_acIng -->
                      <strong
                        :class="{ is_acIng: answerByte }"
                        class="el_reset bl_problem_state_accent"
                        >{{ answerByte | comma }}</strong
                      >/1,000
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 타입 : 객관식 -->
            <div v-else class="bl_problemList_wrapper">
              <ul class="el_reset bl_problemList">
                <!-- 가로 100%(C): is_moFull -->
                <li
                  v-for="example in currentQuestion.exampleList"
                  :key="example.exampleNumber"
                  :class="{
                    is_moFull: currentQuestion.questionTypeCd === '4602',
                  }"
                  class="bl_problemList_item"
                >
                  <div class="bl_problem">
                    <!-- 이미지 타입(C): is_moImg / 텍스트 타입(C): is_moTxt -->
                    <!-- 결과 타입(C): is_moRes -->
                    <div
                      class="cp_chk"
                      :class="{
                        is_moImg: currentQuestion.questionTypeCd === '4601',
                        is_moTxt: currentQuestion.questionTypeCd === '4602',
                      }"
                    >
                      <!-- 체크(A): checked -->
                      <input
                        class="el_form cp_chk_inp"
                        v-model="questionMultipleAnswer"
                        @change="preventCheckGreaterThanCorrectCount"
                        :value="example.exampleNumber"
                        :type="
                          currentQuestion.correctCount > 1
                            ? 'checkbox'
                            : 'radio'
                        "
                      />
                      <div class="cp_chk_bg"></div>
                      <div class="cp_chk_box"></div>
                      <div class="cp_chk_area">
                        <div class="cp_chk_area_pro">
                          <div
                            class="cp_chk_area_pro_val"
                            style="width: 60%"
                          ></div>
                        </div>
                        <div
                          class="cp_chk_area_img"
                          :style="{
                            'background-image': `url('${example.imageUrl}')`,
                          }"
                        ></div>
                        <div class="cp_chk_area_cont">
                          <div class="cp_chk_area_cont_txt">
                            {{ example.info }}
                          </div>
                          <div class="cp_chk_area_cont_chk"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </main>
          <footer class="ly_footer">
            <div class="bl_btnList_wrapper">
              <ul class="el_reset bl_btnList">
                <li v-if="questionNo > 1" class="bl_btnList_item is_moPrev">
                  <div class="bl_btn">
                    <div class="cp_btn">
                      <!-- 비활성(A): disabled -->
                      <button
                        class="el_form cp_btn_btn"
                        @click="$router.back()"
                      >
                        이전
                      </button>
                    </div>
                  </div>
                </li>
                <li v-if="questionNo < questionLength" class="bl_btnList_item">
                  <div class="bl_btn">
                    <div class="cp_btn is_moPrim">
                      <!-- 비활성(A): disabled -->
                      <button
                        :disabled="disabled"
                        @click="chooseAnswer"
                        class="el_form cp_btn_btn"
                      >
                        다음
                      </button>
                    </div>
                  </div>
                </li>
                <li v-else class="bl_btnList_item">
                  <div class="bl_btn">
                    <div class="cp_btn is_moPrim">
                      <!-- 비활성(A): disabled -->
                      <button
                        :disabled="disabled"
                        @click="submit"
                        class="el_form cp_btn_btn"
                      >
                        답변 제출
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closeWebviewLayer } from "@/util/webview";
import ByteTextarea from "@/components/ByteTextarea.vue";
import { comma, getByteLength } from "@/util/string";

export default {
  name: "PromotionQuestion",
  components: { ByteTextarea },
  props: {
    answerList: {
      type: Array,
      default: () => [],
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    try {
      this.questionList = JSON.parse(localStorage.getItem("questionList"));
    } catch {
      alert("문제를 가져올 수 없습니다.");
      closeWebviewLayer();
    }
  },
  data() {
    return {
      questionList: null,
      questionMultipleAnswer: [],
      questionAnswer: "",
    };
  },
  methods: {
    emitAnswer() {
      this.$emit("chooseAnswer", {
        exampleNumber: !this.isShortAnswerQuestion
          ? Array.isArray(this.questionMultipleAnswer)
            ? this.questionMultipleAnswer.sort()
            : [this.questionMultipleAnswer]
          : undefined,
        questionAnswer: this.isShortAnswerQuestion
          ? this.questionAnswer
          : undefined,
        questionNumber: this.currentQuestion.questionNumber,
      });
    },
    chooseAnswer() {
      this.emitAnswer();
      this.$router.push({
        name: "PromotionQuestion",
        params: {
          promotionId: this.promotionId,
          questionNo: this.questionNo + 1,
        },
      });
    },
    preventCheckGreaterThanCorrectCount(event) {
      if (
        Array.isArray(this.questionMultipleAnswer) &&
        this.currentQuestion.correctCount < this.questionMultipleAnswer.length
      ) {
        this.questionMultipleAnswer = this.questionMultipleAnswer.slice(
          0,
          this.currentQuestion.correctCount
        );
        event.target.checked = false;
      }
    },
    async submit() {
      this.emitAnswer();
      this.$emit("submit");
    },
  },
  computed: {
    promotionId() {
      return Number(this.$route.params.promotionId);
    },
    questionNo() {
      return Number(this.$route.params.questionNo);
    },
    questionLength() {
      return this.questionList?.length;
    },
    currentQuestion() {
      return this.questionList?.[this.questionNo - 1] || {};
    },
    isShortAnswerQuestion() {
      return this.currentQuestion.answerTypeCd === "4502";
    },
    disabled() {
      if (
        this.isFetching ||
        (this.isShortAnswerQuestion && !this.questionAnswer)
      ) {
        return true;
      }
      if (
        !this.isShortAnswerQuestion &&
        (!this.questionMultipleAnswer ||
          (Array.isArray(this.questionMultipleAnswer) &&
            this.currentQuestion.correctCount !==
              this.questionMultipleAnswer.length))
      ) {
        return true;
      }
      return false;
    },
    answerByte() {
      return getByteLength(this.questionAnswer);
    },
  },
  filters: {
    comma,
  },
};
</script>

<style scoped>
.id_problem .ly_page .ly_footer {
  box-shadow: none;
}
.id_view.id_problem .ly_page .ly_footer .bl_btn .cp_btn_btn {
  border-radius: 2.5rem;
}
.bl_ttl_txt {
  word-break: break-all;
}
.cp_chk_area_cont_txt {
  word-break: break-all;
}
</style>
