<template>
  <div class="wrapper">
    <div class="page">
      <header class="header">
        <div class="commonNavi">
          <div class="navi">
            <ul class="navi-list">
              <li
                @click="
                  logEvent('event_proceeding_click', onChangeTab('active'))
                "
                :class="{ 'is-active': selectedTab === 'active' }"
              >
                <div class="navi-item">진행중 이벤트</div>
              </li>
              <li
                @click="logEvent('event_ended_click', onChangeTab('inactive'))"
                :class="{ 'is-active': selectedTab === 'inactive' }"
              >
                <div class="navi-item">종료된 이벤트</div>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <main class="main">
        <div class="main-main" ref="scrollContainer">
          <div class="board" ref="scrollList">
            <ul class="board-list">
              <li
                v-for="event in eventList"
                :key="event.eventId"
                @click="
                  logEvent('event_list_click', () => {
                    onEventClick(event);
                  })
                "
              >
                <div class="event">
                  <div class="event-header">
                    <div class="thumb">
                      <div
                        class="thumb-img"
                        :style="{
                          'background-image': `url('${event.bannerImageUrl}')`,
                          'background-color': `${event.bgCode}`,
                        }"
                      ></div>
                      <div
                        class="thumb-close"
                        :style="{ display: !isActive && 'flex' }"
                      >
                        종료된 이벤트 입니다.
                      </div>
                    </div>
                  </div>
                  <div class="event-main">
                    <div class="event-title">{{ event.subject }}</div>
                    <div class="event-date">
                      {{ convertDate(event.startDt) }} ~
                      {{ convertDate(event.endDt) }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dateMixin from "@/mixins/dateMixin";
import { HTTP_EXPRESSION } from "@/util/string";

export default {
  name: "EventList",
  mixins: [scrollMixin, dateMixin],
  data() {
    return {
      eventList: null,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      selectedTab: "active",
    };
  },
  mounted() {
    const eventList = this.loadScrollPosition("eventInfo");
    if (eventList) {
      this.eventList = eventList;
    } else {
      this.setData();
    }
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { list, total },
        } = await userApi.getEventList({
          on: this.isActive,
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.eventList = this.eventList || [];
        this.eventList = [...this.eventList, ...list];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    onChangeTab(tab) {
      this.selectedTab = tab;
      this.eventList = null;
      this.pageIndex = 1;
      this.addScrollEvent();
      this.setData();
    },
    onEventClick(event) {
      if (this.isActive) {
        this.saveScrollPosition("eventInfo", this.eventList);
        if (event.externalUrl) {
          const httpRegex = new RegExp(HTTP_EXPRESSION);
          if (httpRegex.test(event.externalUrl)) {
            const eventLinkTag = document.createElement("a");
            eventLinkTag.href = event.externalUrl;
            eventLinkTag.click();
          } else {
            this.$router.push({ path: event.externalUrl });
          }
        } else {
          this.$router.push({
            name: "EventView",
            params: { eventId: event.eventId },
          });
        }
      } else {
        alert("종료된 이벤트입니다.");
      }
    },
  },
  computed: {
    isActive() {
      return this.selectedTab === "active";
    },
  },
};
</script>

<style scoped>
.type-event .main-main .thumb-img {
  background-size: contain;
}
</style>
