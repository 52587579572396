<template>
  <div class="policy">
    <ul>
      <li>브이씨소프트(주)</li>
      <li>서울특별시 강남구 테헤란로 108길 25, 3층(대동빌딩)</li>
      <li>사업자등록번호 : 332-87-02508</li>
      <li>통신판매업신고 : 2022-서울강남-03105</li>
      <li>대표이사 : 심상일</li>
      <li>대표번호 : 070-7720-3851</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BusinessInformation",
};
</script>
