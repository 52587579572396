<template>
  <div class="wrapper">
    <div class="page">
      <main class="main" v-if="subject">
        <div class="main-header">
          <div class="info">
            <div class="info-title">
              {{ subject }}
            </div>
          </div>
        </div>
        <div class="main-main">
          <div class="content ck-content" v-html="content"></div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { userApi } from "@/api";
import { closeWebviewLayer } from "@/util/webview";

export default {
  name: "FaqView",
  created() {
    this.faqId = this.$route.params.faqId;
    if (!this.faqId) {
      this.$router.push("/faq");
    }
    this.setData();
  },
  data() {
    return {
      faqId: "",
      subject: "",
      content: "",
    };
  },
  methods: {
    async setData() {
      try {
        const {
          data: { content, subject },
        } = await userApi.getFaq(this.faqId);
        this.subject = subject;
        this.content = content;
      } catch (e) {
        console.log(e);
        if (!e?.code) {
          return;
        }
        switch (e.code) {
          case "5231":
            closeWebviewLayer();
            return;
          default:
            return;
        }
      }
    },
  },
};
</script>

<style scoped>
.id-faqView .main-header {
  border-bottom: 1px solid #eee;
  flex: 0 0 auto;
}

.id-faqView .main-header .info {
  padding: 14px 20px;
}

.id-faqView .main-header .info-title {
  color: #222;
  font: normal normal 400 16px / 1.5 "Noto Sans KR";
}

.id-faqView .main-main .content {
  color: #767676;
  font: normal normal 400 14px / 1.43 "Noto Sans KR";
  padding: 20px;
}

.info-title,
.content {
  word-break: break-word;
}
figure {
  margin: 0;
}
img {
  width: 100%;
}
>>> figure.media {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 52.35%;
  overflow: hidden;
}
>>> figure.media > div > div {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 0 !important;
}
</style>
