<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_header">
            <div class="bl_header_ttl">예약자 정보</div>
          </div>
          <div class="bl_input">
            <ul class="el_reset bl_input_list">
              <li class="bl_input_item">
                <nm-byte-input
                  v-model="koreanName"
                  placeholder="(한글) 이름"
                  type="text"
                  pattern="[^ㄱ-힣]"
                  :max-byte="18"
                />
              </li>
              <li class="bl_input_item is_moHalf">
                <nm-byte-input
                  v-model="inputEnglishFirstName"
                  placeholder="(영문) 이름"
                  type="text"
                  pattern="[^a-zA-Z]"
                  :max-byte="30"
                />
              </li>
              <li class="bl_input_item is_moHalf">
                <nm-byte-input
                  v-model="inputEnglishLastName"
                  placeholder="(영문) 성"
                  type="text"
                  pattern="[^a-zA-Z]"
                  :max-byte="30"
                />
              </li>
              <li class="bl_input_item">
                <nm-input
                  v-model="inputPhoneNumber"
                  placeholder="휴대폰"
                  type="text"
                  maxlength="13"
                  pattern="[^0-9]"
                />
              </li>
              <li class="bl_input_item">
                <nm-input
                  v-model="inputEmail"
                  placeholder="이메일"
                  type="text"
                  maxlength="50"
                  :message="emailErrorMessage"
                  :validate="emailValidate"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ly_body_footer">
        <div class="ly_container">
          <div class="bl_btn">
            <nm-button
              class="is_moPrim"
              :disabled="missingRequirements"
              @click="editBookerInfo"
              >확인</nm-button
            >
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { nearmeApi } from "@/api";
import { phoneNumberFormatter } from "@/util/string";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmInput from "@/components/nearme/NmInput.vue";
import NmByteInput from "@/components/nearme/NmByteInput.vue";
import NmButton from "@/components/nearme/NmButton.vue";
export default {
  name: "EditBookerInfo",
  components: {
    DefaultLayout,
    NmInput,
    NmByteInput,
    NmButton,
  },
  data() {
    return {
      reservationCd: null,
      koreanName: "",
      englishFirstName: "",
      englishLastName: "",
      phoneNumber: "",
      email: "",
      emailErrorMessage: "",
      emailValidate: true,
      emailPattern: /^[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\.[a-z]{2,}$/,
      isFetching: false,
    };
  },
  async created() {
    this.reservationCd = this.$route.params.reservation_cd;
    if (!this.reservationCd) {
      alert("잘못된 접근입니다.");
      this.$router.back();
      return;
    }

    await this.setData();
  },
  computed: {
    missingRequirements() {
      return (
        !this.koreanName?.trim() ||
        this.koreanName?.trim()?.length < 2 ||
        !this.inputEnglishFirstName?.trim() ||
        !this.inputEnglishLastName?.trim() ||
        !this.inputPhoneNumber?.trim() ||
        !this.inputEmail.trim()
      );
    },
    inputEnglishFirstName: {
      get() {
        return this.englishFirstName?.toUpperCase();
      },
      set(value) {
        this.englishFirstName = value;
      },
    },
    inputEnglishLastName: {
      get() {
        return this.englishLastName?.toUpperCase();
      },
      set(value) {
        this.englishLastName = value;
      },
    },
    inputPhoneNumber: {
      get() {
        return phoneNumberFormatter(this.phoneNumber);
      },
      set(value) {
        this.phoneNumber = String(value).replace(/\D/g, "");
      },
    },
    inputEmail: {
      set(value) {
        if (value.length === 0) {
          this.emailValidate = true;
          this.emailErrorMessage = "";
        }
        this.email = value;
      },
      get() {
        return this.email;
      },
    },
  },
  methods: {
    async setData() {
      const {
        data: { engFirstName, engLastName, name, phoneNumber, email },
      } = await nearmeApi.getBookerInfo(this.reservationCd);
      this.englishFirstName = engFirstName;
      this.englishLastName = engLastName;
      this.koreanName = name;
      this.phoneNumber = phoneNumber;
      this.inputEmail = email || "";
    },
    async editBookerInfo() {
      if (this.isFetching) {
        return;
      }
      try {
        this.isFetching = true;
        await nearmeApi.updateBookerInfo(this.reservationCd, {
          engFirstName: this.englishFirstName,
          engLastName: this.englishLastName,
          name: this.koreanName,
          phoneNumber: this.phoneNumber,
          email: this.inputEmail,
        });

        alert("수정되었습니다.");
        this.$router.replace({
          name: "UsageHistoryView",
          params: {
            reservation_cd: this.reservationCd,
          },
          query: {
            service_type: this.serviceType,
          },
        });
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>