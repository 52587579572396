<template>
  <li class="bl_airport_item" :class="[{ is_acOpen: isOpen }]">
    <div class="bl_airport_obj">
      <div class="bl_obj_header">
        <div class="bl_obj_header_container">
          <div class="bl_header_ttl">
            <div class="bl_ttl_ico"></div>
            <div class="bl_ttl_ttl">{{ title }}</div>
            <div class="bl_ttl_txt">{{ code }}</div>
          </div>
          <div class="bl_header_arrow" @click="openList"></div>
        </div>
        <div class="bl_ttl_description">{{ description }}</div>
      </div>
      <div class="bl_obj_body">
        <ul class="el_reset bl_body_list">
          <li
            v-for="item in items"
            :key="item.airportCd"
            class="bl_body_item"
            @click="selectItem(item)"
          >
            <div class="bl_body_obj">
              <div class="bl_obj_txt">{{ item.airportName }}</div>
              <div class="bl_obj_ico" @click.stop="openBottomModal(item)"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: "TerminalListItem",
  props: {
    title: {
      type: String,
    },
    code: {
      type: String,
    },
    description: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    openList() {
      this.isOpen = !this.isOpen;
    },
    selectItem(terminal) {
      this.$emit("select", terminal);
    },
    openBottomModal(terminal) {
      this.$emit("open-bottom-modal", terminal);
    },
  },
};
</script>
<style scoped>
.id_airport .ly_page .ly_body .bl_airport_obj .bl_obj_header {
  flex-direction: column;
  align-items: flex-start;
}

.bl_obj_header_container {
  width: 100%;
  display: flex;
}
.bl_ttl_description {
  color: #999;
  flex: 0 0 auto;
  font-size: 1.4rem;
}

.bl_header_arrow {
  height: fit-content;
}
</style>