<template>
  <community-none-dangol v-if="users && !users.length" />
  <div class="lyPage" v-else>
    <main class="lyBody">
      <section class="stContent">
        <div class="profile">
          <ul class="profile-list">
            <community-mini-profile
              class="profile-item"
              v-for="user in users"
              :key="user.uid"
              :user="user"
            />
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import scrollMixin from "@/mixins/scrollMixin";
import { boardApi } from "@/api";
import CommunityNoneDangol from "@/views/community/components/CommunityNoneDangol";
import CommunityMiniProfile from "@/views/community/components/CommunityMiniProfile";
import { formatCount } from "@/util/string";

export default {
  name: "CommunitySearchProfile",
  mixins: [scrollMixin],
  components: {
    CommunityNoneDangol,
    CommunityMiniProfile,
  },
  created() {
    const { keyword } = this.$route.query;
    this.keyword = keyword;
    this.setData();
  },
  data() {
    return {
      keyword: "",
      users: null,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
    };
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      this.isFetching = true;
      try {
        const {
          data: { content, total },
        } = await boardApi.getFollowList({
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.users = this.users || [];
        this.users = [...this.users, ...content];
        if (this.totalCount === 0 && total) {
          document.title = `MY 단골(${formatCount(total)})`;
        }
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>
