import http from "@/util/http";
import httpV2 from "@/util/httpV2";

function getUserInfo() {
  return http.get(`/user/info`);
}

function getEvent(eventId) {
  return http.get(`/user/event/${eventId}`);
}

function getEventList({ on, page, size }) {
  return http.get(`/user/event/list`, {
    params: {
      on,
      page,
      size,
    },
  });
}

function getFaqList({ page, search, size, categoryCd }) {
  return httpV2.get(`/user/faq/list`, {
    params: {
      page,
      search,
      size,
      categoryCd,
    },
  });
}

function getFaq(faqId) {
  return http.get(`/user/faq/${faqId}`);
}

function getNoticeList({ page, size }) {
  return http.get(`/user/notice/list`, {
    params: {
      page,
      size,
    },
  });
}

function getNotice(noticeId) {
  return http.get(`/user/notice/${noticeId}`);
}

function getSupportList({ page, size }) {
  return http.get(`/user/support/list`, {
    params: {
      page,
      size,
    },
  });
}

function createSupport({
  categoryCd,
  email,
  imageIds,
  phone,
  question,
  subject,
}) {
  return http.post(`/user/support/register`, {
    categoryCd,
    email,
    imageIds,
    phone,
    question,
    subject,
  });
}

function getMyBoards({ lastId, page, size }) {
  return http.get(`/user/boards`, {
    params: {
      page,
      lastId,
      size,
    },
  });
}

function getLikeBoards({ lastId, page, size }) {
  return http.get(`/user/likes`, {
    params: {
      page,
      lastId,
      size,
    },
  });
}

function getInviteCode() {
  return http.get(`/user/invite/code`);
}

function submitInviteCode(inviteCode) {
  return http.post(`/user/invite/code`, {
    inviteCode,
  });
}

function getInviteUsers({ page, size }) {
  return http.get(`/user/invite/friends`, {
    params: {
      page,
      size,
    },
  });
}

function submitCouponNumber(couponNumber) {
  return http.post(`/user/coupon/round`, {
    couponNumber,
  });
}

function submitGiftiShowNumber(couponNumber) {
  return http.post(`/user/coupon/voucher`, {
    couponNumber,
  });
}

function getCouponList({ page, size, useableYn }) {
  return http.get(`/user/coupon/list`, {
    params: { page, size, useableYn },
  });
}

function getCouponListPartner({ partnerService, passengers, amount, page, size }) {
  return http.get(`/user/coupon/list/partner`, {
    params: { partnerService, passengers, amount, page, size },
    paramsSerializer(paramObj) {
      const params = new URLSearchParams();
      for (const key in paramObj) {
        if (paramObj[key]) {
          params.append(key, paramObj[key]);
        }
      }

      return params.toString();
    }
  });
}

function getPromotionNewYear() {
  return http.get(`/user/newyears`);
}

function createPromotionNewYear({
  firstCcode,
  roundCount,
  score,
  secondCcode,
  thirdCcode,
}) {
  return http.post(`/user/newyears`, {
    firstCcode,
    roundCount,
    score,
    secondCcode,
    thirdCcode,
  });
}

function getPromotionNewYearNewList({ lastId, size }) {
  return http.get(`/user/newyears/new/list`, {
    lastId,
    size,
  });
}

function getPromotionJeomsinUserInfo() {
  return http.get(`/user/jeomsin`);
}

function createPromotionJeomsinUserInfo({
  additionalAgreeYn,
  birthDate,
  birthHour,
  birthMinute,
  gender,
  isSolar,
  thirdPartiesAgreeYn,
  userName,
}) {
  return http.post(`/user/jeomsin`, {
    additionalAgreeYn,
    birthDate,
    birthHour,
    birthMinute,
    gender,
    isSolar,
    thirdPartiesAgreeYn,
    userName,
  });
}

function getPromotionJeomsinElements() {
  return http.get(`/user/jeomsin/elements`);
}

function getPromotionJeomsinFortune(title) {
  return http.get(`/user/jeomsin/fortune`, {
    params: {
      title,
    },
  });
}

export default {
  getUserInfo,
  getEvent,
  getEventList,
  getFaqList,
  getFaq,
  getNoticeList,
  getNotice,
  getSupportList,
  createSupport,
  getMyBoards,
  getLikeBoards,
  getInviteCode,
  submitInviteCode,
  getInviteUsers,
  submitCouponNumber,
  submitGiftiShowNumber,
  getCouponList,
  getCouponListPartner,
  getPromotionNewYear,
  createPromotionNewYear,
  getPromotionNewYearNewList,
  getPromotionJeomsinUserInfo,
  createPromotionJeomsinUserInfo,
  getPromotionJeomsinElements,
  getPromotionJeomsinFortune,
};
