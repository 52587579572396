<template>
  <bottom-modal-layout class="is_moFail" :close="close">
    <template v-slot:header>
      <div class="bl_right is_moClose" @click="hide(null)"></div>
    </template>
    <template v-slot:main>
      <div class="bl_alert">
        <div class="bl_alert_ico is_moFail"></div>
        <div class="bl_alert_ttl">결제 실패</div>
        <div v-if="props" class="bl_alert_error">(에러코드 : {{ props }})</div>
        <div v-else class="bl_alert_error">(-)</div>
        <div class="bl_alert_txt">{{ content }}</div>
      </div>
    </template>
  </bottom-modal-layout>
</template>
<script>
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";
import { preventBackButton } from "@/util/webview";

export default {
  components: { BottomModalLayout },
  name: "PaymentFailModal",
  data() {
    return {
      resolve: () => {},
      content: null,
      props: null,
      close: false,
    };
  },
  methods: {
    show({ content, props, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.props = props;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = userAgent.search("android") > -1;
      const isIOS = !isAndroid && /iphone|ipad|ipod/i.test(userAgent);

      if (isAndroid) {
        this.$router.go(-2);
      }
      if (isIOS) {
        this.$router.go(-1);
      }
    },
  },
};
</script>