<template>
  <div class="ly_container">
    <div class="bl_input">
      <div class="cp_date">
        <div :class="range ? 'cp_period_obj' : 'cp_date_obj'">
          <div class="cp_obj_month">
            <div class="cp_month_inner">
              <div class="cp_month_btn is_moPrev" @click="prevMonth"></div>
              <div class="cp_month_txt">{{ header }}</div>
              <div class="cp_month_btn is_moNext" @click="nextMonth"></div>
            </div>
          </div>
          <div class="cp_obj_week">
            <ul class="el_reset cp_week_list">
              <li v-for="week in weekList" :key="week" class="cp_week_item">
                {{ week }}
              </li>
            </ul>
          </div>
          <div class="cp_obj_day">
            <ul class="el_reset cp_day_list">
              <template v-for="week in calendar">
                <li
                  v-for="day in week"
                  :key="day.date"
                  class="cp_day_item"
                  :class="{
                    is_moDisabled:
                      day.prevMonth || day.nextMonth || day.disabledDate,
                    is_moToday: day.date === today,
                    is_acStart: day.date === getStartDate,
                    is_acEnd: day.date === getEndDate,
                  }"
                  @click="selectDate(day)"
                >
                  <div
                    class="cp_day_obj"
                    :class="{
                      checked:
                        day.date === getStartDate || day.date === getEndDate,
                      range: isIncludeRange(day),
                    }"
                  >
                    <div class="cp_obj">
                      <div class="cp_obj_txt">
                        {{ day.originalDate.format("D") }}
                      </div>
                      <div class="cp_obj_desc"></div>
                      <div class="cp_obj_chk"></div>
                      <div class="cp_obj_chk2"></div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import dateMixin from "@/mixins/dateMixin";

dayjs.extend(isBetween);
export default {
  name: "NmDatePicker",
  mixins: [dateMixin],
  props: {
    value: {
      type: [String, Array, Object],
      default: "",
    },
    range: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: [Array],
      default: () => [],
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  created() {
    this.today = dayjs().format(this.format);
    this.current = dayjs();
    if (this.range) {
      this.startDate = Array.isArray(this.value) ? this.value[0] : null;
      this.endDate = Array.isArray(this.value) ? this.value[1] : null;
    } else {
      this.startDate = this.value || null;
    }
  },
  data() {
    return {
      current: null,
      weekList: ["일", "월", "화", "수", "목", "금", "토"],
      today: null,
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    header() {
      return this.current?.format("YYYY.MM");
    },
    calendar() {
      const startOfMonth = this.current.startOf("month");
      const endOfMonth = this.current.endOf("month");
      const startDate = startOfMonth.startOf("week");
      const endDate = endOfMonth.endOf("week");

      let currentDate = startDate;
      const calendar = [];

      while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
        const week = [];
        for (let i = 0; i < 7; i++) {
          week.push({
            originalDate: currentDate,
            date: currentDate.format(this.format).toString(),
            prevMonth: currentDate.isBefore(startOfMonth, "month"),
            nextMonth: currentDate.isAfter(endOfMonth, "month"),
            disabledDate:
              currentDate.isBefore(this.disabledDates[0].end, "day") ||
              currentDate.isAfter(this.disabledDates[1].start, "day"),
          });
          currentDate = currentDate.add(1, "day");
        }
        calendar.push(week);
      }

      return calendar;
    },
    getStartDate() {
      return this.getDateTime(this.startDate, "YYYY-MM-DD");
    },
    getEndDate() {
      return this.getDateTime(this.endDate, "YYYY-MM-DD");
    },
  },
  watch: {
    value(value) {
      if (this.range) {
        this.startDate = Array.isArray(value) ? value[0] : null;
        this.endDate = Array.isArray(value) ? value[1] : null;
      } else {
        this.startDate = value || null;
      }
    },
  },
  methods: {
    prevMonth() {
      this.current = this.current.subtract(1, "month");
    },
    nextMonth() {
      this.current = this.current.add(1, "month");
    },
    selectDate(day) {
      if (day.disabledDate) return;
      if (this.range) {
        // 가는 날 오는 날 모두 선택된 경우
        if (this.startDate && this.endDate) {
          this.startDate = day.originalDate;
          this.endDate = null;
          this.$emit("input", [this.startDate, this.endDate]);
          this.$emit("select", [this.startDate, this.endDate]);
          return;
        }

        // 가는 날 선택 안된 경우
        if (!this.startDate) {
          this.startDate = day.originalDate;
        } else {
          this.endDate = day.originalDate;
        }

        // 시작 날짜가 종료 날짜보다 미래일 경우 reverse
        if (this.endDate?.diff(this.startDate) < 0) {
          const tempStartDate = this.startDate;
          this.startDate = this.endDate;
          this.endDate = tempStartDate;
        }

        this.$emit("input", [this.startDate, this.endDate]);
        this.$emit("select", [this.startDate, this.endDate]);
      } else {
        if (this.startDate?.isSame(day.originalDate)) {
          this.startDate = null;
        } else {
          this.startDate = day.originalDate;
        }

        this.$emit("input", this.startDate);
        this.$emit("select", this.startDate);
      }
    },
    isIncludeRange(day) {
      if (this.startDate && this.endDate) {
        return day.originalDate.isBetween(this.startDate, this.endDate);
      } else {
        return false;
      }
    },
  },
};
</script>