<template>
  <div class="ly_body">
    <div class="ly_body_header">
      <div class="bl_input">
        <div class="bl_input_inner">
          <nm-search-input
            v-model="keyword"
            clear
            placeholder="골프장"
            autofocus
          ></nm-search-input>
        </div>
      </div>
    </div>
    <div class="ly_body_body" ref="scrollContainer">
      <div class="ly_container" :class="{ is_acOpen: isOpen }">
        <div class="bl_obj_header">
          <div class="bl_header_ttl">
            <div class="bl_ttl_ico"></div>
            <div class="bl_ttl_ttl">자주 찾는 호텔 리스트</div>
          </div>
          <div class="bl_header_arrow" @click="openList"></div>
        </div>
        <div class="bl_place bl_airport_item">
          <ul class="el_reset bl_place_list">
            <place-list-item
              v-for="place in placeList"
              :key="place.id"
              :item="place"
            >
              <template v-slot:button>
                <div class="bl_obj_btn" @click="selectPlace(place)">
                  {{ buttonTxt }}
                </div>
              </template>
            </place-list-item>
          </ul>
        </div>
        <div
          class="bl_place"
          :class="{
            is_ac: !filteredGolfClubList || filteredGolfClubList.length,
          }"
        >
          <ul class="el_reset bl_place_list" ref="scrollList">
            <place-list-item
              v-for="club in filteredGolfClubList"
              :key="club.ccode"
              :item="club"
            >
              <template v-slot:button>
                <div class="bl_obj_btn" @click="selectClub(club)">
                  {{ buttonTxt }}
                </div>
              </template>
            </place-list-item>
          </ul>
          <div class="no_result">
            검색된 내역이 없어요.<br />
            다시 검색 해주세요.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nearmeApi } from "@/api";
import { recentSearchClub } from "@/util/webview";
import Hangul from "hangul-js";
import PlaceListItem from "@/views/nearme/search/components/PlaceListItem.vue";
import NmSearchInput from "@/components/nearme/NmSearchInput.vue";

export default {
  name: "ClubList",
  components: { PlaceListItem, NmSearchInput },
  props: {
    route: {
      type: String,
      default: "departure",
    },
  },
  data() {
    return {
      keyword: "",
      clubList: null,
      searchParams: {},
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      isFetching: false,
      isOpen: true,
      placeList: null,
    };
  },
  async created() {
    await this.setData();
  },
  mounted() {
    this.addScrollEvent();
  },
  beforeDestroy() {
    this.removeScrollEvent();
  },
  computed: {
    buttonTxt() {
      return this.route === "departure" ? "출발" : "도착";
    },
    filteredGolfClubList() {
      const searchValue = this.keyword?.trim()?.toLowerCase();
      return this.clubList
        ?.filter((club) => {
          return (
            Hangul.search(club?.name.toLowerCase(), searchValue) > -1 ||
            Hangul.search(
              club?.name.replace(/ /g, "").toLowerCase(),
              searchValue
            ) > -1
          );
        })
        .slice(0, this.pageIndex * this.pageSize);
    },
  },
  watch: {
    keyword(value) {
      if (this.isOpen && value.trim()) {
        this.isOpen = false;
      }
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
    async setData() {
      if (this.keyword.trim()) {
        this.isOpen = false;
      }
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, total },
        } = await nearmeApi.getPartnerClubList({
          page: this.pageIndex,
          size: this.pageSize,
          partnershipCd: "5801",
        });
        
        const { data } = await nearmeApi.getHotelList({
          nationCd: "81",
          partnershipCd: "5801",
        });

        this.clubList = content.map((el) => {
          return {
            ccode: el.ccode,
            name: el.club_name,
            eng_name: encodeURI(el.club_name_eng),
            address: el.address,
            lat: el.club_lat,
            lng: el.club_lon,
          };
        });

        this.placeList = data.map((el) => {
          return {
            name: el.hotelName,
            eng_name: encodeURI(el.hotelNameEng),
            address: el.hotelAddress,
            lat: el.hotelLat,
            lng: el.hotelLon,
          };
        });

        this.clubList = this.clubList.concat(this.placeList);

        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    async scrollEvtHandler() {
      if (
        this.totalCount &&
        Math.ceil(this.totalCount / this.pageSize) <= this.pageIndex
      ) {
        return;
      }
      const isWindow = !this.$refs.scrollContainer;
      const scrollY = isWindow
        ? window.scrollY + window.innerHeight
        : this.$refs.scrollContainer.scrollTop +
          this.$refs.scrollContainer.offsetHeight;
      const listHeight = isWindow
        ? document.querySelector("body").scrollHeight
        : this.$refs.scrollList.offsetHeight;
      if (scrollY >= listHeight - 100) {
        this.pageIndex += 1;
      }
    },
    addScrollEvent() {
      if (!this.$refs.scrollContainer && !this.$refs.scrollList) {
        return;
      }
      if (!this.$refs.scrollContainer && this.$refs.scrollList) {
        window?.addEventListener("scroll", this.scrollEvtHandler);
        return;
      }
      this.$refs.scrollContainer?.addEventListener(
        "scroll",
        this.scrollEvtHandler
      );
    },
    removeScrollEvent() {
      window.removeEventListener("scroll", this.scrollEvtHandler);
      this.$refs.scrollContainer?.removeEventListener?.(
        "scroll",
        this.scrollEvtHandler
      );
    },
    async selectClub(club) {
      await recentSearchClub({
        ccodes: [club.ccode],
      });
      this.$emit("select", club);
    },
    selectPlace(place) {
      this.$emit("select", place, this.keyword);
    },
    openList() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
 <style scoped>
.bl_obj_header {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 1rem 2.4rem;
}
.bl_obj_header .bl_header_ttl {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
}
.bl_obj_header .bl_header_ttl .bl_ttl_ico::before {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAAAXNSR0IArs4c6QAABBxJREFUeF7tnD1oFEEUx/9vbwMWklRRBAurpBBbEYzERiFCIoKF2AgpFIJYWsQYTnOxtPID0ykYFAUxEQOCkGACwdYP8CCCIIgYlMSgkNudJ29zFzdn7vbrbj/iXHPN7OzNb//vP2/m7RxBf+oSIM2nPgENyEMhsQE6lucOM4dRZtyYHKaZrCg3NkB9Bb5DwFlmfF22sXs6T1YWIMUHaIQfEeGkBlRDFn0aUP2A0YA8DEUD8glImjGwBOAhK4z/VJhLs2HHbtLVHAUWAddXDdz+vYofaYOVOCAXMJsZi4px8dkw3UtLCpAmQOtMnBBkvFWMsRWF8SRVlUpAAOwyrZzkTSA8Tcqv0gpo0wgTWES4VbLw4BfwMQ5lZQqQm5qEoVK40Gy/yiygMiybgZWKXzUDVtYBuUXlzIKN9qutBGiDbzFQJOB+VL/asoCq/KqoFEZtE1NTg/QtSI71XwBy+dXCxBB1akA1CDBjbuIydWlAGwmIec9LVq5D7C8Ymf4XAEyXDAwF9R033y3nQcx4XMphIAqULQdIoEi1xDLxvlFgKpCyqqB1X1EK88/zVAxivEHaZgmQ4yuV5K+ZULIUYmvLB+CVZeNSXFCyAMgJIctGf1zbGrXCLlUhJomc5CvN9pVMeZBAIcILWVQmEUJesJJQUOK+4gUlUQ9y1+YP59mMY9s0CJDqtrErSL+8UONx6dKzh441IA0oitUBWkFaQVpB0QhoBUXjpz1IK0grKBoBraBo/LQHaQVpBUUjoBUUjZ/2IK0g/wrqvcrdhoFuZhwFob1koKvFxi33cajWHKadHgnvlMJc0u9DN7Xs03ON23MWegxCLwhHCGhz33DVwI5NAH0mwk5XO6cWlrYKR6Q9adl0bzMxyIyBqsFueCA+AbmvEVhPkqqmNqSq4ZxBNfG6Wi2bSTUEoPVubIUzSYZfKAWVPeYlgJwfZ4oCSPpnYGxiiM75uVej2wQGJH7TYuNNvZCq/pFRAcnZDVuhvxkvinsBDQyocnrZq+MQJu3Vpb1kYVvchcbggEZ4lggHvUbzDyCFgnMsHCguW9jbamLRj3+5+ylZ6Iy7fh8cUIE/ENARBFDFaLcbOC2GK985A3eD9CFtswEohILEQ2TaVoxJyZWIcMKvwWdOQccLPAzgStCnH7V9UjX9wCEmA+0Lp6LQjORsmGVhf9z+s7YSCvFxpnqF2aBeFOJWzhHyOA7ONXQtJksM6bDVxE2ZmcIM3M81MuNZFnqTUE7l94VSkFxceflJsmoinJeVup9B+2kjYOT4UpJLjMiAqgcqa7MWE6eYsQ/AoSCZdnmWc1bySmEmTf8vFFpBXkoQYIaBAzkDe8rQdrmu+QLCd9kHIuBT5e8p0vhKXtMAeQF0h6mftkm1SRRQUoMOcl8NyIPWH/sRI3adkQvTAAAAAElFTkSuQmCC")
    no-repeat center/contain;
  content: "";
  display: block;
  height: 2.4rem;
  width: 2.4rem;
}
.bl_obj_header .bl_header_ttl .bl_ttl_ttl {
  flex: 0 0 auto;
  font-size: 1.6rem;
  font-weight: 500;
  max-width: calc(100% - 6.2rem);
  padding-left: 0.5rem;
}
.bl_obj_header .bl_header_arrow {
  flex: 0 0 auto;
  padding-left: 1rem;
  transition: transform 0.1s ease-out;
}
.bl_obj_header .bl_header_arrow::before {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAVdJREFUaEPtmNENgzAMRJsVuiOMUFZoOwJDdgWqSCAFSLCduxRRub/U9r07iwDhdvFfuLj+mwOcnaAn4AmADvgKgQbC5Z4AbCHY4D8T6Pv+EY0Zx/ENGkQp77ruGUL45PTsEpjFv+bJw9kQs/ihpGcFsBG/uHcaxEZ8Vs8KoFAQC38OodWyWyFtIWW5C00sGrJ3IUsDNoh1dvE2am3EAKmZeXgO1DSsBamdJR5ktY0tIMgMESAKQQZIIGhvFUArCFR81KUGYEMwxJsBWBAs8VUAKARTfDVALQRbPARghWghHgbQQrQSTwGQIKZpuocQluf59FigPOGabqNHh9KBy7kyinhaAotCJQRNPB1AWKcmL0a0FUr3RPMqKD0jaa83AcgkQV2bFK4ZQAKR/RyidVj6X1MAaTjjugMwXER6eAKIe4xaT4DhItLDE0DcY9R6AgwXkR6XT+ALj6HQMfhXI7cAAAAASUVORK5CYII=")
    no-repeat center/contain;
  content: "";
  display: block;
  height: 1.6rem;
  width: 1.6rem;
  transform: rotateX(0deg);
}
.bl_place.bl_airport_item {
  border-bottom: 0.1rem solid #f0f0f0;
}
.ly_container.is_acOpen .bl_obj_header .bl_header_arrow {
  transform: rotateX(180deg);
}
.ly_container .bl_place.bl_airport_item {
  display: none;
}
.ly_container.is_acOpen .bl_place.bl_airport_item {
  display: block;
}
</style>