<template>
  <bottom-modal-layout class="is_moStop" :close="close" no-header>
    <template v-slot:main>
      <div class="bl_alert">
        <div class="bl_alert_ico is_moStop"></div>
        <div class="bl_alert_ttl">예약을 그만 하시겠어요?</div>
        <div class="bl_alert_txt">
          예약이 완료되지 않았습니다.<br />
          입력하신 예약 정보는 저장되지 않습니다.
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <footer class="ly_footer">
        <div class="bl_btn">
          <ul class="el_reset bl_btn_list">
            <li class="bl_btn_item">
              <!-- 비활성화(C) : is_acDisabled -->
              <nm-button @click="hide(null)">예약 계속하기</nm-button>
            </li>
            <li class="bl_btn_item">
              <!-- 비활성화(C) : is_acDisabled -->
              <nm-button class="is_moPrim" @click="hide({ cancel: true })"
                >예약 취소하기</nm-button
              >
            </li>
          </ul>
        </div>
      </footer>
    </template>
  </bottom-modal-layout>
</template>
<script>
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import { preventBackButton } from "@/util/webview";

export default {
  components: { BottomModalLayout, NmButton },
  name: "ReservationAbortModal",
  data() {
    return {
      resolve: () => {},
      content: null,
      close: false,
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
  },
};
</script>