var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"cmPost-item"},[_c('div',{staticClass:"cmPost-object"},[_c('div',{staticClass:"cmPost-profile"},[(_vm.isMagazine)?_c('div',{staticClass:"cmPost-profileImage"},[_c('div',{staticClass:"cmPost-profileImageObject admin"})]):_c('div',{staticClass:"cmPost-profileImage",on:{"click":_vm.onProfileClick}},[_c('div',{staticClass:"cmPost-profileImageObject",style:({
            'background-image': ("url('" + _vm.profileImgUrl + "')"),
          })})]),(_vm.isMagazine)?_c('div',{staticClass:"cmPost-profileName"},[_vm._v("APL골프")]):_c('div',{staticClass:"cmPost-profileName",on:{"click":_vm.onProfileClick}},[_c('div',[_vm._v(_vm._s(_vm.board.nickname || "닉네임없음"))]),(_vm.userGenderClass && _vm.board.birth)?_c('div',{staticClass:"cmPost-profileAge",class:_vm.userGenderClass},[_c('div',{staticClass:"cmPost-profileAgeObject"},[_vm._v(_vm._s(_vm.userAgeRange)+"대")])]):_vm._e()]),(_vm.location)?_c('div',{staticClass:"cmPost-profileLocation",on:{"click":function($event){_vm.logEvent('community_place_click', function () {
            _vm.newWebviewLayerV2({
              name: 'CommunityListBySearch',
              query: {
                keyword: _vm.locationKeyword,
                location: _vm.location,
                type: _vm.locationType,
              },
            });
          })}}},[_vm._v(" "+_vm._s(_vm.location)+" ")]):_vm._e(),_c('div',{staticClass:"cmPost-profileMore",on:{"click":function($event){return _vm.logEvent('community_more_click', _vm.onMoreClick)}}},[_vm._v(" 더보기 ")])]),(_vm.board && _vm.board.roundId)?_c('round-review',{attrs:{"board":_vm.board},on:{"showBoardDetail":_vm.showBoardDetail}}):_vm._e(),_c('div',{staticClass:"cmPost-content",on:{"click":function($event){return _vm.logEvent('community_board_click', _vm.showBoardDetail)}}},[(_vm.isPost && _vm.isMagazine)?_c('p',{staticClass:"cmPost-contentObject magazine",domProps:{"innerHTML":_vm._s(_vm.content)}}):(_vm.isMagazine)?_c('div',[_c('div',{staticClass:"cmPost-metaWrapper"},[_c('div',{staticClass:"cmPost-metaThumbnail"},[_c('div',{staticClass:"cmPost-metaItem",style:({
                background: ("url('" + (_vm.board.magazineCommunityImageUrl) + "') center center / cover"),
              })})]),_c('div',{staticClass:"cmPost-metaDescription"},[_c('p',{staticClass:"cmPost-metaTitle"},[_vm._v(_vm._s(_vm.board.magazineSubject))])])])]):(_vm.isPost)?_c('content-with-anchor',{staticClass:"cmPost-contentObject",attrs:{"content":_vm.originContent}}):(_vm.parsedContent)?_c('p',{staticClass:"cmPost-contentObject"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.parsedContent)}}),(_vm.showMore)?_c('span',{staticStyle:{"color":"#4f84ff","display":"inline-block"}},[_vm._v("...더보기")]):_vm._e()]):_c('p',{ref:"content",staticClass:"cmPost-contentObject",domProps:{"innerHTML":_vm._s(_vm.content)}})],1),(_vm.ogMetaData)?_c('open-graph-meta',{attrs:{"ogMetaData":_vm.ogMetaData}}):_vm._e(),(_vm.boardImageList.length)?_c('div',{staticClass:"cmPost-image"},[_c('div',{staticClass:"cmPost-imageWrapper",on:{"click":function($event){return _vm.logEvent('community_board_click')}}},[_c('div',{ref:"imageContainer",staticClass:"cmPost-imageList",class:_vm.layoutClass},[_vm._l((_vm.boardImageList),function(image,i){return _c('div',{key:image.originPath,staticClass:"cmPost-imageItem",on:{"click":function($event){return _vm.openPostImageModal(i)}}},[_c('img',{attrs:{"src":image.originPath,"alt":""}})])}),(_vm.originBoardImageList.length > 3)?_c('div',{staticClass:"cmPost-imageMore",on:{"click":function($event){return _vm.openPostImageModal(2)}}},[_c('div',[_vm._v(" "+_vm._s(_vm.originBoardImageList.length - _vm.boardImageList.length)+"장 더 보기 ")])]):_vm._e()],2)])]):_vm._e(),_c('div',{staticClass:"cmPost-hash"},[_c('ul',{staticClass:"cmPost-hashList"},_vm._l((_vm.tags),function(tag,i){return _c('li',{key:i,staticClass:"cmPost-hashItem",on:{"click":function($event){_vm.logEvent('community_hashtag_click', function () {
              _vm.onTagClick(tag);
            })}}},[_c('div',{staticClass:"cmPost-hashObject"},[_vm._v(_vm._s(tag))])])}),0)]),_c('div',{staticClass:"cmPost-feed"},[_c('div',{staticClass:"cmPost-feedUser"},[_c('ul',{staticClass:"cmPost-feedUserList"},[(!_vm.isAKGolf)?_c('li',{staticClass:"cmPost-feedUserItem is-comment",on:{"click":function($event){return _vm.logEvent('community_comment_click', _vm.showBoardDetail)}}},[_c('div',{staticClass:"cmPost-feedUserObject"},[_vm._v(_vm._s(_vm.commentCount))])]):_vm._e(),_c('li',{staticClass:"cmPost-feedUserItem is-like",class:{ 'is-stateOn': _vm.board.likeYn === 'Y' },on:{"click":function($event){return _vm.logEvent('community_like_click', _vm.toggleLike)}}},[_c('div',{staticClass:"cmPost-feedUserObject"},[_vm._v(_vm._s(_vm.likeCount))])]),_c('li',{staticClass:"cmPost-feedUserItem is-share",on:{"click":function($event){return _vm.logEvent('community_share_click', _vm.sharePost)}}},[_c('div',{staticClass:"cmPost-feedUserObject"})])])]),_c('div',{staticClass:"cmPost-feedWrite"},[_c('ul',{staticClass:"cmPost-feedWriteList"},[_c('li',{staticClass:"cmPost-feedWriteItem"},[_vm._v(_vm._s(_vm.boardCategory))]),_c('li',{staticClass:"cmPost-feedWriteItem"},[_vm._v(_vm._s(_vm.regDt))])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }