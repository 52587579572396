<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div class="bl_ttl">어디로 떠나시나요?</div>
          <!-- journey-form -->
          <journey-form v-model="computedJourneyType" @reverse="reverse">
            <template v-slot:departure-form>
              <div class="bl_place_obj">
                <div class="bl_obj_ico" :class="departureIconClass"></div>
                <input
                  v-model="departureName"
                  class="el_form bl_obj_input"
                  :placeholder="departurePlaceholderTxt"
                  type="text"
                  readonly
                  @click="goToDepartureSearch"
                />
              </div>
            </template>
            <template v-slot:arrival-form>
              <div class="bl_place_obj">
                <div class="bl_obj_ico" :class="arrivalIconClass"></div>
                <input
                  v-model="arrivalName"
                  class="el_form bl_obj_input"
                  :placeholder="arrivalPlaceholderTxt"
                  type="text"
                  readonly
                  @click="goToArrivalSearch"
                />
              </div>
            </template>
          </journey-form>
          <!-- 출발지/도착지 경로 -->
          <nm-route-map
            v-model="activeRouteMap"
            :origin="departureLocation"
            :destination="arrivalLocation"
            :reverse="isReversed"
          />

          <!-- 출발지 / 도착지 모두 입력 완료 시 -->
          <div v-if="activeRouteMap" class="ly_body_footer">
            <div class="bl_btn">
              <nm-button class="is_moPrim" @click="goToSelectSchedule"
                >다음</nm-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { nearmeApi } from "@/api";
import { isJson } from "@/util/string";
import { newWebviewLayerV2, closeWebviewLayer } from "@/util/webview";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import JourneyForm from "@/views/nearme/reservation/components/JourneyForm.vue";
import NmRouteMap from "@/components/modules/nearme/NmRouteMap.vue";
import dateMixin from "@/mixins/dateMixin";
import reservationMixin from "@/mixins/reservationMixin";

export default {
  name: "AirportReservation",
  components: {
    DefaultLayout,
    NmButton,
    JourneyForm,
    NmRouteMap,
  },
  mixins: [dateMixin, reservationMixin],
  data() {
    return {
      journeyType: "one-way",
      isReversed: false,
      departureName: "",
      departureAddress: "",
      departureLat: 0,
      departureLng: 0,
      arrivalName: "",
      arrivalAddress: "",
      arrivalLat: 0,
      arrivalLng: 0,
      searchName: null,
      terminalList: null,
      isBack: false,
    };
  },
  computed: {
    ...mapGetters(["userUid"]),
    computedJourneyType: {
      get() {
        return this.journeyType;
      },
      set(value) {
        this.setJourneyInfoType(value);
        this.journeyType = value;
      },
    },
    departureIconClass() {
      return this.isReversed ? "is_moLocation" : "is_moAirport";
    },
    departurePlaceholderTxt() {
      return this.isReversed ? "출발지" : "출발지 (공항)";
    },
    arrivalIconClass() {
      return this.isReversed ? "is_moAirport" : "is_moLocation";
    },
    arrivalPlaceholderTxt() {
      return this.isReversed ? "도착지 (공항)" : "도착지";
    },
    activeRouteMap() {
      return (
        this.departureName?.trim().length > 0 &&
        this.arrivalName?.trim().length > 0
      );
    },
    departureLocation() {
      return { lat: this.departureLat, lng: this.departureLng };
    },
    arrivalLocation() {
      return { lat: this.arrivalLat, lng: this.arrivalLng };
    },
  },
  async created() {
    try {
      await this.setData();
    } catch(e) {
      console.log(e);
    }

    window.preventWebviewFinish = this.preventWebviewFinish;

    window.getDataTopToBottomWebLayer = (data) => {
      let parsedData = data;
      const isJsonFormat = isJson(parsedData);
      if (isJsonFormat) {
        parsedData = JSON.parse(data);
      }
      if (parsedData.route === "departure") {
        this.setDepartureInfo({
          name: parsedData.name,
          address: parsedData.address,
          lat: parsedData.lat,
          lng: parsedData.lng,
          terminal_cd: parsedData.terminal_cd,
          search_name: parsedData.search_name,
        });
      } else {
        this.setArrivalInfo({
          name: parsedData.name,
          address: parsedData.address,
          lat: parsedData.lat,
          lng: parsedData.lng,
          terminal_cd: parsedData.terminal_cd,
          search_name: parsedData.search_name,
        });
      }

      this.setJourneyFormData();
    };
  },
  beforeDestroy() {
    delete window.preventWebviewFinish;
  },
  methods: {
    async setData() {
      await this.getAirportTerminalList();

      // 임시 저장 정보 조회
      const { data } = await nearmeApi.getReservationTemporary("Airport");
      const isObject = data instanceof Object;

      if (isObject) {
        if (
          confirm(
            "임시 저장된 예약 정보가 있습니다.\n예약 정보를 불러오시겠습니까?"
          )
        ) {
          this.setReservationInfo({
            reverse_yn: data.reserveYn,
            departure_name: data.departureName,
            departure_address: data.departureAddress,
            departure_lat: data.departureLat,
            departure_lng: data.departureLng,
            arrival_name: data.arriveName,
            arrival_lat: data.arriveLat,
            arrival_lng: data.arriveLng,
            arrival_address: data.arriveAddress,
            one_way_date: data.oneWayDate,
            one_way_time: data.oneWayTime,
            round_trip_date: data.roundTripDate,
            round_trip_time: data.roundTripTime,
            passengers: data.passengers,
            carrier_small: data.carrierSmall,
            carrier_medium: data.carrierMedium,
            carrier_large: data.carrierLarge,
            tour_bag: data.tourBag,
            stand_bag: data.standBag,
            half_bag: data.halfBag,
            other_with_in_100: data.otherWithin100,
            other_with_in_150: data.otherWithin150,
            other_with_in_200: data.otherWithin200,
            num_flight: data.numFlight,
            time_flight: data.timeFlight,
            name: data.name,
            eng_first_name: data.engFirstName,
            eng_last_name: data.engLastName,
            phone_number: data.phoneNumber,
            recommendation_path_id: data.recommendationPathId,
            terminal_cd: this.terminalList.find(
              (el) =>
                el.airportName === data.departureName ||
                el.airportName === data.arriveName
            )?.airportCd,
            search_name: data.searchName,
            email: data.email,
          });
        } else {
          this.resetJourneyInfo();
        }
      }
      this.setJourneyFormData();
    },
    async reverse() {
      const tempDepartureName = this.departureName;
      const tempDepartureLat = this.departureLat;
      const tempDepartureLng = this.departureLng;
      this.departureName = this.arrivalName;
      this.arrivalName = tempDepartureName;
      this.departureLat = this.arrivalLat;
      this.departureLng = this.arrivalLng;
      this.arrivalLat = tempDepartureLat;
      this.arrivalLng = tempDepartureLng;
      this.isReversed = !this.isReversed;

      this.setJourneyInfo({
        journey_type: this.computedJourneyType,
        reverse_yn: this.isReversed ? "Y" : "N",
        departure_name: this.departureName,
        arrival_name: this.arrivalName,
        departure_address: this.departureAddress,
        arrival_address: this.arrivalAddress,
        departure_lat: this.departureLat,
        departure_lng: this.departureLng,
        arrival_lat: this.arrivalLat,
        arrival_lng: this.arrivalLng,
        search_name: this.searchName,
      });

      this.setJourneyFormData();
    },
    async getAirportTerminalList() {
      const { data } = await nearmeApi.getAirportInfoList({
        partnershipCd: "5801",
      });
      this.terminalList = data;
    },
    async setJourneyFormData() {
      const {
        journey_type,
        reverse_yn,
        departure_name,
        departure_lat,
        departure_lng,
        arrival_name,
        arrival_lat,
        arrival_lng,
        search_name,
      } = this.getJourneyInfo();

      this.computedJourneyType = journey_type;
      this.isReversed = reverse_yn === "Y" ? true : false;
      this.departureName = departure_name;
      this.departureLat = departure_lat;
      this.departureLng = departure_lng;
      this.arrivalName = arrival_name;
      this.arrivalLat = arrival_lat;
      this.arrivalLng = arrival_lng;
      this.searchName = search_name;

      const params = {
        journey_type: this.computedJourneyType,
        reverse_yn: this.isReversed ? "Y" : "N",
        departure_name: this.arrivalName,
        departure_lat: this.arrivalLat,
        departure_lng: this.arrivalLng,
        arrival_name: this.departureName,
        arrival_lat: this.departureLat,
        arrival_lng: this.departureLng,
        search_name: this.searchName,
      };

      await this.$router.replace({ query: { ...params } }).catch(() => {});
    },
    preventWebviewFinish() {
      setTimeout(async () => {
        if (this.isBack) {
          return;
        } else {
          this.isBack = true;
          if (await this.reservationAbort()) {
            this.resetReservationInfo();
            closeWebviewLayer();
          } else {
            this.isBack = false;
          }
        }
      }, 100);
      return JSON.stringify({ isPreventFinishing: true });
    },
    goToDepartureSearch() {
      newWebviewLayerV2({
        name: "DepartureSearch",
        query: {
          service_type: "airport",
          search_type: this.isReversed ? "location" : "airport",
        },
        hideAppBar: true,
      });
    },
    goToArrivalSearch() {
      newWebviewLayerV2({
        name: "ArrivalSearch",
        query: {
          service_type: "airport",
          search_type: this.isReversed ? "airport" : "location",
        },
        hideAppBar: true,
      });
    },
    goToSelectSchedule() {
      const reservationInfo = this.getReservationInfo();
      this.setJourneyInfo({
        ...reservationInfo,
        reverse_yn: this.isReversed ? "Y" : "N",
      });

      this.$router.push({
        name: "SelectSchedule",
        query: {
          service_type: "airport",
          journey_type: this.journeyType,
        },
      });
    },
  },
};
</script>