<template>
  <component :is="modalComponent" ref="modal" />
</template>

<script>
export default {
  name: "ModalContainer",
  props: {
    modalComponent: {
      type: Object,
      required: true,
    },
  },
  methods: {
    show(payload) {
      this.$refs.modal?.show?.(payload);
    },
  },
};
</script>
