const state = () => ({
  activeSelect: false,
  selectState: null,
  activeEtcForm: false,
  etcFormState: null,
  activeLocationSearch: false,
  locationSearchState: null,
  activeFloatingButton: false,
  floatingButtonState: null,
});

const mutations = {};

const actions = {
  async activateSelect({ state, dispatch }, payload) {
    state.activeSelect = true;
    return new Promise((resolve) => {
      state.selectState = {
        ...payload,
        resolve: async (value) => {
          await dispatch("deactivateSelect");
          resolve(value);
        },
      };
    });
  },
  async deactivateSelect({ state }) {
    state.activeSelect = false;
    state.selectState = null;
  },
  async activateLocationSearch({ state, dispatch }, payload) {
    state.activeLocationSearch = true;
    return new Promise((resolve) => {
      state.locationSearchState = {
        ...payload,
        resolve: async (value) => {
          await dispatch("deactivateLocationSearch");
          resolve(value);
        },
      };
    });
  },
  async deactivateLocationSearch({ state }) {
    state.activeLocationSearch = false;
    state.locationSearchState = null;
  },
  async activateEtcForm({ state, dispatch }, payload) {
    state.activeEtcForm = true;
    return new Promise((resolve) => {
      state.etcFormState = {
        ...payload,
        resolve: async (value) => {
          await dispatch("deactivateEtcForm");
          resolve(value);
        },
      };
    });
  },
  async deactivateEtcForm({ state }) {
    state.activeEtcForm = false;
    state.etcFormState = null;
  },

  removePortone() {
    document.querySelectorAll(".imp-dialog").forEach(e => e.remove());
  },

  async activateFloatingButton({ state, dispatch }, payload) {
    state.activeFloatingButton = true;
    return new Promise((resolve) => {
      state.floatingButtonState = {
        ...payload,
        resolve: async (value) => {
          await dispatch("deactivateFloatingButton");
          resolve(value);
        },
      };
    });
  },
  async deactivateFloatingButton({ state }) {
    state.activeFloatingButton = false;
    state.floatingButtonState = null;
  },
};

const getters = {
  activeSelect: ({ activeSelect }) => activeSelect,
  selectState: ({ selectState }) => selectState,
  activeEtcForm: ({ activeEtcForm }) => activeEtcForm,
  etcFormState: ({ etcFormState }) => etcFormState,
  activeLocationSearch: ({ activeLocationSearch }) => activeLocationSearch,
  locationSearchState: ({ locationSearchState }) => locationSearchState,
  activeFloatingButton:  ({ activeFloatingButton }) => activeFloatingButton,
  floatingButtonState:  ({ floatingButtonState }) => floatingButtonState,
};

export default {
  namespace: true,
  state,
  mutations,
  actions,
  getters,
};
