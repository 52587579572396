import axios from "axios";

function sendErrorHandler(error) {
  if (navigator.userAgent.match(/iP(hone|od|ad)/)) {
    // ios
    error.action = "handleError";
    window.webkit.messageHandlers.aplGolf?.postMessage?.(JSON.stringify(error));
  } else if (
    navigator.userAgent.match(
      /Android|Mobile|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
    )
  ) {
    // android
    window.aplGolf?.handleError?.(JSON.stringify(error));
  }
}

export function setInterceptors(axiosService) {
  axiosService.interceptors.request.use((config) => {
    let header;
    try {
      const h = localStorage.getItem("h");
      header = JSON.parse(h);
    } catch (e) {
      console.log("잘못된 json포맷입니다.");
    }
    config.headers = {
      "Content-Type": "application/json;charset=UTF-8",
      ...header,
    };
    return config;
  });

  axiosService.interceptors.response.use(
    (response) => {
      const { data } = response;
      switch (data?.code) {
        case "200":
        case "5255":
        case "5256":
        case "5271":
        case "5272":
        case "5273":
        case "5274":
        case "5258":
          break;
        case "400":
        case "5901":
        case "5902":
        case "5231":
        case "5241":
        case "6301":
        case "6305":
        case "8100":
          alert(data?.message);
          return Promise.reject(data);
        case "5001":
        case "5014":
        case "5023":
        case "5325":
        case "5451":
        case "5452":
        case "5453":
        case "5454":
        case "5455":
        case "5456":
        case "5201":
        case "6101":
        case "6102":
        case "6103":
        case "6104":
        case "6105":
        case "6110":
        case "6112":
          return Promise.reject(data);
        default:
          sendErrorHandler(response.data);
          return Promise.reject(data);
      }
      return data;
    },
    async (error) => {
      if (axios.isCancel(error)) return Promise.reject(error);
      const { response } = error;
      if (!response) {
        return Promise.reject(error);
      }
      sendErrorHandler(response.data);
      return Promise.reject(error);
    }
  );

  return axiosService;
}
