<template>
  <transition name="fade" appear>
    <div class="ly_layer ly_modal">
      <div class="ly_layer_inner">
        <div class="ly_layer_inner2">
          <div class="ly_layer_inner3">
            <transition name="slide-up" appear>
              <div v-if="!close" class="ly_layer_inner4">
                <header v-if="!noHeader" class="ly_header">
                  <slot name="header" />
                </header>
                <main class="ly_body">
                  <slot name="main" />
                </main>
                <slot name="footer" />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "BottomModalLayout",
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active {
  animation: slide-up 0.3s;
}

.slide-up-leave-active {
  animation: slide-down 0.3s;
}

@keyframes slide-up {
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, 0%);
  }
}

@keyframes slide-down {
  0% {
    transform: translate(-50%, 0%);
  }

  100% {
    transform: translate(-50%, 100%);
  }
}
</style>