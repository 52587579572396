<template>
  <transition name="fade" appear>
    <div class="ly_layer ly_consult">
      <div class="ly_layer_inner">
        <div class="ly_layer_inner2">
          <div class="ly_layer_inner3">
            <div v-if="!close" class="ly_layer_inner4">
              <main class="ly_body">
                <div class="bl_consult">
                  <ul class="el_reset bl_consult_list">
                    <li class="bl_consult_item" @click="openKakaoChat">
                      <div class="bl_consult_obj">
                        <div class="bl_obj_txt">채팅상담</div>
                        <div class="bl_obj_btn is_moChat"></div>
                      </div>
                    </li>
                    <li class="bl_consult_item">
                      <a class="el_reset" href="tel:070-7720-3851">
                        <div class="bl_consult_obj">
                          <div class="bl_obj_txt">전화상담</div>
                          <div class="bl_obj_btn is_moPhone"></div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </main>
              <footer class="ly_footer">
                <div class="bl_close" @click="hide(null)"></div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { preventBackButton, handleOpenWeb } from "@/util/webview";
export default {
  name: "ConsultLayer",
  data() {
    return {
      resolve: () => {},
      close: false,
      content: "",
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
    async openKakaoChat() {
      await preventBackButton({
        isBackPrevent: false,
      });
      handleOpenWeb({url: "http://pf.kakao.com/_bxcjvb/chat"});
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>