<template>
  <modal-layout :close="close">
    <template v-slot:message>
      <span v-html="content" />
    </template>
    <template v-slot:footer>
      <nm-button class="is_moPrim" @click="hide(null)"> 확인 </nm-button>
    </template>
  </modal-layout>
</template>
<script>
import NmButton from "@/components/nearme/NmButton.vue";
import ModalLayout from "@/components/layout/nearme/ModalLayout.vue";
export default {
  components: { ModalLayout, NmButton },
  name: "AlertModal",
  data() {
    return {
      resolve: () => {},
      content: null,
      close: false,
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    hide(resolveValue) {
      this.close = true;
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
  },
};
</script>