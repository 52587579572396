<template>
  <community-none-result v-if="boardList && !boardList.length">
    <template #text> 게시물이 없어요!<br /> </template>
    <template #btn>
      <li @click="closeWebviewLayer" class="cmButton-item is-colorKey">
        <div class="cmButton-object">닫기</div>
      </li>
    </template>
  </community-none-result>
  <div class="lyPage" v-else ref="scrollList">
    <main class="lyBody">
      <section class="stPost">
        <div class="cmPost">
          <ul class="cmPost-list">
            <community-post
              v-for="board in boardList"
              :key="board.boardId"
              :board="board"
              @deletePost="onDeletePost"
              @openPostLayer="onOpenPostLayer"
            />
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import CommunityPost from "@/views/community/components/CommunityPost";
import { boardApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import CommunityNoneResult from "@/views/community/components/CommunityNoneResult";
import { closeWebviewLayer } from "@/util/webview";

export default {
  name: "CommunityList",
  mixins: [scrollMixin],
  components: {
    CommunityPost,
    CommunityNoneResult,
  },
  data() {
    return {
      lastId: undefined,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      keyword: "",
      type: "",
      boardList: null,
      lastOpenBoardId: null,
    };
  },
  async created() {
    const { keyword, location, type } = this.$route.query;
    this.keyword = keyword;
    if (type === "tag") {
      this.keyword = keyword.replace("#", "").replace("%23", "");
    }
    this.type = type;
    if (location) {
      document.title = `pin/${location}`;
    } else if (keyword) {
      document.title = keyword;
    }
    await this.setData();
    window.communityTabActivated = () => {
      this.updateBoard();
    };
  },
  mounted() {
    this.$refs.scrollContainer = window;
    this.addScrollEvent();
  },
  methods: {
    closeWebviewLayer,
    async setData() {
      if (this.isFetching) return;
      if (!this.searchApi) {
        return;
      }
      try {
        this.isFetching = true;
        const {
          data: { content, last },
        } = await this.searchApi({
          [this.paramKey]: this.keyword,
          orderBy: "date",
          lastId: this.lastId,
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.boardList = this.boardList || [];
        this.boardList = [...this.boardList, ...content];
        this.lastId = content[content.length - 1]?.boardId;
        this.last = last;
      } finally {
        this.isFetching = false;
      }
    },
    async onDeletePost() {
      this.pageIndex = 1;
      this.boardList = null;
      this.lastId = undefined;
      this.last = false;
      await this.setData();
    },
    onOpenPostLayer(boardId) {
      this.lastOpenBoardId = boardId;
    },
    async updateBoard() {
      if (!this.lastOpenBoardId) {
        return;
      }
      const {
        data: { content },
      } = await this.searchApi({
        [this.paramKey]: this.keyword,
        size: 1,
        orderBy: "date",
        page: this.pageIndex,
        lastId: this.lastOpenBoardId + 1,
      });
      const [board] = content;
      if (board) {
        this.boardList = this.boardList.map((item) =>
          item.boardId === board.boardId ? board : item
        );
      }
      this.lastOpenBoardId = null;
    },
  },
  computed: {
    searchApi() {
      switch (this.type) {
        case "club":
          return boardApi.getBoardsByClub;
        case "tag":
          return boardApi.getBoardsByHashtag;
        case "address":
          return boardApi.getBoards;
        default:
          return null;
      }
    },
    paramKey() {
      switch (this.type) {
        case "club":
          return "ccode";
        case "tag":
          return "hashtag";
        case "address":
          return "addrId";
        default:
          return null;
      }
    },
  },
};
</script>

<style>
#app {
  /*overflow-y: auto;*/
}
</style>
