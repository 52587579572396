<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="bl_info_wrapper">
              <div class="bl_info">
                <div class="bl_info_ttl">사주명식</div>
                <div class="bl_info_cont">
                  <div class="bl_info_cont_inner">
                    <!-- 나무(C): is_moTree / 불(C): is_moFire / 물(C): is_moWater / 흙(C): is_moSoil / 쇠(C): is_moIron -->
                    <div
                      class="bl_info_cont_fortune"
                      :class="elementType"
                    ></div>
                    <!-- 활성(C): is_ac -->
                    <div
                      class="bl_info_cont_help"
                      :class="{ is_ac: isActiveElementsHelp }"
                    >
                      <div class="bl_info_cont_help_obj">
                        <button class="el_form bl_info_cont_help_obj_btn">
                          <div class="bl_info_cont_help_obj_btn_txt">
                            나의 오행
                          </div>
                          <div
                            class="bl_info_cont_help_obj_btn_ico"
                            @click="openElementsHelp"
                          ></div>
                        </button>
                      </div>
                      <div class="bl_info_cont_help_lay">
                        <div class="bl_info_cont_help_lay_inner">
                          <div class="bl_info_cont_help_lay_inner2">
                            <div class="bl_info_cont_help_lay_inner3">
                              <div class="bl_info_cont_help_lay_inner4">
                                <div class="bl_info_cont_help_lay_header">
                                  <div class="bl_info_cont_help_lay_header_ttl">
                                    나의 오행이란?
                                  </div>
                                  <button
                                    class="el_form bl_info_cont_help_lay_header_close"
                                    @click="closeElementsHelp"
                                  ></button>
                                </div>
                                <div class="bl_info_cont_help_lay_body">
                                  <div class="bl_info_cont_help_lay_body_txt">
                                    사주에 있는 팔자 즉, 여덟 개의 오행 중에
                                  </div>
                                  <ul
                                    class="el_reset bl_info_cont_help_lay_body_fortuneList"
                                  >
                                    <li
                                      class="bl_info_cont_help_lay_body_fortuneList_item"
                                    >
                                      <div
                                        class="bl_info_cont_help_lay_body_fortune is_moTree"
                                      >
                                        木
                                      </div>
                                    </li>
                                    <li
                                      class="bl_info_cont_help_lay_body_fortuneList_item"
                                    >
                                      <div
                                        class="bl_info_cont_help_lay_body_fortune is_moFire"
                                      >
                                        火
                                      </div>
                                    </li>
                                    <li
                                      class="bl_info_cont_help_lay_body_fortuneList_item"
                                    >
                                      <div
                                        class="bl_info_cont_help_lay_body_fortune is_moWater"
                                      >
                                        水
                                      </div>
                                    </li>
                                    <li
                                      class="bl_info_cont_help_lay_body_fortuneList_item"
                                    >
                                      <div
                                        class="bl_info_cont_help_lay_body_fortune is_moSoil"
                                      >
                                        土
                                      </div>
                                    </li>
                                    <li
                                      class="bl_info_cont_help_lay_body_fortuneList_item"
                                    >
                                      <div
                                        class="bl_info_cont_help_lay_body_fortune is_moIron"
                                      >
                                        金
                                      </div>
                                    </li>
                                  </ul>
                                  <div class="bl_info_cont_help_lay_body_txt">
                                    나무, 불, 물, 흙, 쇠가 많은 사람으로
                                    구분하여 표시합니다.
                                  </div>
                                  <div class="bl_info_cont_help_lay_body_txt">
                                    점신에서의 ‘나의 오행‘은 태어난 날의 천간
                                    값을 기준으로 표기됩니다.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bl_info_cont_name">{{ userName }}</div>
                    <div class="bl_info_cont_date">
                      {{ formattedDate }} {{ calendarType }}
                    </div>
                    <div class="bl_info_cont_time">
                      {{ formattedTime }}
                    </div>
                    <div class="bl_info_cont_gender">{{ genderType }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bl_subject_wrapper">
              <div class="bl_subject">
                <div class="bl_subject_ttl">주제별 신년운세 확인하기</div>
                <ul class="el_reset bl_subject_contList">
                  <li
                    v-for="category in categories"
                    :key="category.title"
                    class="bl_subject_contList_item"
                  >
                    <div class="bl_subject_cont">
                      <button
                        @click="
                          $router.push({
                            name: 'JeomsinResult',
                            params: { title: category.title },
                            query: { userName, inviteCode, nickname },
                          })
                        "
                        class="el_form bl_subject_cont_btn"
                      >
                        <div
                          v-if="category.icon"
                          :class="category.icon"
                          class="bl_subject_cont_btn_ico"
                        ></div>
                        <div
                          class="bl_subject_cont_btn_txt"
                          v-html="category.text"
                        ></div>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    <ul class="el_reset ly_layerList">
      <li class="ly_layer is_moDimmer"></li>
      <li class="ly_layer is_moLoading">
        <div class="ly_layer_inner">
          <div class="ly_layer_inner2">
            <div class="ly_layer_inner3">
              <div class="ly_layer_inner4">
                <div class="ly_body">
                  <div class="bl_ad_wrapper">
                    <div class="bl_ad"></div>
                  </div>
                </div>
                <div class="ly_footer">
                  <div class="bl_load_wrapper">
                    <div class="bl_load">
                      <div class="bl_load_img">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 400 150"
                          width="400"
                          height="150"
                          preserveAspectRatio="xMidYMid meet"
                          style="
                            width: 100%;
                            height: 100%;
                            transform: translate3d(0px, 0px, 0px);
                          "
                        >
                          <defs>
                            <clipPath id="__lottie_element_2">
                              <rect width="400" height="150" x="0" y="0"></rect>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#__lottie_element_2)"></g>
                        </svg>
                      </div>
                      <div class="bl_load_txt">
                        홍길동님이 요청하신 사주 운세를<br />
                        분석하고 있어요! 잠시만 기다려주세요.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { introApi, userApi } from "@/api";
import dayjs from "dayjs";

export default {
  name: "JeomsinInfo",
  async created() {
    await this.setData();
    await this.getInviteCode();
    await this.getUserInfo();
  },
  data() {
    return {
      categories: [
        { title: "2023", text: "신년운세", icon: "is_moYear" },
        { title: "2023_2", text: "대운", icon: "is_moLarge" },
        { title: "2023_4", text: "재물운", icon: "is_moMoney" },
        { title: "2023_3", text: "총운", icon: "is_moTotal" },
        { title: "2023_6", text: "결혼운", icon: "is_moWedding" },
        { title: "2023_5", text: "애정운", icon: "is_moLove" },
        { title: "2023_8", text: "사업운", icon: "is_moBusiness" },
        { title: "2023_7", text: "직업운", icon: "is_moJob" },
        { title: "2023_10", text: "건강운", icon: "is_moHealth" },
        { title: "2023_9", text: "친구운", icon: "is_moFriend" },
        { title: "2022", text: "다시보는<br />2022년", icon: "" },
        { title: "2024", text: "미리보는<br />2024년", icon: "" },
      ],
      title: "",
      text: "",
      num: "",
      userName: "",
      birthDate: "",
      isSolar: "",
      birthHour: "",
      birthMinute: "",
      gender: "",
      isActiveElementsHelp: false,
      nickname: "",
      inviteCode: "",
    };
  },
  methods: {
    async setData() {
      const [elements, userInfo] = await Promise.all([
        userApi.getPromotionJeomsinElements(),
        userApi.getPromotionJeomsinUserInfo(),
      ]);
      this.title = elements?.data?.title;
      this.text = elements?.data?.text;
      this.num = elements?.data?.num;
      this.userName = userInfo?.data?.userName;
      this.birthDate = userInfo?.data?.birthDate;
      this.isSolar = userInfo?.data?.isSolar;
      this.birthHour = userInfo?.data?.birthHour;
      this.birthMinute = userInfo?.data?.birthMinute;
      this.gender = userInfo?.data?.gender;
    },
    async getInviteCode() {
      const {
        data: { myInviteCode },
      } = await userApi.getInviteCode();
      this.inviteCode = myInviteCode;
    },
    async getUserInfo() {
      const {
        data: { nickname },
      } = await introApi.getUserInfo();
      this.nickname = nickname;
    },
    openElementsHelp() {
      this.isActiveElementsHelp = true;
      document.querySelector("body").classList.add("is_acDimmer");
    },
    closeElementsHelp() {
      this.isActiveElementsHelp = false;
      document.querySelector("body").classList.remove("is_acDimmer");
    },
  },
  computed: {
    elementType() {
      switch (this.num) {
        case "0":
          return "is_moTree";
        case "1":
          return "is_moFire";
        case "2":
          return "is_moSoil";
        case "3":
          return "is_moIron";
        case "4":
          return "is_moWater";
        default:
          return "";
      }
    },
    formattedDate() {
      if (!this.birthDate) {
        return "";
      }
      return dayjs(this.birthDate).format("YYYY.MM.DD");
    },
    calendarType() {
      switch (this.isSolar) {
        case "S":
          return "양력";
        case "F":
          return "음력(평달)";
        case "L":
          return "음력(윤달)";
        default:
          return "";
      }
    },
    formattedTime() {
      if (!this.birthHour && !this.birthMinute) {
        return "태어난 시간 모름";
      }
      return dayjs()
        .set("hour", this.birthHour)
        .set("minute", this.birthMinute)
        .format("HH:mm");
    },
    genderType() {
      switch (this.gender) {
        case "M":
          return "남성";
        case "F":
          return "여성";
        default:
          return "";
      }
    },
  },
};
</script>
