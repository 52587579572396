<template>
  <li class="bl_place_item">
    <div class="bl_place_obj">
      <div class="bl_obj_cont" @click="click">
        <div class="bl_cont_ttl">{{ name }}</div>
        <div class="bl_cont_txt">
          {{ address }}
        </div>
      </div>
      <slot name="button" />
    </div>
  </li>
</template>
<script>
export default {
  name: "PlaceListItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    name() {
      return this.item?.name;
    },
    address() {
      return this.item?.address;
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  }
}
</script>
