<template>
  <div class="ly_page">
    <div class="ly_page_inner">
      <header class="ly_header">
        <div class="bl_tabUnit_wrapper">
          <ul class="bl_tabUnit">
            <li
              class="bl_tab"
              @click="
                logEvent(
                  isLayer
                    ? 'more_membership_join_coupon_menu_register'
                    : 'coupon_menu_register',
                  $router.replace({ name: 'Coupon', query: { isLayer } })
                )
              "
            >
              <div class="bl_tab_item">쿠폰 등록</div>
            </li>
            <li class="bl_tab">
              <div class="bl_tab_item is_ac">쿠폰 내역</div>
            </li>
          </ul>
        </div>
      </header>
      <main class="ly_body">
        <div
          v-show="couponList && couponList.length"
          class="bl_historyUnit_wrapper"
          ref="scrollList"
        >
          <ul class="bl_historyUnit">
            <li
              class="bl_history"
              v-for="coupon in couponList"
              :key="coupon.productCouponId"
              @click="onCouponClick(coupon)"
            >
              <!-- 활성(C): is_ac -->
              <div
                class="bl_history_item"
                :class="{
                  is_ac: coupon.status === '미사용',
                }"
              >
                <div class="bl_history_item_cont">
                  <div class="bl_history_item_cont_cont">
                    <div class="bl_history_item_cont_cont_ttl">
                      {{ coupon.couponName }}
                    </div>
                    <div class="bl_history_item_cont_cont_date">
                      {{ getCouponDate(coupon) }}
                    </div>
                  </div>
                </div>
                <div
                  class="bl_history_item_use"
                  v-html="getCouponStatus(coupon)"
                ></div>
              </div>
            </li>
          </ul>
        </div>
        <div v-show="couponList && !couponList.length" class="coupon-none">
          보유하신 쿠폰이 없습니다.
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dateMixin from "@/mixins/dateMixin";
import { setDataTopToBottomWebLayer, showToast } from "@/util/webview";

export default {
  name: "CouponList",
  data() {
    return {
      couponList: null,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
    };
  },
  async mounted() {
    await this.setData();
  },
  mixins: [scrollMixin, dateMixin],
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, total },
        } = await userApi.getCouponList({
          page: this.pageIndex,
          size: this.pageSize,
          useableYn: this.isLayer ? "Y" : undefined,
        });
        this.couponList = this.couponList || [];
        this.couponList = [...this.couponList, ...content];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    getCouponStatus(coupon) {
      switch (coupon.status) {
        case "미사용":
          return "미사용";
        case "사용완료":
          return `사용<br/>완료`;
        case "기간만료":
          return `기간<br/>만료`;
        default:
          return "";
      }
    },
    getCouponDate(coupon) {
      if (coupon.usedDt) {
        return `${this.convertDate(coupon.usedDt)} 사용`;
      } else if (coupon.couponEndDt < dayjs().unix()) {
        return `${this.convertDate(coupon.couponEndDt)} 만료`;
      }
      return `${this.convertDate(coupon.couponEndDt)} 까지`;
    },
    onCouponClick(coupon) {
      if (!this.isLayer || coupon.status !== "미사용") {
        return;
      }
      this.logEvent("more_membership_join_coupon_select");
      showToast({ message: "쿠폰을 적용했습니다." });
      setDataTopToBottomWebLayer({
        ...coupon,
        couponItemPeriodUnit: coupon.productPeriodUnit,
      });
    },
  },
  computed: {
    isLayer() {
      return this.$route.query?.isLayer;
    },
  },
};
</script>

<style scoped>
.coupon-none {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #acacac;
  font: normal normal 400 14px / 1.71 "Noto Sans KR";
}
.id_coupon .ly_body .bl_history_item_cont_cont_ttl {
  word-break: break-all;
}
</style>
