<template>
  <div class="ly_wrapper" ref="scrollList">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <header class="ly_header">
            <div class="ly_container">
              <div class="bl_tabList_wrapper">
                <ul class="el_reset bl_tabList">
                  <li
                    class="bl_tabList_item"
                    @click="
                      logEvent('more_membership_detail', () =>
                        $router.replace({ name: 'VoicecaddieMembershipInfo' })
                      )
                    "
                  >
                    <!-- 활성(C): is_ac -->
                    <div class="bl_tab">멤버십</div>
                  </li>
                  <li class="bl_tabList_item">
                    <div class="bl_tab is_ac">결제내역</div>
                  </li>
                </ul>
              </div>
            </div>
          </header>
          <main class="ly_body">
            <div class="ly_container">
              <div class="bl_historyList_wrapper">
                <ul class="el_reset bl_historyList">
                  <li
                    class="bl_historyList_item"
                    v-for="payment in paymentList"
                    :key="payment.orderId"
                  >
                    <div class="bl_history">
                      <div class="bl_history_ttl">{{ payment.orderName }}</div>
                      <div class="bl_history_date">
                        {{ convertDate(payment.paymentDt) }}
                      </div>
                      <div class="bl_history_pay">
                        {{ payment.amount | comma }}원
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { membershipApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dayjs from "dayjs";
import { comma } from "@/util/string";

export default {
  name: "MembershipPayment",
  mixins: [scrollMixin],
  async created() {
    this.searchParams = {
      size: this.pageSize,
    };
    await this.setData();
  },
  data() {
    return {
      searchParams: {},
      paymentList: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
    };
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { content, total },
        } = await membershipApi.getMembershipPayment({
          ...this.searchParams,
          page: this.pageIndex,
        });
        this.paymentList = content;
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    convertDate(timeStamp) {
      if (!timeStamp) {
        return "";
      }
      return dayjs.unix(timeStamp).format("YYYY.MM.DD HH:mm");
    },
  },
  filters: {
    comma,
  },
};
</script>
