var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ly_wrapper"},[_c('div',{staticClass:"ly_page"},[_c('div',{staticClass:"ly_page_inner"},[_c('div',{staticClass:"ly_page_inner2"},[_c('header',{staticClass:"ly_header"},[_c('div',{staticClass:"ly_container"},[_c('div',{staticClass:"bl_naviList_wrapper"},[_c('div',{staticClass:"bl_naviList_outer"},[_c('ul',{staticClass:"el_reset bl_naviList"},_vm._l((_vm.categories),function(category){return _c('li',{key:category.categoryCd,staticClass:"bl_naviList_item"},[_c('div',{staticClass:"bl_navi"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.categoryCd),expression:"categoryCd"}],staticClass:"el_form bl_navi_inp",attrs:{"type":"radio"},domProps:{"value":category.categoryCd,"checked":_vm._q(_vm.categoryCd,category.categoryCd)},on:{"change":[function($event){_vm.categoryCd=category.categoryCd},function($event){return _vm.selectCategoryCd(_vm.categoryCd)}]}}),_c('div',{staticClass:"bl_navi_chk",class:("is_mo" + (category.categoryCd))}),_c('div',{staticClass:"bl_navi_txt"},[_vm._v(" "+_vm._s(_vm.getCategoryName(category))+" ")])])])}),0)])])])]),_c('main',{staticClass:"ly_body"},[_c('div',{staticClass:"ly_container"},[_c('div',{staticClass:"bl_productList_wrapper"},[_c('ul',{staticClass:"el_reset bl_productList"},_vm._l((_vm.productList),function(product){return _c('li',{key:product.productId,staticClass:"bl_productList_item",on:{"click":function($event){return _vm.$router.push({
                      name: 'AKGolfProductView',
                      params: {
                        categoryCd: _vm.categoryCd,
                        productId: product.productId,
                      },
                    })}}},[_c('div',{staticClass:"bl_product"},[_c('div',{staticClass:"bl_product_box"},[_c('div',{staticClass:"bl_product_box_img",style:({
                          'background-image': ("url('" + (product.thumbImgUrl) + "')"),
                        })}),_c('div',{staticClass:"bl_product_box_rank"},[_vm._v(" "+_vm._s(product.rank)+" ")])]),_c('div',{staticClass:"bl_product_label"},[_vm._v(_vm._s(product.brand))]),_c('div',{staticClass:"bl_product_ttl"},[_vm._v(" "+_vm._s(product.productName)+" ")]),_c('div',{staticClass:"bl_product_info"},[_c('div',{staticClass:"bl_product_info_sale"},[_c('div',{staticClass:"bl_product_info_sale_num"},[_vm._v(" "+_vm._s(product.discountRatio)+" ")]),_c('div',{staticClass:"bl_product_info_sale_unit"},[_vm._v("%")])]),_c('div',{staticClass:"bl_product_info_price"},[_c('div',{staticClass:"bl_product_info_price_num"},[_vm._v(" "+_vm._s(_vm._f("comma")(product.price))+" ")]),_c('div',{staticClass:"bl_product_info_price_unit"},[_vm._v("원")])])])])])}),0)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }