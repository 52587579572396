import router from "@/router";
const jsInterface = getJsInterface();

function getJsInterface() {
  if (navigator.userAgent.match(/iP(hone|od|ad)/)) {
    return (action, param = {}) => {
      window.webkit.messageHandlers.aplGolf?.postMessage?.(
        JSON.stringify({
          action,
          data: typeof param === "string" ? param : JSON.stringify(param),
        })
      );
    };
  } else if (
    navigator.userAgent.match(
      /Android|Mobile|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/
    )
  ) {
    // android
    return (action, param = {}) => {
      window.aplGolf?.[action]?.(JSON.stringify({ data: param }));
    };
  }
}

// 띄워져있는 레이어 닫기
export async function closeWebviewLayer({ toCommunity } = {}) {
  jsInterface("closeWebLayer", { toCommunity });
}

// 커뮤니티 게시글등록,수정시 장소 선택
export async function setCommunityBoardPlace({
  addrId,
  address,
  ccode,
  clubName,
}) {
  jsInterface("setCommunityBoardPlace", { addrId, address, ccode, clubName });
}

// 커뮤니티 게시글등록,수정시 등록버튼 활성화
export async function passRequirements({ isPass }) {
  jsInterface("passRequirements", { isPass });
}

// 커뮤니티 게시글 공유
export async function sharedPost({ bridgeUrl, url }) {
  jsInterface("sharedPost", {
    url: `${bridgeUrl}?url=${url}`,
  });
}

// 네이티브 토스트팝업 호출
export async function showToast({ message }) {
  jsInterface("showToast", { message });
}

// 키보드 노출
export async function keyboardUp() {
  jsInterface("keyboardUp", {});
}

// 키보드 닫기
export async function keyboardDown() {
  jsInterface("keyboardDown", {});
}

// 바텀시트가 확성화 되어 있을때 스크롤을 막기위한 함수
export async function isBottomSheetShow({ isShow }) {
  jsInterface("isBottomSheetShow", { isShow });
}

// 이미지 저장
export async function saveImage({ imageUrl }) {
  jsInterface("saveImage", { imageUrl });
}

// 텍스트 복사
export async function saveText({ textStr }) {
  jsInterface("saveText", { textStr });
}

// 앱내에 브라우저 열기
export async function handleOpenWeb({ url }) {
  jsInterface("handleOpenWeb", { url });
}

// 닉네임 변경 앱화면 호출
export async function showMyProfileInfo() {
  jsInterface("showMyProfileInfo", {});
}

// 본인인증 앱화면 호출
export async function showMyInfo() {
  jsInterface("showMyInfo", {});
}

// 해당 웹뷰 호출
export async function newWebviewLayerV2({
  path,
  name,
  query,
  params,
  hideAppBar = false,
}) {
  const { href } = path
    ? router.resolve(path)
    : router.resolve({ name, query, params });
  const url = decodeURI(href);
  const { pathname, search } = location;
  if (pathname + search === url) {
    // 같은 주소
    return;
  }

  jsInterface("newWebLayerV2", {
    url,
    hideAppBar,
  });
}

// 네이티브쪽 포인트 갱신 호출
export async function pointAccumulationComplete() {
  jsInterface("pointAccumulationComplete", {});
}

// 이용권 앱화면 호출
export async function showMyTicket() {
  jsInterface("showMyTicket", {});
}

// 포인트충전 앱화면 호출
export async function showPointCharging() {
  jsInterface("showPointCharging", {});
}

// 커스텀 alert 호출
export async function showJsAlert({
  id = "",
  title = "",
  msg = "",
  positiveText = "",
}) {
  jsInterface("showJsAlert", { id, title, msg, positiveText });
}

// 커스텀 confirm 호출
export async function showJsConfirm({
  id = "",
  title = "",
  msg = "",
  positiveText = "",
  negativeText = "",
}) {
  jsInterface("showJsConfirm", {
    id,
    title,
    msg,
    positiveText,
    negativeText,
  });
}

// 라운드리뷰 수정 앱화면 호출
export async function showRoundReviewEdit({ roundId }) {
  jsInterface("showRoundReviewEdit", {
    roundId,
  });
}

// 골프장 코스정보 앱화면 호출
export async function showGolfCourseDetail({ ccode }) {
  jsInterface("showGolfCourseDetail", {
    ccode,
  });
}

// 골프장 정보 골프장 리스트 네이티브에 업데이트
export async function updateClubList({ clubList }) {
  jsInterface("updateClubList", {
    clubList,
  });
}

export async function setDataTopToBottomWebLayer(value) {
  jsInterface("setDataTopToBottomWebLayer", JSON.stringify(value));
}

// 이용권 내역 앱화면 호출
export async function showUsageHistoryTicket() {
  jsInterface("showUsageHistoryTicket", {});
}

// [니어미] 뒤로가기 버튼 활성/비활성 시 해당 함수 호출
// true : 뒤로가기 방지 
// false : 뒤로가기 활성
export async function preventBackButton({ isBackPrevent }) {
  jsInterface("preventBackButton", {
    isBackPrevent,
  });
}

// [니어미] 골프장 픽업 예약 시 출발지 또는 도착지로 선택한 골프장 ccode 전달
export async function recentSearchClub({ ccodes }) {
  jsInterface("recentSearchClub", {
    ccodes,
  });
}

// [니어미] 결제화면에서 결제 모듈 활성/비활성 시 호출
// true : 뒤로가기 버튼 숨기기
// false : 뒤로가기 버튼 보이기
export async function hideBackButton({ isHide }) {
  jsInterface("hideBackButton", {
    isHide,
  });
}