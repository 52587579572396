export default {
  methods: {
    getReservationInfo() {
      const reservationInfo = localStorage.getItem("reservation-info") || null;
      if (reservationInfo) {
        return JSON.parse(localStorage.getItem("reservation-info"));
      } else {
        return {};
      }
    },
    resetReservationInfo() {
      localStorage.setItem("reservation-info", JSON.stringify({}));
    },
    setReservationInfo({
      reverse_yn,
      name,
      eng_first_name,
      eng_last_name,
      phone_number,
      one_way_date,
      one_way_time,
      round_trip_date,
      round_trip_time,
      departure_name,
      departure_eng_name,
      departure_address,
      departure_lat,
      departure_lng,
      arrival_name,
      arrival_eng_name,
      arrival_address,
      arrival_lat,
      arrival_lng,
      passengers,
      carrier_small,
      carrier_medium,
      carrier_large,
      half_bag,
      tour_bag,
      stand_bag,
      other_with_in_100,
      other_with_in_150,
      other_with_in_200,
      recommendation_path_id,
      terminal_cd,
      search_name,
      email,
    }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.journey_type =
        round_trip_date === null ? "one-way" : "round-trip";
      reservationInfo.reverse_yn = reverse_yn || "N";
      reservationInfo.departure_name = departure_name;
      reservationInfo.departure_eng_name = departure_eng_name || null;
      reservationInfo.arrival_name = arrival_name;
      reservationInfo.arrival_eng_name = arrival_eng_name || null;
      reservationInfo.departure_address = departure_address;
      reservationInfo.arrival_address = arrival_address;
      reservationInfo.departure_lat = departure_lat;
      reservationInfo.departure_lng = departure_lng;
      reservationInfo.arrival_lat = arrival_lat;
      reservationInfo.arrival_lng = arrival_lng;
      reservationInfo.arrival_lng = arrival_lng;
      reservationInfo.recommendation_path_id = recommendation_path_id;
      reservationInfo.one_way_date = one_way_date;
      reservationInfo.one_way_time = one_way_time;
      reservationInfo.round_trip_date = round_trip_date || null;
      reservationInfo.round_trip_time = round_trip_time || null;
      reservationInfo.passengers = Number(passengers) || 1;
      reservationInfo.carrier_small = Number(carrier_small) || 0;
      reservationInfo.carrier_medium = Number(carrier_medium) || 0;
      reservationInfo.carrier_large = Number(carrier_large) || 0;
      reservationInfo.half_bag = Number(half_bag) || 0;
      reservationInfo.tour_bag = Number(tour_bag) || 0;
      reservationInfo.stand_bag = Number(stand_bag) || 0;
      reservationInfo.other_with_in_100 = Number(other_with_in_100) || 0;
      reservationInfo.other_with_in_150 = Number(other_with_in_150) || 0;
      reservationInfo.other_with_in_200 = Number(other_with_in_200) || 0;
      reservationInfo.name = name;
      reservationInfo.eng_first_name = eng_first_name;
      reservationInfo.eng_last_name = eng_last_name;
      reservationInfo.phone_number = phone_number;
      if (terminal_cd) {
        reservationInfo.terminal_cd = terminal_cd;
      }
      reservationInfo.search_name = search_name;
      reservationInfo.email = email;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    setJourneyInfoType(journey_type) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.journey_type = journey_type;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    setJourneyInfo({
      journey_type,
      reverse_yn,
      departure_name,
      departure_eng_name,
      arrival_name,
      arrival_eng_name,
      departure_address,
      arrival_address,
      departure_lat,
      departure_lng,
      arrival_lat,
      arrival_lng,
      recommendation_path_id,
      terminal_cd,
      search_name,
    }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.journey_type = journey_type;
      reservationInfo.reverse_yn = reverse_yn;
      reservationInfo.departure_name = departure_name;
      reservationInfo.departure_eng_name = departure_eng_name;
      reservationInfo.arrival_name = arrival_name;
      reservationInfo.arrival_eng_name = arrival_eng_name;
      reservationInfo.departure_address = departure_address;
      reservationInfo.arrival_address = arrival_address;
      reservationInfo.departure_lat = departure_lat;
      reservationInfo.departure_lng = departure_lng;
      reservationInfo.arrival_lat = arrival_lat;
      reservationInfo.arrival_lng = arrival_lng;
      reservationInfo.arrival_lng = arrival_lng;
      reservationInfo.recommendation_path_id = recommendation_path_id || null;
      if (terminal_cd) {
        reservationInfo.terminal_cd = terminal_cd;
      }
      reservationInfo.search_name = search_name;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    setDepartureInfo({
      name,
      eng_name,
      address,
      lat,
      lng,
      terminal_cd,
      search_name,
    }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.departure_name = name;
      reservationInfo.departure_eng_name = eng_name;
      reservationInfo.departure_address = address;
      reservationInfo.departure_lat = lat;
      reservationInfo.departure_lng = lng;
      if (terminal_cd) {
        reservationInfo.terminal_cd = terminal_cd;
      }
      if (search_name) {
        reservationInfo.search_name = search_name;
      }
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    setArrivalInfo({
      name,
      eng_name,
      address,
      lat,
      lng,
      terminal_cd,
      search_name,
    }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.arrival_name = name;
      reservationInfo.arrival_eng_name = eng_name;
      reservationInfo.arrival_address = address;
      reservationInfo.arrival_lat = lat;
      reservationInfo.arrival_lng = lng;
      if (terminal_cd) {
        reservationInfo.terminal_cd = terminal_cd;
      }
      if (search_name) {
        reservationInfo.search_name = search_name;
      }
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    resetJourneyInfo() {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.journey_type = "one-way";
      reservationInfo.reverse_yn = "N";
      reservationInfo.departure_name = "";
      reservationInfo.departure_eng_name = "";
      reservationInfo.departure_address = "";
      reservationInfo.arrival_name = "";
      reservationInfo.arrival_eng_name = "";
      reservationInfo.arrival_address = "";
      reservationInfo.departure_lat = "";
      reservationInfo.departure_lng = "";
      reservationInfo.arrival_lat = "";
      reservationInfo.arrival_lng = "";
      reservationInfo.recommendation_path_id = null;
      reservationInfo.terminal_cd = null;
      reservationInfo.search_name = null;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    getJourneyInfo() {
      const reservationInfo = this.getReservationInfo();
      return {
        journey_type: reservationInfo.journey_type || "one-way",
        reverse_yn: reservationInfo.reverse_yn || "N",
        departure_name: reservationInfo.departure_name || "",
        departure_eng_name: reservationInfo.departure_eng_name || "",
        departure_address: reservationInfo.departure_address || "",
        departure_lat: reservationInfo.departure_lat || "",
        departure_lng: reservationInfo.departure_lng || "",
        arrival_name: reservationInfo.arrival_name || "",
        arrival_eng_name: reservationInfo.arrival_eng_name || "",
        arrival_address: reservationInfo.arrival_address || "",
        arrival_lat: reservationInfo.arrival_lat || "",
        arrival_lng: reservationInfo.arrival_lng || "",
        recommendation_path_id: reservationInfo.recommendation_path_id || null,
        terminal_cd: reservationInfo.terminal_cd,
        search_name: reservationInfo.search_name,
      };
    },
    hasJourneyInfo() {
      const required = [
        "journey_type",
        "reverse_yn",
        "departure_name",
        "departure_lat",
        "departure_lng",
        "arrival_name",
        "arrival_lat",
        "arrival_lng",
      ];
      const reservationInfo = this.getReservationInfo();
      return required.every((x) => x in reservationInfo);
    },
    getScheduleInfo() {
      const reservationInfo = this.getReservationInfo();
      return {
        one_way_date: reservationInfo.one_way_date || null,
        one_way_time: reservationInfo.one_way_time || null,
        round_trip_date: reservationInfo.round_trip_date || null,
        round_trip_time: reservationInfo.round_trip_time || null,
      };
    },
    setScheduleInfo({
      one_way_date,
      one_way_time,
      round_trip_date,
      round_trip_time,
    }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.one_way_date = one_way_date;
      reservationInfo.one_way_time = one_way_time;
      reservationInfo.round_trip_date = round_trip_date || null;
      reservationInfo.round_trip_time = round_trip_time || null;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    resetScheduleInfo() {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.one_way_date = null;
      reservationInfo.one_way_time = null;
      reservationInfo.round_trip_date = null;
      reservationInfo.round_trip_time = null;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    hasScheduleInfo() {
      const required = [
        "one_way_date",
        "one_way_time",
        "round_trip_date",
        "round_trip_time",
      ];
      const reservationInfo = this.getReservationInfo();
      return required.every((x) => x in reservationInfo);
    },
    setBoardingInfo({
      passengers,
      carrier_small,
      carrier_medium,
      carrier_large,
      half_bag,
      tour_bag,
      stand_bag,
      other_with_in_100,
      other_with_in_150,
      other_with_in_200,
    }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.passengers = passengers;
      reservationInfo.carrier_small = carrier_small;
      reservationInfo.carrier_medium = carrier_medium;
      reservationInfo.carrier_large = carrier_large;
      reservationInfo.half_bag = half_bag;
      reservationInfo.tour_bag = tour_bag;
      reservationInfo.stand_bag = stand_bag;
      reservationInfo.other_with_in_100 = other_with_in_100;
      reservationInfo.other_with_in_150 = other_with_in_150;
      reservationInfo.other_with_in_200 = other_with_in_200;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    resetBoardingInfo() {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.passengers = 1;
      reservationInfo.carrier_small = 0;
      reservationInfo.carrier_medium = 0;
      reservationInfo.carrier_large = 0;
      reservationInfo.half_bag = 0;
      reservationInfo.tour_bag = 0;
      reservationInfo.stand_bag = 0;
      reservationInfo.other_with_in_100 = 0;
      reservationInfo.other_with_in_150 = 0;
      reservationInfo.other_with_in_200 = 0;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    getBoardingInfo() {
      const reservationInfo = this.getReservationInfo();
      return {
        passengers: reservationInfo.passengers || 1,
        carrier_small: reservationInfo.carrier_small || 0,
        carrier_medium: reservationInfo.carrier_medium || 0,
        carrier_large: reservationInfo.carrier_large || 0,
        half_bag: Number(reservationInfo.half_bag) || 0,
        tour_bag: Number(reservationInfo.tour_bag) || 0,
        stand_bag: Number(reservationInfo.stand_bag) || 0,
        other_with_in_100: Number(reservationInfo.other_with_in_100) || 0,
        other_with_in_150: Number(reservationInfo.other_with_in_150) || 0,
        other_with_in_200: Number(reservationInfo.other_with_in_200) || 0,
      };
    },
    getLuggageTotalCount() {
      const reservationInfo = this.getReservationInfo();
      const carrierTotalCount =
        (reservationInfo.carrier_small || 0) +
        (reservationInfo.carrier_medium || 0) +
        (reservationInfo.carrier_large || 0);

      const clubBagTotal =
        (reservationInfo.half_bag || 0) +
        (reservationInfo.tour_bag || 0) +
        (reservationInfo.stand_bag || 0);

      const otherTotalCount =
        (reservationInfo.other_with_in_100 || 0) +
        (reservationInfo.other_with_in_150 || 0) +
        (reservationInfo.other_with_in_200 || 0);

      return carrierTotalCount + clubBagTotal + otherTotalCount;
    },
    hasBoardingInfo() {
      const required = [
        "passengers",
        "carrier_small",
        "carrier_medium",
        "carrier_large",
        "half_bag",
        "tour_bag",
        "stand_bag",
        "other_with_in_100",
        "other_with_in_150",
        "other_with_in_200",
      ];
      const reservationInfo = this.getReservationInfo();
      return required.every((x) => x in reservationInfo);
    },
    setBookerInfo({ name, eng_first_name, eng_last_name, phone_number, email }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.name = name;
      reservationInfo.eng_first_name = eng_first_name;
      reservationInfo.eng_last_name = eng_last_name;
      reservationInfo.phone_number = phone_number;
      reservationInfo.email = email;
      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    resetBookerInfo() {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.name = "";
      reservationInfo.eng_first_name = "";
      reservationInfo.eng_last_name = "";
      reservationInfo.phone_number = "";
      reservationInfo.email = "";

      localStorage.setItem("reservation-info", JSON.stringify(reservationInfo));
    },
    hasBookerInfo() {
      const required = [
        "name",
        "eng_first_name",
        "eng_last_name",
        "phone_number",
        "email",
      ];
      const reservationInfo = this.getReservationInfo();
      return required.every((x) => x in reservationInfo);
    },
    getBookerInfo() {
      const reservationInfo = this.getReservationInfo();
      return {
        name: reservationInfo?.name || "",
        eng_first_name: reservationInfo?.eng_first_name || "",
        eng_last_name: reservationInfo?.eng_last_name || "",
        phone_number: reservationInfo?.phone_number || "",
        email: reservationInfo?.email || "",
      };
    },
    setFlightInfo({ time_flight, num_flight, date_flight }) {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.time_flight = time_flight;
      reservationInfo.num_flight = num_flight;
      reservationInfo.date_flight = date_flight;
      localStorage.setItem(
        "reservation-info",
        JSON.stringify(reservationInfo)
      );
    },
    resetFlightInfo() {
      const reservationInfo = this.getReservationInfo();
      reservationInfo.time_flight = null;
      reservationInfo.num_flight = null;
      reservationInfo.date_flight = null;
      localStorage.setItem(
        "reservation-info",
        JSON.stringify(reservationInfo)
      );
    },
    getFlightInfo() {
      const reservationInfo = this.getReservationInfo();
      return {
        time_flight: reservationInfo?.time_flight || null,
        num_flight: reservationInfo?.num_flight || null,
        date_flight: reservationInfo?.date_flight || null,
      };
    },
  },
};
