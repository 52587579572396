import jwt from "jsonwebtoken";

const state = () => ({
  header: localStorage.getItem("h") || null,
});

const mutations = {};

const actions = {
  setHeader({ state }, payload) {
    state.header = payload;
  },
};

const getters = {
  header: ({ header }) => {
    try {
      return JSON.parse(header);
    } catch {
      return "";
    }
  },
  userUid: ({ header }) => {
    try {
      const h = JSON.parse(header);
      const token = h["vcinc-token"];
      if (token) {
        return jwt.decode(token)?.sub;
      }
      return "";
    } catch {
      return "";
    }
  },
};

export default {
  namespace: true,
  state,
  mutations,
  actions,
  getters,
};
