<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <div
            :class="{ is_ac: !usageHistoryList || usageHistoryList.length }"
            class="bl_history"
          >
            <ul
              class="el_reset bl_history_list"
              v-for="usageHistory in usageHistoryList"
              :key="usageHistory.reservationCd"
            >
              <usage-history-list-item
                :reservation="usageHistory"
                @click="
                  onClickUsageHistory(
                    usageHistory.serviceName === 'NearMe Airport' ||
                      usageHistory.serviceName === 'NearMe Limo'
                      ? 'airport'
                      : 'club',
                    usageHistory.reservationCd
                  )
                "
              />
            </ul>
            <div class="no_result">이용 내역이 없습니다.</div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { nearmeApi } from "@/api";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import UsageHistoryListItem from "@/views/nearme/history/components/UsageHistoryListItem.vue";

export default {
  components: { DefaultLayout, UsageHistoryListItem },
  name: "UsageHistoryList",
  data() {
    return {
      usageHistoryList: null,
    };
  },
  async created() {
    await this.setData();
  },
  methods: {
    async setData() {
      const { data } = await nearmeApi.getUsageHistoryList();
      this.usageHistoryList = data;
    },
    onClickUsageHistory(serviceType, reservationCd) {
      this.$router.push({
        name: "UsageHistoryView",
        query: {
          service_type: serviceType,
        },
        params: {
          reservation_cd: reservationCd,
        },
      });
    },
  },
};
</script>