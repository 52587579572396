import http from "@/util/http";
import httpV2 from "@/util/httpV2";

function getMapClubList(ccodes) {
  return http.get(`/club/list/search/sort/info`, {
    params: {
      ccodes,
    },
  });
}

function getClubListSearch(ccodes){
  return httpV2.get("/club/list/search", {
    params: {
      ccodes,
    },
    paramsSerializer(paramObj) {
      const params = new URLSearchParams();
      for (const key in paramObj) {
        if (paramObj[key]) {
          params.append(key, paramObj[key]);
        }
      }

      return params.toString();
    },
  })
}

export default {
  getMapClubList,
  getClubListSearch,
};
