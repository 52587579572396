<template>
  <div class="policy">
    <h1>개인정보 추가 수집에 대한 동의</h1>
    <p>
      개인정보 추가 수집에 대한 동의란, APL골프 APP에서의 서비스 이용 시 또는
      이벤트 진행 과정에서 회원가입 시 수집하지 않았던 개인정보를 추가로 수집할
      때에 이용자들에게 고지하고 동의를 받는 경우를 말합니다.
    </p>
    <table>
      <colgroup>
        <col style="width: 25%" />
        <col style="width: 75%" />
      </colgroup>
      <tbody>
        <tr>
          <th>서비스명</th>
          <td>수집하는 개인정보 항목</td>
        </tr>
        <tr>
          <th>운세</th>
          <td>
            &lt;사주정보&gt;<br />이름, 생년월일, 양/음력, 태어난 시간, 성별
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "JeomsinPrivacy",
};
</script>

<style scoped>
table {
  margin: 0 20px;
  margin-bottom: 14px;
}
table,
td,
th {
  border: 1px solid black;
  border-collapse: collapse;
}
td,
th {
  padding: 6px;
}
th {
  background-color: #f3f3f3;
}
</style>
