import store from "@/store";

export async function $openSelect(payload) {
  return await store.dispatch("activateSelect", payload);
}

export async function $openLocation(payload) {
  return await store.dispatch("activateLocationSearch", payload);
}

export async function $openEtcForm(payload) {
  return await store.dispatch("activateEtcForm", payload);
}

export async function $openFloatingButton(payload) {
  return await store.dispatch("activateFloatingButton", payload);
}
