<template>
  <div class="policy">
    <h1>파트너 상품 구매자 약관</h1>

    <h1>제 1 장 총칙</h1>
    <h2>제 1 조 (목적)</h2>
    <p>
      이 약관은 브이씨소프트㈜ (이하 ‘회사’)가 운영하는 APL골프 앱 및 웹 서비스
      (이하 ‘APL골프’)를 통한 여행자와 차량예약 서비스 파트너의 계약 중개와
      관련하여, 여행자의 권리와 의무 등 법률관계를 명확히 함을 목적으로 합니다.
    </p>
    <h2>제 2 조 (용어의 정의)</h2>
    <p>
      이 약관은 브이씨소프트㈜ (이하 ‘회사’)가 운영하는 APL골프 앱 및 웹 서비스
      (이하 ‘APL골프’)를 통한 여행자와 차량예약 서비스 파트너의 계약 중개와
      관련하여, 여행자의 권리와 의무 등 법률관계를 명확히 함을 목적으로 합니다.
    </p>
    <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
    <ol>
      <li>
        (1) “여행자”라 함은 회사의 중개 서비스를 통하여 파트너와 여행계약을
        체결하고 파트너로부터 서비스를 제공받는 자를 말합니다.
      </li>
      <li>
        (2) “파트너”라 함은 회사가 여행자에게 중개한 자로서 여행자에게 계약에
        따라 각종 여행 관련 서비스를 제자를 말합니다.
      </li>
      <li>
        (3) “여행 계약”이라 함은 여행자와 파트너 사이에 체결되는 계약이며, 계약
        내용은 계약 당시 여행자가 약관 및 정책에 명시된 사항을 말합니다.
      </li>
      <li>
        (4) “여행”이라 함은 고객이 여행계약에 따라 파트너로부터 제공받는 운송,
        투어, 숙박 등을 말합니다.
      </li>
      <li>
        (5) “투어 진행자”라 함은 여행자가 파트너에게 제공받는 여행 서비스 중
        현지에서 투어, 운송 등의 인솔 및 담당하는 자를 말합니다.
      </li>
      <li>
        (6) “서면”이라 함은 사전적 정의의 지면, 또는 회사와 기타 플랫폼의
        정보처리시스템을 통해 전자적 형태로 송신·수신 또는 저장된 정보를
        말합니다.
      </li>
      <li>
        (7) “수수료”라 함은 여행자가 파트너와의 여행계약 체결을 중개/대리한
        대가로 회사에 지급하는 보수를 말합니다.
      </li>
      <li>
        (8) “파트너요금”이라 함은 여행자가 서비스 제공의 대가로 회사를 통해
        파트너에게 지급하는 보수를 말합니다.
      </li>
      <li>
        (9) “여행요금”이라 함은 여행자가 여행계약과 관련하여 회사에 지급하는
        수수료 및 파트너요금을 합한 말합니다.
      </li>
      <li>
        (10) “탑승지”라 함은 일정에 따라 투어 진행자와 여행자가 만나기로 약속한
        장소를 말합니다.
      </li>
      <li>
        (11) “지각 시간”이라 함은 투어 진행자와 여행자가 탑승지에서 만나기로
        정한 시각으로부터 지각한 당탑승지에 실제 도착하기까지 경과한 시간을
        말합니다.
      </li>
    </ol>
    <h1>제 2 장 계약</h1>
    <h2>제 3 조 (여행계약의 당사자 및 회사의 지위)</h2>
    <p>
      여행자가 여행계약을 체결하고 서비스를 제공하는 법적 주체는 파트너이며,
      회사는 APL골프를 통하여 여행자가 원하는 날짜와 조건을 맞춰 파트너와
      여행계약을 체결하고 서비스를 제공받는 것을 중개하는 업무를 수행합니다.
    </p>
    <h2>제 4 조 (파트너의 독립당사자 지위)</h2>
    <p>
      회사는 여행자와 파트너 사이에서 체결되는 여행 계약의 중개만 진행하며,
      파트너는 회사와 고용 관계에 있지 않습니다. 또한, 회사는 약관에 명시된 사항
      이외에, 파트너가 가지는 책임을 일체 부담하지 않는다는 점을 명확히 합니다.
    </p>
    <h2>제 5 조 (여행계약의 체결)</h2>
    <ol>
      <li>
        (1) 여행계약은 계약 당시 여행자가 동의한 약관 및 정책에 명시된 사항을 그
        계약내용으로 합니다.
      </li>
      <li>
        (2) 여행 계약은 여행자가 APL골프를 통해 예약에 대한 상세 정보를
        확인하고, 약관 및 정책에 대한 동의 의사를 표한 뒤 여행요금을 지급하는
        시점에 그 계약이 체결되었다고 간주되며, 여행자는 여행계약을 체결한
        이후부터, 여행자로서의 지위를 갖게 됩니다.
      </li>
      <li>
        (3) 파트너는 제2항의 절차를 거쳐 여행계약이 체결된 경우, APL골프를 통해
        여행자와 최종 합의한 여행의 구체적 스케줄 및 비용 등의 내용을 APL골프
        서비스 내에 명시하고, 고객에게 해당 내용을 전달합니다. 이로써 파트너와
        여행자 간의 약관 동의 및 계약 내용 교부가 이루어진 것으로 간주합니다.
      </li>
      <li>
        (4) 여행자가 APL골프가 아닌 다른 플랫폼을 통하여 예약을 진행한 경우, 본
        조의 여행계약이 체결되지 않은 것으로 간주하며, 회사에게 중개에 대한 책임
        및 손해에 대한 배상을 요구할 수 없습니다.
      </li>
    </ol>
    <h2>제 6 조 (여행계약 체결의 거절)</h2>
    <p>
      파트너(또는 파트너를 대신하여 회사)는 다음 각 호에 해당하는 사유가 있을
      경우, 해당 여행자에 대하여 여행계약의 체결을 거절할 수 있으며, 계약 체결
      이후에 해당 사유를 알게 된 경우에도 계약을 해제할 수 있습니다.
    </p>
    <ol>
      <li>
        (1) 여행자가 질병, 신체이상 등의 사유로 단체여행(다른 여행자의 여행에
        지장을 초래하는 등)의 원활한 실시에 지장이 있다고 인정되는 경우
      </li>
      <li>
        (2) 여행자가 보행이나 운송수단 탑승이 불가능한 부상, 전염성 질병 등
        여행자의 신체에 이상이 발생하여 여행의 진행이 불가능함이 의료기관이
        발급한 진단서에 의해 객관적으로 확인되는 경우
      </li>
    </ol>
    <h2>제 7 조 (여행계약 체결 이후 조건 변경)</h2>
    <ol>
      <li>
        1. 여행계약이 체결된 이후에는 계약상 여행조건이 변경될 수 없습니다.
      </li>
      <li>
        2. 제1항에도 불구하고, 여행계약상의 여행조건은 다음 각 호의 사유가
        발생하는 경우 예외적으로 변경될 수 있습니다. 단, 이 경우 파트너는 지체
        없이 회사에 해당 내용을 통보하고, 여행 시작 전까지 확보된 연락처를 통해
        여행자에게 안내해야 합니다.
        <ol>
          <li>
            (1) 여행자의 안전과 보호를 위하여 여행조건의 변경이 부득이하다고
            판단되는 경우
          </li>
          <li>
            (2) 천재지변, 사고, 납치, 운송 및 숙박업체 등의 파업 또는 휴업 등
            여행의 목적을 달성할 수 없는 사유
          </li>
          <li>
            (3) 파트너가 계약서 등에 명시된 여행일정을 변경하는 경우 (단, 해당
            날짜의 일정이 시작되기 전에 여행자의 서면 동의를 받아야 하며,
            서면에는 변경일시, 변경내용, 변경으로 발생하는 비용이 포함되어야
            합니다.)
          </li>
          <li>
            (4) (1), (2)호의 경우, 파트너가 여행자로부터 여행일정 변경 동의를
            받기 어렵다고 판단 시 일정변경에 대한 동의를 받지 않을 수 있습니다.
            다만, 파트너는 사후에 서면으로 그 변경 사유 및 비용 등을 설명하여야
            합니다.
          </li>
        </ol>
      </li>
      <li>
        3. 제2항의 여행조건 변경으로 인하여 여행요금에 증감이 생기는 경우, 해당
        증감분은 여행 종료 후 10일 이내에 회사를 통하여 정산 혹은 환급을
        진행해야 합니다. 단, 각 당사자가 합의할 경우 익월 정산으로 진행할 수
        있습니다.
      </li>
      <li>
        4. 제2항의 여행조건 변경과 관련하여 요금이 추가적으로 발생하는 경우,
        파트너는 이를 제3항에 따라 회사로부터 정산 후 지급받아야 하며 회사를
        통하지 않은 채 여행자로부터 직접 요금 및 기타 어떠한 명목의 보수도
        지급받아서는 안 됩니다.
      </li>
      <li>
        5. 파트너는 기상 악화 등 여행자의 안전이 위협받을 수 있다고 판단되는
        경우, 투어를 진행하지 않아야 합니다. 안전에 대한 파트너의 잘못된
        판단으로 투어가 진행되어 발생하는 모든 사고에 대해서는 파트너에게 책임이
        있습니다. 다만 여행자 본인의 요청과 동의로 인해 진행한 투어에서 발생한
        사고는 파트너에게 책임이 없습니다.
      </li>
      <li>
        6. 여행자는 본인의 사정 및 요구로 인하여 요금에 포함된 서비스를 제공받지
        못한 경우 회사 혹은 파트너에게 그에 상응하는 요금의 환급을 청구할 수
        없습니다.
      </li>
    </ol>

    <h1>제 3 장 여행요금</h1>
    <h2>제 8 조 (여행요금)</h2>
    <ol>
      <li>
        1. 여행자는 여행계약의 중개 및 서비스 제공의 대가로서 예약 내역에 기재된
        금액의 여행요금을 회사에 지급합니다. 여행요금에는 다음 각 호의 요금 또는
        비용은 포함되어 있지 않습니다.
        <ol>
          <li>
            (1) 여행 중 발생할 수 있는 개인적 성질의 비용(통신료, 관세, 일체의
            팁, 세탁비, 개인적으로 추가한 식사, 음료 등)
          </li>
          <li>
            (2) 여행 중 치료비, 입원비 등 여행자의 질병, 상해 또는 그 밖의
            사유로 인하여 지불해야 하는 일체의 비용
          </li>
          <li>
            (3) 여행자가 통상의 규격이나 규정을 초과하여 발생한 비용(초과 규격의
            수하물 등 각종 추가 요금)
          </li>
          <li>(4) 여행 수속 제 비용(여권 인지대, 사증료 등)</li>
        </ol>
      </li>
      <li>
        2. 여행자는 여행요금을 당사가 지정한 방법으로 여행요금을 지급합니다.
      </li>
    </ol>
    <h1>제 4 장 여행개시 전 계약해제</h1>
    <h2>제 9 조 (여행 개시 전 여행자의 계약해제)</h2>
    <ol>
      <li>
        1. 여행자가 여행계약을 체결한 후 여행 개시일 이전까지 이를 임의로
        해제하는 경우, 파트너는 본 약관 별첨 취소 및 환불 규정에 따라 여행자에게
        여행요금의 전부 또는 일부를 환불합니다. 단, 여행계약 체결 시 파트너 혹은
        상품 공금업체의 자체 취소 환불 규정이 명시된 경우 해당 내용을 우선으로
        적용합니다.
      </li>
      <li>
        2. 회사는 본 조에 따라 파트너와 여행자 사이에 발생하는 손해배상 등
        법률관계에 대하여 보증책임 및 기타 어떠한 의무 또는 책임도 부담하지
        않습니다.
      </li>
    </ol>
    <h2>제 10 조 (여행 개시 전 파트너의 계약해제)</h2>
    <ol>
      <li>
        1. 예약 확정 이후, 파트너가 여행자와 체결하였던 여행계약을 임의로
        해제하는 경우, 파트너는 그로 인하여 여행자가 입은 손해를 배상할 의무가
        있습니다. 손해배상액은 회사의 내부 규정 및 지침에 따라 산정하되,
        여행요금을 최대한도로 합니다. 다만, 여행자가 입은 실제 손해액이
        여행요금을 초과함이 객관적으로 입증되는 경우, 파트너는 여행자에게 그
        실제 손해액을 배상할 책임을 부담합니다.
      </li>
      <li>
        2. 회사가 파트너를 대신하여 여행자에게 제1항의 손해배상의무를 이행하는
        경우, 회사는 파트너에 대하여 여행자에게 배상한 금액을 구상할 수
        있습니다.
      </li>
      <li>
        3. 본 조에 따라 파트너가 여행계약을 해제하는 경우, 파트너는 제1항의
        여행자에 대한 손해배상과 별도로 회사가 입은 손해로서 약관에 기재된
        수수료 상당액을 배상할 의무를 부담합니다.
      </li>
    </ol>
    <h2>제 11 조 (여행 개시 전 기타 사유로 인한 계약 해제)</h2>
    <p>
      여행 개시일 이전에 다음 각 항에 해당하는 사유가 있는 경우, 여행자 또는
      파트너는 별도의 손해배상 없이 여행계약을 해제할 수 있습니다. 단, 계약 당시
      명시된 파트너 및 상품 공급업체의 자체 취소 환불 규정이 우선으로 적용되어
      환불이 불가할 수 있습니다.
    </p>
    <ol>
      <li>1. 제 6 조의 사유가 있는 경우</li>
      <li>
        2. 보행이나 운송수단 탑승이 불가능한 부상, 전염성 질병 등 여행자의
        신체에 이상이 발생하여 여행 진행이 불가능함이 정식 의료기관이 발급한
        진단서에 의해 객관적으로 확인되는 경우
      </li>
      <li>
        3. 2항의 경우 당사자 1인의 계약이 해제 가능하며, 당사자와 함께
        여행계약을 체결한 동행 여행자는 3촌 이내의 친족임을 입증하여 계약 해제가
        가능합니다. 단, 아래와 같은 입증서류를 회사에 제출하여야 합니다.
        <ol>
          <li>(1) 진단서</li>
          <li>(2) 3촌 이내의 친족임을 확인할 수 있는 서류</li>
          <li>(3) 기타 입증서류</li>
        </ol>
      </li>
      <li>
        4. 여행자의 배우자 또는 직계존비속이 신체 이상으로 3일 이상 병원(의원)에
        입원하여 여행 출발 전까지 퇴원이 곤란한 경우 그 배우자 또는 보호자
        1인으로서 계약 해제가 가능합니다. 단, 아래와 같은 입증서류를 회사에
        제출하여야 합니다.
        <ol>
          <li>(1) 친족을 확인할 수 있는 서류</li>
          <li>(2) 진단서</li>
          <li>(3) 기타 입증서류</li>
        </ol>
      </li>
      <li>
        5. 여행자의 3촌 이내 친족이 사망한 경우 계약 해제가 가능합니다. 단,
        아래와 같은 입증서류를 회사에 제출하여야 합니다.
        <ol>
          <li>(1) 친족을 확인할 수 있는 서류</li>
          <li>(2) 진단서 또는 사망진단서</li>
          <li>(3) 기타 입증서류</li>
        </ol>
      </li>
      <li>
        6. 기타 이에 준하는 것으로서 당사자 쌍방에게 책임 없는 사유로 여행
        진행이 불가능하게 된 경우 계약 해제가 가능합니다. 단, 아래와 같은 입증
        서류를 회사에 제출하여야 합니다.
        <ol>
          <li>(1) 도난 증빙서</li>
          <li>(2) 결함/지연 증빙서</li>
          <li>(3) 기타 입증서류</li>
        </ol>
      </li>
    </ol>
    <h1>제 5 장 여행개시 후 계약해지</h1>
    <h2>제 12 조 (여행 개시 후 당사자 간의 합의에 의한 계약 해지)</h2>
    <ol>
      <li>
        1. 여행자 또는 파트너는 여행이 개시된 이후에는 상대방과의 합의 없이
        임의로 여행계약을 해지할 수 없습니다.
      </li>
      <li>
        2. 상대방과의 합의에 따라 여행계약을 해지하는 경우, 파트너요금의 환불
        또한 상호 합의한 내용에 따릅니다.
      </li>
      <li>
        3. 본 조의 합의해지와 관련하여, 회사는 여행자로부터 지급받은 수수료를
        환불할 의무가 없습니다.
      </li>
    </ol>
    <h2>제 13 조 (여행 개시 후 한 당사자의 귀책으로 인한 계약 해지)</h2>
    <ol>
      <li>
        1.여행자 또는 파트너는 상대방의 귀책으로 인하여 여행의 진행이 현저히
        곤란하게 된 경우 여행계약을 해지할 수 있습니다.
      </li>
      <li>
        1.여행자의 귀책으로 제1항의 계약 해지가 발생한 경우, 여행자는 여행요금의
        환불을 청구할 수 없습니다.
      </li>
      <li>
        1.파트너의 귀책으로 제1항의 계약 해지가 발생한 경우, 여행자는 회사에
        여행요금 전액의 환불을 청구할 수 있습니다. 이 경우, 회사는 여행자에게
        반환한 수수료 금액과 추가 손해배상 금액을 파트너에게 청구할 수 있으며,
        이는 최대 여행요금의 100%로 한정합니다.
      </li>
      <li>
        1.본 조에 따른 여행계약의 해지가 발생한 경우, 귀책사유 있는 당사자는
        여행요금과 별개로 본인의 귀책으로 인해 상대방이 입은 손해를 배상하여야
        합니다.
      </li>
    </ol>
    <h2>제 14 조 (여행 개시 후 기타 사유로 인한 계약 해지)</h2>
    <ol>
      <li>
        1. 파트너 및 여행자 모두에게 귀책 없는 사유로 인하여 여행의 진행이
        불가능한 경우, 각 당사자는 여행계약을 해지할 수 있습니다.
      </li>
      <li>
        2. 제1항의 해지가 발생한 경우, 파트너는 파트너요금 중 일정상 계획된 전체
        여행 시간 중 실제로 진행되지 못한 시간의 비율 상당 금액을 여행자에게
        반환하여야 합니다.
      </li>
      <li>
        3. 제1항의 해지가 발생한 경우, 회사는 제2항에 따라 파트너가 여행자에게
        반환하여야 할 금액 상당을 정산하여 여행자에게 환불하며, 파트너에게는
        여행자로부터 지급받은 요금 중 위 환불금을 공제하고 난 후 잔여 요금을
        정산하여 지급합니다.
      </li>
      <li>
        4. 제2항의 전체 여행 시간 중 실제로 진행되지 못한(또는 진행된) 여행
        시간의 비율은 파트너와 투어 진행자 및 여행자의 합의를 통하여 정하며,
        합의가 되지 않는 경우 회사가 객관적이고 중립적인 기준에 따라 그 비율을
        정합니다.
      </li>
      <li>
        5. 본 조에 따른 해지와 관련하여 회사 및 파트너는 제2항 및 제3항에 규정된
        사항 이외에 여행자에 대하여 그 밖의 다른 의무를 부담하지 않습니다.
      </li>
    </ol>

    <h1>6 장 여행의 개시와 종료</h1>
    <h2>제 15 조 (여행의 개시 및 종료 시점)</h2>
    <p>
      여행의 개시 시점은 일정 첫 날 탑승지에서 여행자가 투어 진행자와 만난
      시점으로 하며, 여행의 종료 시점은 일정 마지막 날 여행자와 투어 진행자가
      일정을 마치고 헤어지는 시점으로 합니다.
    </p>
    <h2>제 16 조 (여행자의 지각)</h2>
    <ol>
      <li>
        1. 여행자가 사전 연락 없이 약속한 시간에 픽업장소에 도착하지 않는 경우,
        투어 진행자는 제14조에 따라 여행계약을 해지할 수 있습니다.
      </li>
      <li>
        2. 사전에 투어를 함께 진행하기로 예정된 다른 여행자의 전부 또는 일부가
        정시에 탑승지에 도착한 경우에는, 투어 진행자는 예정된 일정에 따라 투어를
        진행할 수 있으며, 지각한 여행자에게 지각시간 상당의 투어를 추가적으로
        제공할 의무가 없습니다.
      </li>
    </ol>
    <h2>제 17 조 (투어 진행자의 지각)</h2>
    <ol>
      <li>
        1. 여행자와 약속한 시간까지 탑승지에 도착하지 못할 것이 예상되는 경우,
        투어 진행자는 약속시간 이전까지 여행자에게 도착이 늦어지게 된 경위, 예상
        도착 시간, 지체된 시간만큼의 추가 투어가 제공된다는 사실 등을 구체적으로
        통지(이하 “우선통지”) 하여야 합니다.
      </li>
      <li>
        2. 우선통지는 여행자가 그 통지를 수령하였음이 확인된 경우에만 적법한
        것으로 인정되며, 여행자는 파트너 혹은 투어 진행자로부터 우선통지를
        수령하지 못한 상태에서 투어 진행자가 연락 없이 약속한 시간으로부터
        15분이 지나도록 탑승지에 도착하지 않는 경우 여행계약을 해지할 수
        있습니다.
      </li>
      <li>
        3. 투어 진행자의 귀책사유로 투어의 개시가 지체된 경우, 투어 진행자는 위
        지체된 시간만큼의 투어를 여행자에게 추가로 제공하여야 합니다.
      </li>
    </ol>
    <h1>제 7 장 의무</h1>
    <h2>제 18 조 (각 당사자의 의무)</h2>
    <ol>
      <li>
        1. 파트너는 여행계약 내용을 준수하고, 여행자에게 안전하고 만족스러운
        서비스를 제공하기 위한 여행계약상의 의무를 성실하게 이행하여야 합니다.
      </li>
      <li>
        2. 여행자는 여행계약 및 여행자 약관에 따른 의무를 성실하게 이행하며,
        안전하고 즐거운 여행을 위해 여행자간 화합도모 및 파트너 및 투어 진행자가
        요청하는 여행질서 유지에 적극적으로 협조합니다.
      </li>
      <li>
        3. 회사는 여행계약 체결의 중개 행위 등에 있어 맡은 바 임무를 충실히
        수행합니다.
      </li>
    </ol>
    <h2>제 19 조 (여행자의 의무)</h2>
    <ol>
      <li>
        1. 여행자는 상업적 목적으로 회사의 상품을 다량으로 구매하여 재 판매할 수
        없습니다.
      </li>
      <li>
        2. 여행자는 여행의 원활한 진행을 위하여 파트너가 회사를 통하여 제공하는
        설명 및 자료의 여행 일정, 여행비용 내역, 약속 시간 및 장소 등 여행 관련
        정보를 확인합니다.
      </li>
      <li>
        3. 여행자는 여행의 원활한 진행을 위하여 파트너의 인솔 및 요청에 성실히
        협조합니다.
      </li>
      <li>
        4. 여행자는 합리적인 이유 없이 파트너에게 여행과 직접적 관련이 없는
        부당한 요구를 할 수 없습니다.
      </li>
      <li>
        5. 여행자는 본인의 사정 및 귀책으로 요금에 포함된 운송 서비스를 제공받지
        못한 경우 파트너에게 그에 상응하는 요금의 환급을 청구할 수 없습니다.
      </li>
      <li>
        6. 여행자는 여행 종료 이후 APL골프 내에 여행에 관한 후기 게시물(이하
        “리뷰”)을 작성 및 게시할 수 있습니다.
      </li>
      <li>
        7. 다만, 리뷰가 다음 각 호의 어느 하나에 해당하는 경우, 회사는 해당
        리뷰를 여행자의 동의 없이 삭제 또는 변경할 수 있습니다.
        <ol>
          <li>(1) 사용자의 개인적인 경험 이외의 내용을 담고 있는 경우</li>
          <li>
            (2) 실제 여행과 관련되지 않은 후기인 경우(예: 정치, 종교, 사회,
            환경(기상이변)에 대한 후기)
          </li>
          <li>
            (3) 위해하고 불법적인 행동 및 폭력을 지지하거나 비속어, 성적 언어,
            명예훼손, 위협 또는 차별적인 내용을 포함하는 경우
          </li>
          <li>
            (4) 타인의 권리(지적 재산권 및 개인정보 보호에 관한 권리 등을
            포함)를 침해하는 내용을 포함하는 경우 (예: 타인의 실명, 주소 또는
            인적 정보를 당사자의 허락없이 기재하는 경우 등)
          </li>
          <li>
            (5) 강요의 목적으로 후기 게시물이 이용된 것이 객관적으로 입증된 경우
          </li>
          <li>(6) 회사에서 조사가 진행 중인 건에 대한 내용을 포함하는 경우</li>
          <li>
            (7) 기타 이상에 준하는 위법, 부당한 목적 또는 방법으로 리뷰가 작성된
            경우
          </li>
          <li>
            (8) 여행 미 참여 혹은 지각, 이탈 등 정상적인 여행 진행이 되지 않은
            후기인 경우
          </li>
        </ol>
      </li>
    </ol>
    <h2>제 20 조 (파트너 및 회사의 설명 의무)</h2>
    <p>
      파트너는 여행계약의 중요 내용 및 변경사항을 여행자에게 설명해야 하며,
      회사는 고객센터로 관련된 문의 인입 시, 여행자가 관련된 내용을 이해할 수
      있도록 가능한 범위 내에서 자세하게 설명합니다.
    </p>
    <h2>제 21 조 (파트너 및 회사의 개인정보 관리의무)</h2>
    <ol>
      <li>
        1. 파트너 및 회사는 여행과 관련하여 취득한 여행자 개인정보(성명,
        여권번호, 연락처 등)를 개인정보 보호법 등 제반 법령에 따라 적법하게
        관리해야 합니다.
      </li>
      <li>
        2. 회사는 여행이 확정되기 전까지 투어 진행자의 연락처 등 개인정보를
        여행자의 지위가 없는 제3자에게 제공하지 않아야 합니다. 단, 다음 호의
        경우는 예외로 합니다.
        <ol>
          <li>
            (1) 그 밖에 여행대기자가 투어 진행자에게 개별적 연락이 필요한 합리적인
            사유가 있는 경우
          </li>
        </ol>
      </li>
    </ol>
    <h1>제 8 장 손해배상</h1>
    <h2>제 22 조 (파트너의 손해배상)</h2>
    <ol>
      <li>
        1. 파트너가 여행계약을 위반하는 경우 이로 인한 여행자의 손해를 배상해야
        합니다. 단, 여행자에게 신체 손상이 없는 경우 손해배상책임은 여행요금의
        100%를 한도로 합니다.
      </li>
      <li>
        2. 파트너는 여행 개시 시점부터 완료 시점까지 파트너 본인 또는 그 고용인,
        파트너의 고의 또는 과실로 여행자에게 손해를 가한 경우 여행자는 손해
        금액의 입증을 위해서 객관적인 증빙자료를 파트너와 회사에 제출해야
        합니다.
      </li>
      <li>
        3. 파트너는 여행 중 항공기, 기차, 선박 등 교통수단의 연발착 또는
        교통체증 등으로 인하여 여행자가 입은 손해를 배상합니다. 단 파트너가 이에
        대한 고의 또는 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
      </li>
      <li>
        4. 투어 진행자는 여행자의 수하물을 수령, 인도, 보관할 의무가 없으며,
        수하물이 멸실, 훼손 또는 연착되어 여행자에게 발생한 손해를 배상할 책임이
        없습니다.
      </li>
    </ol>
    <h2>제 23 조 (여행자의 손해배상)</h2>
    <ol>
      <li>
        1. 여행자의 고의 또는 과실로 회사 또는 파트너에게 손해가 발생한 경우,
        여행자는 그 손해를 배상할 의무가 있습니다.
      </li>
      <li>
        2. 여행자가 파트너에 대해서 성추행, 성희롱 등 범죄 행위를 하였을 경우,
        여행계약은 여행자 귀책사유로 취소되며 여행자는 파트너에 대하여 민형사상
        책임이 있습니다. 또한 해당 여행자는 회사가 입은 손해 및 각종 발생 비용
        등의 손실을 배상할 책임 또한 부담합니다.
      </li>
    </ol>
    <h2>제 24 조 (회사의 손해배상)</h2>
    <ol>
      <li>
        1. 회사는 여행계약 체결의 중개와 관련하여 회사 또는 회사의 고용인이 고의
        또는 과실로 여행자에게 손해를 가한 경우, 그로 인하여 여행자가 입은
        손해만을 배상합니다.
      </li>
      <li>
        2. 회사는 여행계약이 체결된 이후 여행자와 파트너 사이에서 발생한
        손해배상 등 법률관계와 관련하여 여행자 또는 파트너에 대하여 어떠한
        책임도 부담하지 않습니다. 또한, 파트너약관에 명시된 사항 또는 고의 또는
        중과실로 인한 불법행위를 행한 경우 이외에는 여행자 또는 파트너에 대하여
        어떠한 손해배상 책임도 부담하지 않습니다.
      </li>
    </ol>
    <h1>제 9 장 기타</h1>
    <h2>제 25 조 (면책사항)</h2>
    <ol>
      <li>
        1. 회사 또는 파트너는 천재지변 또는 이에 준하는 불가항력으로 인하여
        약관의 내용을 준수할 수 없는 경우, 이에 대한 책임이 면책됩니다.
      </li>
      <li>
        2. 회사 또는 파트너는 여행자의 귀책사유로 인한 여행계약 불이행에 대하여
        책임을 지지 않습니다.
      </li>
    </ol>
    <h2>제 26 조 (분쟁 조정 및 관할 법원)</h2>
    <ol>
      <li>
        1. 본 계약에 명시되지 아니한 사항 또는 이 계약의 해석에 관하여 다툼이
        있는 경우에는 회사 또는 여행자가 합의하여 결정하되, 합의가 이루어지지
        아니한 경우에는 관계법령 및 일반관례에 따릅니다.
      </li>
      <li>
        2. 본 계약과 관련하여 발생한 분쟁 등에 관해 대한민국 법령이 적용되며,
        회사의 주소지를 관할하는 법원을 관할법원으로 합니다.
      </li>
    </ol>
    <h2>제 27 조 (기타사항)</h2>
    <ol>
      <li>
        특수 지역 여행으로 객관적이고 정당한 사유가 발생한 경우, 본 약관의
        내용과 다르게 적용할 수 있습니다.
      </li>
    </ol>
    <h2>부칙</h2>
    <p>이 약관은 2024년 01월 01일부터 적용됩니다.</p>
  </div>
</template>
<script>
export default {
  name: "PartnerProduct",
};
</script>
<style scoped>
@import "~@/assets/css/nearme/reset.css";
</style>