<template>
  <div class="policy">
    <h1>개인정보 수집 및 이용 방침</h1>
    <p>
      브이씨소프트㈜ (이하 ‘회사’)는 차량 서비스 예약 및 이용 등의 업무처리를
      위하여 고객으로부터 최소한의 필수정보를 수집하며, 제공한 모든 개인정보는
      별도의 동의가 없는 한 해당목적 이외의 다른 목적으로 사용하지 않습니다.
    </p>
    <h2>1. 개인정보 수집 및 이용목적</h2>

    <p>
      차량 서비스 예약, 예약내역의 확인 및 상담, 컨텐츠 제공, 구매 및 요금결제,
      분쟁조정을 위한 기록보존, 불만처리, 민원처리, 고지사항 전달 등
    </p>

    <h2>2. 개인정보 수집 항목</h2>
    <p>
      회사는 적법한 절차와 기준에 의거하여 고객의 개인정보를 수집하고 있으며,
      고객의 서비스 이용에 필요한 최소한의 정보만을 수집하고 있습니다.
    </p>

    <table>
      <thead>
        <tr>
          <th>수집하는 개인정보 항목</th>
          <th>용도</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            예약자 이름 (한글, 영문), 휴대폰 번호,<br />
            출발/도착지 정보, 항공편 정보
          </td>
          <td>
            서비스 상담 및 예약내역 확인, 약관의 주요<br />
            변경 사항, 예약정보 등을 제공
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h2>3. 개인정보의 보유 및 이용 기간</h2>
    <p>
      전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법,
      통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와
      같습니다.
      <br />
      회사는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른
      목적으로는 절대 이용하지 않습니다.<br />
    </p>
    <h3>전자금융거래법</h3>
    <ol>
      <li>전자금융에 관한 기록 : 5년 보관</li>
    </ol>
    <h3>전자상거래 등에서 소비자 보호에 관한 법률</h3>
    <ol>
      <li>계약 또는 청약철회 등에 관한 기록 : 5년 보관</li>
      <li>대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관</li>
      <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관</li>
    </ol>
    <h2>4. 동의를 거부할 권리 및 동의를 거부할 경우의 불이익</h2>
    <p>
      개인정보 주체자는 개인정보 수집, 이용에 대한 동의를 거부할 권리가
      있습니다. 동의를 거부할 경우 차량 예약 서비스 이용이 불가함을
      알려드립니다.
    </p>
  </div>
</template>

<script>
export default {
  name: "GpsAndPrivacy",
};
</script>

<style scoped>
@import "~@/assets/css/nearme/reset.css";
</style>