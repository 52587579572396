<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <header class="ly_header"></header>
          <main class="ly_body">
            <div class="bl_phone">
              <div class="bl_phone_num">
                <div class="cp_inp">
                  <input
                    class="el_form cp_inp_inp"
                    v-model="phone1"
                    @input="moveToFocus($event, 3, 'phone2')"
                    type="number"
                    maxlength="3"
                  />
                  <div class="cp_inp_box"></div>
                </div>
              </div>
              <div class="bl_phone_dash">-</div>
              <div class="bl_phone_num">
                <div class="cp_inp">
                  <input
                    class="el_form cp_inp_inp"
                    ref="phone2"
                    v-model="phone2"
                    @input="moveToFocus($event, 4, 'phone3')"
                    type="number"
                    maxlength="4"
                  />
                  <div class="cp_inp_box"></div>
                </div>
              </div>
              <div class="bl_phone_dash">-</div>
              <div class="bl_phone_num">
                <div class="cp_inp">
                  <input
                    class="el_form cp_inp_inp"
                    ref="phone3"
                    v-model="phone3"
                    type="number"
                    maxlength="4"
                  />
                  <div class="cp_inp_box"></div>
                </div>
              </div>
            </div>
            <div class="bl_agree">
              <!-- 체크(A): checked -->
              <input
                class="el_form bl_agree_inp"
                type="checkbox"
                v-model="agree"
              />
              <div class="bl_agree_chk"></div>
            </div>
            <div class="bl_attend">
              <!-- 비활성(A): disabled -->
              <button
                class="el_form bl_attend_btn"
                @click="submit"
                :disabled="!passRequirement"
              ></button>
            </div>
          </main>
          <footer class="ly_footer"></footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Pincrux",
  created() {
    const { pinkey, pubkey } = this.$route.query;
    this.pinkey = pinkey;
    this.pubkey = pubkey;
  },
  data() {
    return {
      phone1: "",
      phone2: "",
      phone3: "",
      agree: false,
    };
  },
  computed: {
    passRequirement() {
      return !!(
        this.agree &&
        this.phone1.length === 3 &&
        this.phone2.length >= 3 &&
        this.phone3.length === 4
      );
    },
    hpNo() {
      return `${this.phone1}${this.phone2}${this.phone3}`;
    },
  },
  methods: {
    async submit() {
      if (!this.passRequirement) {
        return;
      }
      if (!this.pinkey) {
        alert("잘못된 요청입니다.");
        return;
      }
      await axios.post(
        `${process.env.VUE_APP_API_URL}/partnership/pincrux/pinkey`,
        {
          hpNo: this.hpNo,
          pinkey: this.pinkey,
          pubkey: this.pubkey,
        }
      );

      window.location.href = "https://aplgolf.onelink.me/Flm6/5ipe3sqf";
    },
    moveToFocus(e, length, ref) {
      if (e.target.value?.length >= length) {
        this.$refs[ref].focus();
      }
    },
  },
};
</script>
