<template>
  <div class="wrapper">
    <div class="page">
      <main class="main" ref="scrollContainer">
        <div class="main-main">
          <div class="board">
            <ul class="board-list" ref="scrollList">
              <li
                v-for="notice in noticeList"
                :key="notice.noticeId"
                @click="
                  logEvent('notice_list_click', () => {
                    onNoticeClick(notice);
                  })
                "
              >
                <div class="info">
                  <div class="info-title">
                    {{ notice.subject }}
                  </div>
                  <div class="info-date">
                    {{ convertDate(notice.regDt) }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { userApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dateMixin from "@/mixins/dateMixin";
import { HTTP_EXPRESSION } from "@/util/string";

export default {
  name: "NoticeList",
  mixins: [scrollMixin, dateMixin],
  data() {
    return {
      noticeList: null,
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
    };
  },
  mounted() {
    const noticeList = this.loadScrollPosition("noticeInfo");
    if (noticeList) {
      this.noticeList = noticeList;
    } else {
      this.setData();
    }
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        const {
          data: { list, total },
        } = await userApi.getNoticeList({
          page: this.pageIndex,
          size: this.pageSize,
        });
        this.noticeList = this.noticeList || [];
        this.noticeList = [...this.noticeList, ...list];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    onNoticeClick(notice) {
      this.saveScrollPosition("noticeInfo", this.noticeList);
      if (notice.externalUrl) {
        const httpRegex = new RegExp(HTTP_EXPRESSION);
        if (httpRegex.test(notice.externalUrl)) {
          const noticeLinkTag = document.createElement("a");
          noticeLinkTag.href = notice.externalUrl;
          noticeLinkTag.click();
        } else {
          this.$router.push({ path: notice.externalUrl });
        }
      } else {
        this.$router.push({
          name: "NoticeView",
          params: { noticeId: notice.noticeId, notice: notice },
        });
      }
    },
  },
};
</script>

<style scoped>
main {
  overflow: auto;
}
</style>
