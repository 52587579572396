import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAHAGOuSg6yD8VWAVVWLH_En-i7q_Cwhmg",
  authDomain: "apl-golf.firebaseapp.com",
  projectId: "apl-golf",
  storageBucket: "apl-golf.appspot.com",
  messagingSenderId: "623845812282",
  appId: "1:623845812282:web:92112af0d0bf7c7423ed03",
  measurementId: "G-8GNKGJ9S51",
};

// Initialize Firebase
const app =
  process.env.VUE_APP_FIREBASE_ENABLE === "true" &&
  initializeApp(firebaseConfig);
const analytics = app && getAnalytics(app);
export default analytics;
