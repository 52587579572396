<template>
  <div class="policy">
    <h1>개인정보 처리방침</h1>
    <p>
      브이씨소프트(주)(이하 “회사”)는 고객님들의 개인정보를 소중히 다루고
      있습니다. 회사는 '개인정보보호법'과 ‘정보통신망이용촉진 및 정보 보호 등에
      관한 법률’ 등 모든 관련 법규를 준수하며 회원님의 개인정보가 보호받을 수
      있도록 최선을 다하고 있습니다. 개인정보 보호정책을 통해 이용자가 제공한
      개인정보가 어떠한 용도와 방식으로 이용되며, 개인정보 보호를 위해 어떠한
      방법을 통해 관리되고 있는지에 대해 알려드립니다.
    </p>
    <h2>제1조(개인정보의 처리 목적)</h2>
    <p>
      회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
      개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이
      변경되는 경우에는 ‘개인정보 보호법’ 제18조에 따라 별도의 동의를 받는 등
      필요한 조치를 이행할 예정입니다.
    </p>
    <ol>
      <li>
        1. 회원가입 및 관리 <br />

        회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격
        유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시
        법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를
        처리합니다.
      </li>
      <li>
        2. 민원사무 처리
        <br />
        민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과
        통보 목적으로 개인정보를 처리합니다.
      </li>
      <li>
        3. 재화 또는 서비스 제공
        <br />
        물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공,
        본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를
        처리합니다.
      </li>
      <li>
        4. 마케팅 및 광고에의 활용
        <br />
        신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공
        및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 ,
        서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
        등을 목적으로 개인정보를 처리합니다.
      </li>
    </ol>
    <h2>제2조(개인정보의 처리 및 보유 기간)</h2>
    <ol>
      <li>
        ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
        개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를
        처리·보유합니다.
      </li>
      <li>
        ② 회사는 법령에 따른 개인정보 보유∙이용기간 또는 이용자로부터 개인정보를
        수집 시에 동의받은 기간 내에서 개인정보를 처리∙보유하며, 각각의 개인정보
        처리 및 보유 기간은 다음과 같습니다.
      </li>
      <li>
        1. 회원 가입 및 관리 : 회원 탈퇴 시까지<br />
        다만, 다음의 사유에 해당하는 경우에는 각 사유 별 명시한 기간 동안
        보존합니다.
        <ol>
          <li>
            가) 관계 법령 위반에 따른 수사∙조사 등이 진행중인 경우에는 해당
            수사∙조사 종료시까지
          </li>
          <li>나) 회사 내부 방침에 의한 정보보유 사유</li>
          <li>- 반복 재가입으로 인한 부정이용방지 : 30일</li>
          <li>- 부정이용기록 : 5년</li>
        </ol>
      </li>
      <li>
        2. 재화 또는 서비스 제공 : 재화∙서비스 공급완료 및 요금결제∙정산
        완료시까지<br />
        다만, 이 외에 법령에 따라 일정기간 보관해야 하는 경우는 아래와 같습니다.
        <ol>
          <li>
            - 계약 또는 청약철회 등에 관한 기록/ 보존 이유 : 전자상거래 등에서의
            소비자보호에 관한 법률<br />
            보존 기간 : 5년
          </li>
          <li>
            - 대금결제 및 재화 등의 공급에 관한 기록/ 보존 이유 : 전자상거래
            등에서의 소비자보호에 관한 법률<br />
            보존 기간 : 5년
          </li>
          <li>
            - 소비자의 불만 또는 분쟁처리에 관한 기록/ 보존 이유 : 전자상거래
            등에서의 소비자보호에 관한 법률
            <br />
            보존 기간 : 3년
          </li>
          <li>
            - 표시, 광고에 관한 기록/ 보존 이유 : 전자상거래 등에서의
            소비자보호에 관한 법률
          </li>
          <li>
            - 전자금융 거래에 관한 기록/ 보존 이유 : 전자금융거래법<br />
            보존 기간 : 5년
          </li>
          <li>
            - 웹사이트 방문기록/ 보존 이유 : 통신비밀보호법<br />
            보존 기간 : 3개월
          </li>
          <li>
            - 모든 거래에 관한 장부 및 증빙서류/ 보존 이유 : 국세기본법
            <br />
            보존 기간 : 5년
          </li>
        </ol>
      </li>
    </ol>
    <h2>제3조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)</h2>
    <ol>
      <li>
        ① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구
        등의 권리를 행사할 수 있습니다.
      </li>
      <li>
        ② 제1항에 따른 권리 행사는 회사에 대해 ‘개인정보 보호법’ 시행령
        제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수
        있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
      </li>
      <li>
        ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
        대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한
        고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
      </li>
      <li>
        ④ 개인정보 열람 및 처리정지 요구는 ‘개인정보 보호법’ 제35조 제4항,
        제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
      </li>
      <li>
        ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
      </li>
      <li>
        ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의
        요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를
        확인합니다.
      </li>
    </ol>
    <h2>제4조(처리하는 개인정보의 항목 작성)</h2>
    <ol>
      <li>
        ① 회사는 다음의 개인정보 항목을 처리하고 있습니다.
        <ol>
          <li>
            1. 회원 가입 및 관리<br />
            필수항목 : 이메일, 휴대전화번호, 비밀번호, 닉네임<br />
            선택항목 : 성별, 생년월일, 지역
          </li>
        </ol>
      </li>
    </ol>
    <h2>제5조(개인정보의 파기)</h2>
    <ol>
      <li>
        ① 회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한,
        수집한 이용자의 개인정보의 수집 및 이용목적이 달성되거나, 이용자가
        회원탈퇴를 요청한 경우 지체 없이 파기하여 향후 어떠한 용도로도 열람 또는
        이용할 수 없도록 처리합니다. 단, “제3조. 개인정보 처리 및 보유기간”에서
        명시한 사항에 해당하는 경우는 예외를 두고 있습니다.
      </li>
      <li>
        ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이
        달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
        경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
        달리하여 보존합니다.
      </li>
      <li>
        ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다
        <ol>
          <li>
            1. 파기절차<br />
            회사는 이용자의 개인정보는 이용목적의 달성 및 이용자의 서비스
            해지(탈퇴) 요청이 있을 경우 지체 없이 파기합니다. 단, 회사의
            내부방침 또는 법령에 의하여 보존할 필요성이 있는 경우에는 별도의
            DB로 옮겨져 보존합니다. 이 때, DB로 옮겨진 개인정보는 법령에 의한
            경우가 아니고는 다른 목적으로 이용되지 않습니다.
          </li>
          <li>
            2. 파기방법
            <ol>
              <li>
                ①전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
                사용합니다.
              </li>
              <li>
                ②종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                파기합니다
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <h2>제6조(개인정보의 안전성 확보 조치)</h2>
    <p>
      회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
    </p>
    <ol>
      <li>
        1. 정기적인 자체 감사 실시<br />
        개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를
        실시하고 있습니다.
      </li>
      <li>
        2. 개인정보 취급 직원의 최소화 및 교육<br />
        개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여
        개인정보를 관리하는 대책을 시행하고 있습니다.
      </li>
      <li>
        3. 내부관리계획의 수립 및 시행<br />
        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
        있습니다.
      </li>
      <li>
        4. 해킹 등에 대비한 기술적 대책<br />
        회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
        위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터
        접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및
        차단하고 있습니다.
      </li>
      <li>
        5. 개인정보의 암호화<br />
        이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
        본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화
        하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
      </li>
      <li>
        6. 접속기록의 보관 및 위변조 방지<br />
        개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고
        있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
        고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고
        있습니다.<br />
        또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고
        있습니다.
      </li>

      <li>
        7. 개인정보에 대한 접근 제한<br />
        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
        부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를
        하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
        있습니다.
      </li>
      <li>
        8. 문서보안을 위한 잠금장치 사용<br />
        개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에
        보관하고 있습니다.
      </li>
      <li>
        9. 비인가자에 대한 출입 통제<br />
        개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해
        출입통제 절차를 수립, 운영하고 있습니다.
      </li>
    </ol>
    <h2>제7조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</h2>
    <p>
      회사는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
      사용하지 않습니다.
    </p>

    <h2>제8조 (개인정보 보호책임자)</h2>
    <ol>
      <li>
        ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
        관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
        개인정보보호 책임자를 지정하고 있습니다.
        <ol>
          <li>
            ▶ 개인정보 보호책임자<br />
            성명 : 심상일<br />
            대표전화 : 070-7720-3851<br />
            이메일 : aplgolf@vcsoft.co.kr
          </li>
        </ol>
      </li>
      <li>
        ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
        개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
        보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에
        대해 지체 없이 답변 및 처리해드릴 것입니다.
      </li>
    </ol>
    <h2>제9조 (권익침해 구제방법)</h2>
    <p>
      정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,
      한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할
      수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의
      기관에 문의하시기 바랍니다.
    </p>
    <ol>
      <li>
        1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
      </li>
      <li>2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
      <li>3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
      <li>4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
    </ol>
    <p>
      「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제),
      제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의
      장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
      행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
    </p>
    <p>
      ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr)`
      홈페이지를 참고하시기 바랍니다.
    </p>
    <h2>제10조(개인정보 처리방침 변경)</h2>
    <ol>
      <li>① 이 개인정보처리방침은 2022년 10월 28부터 적용됩니다.</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>
