<template>
  <div style="height: 100%">
    <div class="ly_wrapper">
      <div class="ly_page">
        <div class="ly_page_inner">
          <div class="ly_page_inner2">
            <header class="ly_header">
              <slot name="header" />
            </header>
            <main class="ly_body">
              <slot name="main" />
            </main>
            <footer class="ly_footer">
              <div class="ly_container">
                <slot name="footer" />
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <modal></modal>
  </div>
</template>

<script>
import "@/assets/js/nearme/common";
import Modal from "@/components/modal/nearme/Modal.vue";
export default {
  name: "DefaultLayout",
  components: {
    Modal,
  },
};
</script>

<style scoped>
@import "~@/assets/css/nearme/common.css";
@import "~@/assets/css/nearme/content.css";
@import "~@/assets/css/nearme/normalize.css";
</style>
