<template>
  <div class="ly_wrapper">
    <div class="ly_page" v-if="ready">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="bl_progress_wrapper" v-if="haveProgressDepth">
              <jeomsin-progress
                v-for="depth in depthList"
                :key="depth"
                :data="result[depth]"
              />
            </div>
            <div class="bl_descList_wrapper">
              <ul class="el_reset bl_descList">
                <jeomsin-depth
                  v-for="depth in depthList"
                  :key="depth"
                  :data="result[depth]"
                />
              </ul>
            </div>
            <div class="bl_invite_wrapper">
              <div class="bl_invite">
                <div class="bl_invite_ttl">친구 초대하기</div>
                <div class="bl_invite_txt">
                  초대한 친구가 APL골프에 회원가입하고<br />
                  내 초대 코드를 등록하면 1만 포인트 적립!
                </div>
                <div class="bl_invite_btn">
                  <button class="el_form bl_invite_btn_btn" ref="kakaoShareBtn">
                    <div class="bl_invite_btn_btn_ico"></div>
                    <div class="bl_invite_btn_btn_txt">
                      카카오톡으로 공유하기
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    <ul class="el_reset ly_layerList">
      <li class="ly_layer is_moDimmer"></li>
      <li class="ly_layer is_moLoading">
        <div class="ly_layer_inner">
          <div class="ly_layer_inner2">
            <div class="ly_layer_inner3">
              <div class="ly_layer_inner4">
                <div class="ly_body">
                  <div class="bl_ad_wrapper">
                    <div class="bl_ad"></div>
                  </div>
                </div>
                <div class="ly_footer">
                  <div class="bl_load_wrapper">
                    <div class="bl_load">
                      <jeomsin-loading />
                      <div class="bl_load_txt">
                        {{ userName }}님이 요청하신 사주 운세를<br />
                        분석하고 있어요! 잠시만 기다려주세요.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { userApi } from "@/api";
import { closeWebviewLayer } from "@/util/webview";
import JeomsinDepth from "@/views/promotion/components/JeomsinDepth.vue";
import JeomsinProgress from "@/views/promotion/components/JeomsinProgress.vue";
import JeomsinLoading from "@/views/promotion/components/JeomsinLoading.vue";
import { initJeomsinKakao, initKakao } from "@/util/kakao";

export default {
  name: "JeomsinResult",
  components: {
    JeomsinLoading,
    JeomsinProgress,
    JeomsinDepth,
  },
  async created() {
    const { userName, inviteCode, nickname } = this.$route.query;
    const { title } = this.$route.params;
    this.title = title;
    this.userName = userName;
    this.inviteCode = inviteCode;
    this.nickname = nickname;
    const category = await this.checkTitle();
    if (!category) {
      return;
    }
    document.title = category.text;
    try {
      document.querySelector("body").classList.add("is_acLoading");
      await Promise.all([this.setLoadingEnd(), this.setData()]);
    } finally {
      this.ready = true;
      if (!this._isDestroyed) {
        document.querySelector("body").classList.remove("is_acLoading");
      }
    }

    await initKakao();
    await initJeomsinKakao(this.$refs.kakaoShareBtn, {
      title: `<APL골프X점신>\n초대 코드: ${this.inviteCode}`,
      nickname: this.nickname,
      inviteCode: this.inviteCode,
    });
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("is_acLoading");
  },
  data() {
    return {
      categories: [
        { title: "2023", text: "신년운세", icon: "is_moYear" },
        { title: "2023_2", text: "대운", icon: "is_moLarge" },
        { title: "2023_4", text: "재물운", icon: "is_moMoney" },
        { title: "2023_3", text: "총운", icon: "is_moTotal" },
        { title: "2023_6", text: "결혼운", icon: "is_moWedding" },
        { title: "2023_5", text: "애정운", icon: "is_moLove" },
        { title: "2023_8", text: "사업운", icon: "is_moBusiness" },
        { title: "2023_7", text: "직업운", icon: "is_moJob" },
        { title: "2023_10", text: "건강운", icon: "is_moHealth" },
        { title: "2023_9", text: "친구운", icon: "is_moFriend" },
        { title: "2022", text: "다시보는 2022년", icon: "" },
        { title: "2024", text: "미리보는 2024년", icon: "" },
      ],
      title: "",
      userName: "",
      result: null,
      inviteCode: "",
      nickname: "",
      ready: false,
    };
  },
  methods: {
    async setData() {
      const { data } = await userApi.getPromotionJeomsinFortune(this.title);
      if (data.category1) {
        this.result = [
          data.category1.depth1,
          ...Object.keys(data.category2)
            .filter((key) => key.includes("depth"))
            .map((key) => data.category2[key]),
        ].reduce((a, v, i) => ({ ...a, [`depth${i + 1}`]: v }), {});
        return;
      }
      this.result = data;
    },
    checkTitle() {
      return new Promise((resolve) => {
        const category = this.categories.find(
          (cate) => cate.title === this.title
        );
        if (category) {
          resolve(category);
          return;
        }
        alert("잘못된 요청입니다.");
        closeWebviewLayer();
        resolve(false);
      });
    },
    async setLoadingEnd() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    },
  },
  computed: {
    depthList() {
      if (!this.result) {
        return [];
      }
      return Object.keys(this.result).filter((key) => key.includes("depth"));
    },
    haveProgressDepth() {
      return this.title === "2023";
    },
  },
};
</script>

<style scoped>
.id_result .ly_page .ly_body .bl_descList_wrapper:first-child {
  padding: 2rem 2rem 3rem 2rem;
}
</style>
