<template>
  <bottom-modal-layout class="is_moBag" :close="close">
    <template v-slot:header>
      <div class="bl_ttl">수하물 안내</div>
      <div class="bl_right is_moClose" @click="hide(null)"></div>
    </template>
    <template v-slot:main>
      <div class="bl_cont">
        <div class="bl_cont_img"></div>
        <div class="bl_cont_ttl">
          무릎에 놓을 수 없는 큰 수하물을 등록해주세요.
        </div>
        <div class="bl_cont_desc">
          <ul class="el_reset bl_desc_list">
            <li class="bl_desc_item">
              <div class="bl_desc_obj">
                <div class="bl_obj_dot"></div>
                <div class="bl_obj_txt">수하물은 1인 1개까지 무료에요.</div>
              </div>
            </li>
            <li class="bl_desc_item">
              <div class="bl_desc_obj">
                <div class="bl_obj_dot"></div>
                <div class="bl_obj_txt">
                  무릎에 놓을 짐은 등록하지 않아도 돼요.
                </div>
              </div>
            </li>
            <li class="bl_desc_item">
              <div class="bl_desc_obj">
                <div class="bl_obj_dot"></div>
                <div class="bl_obj_txt">
                  세 변의 합계가 200cm 이상인 짐은 맡길 수 없어요.
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </bottom-modal-layout>
</template>
<script>
import { preventBackButton } from "@/util/webview";
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";

export default {
  name: "LuggageNoticeModal",
  components: {
    BottomModalLayout,
  },
  data() {
    return {
      resolve: () => {},
      content: null,
      close: false,
    };
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
  },
};
</script>