import axios from "axios";
import { setInterceptors } from "@/common/interceptor";

function createAxiosService() {
  const axiosService = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}`,
    timeout: Number(process.env.VUE_APP_API_TIMEOUT),
  });
  return setInterceptors(axiosService);
}

const nearmeHttp = createAxiosService();

export default nearmeHttp;
