<template>
  <div class="policy">
    <h1>APL골프 이용약관</h1>
    <h1>제 1장 총칙</h1>
    <h2>제 1 조 (목적)</h2>
    <p>
      이 약관은 브이씨소프트(주)(이하 "회사")가 제공하는 골프 라운드 거리측정
      서비스, 앱 및 모바일 서비스(이하 "서비스"라 한다)의 이용과 관련하여 회사와
      회원간의 권리, 의무 및 책임, 기타 필요한 사항을 규정함을 목적으로 합니다.
    </p>
    <h2>제 2 조 (정의)</h2>
    <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
    <ol>
      <li>
        ①"서비스"는 ㈜브이씨와 기술제휴를 통해 회사가 골프장 및 골퍼에게
        제공하는 서비스 및 각종 제반 서비스를 말합니다.
      </li>
      <li>
        ②"회원"은 회사의 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을
        체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.
      </li>
      <li>
        ③"아이디(ID)"는 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고
        "회사"가 승인하는 이메일 형식의 문자와 숫자의 조합으로 "회원"을 식별할
        수 있는 고유한 식별기준을 의미합니다.
      </li>
      <li>
        ④"비밀번호"는 "회원"이 부여 받은 "아이디와 일치되는 "회원"임을 확인하고
        비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 각각이나 조합을
        의미합니다.
      </li>
      <li>
        ⑤"유료서비스"는 "회사"가 유료로 제공하는 골프 라운드 거리측정 서비스 및
        제반 서비스를 의미합니다.
      </li>
      <li>
        ⑥"포인트"는 골프 라운드 거리측정 서비스를 이용하기 위한 유료 재화입니다.
      </li>
      <li>
        ⑦"게시물"은 "회원"이 "서비스"를 이용함에 있어 "서비스"상에 게시한
        부호ㆍ문자 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을
        의미합니다.
      </li>
      <li>
        ⑧"인앱결제"는 본 서비스 약관 및 앱 마켓(구글 플레이스토어, 애플 앱스토어
        등) 약관에 의거하여 앱 내에서 재화 또는 콘텐츠를 구매하기 위한 결제
        행위를 의미합니다.
      </li>
      <li>
        ⑨"PG결제"는 "회원"이 "유료서비스"를 이용하기 위해 각종 지불수단을 통하여
        일정 금액을 회사 또는 회사가 정한 제3자에 지불하는 것을 의미합니다.
      </li>
      <li>
        ⑩"정기결제형 유료서비스"는 "유료서비스" 중 "정기결제" 형태로 결제가
        이루어지고 이용 기간이 자동으로 갱신되는 서비스를 말합니다.
      </li>
      <li>
        ⑪"정기결제"는 "회원"이 "정기결제형 유료서비스"를 이용하기 위해 미리
        등록한 결제 수단을 통하여 월간/연간 단위의 이용 기간에 대한 요금이
        정기적으로 결제되는 이용 방식을 말합니다. "정기결제"를 해지하면 남은
        이용기간 동안 "정기결제형 유료서비스"를 사용할 수 있으며 다음 결제
        예정일에 자동으로 해지됩니다.
      </li>
    </ol>
    <h2>제 3 조 (약관의 게시와 개정)</h2>
    <ol>
      <li>
        ①"회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에
        게시합니다.
      </li>
      <li>
        ②"회사"는 "약관의규제에관한법률",
        "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등
        관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
      </li>
      <li>
        ③"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 이전부터
        적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의
        경우에는 최소 30일 이상의 사전 유예기간을 두고 공지합니다. 공지 외에
        일정기간 서비스내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을
        통해 따로 명확히 통지하도록 합니다.
      </li>
      <li>
        ④회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간
        내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을
        명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를
        하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
      </li>
      <li>
        ⑤회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을
        적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만,
        기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을
        해지할 수 있습니다.
      </li>
      <li>
        ⑥회사가 고지의 의무를 이행했음에도 불구하고 회원이 변경된 약관에 대한
        정보를 알지 못해 발생하는 피해는 회사에서 책임지지 않습니다.
      </li>
    </ol>
    <h2>제 4 조 (약관의 해석)</h2>
    <ol>
      <li>①이 약관은 회사가 제공하는 서비스에 관한 이용안내에 적용합니다.</li>
      <li>
        ②이 약관에서 명시되지 아니한 사항에 대해서는 관계법령 및 서비스 별
        안내의 취지에 따라 적용할 수 있습니다.
      </li>
    </ol>
    <h1>제 2장 회원가입과 정보변경</h1>
    <h2>제 5 조 (회원가입)</h2>
    <ol>
      <li>
        ①이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에
        대하여 동의를 한 다음 회원가입신청을 하고 "회사"가 이러한 신청에 대하여
        승낙함으로써 체결됩니다.
      </li>
      <li>
        ②"회사"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로
        합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지
        않거나 사후에 이용계약을 해지할 수 있습니다.
        <ol>
          <li>
            가.가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는
            경우, 단 "회사"의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다
          </li>
          <li>나.실명이 아니거나 타인의 명의를 이용한 경우</li>
          <li>
            다.허위의 정보를 기재하거나, "회사"가 제시하는 내용(이메일,
            휴대폰번호)을 기재하지 않은 경우
          </li>
          <li>
            라.14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
          </li>
          <li>
            마.가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 약관
            규정을 위반하여 신청하는 경우
          </li>
          <li>
            바.부정한 용도 및 개인영리추구를 목적으로 서비스를 이용하고자 하는
            경우
          </li>
        </ol>
      </li>
      <li>
        ③제2항에 따른 신청에 있어 "회사"는 관계법령 및 회사정책에 따라 서비스의
        이용 가능한 범위, 시간, 횟수 등에 차등을 두어 이를 승낙할 수 있습니다.
      </li>
      <li>
        ④"회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는
        경우에는 승낙을 유보할 수 있습니다.
      </li>
      <li>
        ⑤제2항과 제3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우,
        "회사"는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
      </li>
      <li>
        ⑥제1항 및 제2항과 관련하여 회사는 사전 또는 사후에 회원에게 전문기관을
        통한 실명확인 및 본인인증을 요청할 수 있습니다.
      </li>
      <li>
        ⑦이용계약의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한
        시점으로 합니다.
      </li>
      <li>
        ⑧"회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여 이용시간,
        이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
      </li>
      <li>
        ⑨"회사"는 "회원"에 대하여 "영화및비디오물의진흥에관한법률" 및
        "청소년보호법"등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별
        제한을 할 수 있습니다.
      </li>
    </ol>
    <h2>제 6 조 (회원정보의 변경)</h2>
    <ol>
      <li>
        ①"회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고
        수정할 수 있습니다.
      </li>
      <li>
        ②"회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로
        수정을 하거나 전자우편 기타 방법으로 "회사"에 대하여 그 변경사항을
        알려야 합니다.
      </li>
      <li>
        ③제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는
        책임지지 않습니다.
      </li>
    </ol>
    <h2>제 7 조 (개인정보보호 처리방침)</h2>
    <ol>
      <li>
        ①"회사"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의
        개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는
        관련법 및 "회사"의 개인정보취급방침이 적용됩니다. 다만, "회사"의 공식
        사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이 적용되지
        않습니다.
      </li>
      <li>
        ② 회사는 서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙
        없이 제3자에게 누설, 배포하지 않습니다. 단, 아래 경우에는 그러하지
        않습니다.
        <ol>
          <li>
            가.전기통신기본법 등 법률 규정에 의해 국가기관의 요구가 있는 경우
          </li>
          <li>
            나.범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이
            있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
          </li>
          <li>
            다.회사에 가입한 미성년자의 법정대리인이 정보를 요청하여 서류상으로
            가족관계가 확인된 경우
          </li>
          <li>
            라.회원이 회사에 제공한 개인정보를 스스로 공개하거나 공개 승낙을 한
            경우(동의서명 경우)
          </li>
          <li>
            마.회사가 제공하는 정보, 자료 송부를 위하여 관련업체에게 최소한의
            회원의 정보를 알려주는 경우
          </li>
          <li>
            바.회사가 회원의 서비스 이용 편의나 원활한 이용을 위하여 여러 분야의
            전문 콘텐츠 사업자 혹은 비즈니스 사업자에게 최소한의 회원정보를
            알려주는 경우
          </li>
          <li>
            사.통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서
            특정개인을 식별할 수 없는 형태로 제공하는 경우
          </li>
        </ol>
      </li>
    </ol>
    <h2>제 8 조 ("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)</h2>
    <ol>
      <li>
        ①"회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며,
        이를 제3자가 이용하도록 하여서는 안 됩니다.
      </li>
      <li>
        ②"회사"는 "회원"의 "아이디"가 개인정보 유출 우려가 있거나, 반사회적 또는
        미풍양속에 어긋나거나 "회사" 및 "회사"의 운영자로 오인한 우려가 있는
        경우, 해당 "아이디"의 이용을 제한할 수 있습니다.
      </li>
      <li>
        ③"회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을
        인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야
        합니다.
      </li>
      <li>
        ④제3항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지 않거나, 통지한
        경우에도 "회사"의 안내에 따르지 않아 발생한 불이익에 대하여 "회사"는
        책임지지 않습니다.
      </li>
    </ol>
    <h2>제 9 조 ("회원"에 대한 통지)</h2>
    <ol>
      <li>
        ①"회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한
        서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
      </li>
      <li>
        ②"회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의 게시판에
        게시함으로써 제1항의 통지에 갈음할 수 있습니다.
      </li>
    </ol>
    <h2>제 10 조 ("회사"의 의무)</h2>
    <ol>
      <li>
        ①"회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
        않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여
        노력합니다.
      </li>
      <li>
        ②"회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록
        개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며
        개인정보취급방침을 공시하고 준수합니다.
      </li>
      <li>
        ③"회사"는 서비스이용과 관련하여 발생하는 이용자의 불만 또는
        피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
        구비합니다.
      </li>
      <li>
        ④"회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이
        정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한
        의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여
        "회원"에게 처리과정 및 결과를 전달합니다.
      </li>
    </ol>
    <h2>제 11 조 ("회원"의 의무)</h2>
    <ol>
      <li>
        ①"회원"은 다음 행위를 하여서는 안 됩니다.
        <ol>
          <li>가.신청 또는 변경 시 허위내용의 등록</li>
          <li>나.타인의 정보도용</li>
          <li>다."회사"가 게시한 정보의 변경</li>
          <li>
            라."회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는
            게시 행위
          </li>
          <li>마."회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
          <li>
            바."회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          </li>
          <li>
            사.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
            정보를 "서비스"에 공개 또는 게시하는 행위
          </li>
          <li>아.회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위</li>
          <li>
            자.구매 또는 이벤트로 획득한 포인트, APL골프 "서비스" 등을 유상양도,
            판매, 담보제공 등의 방법으로 처분하는 행위
          </li>
          <li>
            차.윤락행위 알선, 음행을 매개하는 내용의 정보를 유통시키는 경우
          </li>
          <li>카.타 회원의 개인정보를 수집 또는 저장하는 경우</li>
          <li>타.기타 불법적이거나 부당한 행위</li>
        </ol>
      </li>
      <li>
        ②"회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한
        주의사항, "회사"가 통지하는 사항 등을 준수하여야 하며, 기타 "회사"의
        업무에 방해되는 행위를 하여서는 안 됩니다.
      </li>
    </ol>
    <h1>제 3장 서비스제공 및 이용</h1>
    <h2>제 12 조 ("서비스"의 이용시간 및 제공)</h2>
    <ol>
      <li>
        ①"서비스"의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, "회사"의
        업무 상이나 기술상의 이유로 서비스가 일시 중지될 수 있고, 또한 운영 상의
        목적으로 "회사"가 정한 기간에는 서비스가 일시 중지될 수 있습니다.
      </li>
      <li>
        ②"회사"는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을
        별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에
        공지합니다.
      </li>
      <li>
        ③"회사"는 회원에게 하기 내용과 같은 서비스를 제공합니다.
        <ol>
          <li>가.골프장 정보 제공(주소, 코스, 홀, 전화번호, 날씨 등)</li>
          <li>나.위치정보 기반 서비스(APL)</li>
          <li>다.스코어카드 입력</li>
          <li>라.스코어관리 서비스</li>
          <li>마.게시판형 서비스(맛집 등)</li>
          <li>
            바.기타 "회사"가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해
            "회원"에게 제공하는 일체의 서비스
          </li>
        </ol>
      </li>
      <li>
        ④"회사"는 위치기반 서비스 제공을 위하여 회원의 위치정보를 이용할 수
        있고, 회원은 본 약관에 동의함으로써 이에 동의한 것으로 간주하며 회사는
        회원이 제공한 개인위치정보를 고객의 동의 없이 서비스 제공 이외의
        목적으로 이용하지 않습니다.
      </li>
      <li>
        ⑤"회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절,
        서비스이용의 폭주 등으로 운영상 상당한 이유가 있는 경우 "서비스"의
        제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제9조["회원"에
        대한 통지]에 정한 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에
        통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
      </li>
      <li>
        ⑥"회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
        정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
      </li>
    </ol>
    <h2>제 13 조 ("서비스"의 변경)</h2>
    <ol>
      <li>
        ①"회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
        제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.
      </li>
      <li>
        ②"서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
        변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스
        초기화면에 게시하여야 합니다.
      </li>
      <li>
        ③"회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및
        운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한
        규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.
      </li>
    </ol>
    <h2>제 14 조 (정보의 제공 및 광고의 게재)</h2>
    <ol>
      <li>
        ①"회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를
        공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만,
        "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을
        제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
      </li>
      <li>
        ②제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는
        "회원"의 사전 동의를 받아서 전송합니다. 다만, "회원"의 거래관련 정보 및
        고객문의 등에 대한 회신에 있어서는 제외됩니다.
      </li>
      <li>
        ③"회사"는 "서비스"의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편
        등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은
        수신거절을 "회사"에게 할 수 있습니다.
      </li>
      <li>
        ④"이용자(회원, 비회원 포함)"는 회사가 제공하는 서비스와 관련하여 게시물
        또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
      </li>
    </ol>
    <h2>제 15 조 ("게시물"의 관리)</h2>
    <ol>
      <li>
        ①"회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는
        내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의
        게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법에 따라 조치를
        취하여야 합니다.
      </li>
      <li>
        ②"회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될
        만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에
        따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.
      </li>
      <li>
        ③"회사"는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이
        삭제하거나 이동 또는 등록 거부를 할 수 있습니다.
        <ol>
          <li>
            가.다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는
            내용인 경우
          </li>
          <li>
            나.공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우
          </li>
          <li>다.불법복제 또는 해킹을 조장하는 내용인 경우</li>
          <li>라.범죄와 결부된다고 객관적으로 인정되는 내용일 경우</li>
          <li>마.영리를 목적으로 하는 광고의 경우</li>
          <li>
            바.다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인
            경우
          </li>
          <li>
            사. 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지
            않는 경우
          </li>
        </ol>
      </li>
    </ol>
    <h2>제 16 조 ("게시물"의 저작권)</h2>
    <ol>
      <li>
        ①"회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물을 게시한
        "회원"에게 귀속됩니다.
      </li>
      <li>
        ②"회원"이 "서비스" 내에 게시하는 "게시물"을 "회사"는 게시자의 동의 없이
        상업적으로 이용할 수 없습니다.
      </li>
      <li>
        ③"회원"은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등
        서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
      </li>
    </ol>
    <h2>제 17 조 (권리의 귀속)</h2>
    <ol>
      <li>
        ①"서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다. 단,
        "회원"의 "게시물" 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
      </li>
      <li>
        ②"회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라
        계정, "아이디", 콘텐츠, "포인트" 등을 이용할 수 있는 이용권만을
        부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수
        없습니다.
      </li>
    </ol>
    <h2>제 18 조 (주문 및 결제)</h2>
    <ol>
      <li>
        ①"회원"은 회사가 제공하는 다음 각 호 또는 이와 유사한 절차에 의하여 재화
        구매를 신청합니다.
        <ol>
          <li>가.재화 목록 확인 및 구매 대상 선택</li>
          <li>나.결제금액 확인 및 결제</li>
          <li>다.결제금액 재확인 및 재화 지급 확인</li>
        </ol>
      </li>
      <li>
        ②"결제"는 "인앱결제"와 "PG결제"로 이뤄집니다. "인앱결제"는 앱 마켓이
        정하는 정책, 방법 등에 따라 금액을 납부하고 "PG결제"는 회사가 정한
        제3자에 금액을 지불합니다.
      </li>
      <li>
        ③"결제" 금액은 서비스 내에서 표기된 가격에 의하나, 외화 결제 시 환율 및
        앱 마켓의 수수료 등으로 인해 구매 시점의 예상 지불 금액과 실제
        청구금액이 달라질 수 있습니다.
      </li>
    </ol>
    <h2>제 19 조 (포인트)</h2>
    <ol>
      <li>
        ①"회사"는 "회원"의 구매활동, 이벤트 참여 등에 따라 "회원"에게 일정한
        "포인트"를 부여할 수 있습니다.
      </li>
      <li>
        ②"회원"은 "포인트"를 결제 수단으로 사용할 수 있으며, "회사"는 "포인트"의
        적립기준, 사용방법, 사용기한 및 제한에 대한 사항을 "서비스" 화면에
        별도로 게시하거나 통지합니다. "포인트"의 사용조건에 관한 사항은 "회사"의
        정책에 따라 달라질 수 있습니다.
      </li>
      <li>
        ③"포인트"의 유효기간은 하기 내용과 같습니다.
        <ol>
          <li>
            가.결제를 통해 구매한 포인트(유료 포인트)는 상법 제64조 상사시효에
            근거해 구매 시점으로부터 5년 경과 후 소멸
          </li>
          <li>
            나.앱 내 활동 및 이벤트로 지급받은 포인트(무료 포인트)는 지급
            시점으로부터 365일 경과 후 소멸
          </li>
          <li>
            다.유효기간 동안 사용하지 않을 경우 포인트는 순차적으로 소멸됩니다.
            다만, 마케팅 및 기타 프로모션 등을 통하여 지급되거나 사전특약(사전
            안내 포함)이 있는 "포인트"의 유효기간은 각각 별도로 설정될 수
            있습니다.
          </li>
        </ol>
      </li>
      <li>
        ④"포인트"의 사용은 1) 유효기간이 임박한 포인트 우선 사용, 2) 유효기간이
        동일한 경우 무료로 적립된 포인트 우선 사용됩니다.
      </li>
      <li>
        ⑤"포인트"는 현금으로 환급될 수 없으며, "포인트"의 사용 기간이 만료되거나
        이용계약이 종료되면 별도의 고지 없이 소멸될 수 있습니다.
      </li>
      <li>
        ⑥"회원"은 "포인트"을 제3자 또는 다른 아이디로 양도할 수 없으며 유상으로
        거래하거나 현금으로 전환할 수 없습니다.
      </li>
      <li>
        ⑦"회사"는 "회원"이 "회사"가 승인하지 않은 방법 또는 허위 정보 제공, 본
        약관에 위배되는 등의 부정한 방법으로 "포인트"를 획득하거나 부정한
        목적이나 용도로 "포인트"를 사용하는 경우 "포인트"의 사용을 제한하거나
        "회사"가 정한 방법으로 회수할 수 있으며, "포인트"를 사용한 구매신청을
        취소 할 수 있고, 회원 자격을 정지할 수 있습니다.
      </li>
      <li>
        ⑧"회원" 탈퇴 시 전환/적립된 "포인트" 중 미사용한 "포인트"는 즉시
        소멸되며, 탈퇴 후 재가입하더라도 소멸된 "포인트"는 복구되지 아니합니다.
      </li>
    </ol>
    <h2>제 20 조 (이용권)</h2>
    <ol>
      <li>
        ①"회원"은 앱 마켓을 통한 "정기결제" 또는 앱 내 재화인 "포인트"를 사용해
        "이용권"을 구매할 수 있으며 오픈 마켓 등에서 구매 또는 이벤트로 지급받은
        "쿠폰"을 등록해 "이용권"을 획득할 수 있습니다.
      </li>
      <li>
        ②"이용권"은 APL골프의 "서비스" 중 골프 라운드 거리측정 서비스에서 사용할
        수 있는 "상품"으로 해당 "이용권"의 결제완료 시부터 약정 기간 또는 약정
        횟수만큼 라운드 거리측정 서비스를 이용할 수 있는 유료 서비스입니다.
      </li>
      <li>
        ③"이용권"은 "회원"의 사용 요청에 따라 사용 처리되며 약정 기간 또는 약정
        횟수를 다하면 자동 사용 종료 처리됩니다.
      </li>
      <li>
        ④"이용권"의 유효기간은 1년(365일) 으로 유효기간 동안 이용권을 사용
        개시하지 않는 경우 소멸합니다. 다만, 마케팅 및 기타 프로모션 등을 통하여
        지급되거나 사전특약(사전 안내 포함)이 있는 "이용권"의 유효기간은 각각
        별도로 설정될 수 있습니다.
      </li>
      <li>
        ⑤"이용권"의 사용기간은 유효기간 내에 사용처리한 "이용권"을 이용할 수
        있는 기간으로 회사가 설정한 기간에 따라 설정됩니다. 사용 기간의 단위는
        시간과 일수로 구분됩니다. 예를 들어 1일 이용권을 사용한 경우 24시간 동안
        사용할 수 있고 24시간 후 이용권은 사용 종료 상태가 되어 더 이상 사용할
        수 없습니다.
      </li>
      <li>
        ⑥"이용권"은 한 명의 "회원"이 이용중일 경우 동일한 계정으로
        다수의 단말기에서 동시에 라운드를 이용할 수 없습니다. 이미 해당 "회원"이
        라운드 거리측정 서비스를 이용중인 경우 먼저 이용중인 디바이스에서
        라운드가 종료 처리됩니다.
      </li>
      <li>
        ⑦"이용권"을 사용해 라운드 거리측정 서비스를 이용하고 라운드가 정상적으로
        종료되지 않은 경우 안정적인 서비스 운영 및 부정 이용 방지를 위해
        "회사"는 매일 오전 2:00에 모든 라운드를 일괄 종료 처리합니다.
      </li>
      <li>
        ⑧"포인트"를 사용하여 구매한 "이용권"에 대해 환불을 요청한 경우에는
        "이용권"의 사용이 즉시 중지되며 사용된 순서의 반대로 환불됩니다. 사용한
        재화는 구매 당시 지급했던 재화 그대로 3 영업일 이내에 환급합니다.
      </li>
      <li>
        ⑨"인앱결제"를 사용한 "정기결제형 유료서비스"의 "이용권" 구매에 대한
        환불을 요청한 경우에는 각 스토어 이용약관에 따라 스토어에서 직접
        관리합니다. 오픈 마켓 등에서 구매한 "이용권"의 취소·환불은 구매처에 문의
        바랍니다.
      </li>
      <li>
        ⑩"서비스"의 중대한 하자에 의해 콘텐츠가 손상, 훼손, 삭제되었거나
        불량했을 경우 "포인트"를 환급하거나 콘텐츠의 복원 등으로 보상 받을 수
        있습니다.
      </li>
    </ol>
    <h2>제 21 조 (청약철회)</h2>
    <ol>
      <li>
        ①"회원"은 "회사"가 제공하는 서비스, 재화 등의 구매에 관하여 계약을
        체결한 경우 구매일(계약체결일) 또는 이용가능일로부터 7일 이내에 별도의
        수수료 없이 청약철회의 신청을 할 수 있습니다. 다만, 회사가 회원에게
        사전에 합의한 내용과 다르게 서비스를 제공하거나 서비스를 제공하지
        아니하는 경우, 회원은 유료 결제를 한 날로부 터 3개월 이내 또는 해당
        사실을 알거나 알 수 있었던 날로부터 30일 이내에 회사에 대하여 서비스의
        이용에 관한 청약을 철회할 수 있습니다. 단, 상품 특성에 따라 별도의 위약
        규정이 있는 상품의 경우해당 상품별로 별도 규정된 위약 규정을 고지하며
        이용자가 구매 시에 이러한 위약 규정을 고지 받은 경우에는 본 약관보다
        우선 적용됩니다.
      </li>
      <li>
        ②구매 후 7일이 지났거나 사용한 경우, 재화 등의 가치가 현저히 감소한
        경우, 기타 청약철회가 제한될 수 있는 사유가 발생한 경우에는 관련 법령에
        따라 청약철회가 제한될 수 있습니다. 이 경우 회사는 회원에 대하여 해당
        유료서비스 이용시 고지하는 등 관련 법률에서 정한 바에 따른 조치를
        취하기로 합니다.
      </li>
      <li>
        ③회원은 다음 각 호의 어느 하나에 해당하는 경우에는 회사의 의사에 반하여
        제2항에 따른 청약철회를 할 수 없습니다.
        <ol>
          <li>
            가.회원에게 책임이 있는 사유로 재화 등이 멸실 또는 훼손된 경우
          </li>
          <li>나.회원이 재화 등을 사용 또는 일부 소비한 경우</li>
          <li>다.시간이 지나 다시 판매하기 곤란할 경우</li>
          <li>라.그 밖에 거래의 안전을 위하여 법령으로 정하는 경우</li>
        </ol>
      </li>
      <li>
        ④재화등의 구매를 미성년자가 법정대리인의 동의 없이 진행한 경우, 미성년자
        또는 법정대리인은 "회사"에게 청약철회를 요청할 수 있으며, "회사"는
        법정대리인임을 증명할 수 있는 서류를 요구할 수 있습니다.
      </li>
      <li>
        ⑤"회원"은 구두 또는 서면(전자문서 포함)으로 청약철회를 할 수 있습니다.
      </li>
      <li>
        ⑥구매자가 교환 또는 반품신청을 한 날로부터 14일이 지날 때까지 이미
        수령한 상품 등을 판매자에게 반환하지 아니 하거나 전화, 이메일 등으로
        연락을 받지 아니하는 경우에는 해당 구매자의 교환 또는 반품신청은 효력을
        상실합니다
      </li>
      <li>
        ⑦청약철회 및 환불 등을 요구할 경우에는 회사 고객센터를 통해 문의할 수
        있으며 회사는 각 스토어를 통해 구매내역을 확인하는 절차를 거친 다음
        진행됩니다. 7일 이내에 청약철회 및 환불을 요구한 경우 별도의 절차 없이
        처리되나 7일을 초과한 경우 이용자의 정당한 철회 사유를 확인하기 위해
        필요 시 이용자에게서 제공받은 정보를 통해 이용자에게 연락하여 정확한
        사실 확인을 할 수 있으며, 추가적으로 증빙을 요구할 수 있습니다.
      </li>
      <li>
        ⑧애플리케이션을 통한 결제는 각 스토어가 제공하는 결제방식에 따릅니다.
        따라서 결제 과정에서 과오금이 발생하는 경우 원칙적으로 각 스토어에
        환급을 요청하여야 합니다. 단, 각 스토어의 정책, 시스템에 따라 가능한
        경우 회사가 각 스토어에게 필요한 환급절차의 이행을 요청할 수도 있습니다.
      </li>
    </ol>
    <h2>제 22조 (청약철회 효과)</h2>
    <ol>
      <li>
        ①회사는 청약철회 또는 계약해지의 의사표시를 수령한 날로부터 3영업일
        이내에 대금의 결제와 동일한 방법으로 대금을 환불하는 것을 원칙으로 하되,
        동일한 방법으로 환불이 불가능할 때에는 즉시 이를 고지하고 회원이 선택한
        방법으로 환불합니다.
      </li>
      <li>
        ②회사는 회원의 결제 건에 대하여 결제업자 또는 앱마켓에 대하여 대금지급의
        정지, 취소 등 「전자상거래등에서의 소비자 보호에 관한 법률」에 따른
        필요한 조치를 취합니다. "회사"의 요청에 따른 앱 마켓의 조치에 관해서는
        해당 앱 마켓의 정책을 참고하여 주시기 바랍니다. 이후 발생하는 환불 절차
        및 기간의 문제에 대해서 "회사"는 책임지지 않습니다.
      </li>
      <li>
        ③"회원"이 앱 마켓 결제 정책을 부정하게 이용하는 등 환불 대상이 아님에도
        부당하게 유료 포인트를 취득한 경우 "회사"는 "회원"이 보유한 유료
        포인트에서 환불액만큼 차감하거나 잔액이 부족한 경우 "회원"에게 해당
        환불액의 반환을 청구할 수 있습니다.
      </li>
    </ol>
    <h2>제 23 조 (환불)</h2>
    <ol>
      <li>
        ①"회원"은 앱 마켓 청약철회 기간의 초과 등 유료 포인트의 청약철회가
        불가능한 경우에도 잔액에 대해 1:1문의를 통해 "회사"에게 환불을 요청할 수
        있습니다.
      </li>
      <li>
        ②"회원"이 포인트 구매를 통해 획득한 무료 포인트를 사용하고 환불을 요청한
        경우 해당 금액 만큼 공제하고 남은 유료 포인트 잔액을 환불합니다.
      </li>
      <li>
        ③"회사"는 청약철회 기간을 초과한 유료 포인트 잔액에 대해서 10%의 환불
        수수료를 공제한 금액을 환불합니다. 다만, 남은 유료 포인트 잔액이
        10,000포인트 미만인 경우 1,000원의 환불 수수료를 공제하고 잔액이
        1,000포인트 이하인 경우 환불이 불가합니다. 회사의 귀책 사유가 있는 경우
        환불 수수료를 공제하지 않습니다.
      </li>
      <li>
        ④"회사"는 환불을 위해 "회원"의 정보를 요청할 수 있으며 적법한 확인
        절차가 완료되면 회원이 선택한 방법으로 3영업일 이내에 환불합니다. 만약
        "회사"가 환급을 지연한 경우 「전자상거래법」 등 법률에서 정하는 이율에
        따라 지연가산금을 지급합니다.
      </li>
    </ol>
    <h2>제 24 조 (과오금 환급)</h2>
    <ol>
      <li>
        ①과오금이 발생한 경우 회사는 이용대금 결제와 동일한 방법으로 환급합니다.
        단, 동일한 방법으로 환급이 불가능할 때에는 이를 사전에 고지합니다.
      </li>
      <li>
        ②"회원"에게 책임 있는 사유로 과오금이 발생한 경우 환급에 소요되는 수수료
        등은 "회원"이 부담합니다.
      </li>
      <li>
        ③"회사"에게 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용,
        수수료 등에 관계없이 과오금 전액을 환급합니다.
      </li>
      <li>
        ④"회사"의 과오금 환급절차는 「디지털 콘텐츠 이용자 보호지침」에
        따릅니다.
      </li>
    </ol>
    <h2>제 25 조 (손해배상)</h2>
    <p>
      회사는 서비스에서 무료로 제공하는 서비스의 이용과 관련하여
      개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대하여는 어떠한
      손해도 책임을 지지 않습니다.
    </p>
    <h2>제 26 조 (계약해제, 해지 등)</h2>
    <ol>
      <li>
        ①"회원"은 언제든지 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할
        수 있으며, "회사"는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야
        합니다.
      </li>
      <li>
        ②"회원"이 계약을 해지할 경우, 관련법 및 개인정보취급방침또는 회사
        내부관리 계획(반복 재가입으로 인한 부정방지 이용 : 30일)에 따라 "회사"가
        회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는
        소멸됩니다.
      </li>
      <li>
        ③"회원"이 계약을 해지하는 경우, "회원"이 작성한 본인 계정에 등록된
        게시물 일체는 삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 되어
        재게시되거나, 공용게시판에 등록된 "게시물" 등은 삭제되지 않으니 사전에
        삭제 후 탈퇴하시기 바랍니다.
      </li>
    </ol>
    <h2>제 27 조 (이용제한 등)</h2>
    <ol>
      <li>
        ①"회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인
        운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을
        단계적으로 제한할 수 있습니다.
      </li>
      <li>
        ②"회사"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용,
        "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및
        운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포,
        접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를
        할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한
        "포인트" 및 기타 혜택 등도 모두 소멸되며, "회사"는 이에 대해 별도로
        보상하지 않습니다.
      </li>
      <li>
        ③"회사"는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의
        보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
      </li>
      <li>
        ④"회사"는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
        이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.
      </li>
      <li>
        ⑤본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는
        "회사"는 제9조["회원"에 대한 통지]에 따라 통지합니다.
      </li>
      <li>
        ⑥"회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라
        이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는
        경우 "회사"는 즉시 "서비스"의 이용을 재개합니다.
      </li>
    </ol>
    <h2>제 28 조 (책임제한)</h2>
    <ol>
      <li>
        ①"회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를
        제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.
      </li>
      <li>
        ②"회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는
        책임을 지지 않습니다.
      </li>
      <li>
        ③"회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
        정확성 등의 내용에 관하여는 책임을 지지 않습니다. (컴퓨터오류, 신상정보,
        전자우편 주소 부실기재 등)
      </li>
      <li>
        ④"회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여
        거래 등을 한 경우에는 책임이 면제됩니다.
      </li>
      <li>
        ⑤"회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이
        없는 한 책임을 지지 않습니다.
      </li>
      <li>
        ⑥ 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로
        제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
      </li>
      <li>
        ⑦ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로
        발생한 손해에 대한 책임이 면제됩니다.
      </li>
      <li>
        ⑧ 회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로
        발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도
        없습니다.
      </li>
    </ol>
    <h2>제 29 조 (준거법 및 재판관할)</h2>
    <ol>
      <li>①"회사"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
      <li>
        ②"회사"와 "회원"간 발생한 분쟁에 관한 소송은 제소 당시의 "회원"의 주소에
        의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다.
        단, 제소 당시 "회원"의 주소 또는 거소가 명확하지 아니한 경우의
        관할법원은 민사소송법에 따라 정합니다.
      </li>
      <li>
        ③해외에 주소나 거소가 있는 "회원"의 경우 "회사"와 "회원"간 발생한 분쟁에
        관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로
        합니다.
      </li>
    </ol>
    <h2>제 30 조 (이용약관에 대한 문의 사항)</h2>
    <p>"이용약관"에 대한 의문 사항은 아래 연락처를 참조하시기 바랍니다.</p>
    <p>1. 대표전화 : 070-7720-3851</p>
    <p>2. 이메일 : support@vcsoft.co.kr</p>
    <h2>부칙</h2>
    <ol>
      <li>①본 약관은 2023년 08월 14일부터 적용됩니다.</li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Service",
};
</script>
