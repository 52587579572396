<template>
  <default-layout>
    <template v-slot:header>
      <div class="bl_ttl">{{ title }}</div>
      <div class="bl_right is_moClose" @click="back"></div>
    </template>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="ly_container">
          <nm-map
            :lat="lat"
            :lng="lng"
            :service-type="serviceType"
            :marker-label="buttonTxt"
            @location-info="setCurrentLocationInfo"
            @out-of-area="disabled = $event"
          />
          <div class="bl_info">
            <div class="bl_info_desc">
              {{ routeTxt }} 위치를 조정하려면 핀을 이동하세요.
            </div>
            <div class="bl_info_ttl">{{ name }}</div>
            <div class="bl_info_txt">
              {{ address }}
            </div>
            <div class="bl_info_btn">
              <nm-button
                class="is_moPrim"
                :disabled="disabled"
                @click="setLocation"
                >{{ buttonTxt }}지 설정</nm-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import { setDataTopToBottomWebLayer, closeWebviewLayer } from "@/util/webview";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import NmMap from "@/components/modules/nearme/NmMap.vue";
import NmButton from "@/components/nearme/NmButton.vue";
export default {
  name: "PlaceOnMap",
  components: {
    DefaultLayout,
    NmMap,
    NmButton,
  },
  data() {
    return {
      title: "",
      serviceType: null,
      name: "",
      address: "",
      lat: "",
      lng: "",
      disabled: false,
      route: "",
      searchName: null,
    };
  },
  created() {
    const { service_type, lat, lng, route, search_name } = this.$route.query;
    this.serviceType = service_type;
    this.lat = lat;
    this.lng = lng;
    this.route = route;
    this.searchName = search_name;

    this.title =
      this.serviceType === "club" ? "골프장 픽업 예약" : "공항 픽업 예약";

    this.$nextTick(() => {
      document.title = this.title;
    });
  },
  computed: {
    routeTxt() {
      return this.route === "departure" ? "탑승" : "도착";
    },
    buttonTxt() {
      return this.route === "departure" ? "출발" : "도착";
    },
  },
  methods: {
    back() {
      closeWebviewLayer();
    },
    setCurrentLocationInfo(info) {
      this.name = info.name;
      this.address = info.address;
      this.lat = info.lat;
      this.lng = info.lng;
    },
    async setLocation() {
      if (this.route === "departure") {
        setDataTopToBottomWebLayer({
          route: "departure",
          name: this.name,
          eng_name: null,
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          search_name: this.searchName,
        });
      } else {
        setDataTopToBottomWebLayer({
          route: "arrival",
          name: this.name,
          eng_name: null,
          address: this.address,
          lat: this.lat,
          lng: this.lng,
          search_name: this.searchName,
        });
      }
    },
  },
};
</script>