<template>
  <div>
    <template v-for="(content, i) in parsedContent">
      <span v-if="content.type === 'text'" :key="i">{{ content.value }}</span>
      <span
        v-if="content.type === 'html'"
        :key="i"
        v-html="content.value"
      ></span>
    </template>
  </div>
</template>

<script>
import { HTTP_EXPRESSION, URL_EXPRESSION } from "@/util/string";

export default {
  name: "ContentWithAnchor",
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  methods: {
    addAnchorTagOnUrl(content = "") {
      const urlRegex = new RegExp(URL_EXPRESSION);
      const httpRegex = new RegExp(HTTP_EXPRESSION);

      return content
        .split("\n")
        .map((v) => {
          return [
            ...v.split(urlRegex).map((_v) =>
              urlRegex.test(_v)
                ? {
                    type: "html",
                    value: httpRegex.test(_v)
                      ? _v.replace(
                          urlRegex,
                          '<a href="$1" target="_blank">$1</a>'
                        )
                      : _v.replace(
                          urlRegex,
                          '<a href="https://$1" target="_blank">$1</a>'
                        ),
                  }
                : {
                    type: "text",
                    value: _v,
                  }
            ),
            {
              type: "html",
              value: "</br>",
            },
          ];
        })
        .flat();
    },
  },
  computed: {
    parsedContent() {
      return this.addAnchorTagOnUrl(this.content);
    },
  },
};
</script>
