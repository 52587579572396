import http from "@/util/http";

function getCategories() {
  return http.get(`/partnership/akgolf/category`);
}

function getCategoryProducts(categoryCd) {
  return http.get(`/partnership/akgolf/category/${categoryCd}/product`);
}

function getCategoryProduct(categoryCd, productId) {
  return http.get(
    `/partnership/akgolf/category/${categoryCd}/product/${productId}`
  );
}

export default {
  getCategories,
  getCategoryProducts,
  getCategoryProduct,
};
