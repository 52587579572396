<template>
  <transition name="fade" appear>
    <div class="ly_layer ly_alert">
      <div class="ly_layer_inner">
        <div class="ly_layer_inner2">
          <div class="ly_layer_inner3">
            <transition name="pop-up" appear>
              <div class="ly_layer_inner4">
                <main class="ly_body">
                  <slot name="message" />
                </main>
                <footer class="ly_footer">
                  <!-- 비활성화(C) : is_acDisabled -->
                  <slot name="footer" />
                </footer>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "ModalLayout",
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>