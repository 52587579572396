<template>
  <div class="mdSelect" @click.self="close">
    <div class="mdSelect-wrapper">
      <div class="mdSelect-header">
        <section class="stTitle">
          <i class="close" @click="close"> 닫기 </i>
        </section>
      </div>
      <div class="mdSelect-body" v-if="postImageList && postImageList.length">
        <!-- 활성 : is-active -->
        <Splide
          ref="imageContainer"
          :options="slideOption"
          @splide:move="onMoveSlide"
          @splide:drag="onDragSlide"
          @splide:dragged="onDraggedSlide"
        >
          <SplideSlide
            class="cmPost-imageItem"
            v-for="image in postImageList"
            :key="image.originPath"
          >
            <div class="cmPost-imageContent">
              <img
                v-if="image.originPath"
                :src="image.originPath"
                ref="postImage"
                @touchstart="onTouchStart"
                @touchmove="onTouchMove"
                @touchend="onTouchEnd"
                alt=""
              />
            </div>
          </SplideSlide>
        </Splide>
      </div>
      <div class="cmPost-imageLabel" v-if="postImageList.length > 1">
        {{ currentSlide + 1 }}/{{ postImageList.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { boardApi } from "@/api";
import { closeWebviewLayer } from "@/util/webview";

export default {
  name: "LocationSearchModal",
  async created() {
    const { startIndex } = this.$route.query;
    const { boardId } = this.$route.params;
    this.currentSlide = Number(startIndex) || 0;
    this.boardId = boardId;
    await this.getBoard();
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("is-activePostImage");
  },
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      boardId: "",
      board: null,
      currentSlide: 0,
      dragging: false,
      scaleImageRef: null,
    };
  },
  methods: {
    async getBoard() {
      try {
        const { data: board } = await boardApi.getBoard(this.boardId);
        this.board = board;
      } catch (e) {
        if (!e?.code) {
          return;
        }
        switch (e.code) {
          case "5901":
            close();
            return;
          default:
            return;
        }
      }
    },
    close() {
      closeWebviewLayer();
    },
    onMoveSlide(target, next) {
      this.currentSlide = next;
    },
    onDragSlide() {
      this.dragging = true;
    },
    onDraggedSlide() {
      this.dragging = false;
    },
    onTouchStart(e) {
      if (e.touches.length === 2) {
        this.scaleImageRef = this.$refs.imageContainer.$el;
        this.scaling = true;
        this.$el.classList.add(`onScale`);
      }
    },
    onTouchMove(e) {
      if (this.scaling && !this.dragging) {
        this.pinchEvt(e);
        e.stopPropagation();
      }
    },
    onTouchEnd() {
      if (this.scaling) {
        this.$el.classList.remove(`onScale`);
        this.scaleImageRef.style.transform = `scale(1) translate(0, 0)`;
        this.scaling = false;
        this.dist = undefined;
        this.scaleImageRef = null;
        this.initVectorAvg = null;
      }
    },
    pinchEvt(e) {
      const dist = Math.hypot(
        e.touches[0].pageX - e.touches[1].pageX,
        e.touches[0].pageY - e.touches[1].pageY
      );
      this.dist ??= dist;
      const imageWidth = this.scaleImageRef.clientWidth;
      const scale = 1 + (dist - this.dist) / imageWidth;
      const { x, y } = this.getVectorAvg(e.touches);
      this.initVectorAvg ??= { x, y };
      this.scaleImageRef.style.transform = `scale(${scale}) translate(${-(
        this.initVectorAvg.x - x
      )}px, ${-(this.initVectorAvg.y - y)}px)`;
    },
    getVectorAvg(vectors) {
      return {
        x:
          [...vectors]
            .map((v) => {
              return v.clientX;
            })
            .reduce((a, b) => a + b) / vectors.length,
        y:
          [...vectors]
            .map((v) => {
              return v.clientY;
            })
            .reduce((a, b) => a + b) / vectors.length,
      };
    },
  },
  computed: {
    postImageList() {
      let imageList = this.board?.boardImageList || [];
      if (typeof imageList === "string") {
        imageList = imageList
          ?.split(",")
          .map((image) => ({ originPath: image }));
      }
      return imageList;
    },
    slideOption() {
      return {
        arrows: false,
        pagination: false,
        drag: !this.scaling,
        start: this.currentSlide || 0,
      };
    },
  },
};
</script>

<style scoped>
.mdSelect {
  visibility: visible;
  background-color: #fff;
}
.mdSelect-wrapper {
  position: static;
  height: 100%;
}
.mdSelect-body {
  height: 100%;
}
.mdSelect-header .stTitle .close {
  z-index: 100;
  right: 20px;
  top: 13px;
}

.cmPost-imageLabel {
  left: 50%;
  transform: translate(-50%);
  right: auto;
}
>>> .splide,
>>> .splide__track,
>>> .splide__list {
  height: 100%;
}
>>> .splide__list {
  width: 100%;
  align-items: center;
}
>>> .splide__slide {
  display: flex;
  height: 100%;
  max-height: calc(100% - 156px);
}
.cmPost-imageContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.cmPost-imageContent img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
