<template>
  <div class="policy">
    <h1>개인정보 수집/이용 동의</h1>
    <h2>[NICE평가정보 귀중]</h2>
    <p>
      NICE평가정보(주) (이하 “회사”)가 에스케이텔레콤(주), (주)케이티,
      LG유플러스(주)의 업무 대행을 통해 제공하는 휴대폰본인확인서비스와 관련하여
      고객으로부터 수집한 고유식별정보를 이용 및 처리하거나 제3자에게 제공하고자
      할 때에는 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’에 따라서
      고객으로 부터 동의를 받아야 합니다.
    </p>
    <h2>1. 개인정보의 내용</h2>
    <h3>가. 정보항목</h3>
    <ol>
      <li>
        - 생년월일, 성명, 성별, 내/외국인 구분, 휴대폰번호, 가입한 이동통신사,
        IP주소
      </li>
    </ol>
    <h3>나. 수집방법</h3>
    <ol>
      <li>
        - 본인인증 요청시 회원사 서비스 페이지 또는 본인인증 팝업창 페이지에서
        이용자가 직접 입력
      </li>
      <li>- 본인인증 과정에서 시스템에서 생성되는 정보 수집(Ex. IP주소)</li>
    </ol>
    <h2>2. 개인정보의 이용목적과 제공정보</h2>
    <h3>가. 이용 목적</h3>
    <ol>
      <li>
        - 고객의 회원가입, ID/PW찾기, 거래동의 등을 위한 휴대폰 본인확인 결과
      </li>
      <li>- 휴대폰 소지 확인을 위한 SMS 인증번호 전송</li>
      <li>- 부정 이용 방지 및 수사의뢰</li>
      <li>- 휴대폰번호보호서비스 해제(서비스 가입자에 한함)</li>
      <li>- 기타 법률에서 정한 목적</li>
    </ol>
    <h3>나. 제공하는 개인정보</h3>
    <ol>
      <li>
        - 성명, 성별, 생년월일, 내/외국인, 휴대폰번호, 이동통신사, IP주소, CI,
        DI [제공사 : 서비스회원사(업체별 선택 및 이용방식에 따라 전부 또는
        일부를 제공)]
      </li>
      <li>
        - 성명, 성별, 생년월일, 휴대폰번호, 이동통신사 [제공사 : SKT, KT, LGU+]
      </li>
      <li>- 휴대폰번호 [제공사 : SKT, KT, LGU+]</li>
    </ol>
    <h2>3. 개인정보의 보유 및 이용기간</h2>
    <p>
      이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
      지체없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명기한 기간
      동안 보유합니다.
    </p>

    <h3>
      가. 회사 내부 방침에 의한 정보보유 사유 - 본인확인 발생 및 차단기록,
      휴대폰번호
    </h3>
    <p>보유 이유: 부정 이용 방지 및 민원 처리</p>
    <p>보유 기간: 1년</p>

    <h3>나. 관계법령에 의한 정보보유 사유</h3>
    <ol>
      <li>- 이용자 불만 또는 분쟁 처리에 관한 기록</li>
    </ol>
    <p>보유 이유: 전자상거래 등에서의 소비자보호에 관한 법률</p>
    <p>보유 기간: 3년</p>

    <h2>[SK텔레콤 귀중]</h2>

    <h2>1. 수집항목</h2>
    <ol>
      <li>
        - 이용자의 성명, 이동전화번호, 가입한 이동전화 회사, 생년월일, 성별
      </li>
      <li>- 연계정보(CI), 중복가입확인정보(DI)</li>
      <li>- 이용자가 이용하는 웹사이트 또는 Application 정보, 이용일시</li>
      <li>- 내외국인 여부</li>
      <li>- 가입한 이동전화회사 및 이동전화브랜드</li>
    </ol>
    <h2>2. 이용목적</h2>
    <ol>
      <li>
        - 이용자가 웹사이트 또는 Application에 입력한 본인확인정보의 정확성 여부
        확인(본인확인서비스 제공)
      </li>
      <li>
        - 해당 웹사이트 또는 Application에 연계정보(CI)/중복가입확인정보(DI)
        전송
      </li>
      <li>- 서비스 관련 상담 및 불만 처리 등</li>
      <li>
        - 이용 웹사이트/Application 정보 등에 대한 분석 및 세분화를 통한,
        이용자의 서비스 이용 선호도 분석
      </li>
    </ol>
    <h2>3. 개인정보의 보유기간 및 이용기간</h2>

    <ol>
      <li>
        - 이용자가 서비스를 이용하는 기간에 한하여 보유 및 이용. 다만, 아래의
        경우는 제외
      </li>
      <li>
        - 법령에서 정하는 경우 해당 기간까지 보유(상세 사항은 회사의
        개인정보처리방침에 기재된 바에 따름)
      </li>
    </ol>
    <h2>4. 본인확인서비스 제공을 위한 개인정보의 취급위탁</h2>
    <p>
      수탁자 : NICE평가정보(주), (주)다날, (주)드림시큐리티, SCI평가정보(주),
      NHN한국사이버결제(주), (주)KG모빌리언스, 코리아크레딧뷰로(주),
      한국모바일인증(주)
    </p>
    <p>
      취급위탁 업무 : 본인확인정보의 정확성 여부 확인(본인확인서비스 제공),
      연계정보(CI)/중복가입확인정보(DI) 생성 및 전송, 서비스 관련 상담 및 불만
      처리, 휴대폰인증보호 서비스, 이용자의 서비스 이용 선호도 분석정보 제공관련
      시스템 구축·광고매체 연동 및 위탁영업 등
    </p>
    <p>
      ※ 수탁자의 상세 개인정보 취급 위탁 내용은 각 수탁자가 정하는 절차와 방법에
      따라 수탁자 홈페이지 등에 게시된 수탁자의 개인정보 처리방침 및 제3자 제공
      동의 방침 등에 따릅니다.
    </p>
    <h2>
      5. 위 개인정보 수집·이용 및 취급위탁에 동의하지 않으실 경우, 서비스를
      이용하실 수 없습니다.
    </h2>
    <p>
      ※ 회사가 제공하는 서비스와 관련된 개인정보의 취급과 관련된 사항은, 회사의
      개인정보처리방침(회사 홈페이지 www.sktelecom.com )에 따릅니다.
    </p>

    <h2>[(주)케이티 귀중]</h2>

    <p>
      (주)케이티(이하 ‘회사’라 함)가 제공하는 본인확인서비스를 이용하기 위해,
      다음과 같이 ‘회사’가 본인의 개인정보를 수집 및 이용하고, 개인정보의 취급을
      위탁하는 것에 동의합니다.
    </p>

    <h2>1.수집항목</h2>
    <ol>
      <li>
        - 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별, 생년월일,
        내/외국인 구분
      </li>
      <li>- 연계정보(CI), 중복가입확인정보(DI)</li>
      <li>
        - 이용자가 본인확인을 요청한 서비스명 및 URL정보, 본인확인 이용일시, IP
        주소
      </li>
    </ol>
    <h2>2.이용목적</h2>
    <ol>
      <li>
        - 인터넷사업자의 서비스(회원가입, ID/PW찾기 등) 이용에 필요한 이용자
        본인확인 여부 및 정보 전달<br />
        (※ 이용자가 본인확인을 요청한 인터넷사업자에 한합니다.)
      </li>
      <li>
        - (주)케이티 등 이동통신사에 이용자 정보를 전송하여 본인확인 및 휴대폰
        정보의 일치 여부 확인
      </li>
      <li>- 휴대폰 사용자 확인을 위한 SMS 인증번호 전송</li>
      <li>- 부정 이용 방지 및 수사의뢰</li>
      <li>- 이용자 본인 요청에 의한 본인확인 이력정보 제공</li>
      <li>- 휴대폰번호보호서비스 가입여부 확인(서비스 가입자에 한함)</li>
    </ol>
    <h2>3.개인정보의 보유 및 이용기간</h2>
    <p>
      "회사"는 이용자의 개인정보를 이용목적이 달성되거나 보유 및 보존기간이
      종료하면 해당 정보를 지체없이 파기 하며 별도의 보관을 하지 않습니다. 단,
      관련 법령 및 회사방침에 따라 보존하는 목적과 기간은 아래와 같습니다.
    </p>
    <ol>
      <li>
        - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래등에서의
        소비자보호에 관한 법률)
      </li>
    </ol>
    <h2>4.본인확인서비스 제공을 위한 개인정보의 취급 위탁</h2>
    <ol>
      <li>-수탁자 : NICE평가정보</li>
      <li>
        -취급위탁 업무 : 본인확인정보의 정확성 여부 확인, 연계정보(CI) 및
        중복가입확인정보(DI) 전송, 서비스 관련 상담 및 불만 처리 등
      </li>
    </ol>
    <h2>
      5. 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않는 경우, 서비스를
      이용할 수 없습니다.
    </h2>
    <p>
      ‘회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은 아래의 ‘회사’
      홈페이지에 기재된 개인정보처리방침에 따릅니다.
    </p>
    <ol>
      <li>-(주)케이티 : www.kt.com</li>
    </ol>
    <p>본인은 상기 내용을 숙지하였으며 이에 동의합니다.</p>

    <h2>[LG유플러스(주) 귀중]</h2>
    <p>
      LG유플러스(주) (이하 ‘회사’라 함)가 제공하는 본인확인서비스를 이용하기
      위해, 다음과 같이 ‘회사’가 본인의 개인정보를 수집 및 이용하고, 개인정보의
      취급을 위탁하는 것에 동의합니다.
    </p>
    <h2>1.수집항목</h2>
    <ol>
      <li>-고객의 생년월일, 이동전화번호, 성명, 성별, 내/외국인 구분</li>
      <li>-연계정보(CI), 중복가입확인정보(DI)</li>
      <li>-고객이 이용하는 웹사이트 등</li>
    </ol>
    <h2>2.이용목적</h2>
    <ol>
      <li>
        -고객이 웹사이트 또는 Application 등에 입력한 본인확인정보의 정확성 여부
        확인
      </li>
      <li>
        -해당 웹사이트 또는 Application 등에 연계정보(CI)와 중복가입확인정보)DI)
        전송
      </li>
      <li>-서비스 관련 상담 및 불만 처리</li>
      <li>-기타 법룰에서 정한 목적</li>
    </ol>

    <h2>3.개인정보의 보유 및 이용기간</h2>
    <ol>
      <li>
        -고객이 서비스를 이용하는 기간에 한하여 보유 및 이용을 원칙으로 하되,
        법률에서 정하는 경우 해당 기간까지 보유 및 이용(세부사항은 ‘회사’의
        개인정보처리방침에 따름)
      </li>
    </ol>
    <h2>4.본인확인서비스 제공을 위한 개인정보의 취급 위탁 수탁자 위탁</h2>
    <table>
      <colgroup>
        <col style="width: 40%" />
        <col style="width: 60%" />
      </colgroup>
      <tbody>
        <tr>
          <th>수탁자</th>
          <th>위탁업무내용</th>
        </tr>
        <tr>
          <td>NICE평가정보(주)</td>
          <td>본인확인 서비스 중계 업무 제공</td>
        </tr>
        <tr>
          <td>CS리더, LB휴넷, 아이알링크 주식회사, (주)씨에스원파트너</td>
          <td>고객센터 운영</td>
        </tr>
        <tr>
          <td>미디어로그</td>
          <td>PASS 어플리케이션 및 웹사이트 운영/관리</td>
        </tr>
        <tr>
          <td>아톤</td>
          <td>PASS 서비스 장애 및 VOC 처리</td>
        </tr>
      </tbody>
    </table>
    <h2>
      5. 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않는 경우, 서비스를
      이용할 수 없습니다.
    </h2>
    <p>
      ‘회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은 아래의 ‘회사’
      홈페이지에 기재된 개인정보처리방침에 따릅니다.
    </p>
    <ol>
      <li>-LG유플러스(주) : www.lguplus.co.kr</li>
    </ol>
    <p>본인은 상기 내용을 숙지하였으며 이에 동의합니다.</p>
  </div>
</template>

<script>
export default {
  name: "Privacy",
};
</script>

<style scoped>
table {
  margin: 0 20px;
  margin-bottom: 14px;
}
table,
td,
th {
  border: 1px solid black;
  border-collapse: collapse;
}
td,
th {
  padding: 6px;
}
th {
  background-color: #f3f3f3;
}
</style>
