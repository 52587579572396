<template>
  <div class="policy">
    <h1>개인정보 제3자 제공 동의</h1>
    <p>
      브이씨소프트(주)는 ‘운세’ 서비스의 원활한 진행을 위해 다음과 같은 정보를
      (주)테크랩스에 제공합니다.
    </p>
    <ol>
      <li>①개인정보를 제공받는 자 : (주)테크랩스</li>
      <li>
        ②제공받는 자의 개인정보 이용목적<br />
        -운세 콘텐츠의 제공<br />
        -서비스 이용자의 본인 확인, 개인식별, 불량 이용자 방지와 비인가 사용
        방지, 이후 분쟁 처리를 위한 기록 보존, 문의 및 민원 처리, 고지사항 전달
        등에 활용
      </li>
      <li>
        ③제공하는 개인정보 항목 : 이름, 생년월일, 양/음력, 태어난 시간, 성별
      </li>
      <li>④제공받는 자의 보유 및 이용 기간 : 이벤트 종료 시까지</li>
    </ol>
    <p>
      귀하는 위와 같이 개인정보를 수집∙이용하는 데 동의를 거부할 권리가
      있습니다. 필수 수집 항목에 대한 동의를 거절하는 경우 서비스 이용이 제한될
      수 있습니다.
    </p>
  </div>
</template>

<script>
export default {
  name: "GpsService",
};
</script>
