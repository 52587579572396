<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <main class="ly_body">
            <div class="bl_header_wrapper">
              <div class="bl_header">
                <div class="bl_header_ttl">Q{{ questionNo }}.</div>
                <div v-if="answerLength > 1" class="bl_header_state">
                  <strong class="el_reset bl_header_state_accent">{{
                    questionNo
                  }}</strong>
                  / {{ answerLength }}
                </div>
              </div>
            </div>
            <div class="bl_ttl_wrapper">
              <div class="bl_ttl">
                <div class="bl_ttl_txt">
                  {{ currentQuestion.title }}
                </div>
                <div
                  class="bl_ttl_multi"
                  v-if="currentQuestion.correctCount > 1"
                >
                  답변 {{ currentQuestion.correctCount }}개
                </div>
                <div class="bl_ttl_img" v-if="currentQuestion.imageUrl">
                  <div
                    class="bl_ttl_img_val"
                    :style="{
                      'background-image': `url('${currentQuestion.imageUrl}')`,
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <!-- 타입 : 주관식 -->
            <div v-if="isShortAnswerQuestion" class="bl_problemList_wrapper">
              <ul class="el_reset bl_problemList">
                <li class="bl_problemList_item is_moFull">
                  <div class="bl_problem">
                    <div class="cp_area is_moRes">
                      <textarea
                        class="el_form cp_area_inp"
                        placeholder="내용을 입력하세요."
                        disabled
                        v-html="currentQuestion.myAnswer"
                      ></textarea>
                      <div class="cp_area_box"></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 타입 : 객관식 -->
            <div v-else class="bl_problemList_wrapper">
              <ul class="el_reset bl_problemList">
                <!-- 가로 100%(C): is_moFull -->
                <li
                  v-for="example in currentQuestion.exampleList"
                  :key="example.exampleNumber"
                  :class="{
                    is_moFull: currentQuestion.questionTypeCd === '4602',
                  }"
                  class="bl_problemList_item"
                >
                  <div class="bl_problem">
                    <!-- 이미지 타입(C): is_moImg / 텍스트 타입(C): is_moTxt -->
                    <!-- 결과 타입(C): is_moRes -->
                    <div
                      class="cp_chk is_moRes"
                      :class="{
                        is_moImg: currentQuestion.questionTypeCd === '4601',
                        is_moTxt: currentQuestion.questionTypeCd === '4602',
                      }"
                    >
                      <!-- 체크(A): checked -->
                      <input
                        class="el_form cp_chk_inp"
                        v-model="questionMultipleAnswer"
                        :value="example.exampleNumber"
                        type="checkbox"
                      />
                      <div class="cp_chk_bg"></div>
                      <div class="cp_chk_box"></div>
                      <div class="cp_chk_area">
                        <div class="cp_chk_area_pro">
                          <div
                            class="cp_chk_area_pro_val"
                            :style="{ width: `${example.choicePercent}%` }"
                          ></div>
                        </div>
                        <div
                          class="cp_chk_area_img"
                          :style="{
                            'background-image': `url('${example.imageUrl}')`,
                          }"
                        ></div>
                        <div class="cp_chk_area_cont">
                          <div class="cp_chk_area_cont_txt">
                            {{ example.info }}
                          </div>
                          <div class="cp_chk_area_cont_chk"></div>
                          <div class="cp_chk_area_cont_per">
                            <div class="cp_chk_area_cont_per_val">
                              {{ example.choicePercent }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </main>
          <footer class="ly_footer">
            <div class="bl_btnList_wrapper">
              <ul class="el_reset bl_btnList">
                <li
                  :class="{ is_moPrev: questionNo < answerLength }"
                  class="bl_btnList_item"
                >
                  <div class="bl_btn">
                    <div class="cp_btn">
                      <!-- 비활성(A): disabled -->
                      <button
                        class="el_form cp_btn_btn"
                        @click="closeWebviewLayer"
                      >
                        목록으로
                      </button>
                    </div>
                  </div>
                </li>
                <li v-if="questionNo < answerLength" class="bl_btnList_item">
                  <div class="bl_btn">
                    <div class="cp_btn is_moPrim">
                      <!-- 비활성(A): disabled -->
                      <button @click="nextAnswer" class="el_form cp_btn_btn">
                        다음결과
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closeWebviewLayer } from "@/util/webview";
import { boardApi } from "@/api";

export default {
  name: "PromotionQuestion",
  async created() {
    try {
      const { questionList, promotionType } = JSON.parse(
        localStorage.getItem("answerList")
      );
      this.answerList = questionList;
      this.promotionType = promotionType;
    } catch {
      alert("결과를 가져올 수 없습니다.");
      closeWebviewLayer();
    }
  },
  data() {
    return {
      answerList: null,
      promotionType: "",
    };
  },
  methods: {
    closeWebviewLayer,
    async setData() {
      const { data } = await boardApi.getPromotionAnswer(
        this.promotionId,
        this.joinNumber
      );
      this.answerList = data;
    },
    nextAnswer() {
      this.$router.push({
        name: "PromotionResult",
        params: {
          promotionId: this.promotionId,
          joinNumber: this.joinNumber,
          questionNo: this.questionNo + 1,
        },
      });
    },
  },
  computed: {
    promotionId() {
      return Number(this.$route.params.promotionId);
    },
    questionNo() {
      return Number(this.$route.params.questionNo);
    },
    joinNumber() {
      return Number(this.$route.params.joinNumber);
    },
    answerLength() {
      return this.answerList?.length;
    },
    currentQuestion() {
      return this.answerList?.[this.questionNo - 1] || {};
    },
    questionMultipleAnswer() {
      return this.currentQuestion?.myAnswer?.split(",");
    },
    isShortAnswerQuestion() {
      return this.currentQuestion.answerTypeCd === "4502";
    },
  },
};
</script>

<style scoped>
.id_problem .ly_page .ly_footer {
  box-shadow: none;
}
.id_view.id_problem .ly_page .ly_footer .bl_btn .cp_btn_btn {
  border-radius: 2.5rem;
}
.bl_ttl_txt {
  word-break: break-all;
}
.cp_chk_area_cont_txt {
  word-break: break-all;
}
</style>
