import nearmeHttp from "@/util/nearmeHttp";

function getUsageHistoryList() {
  return nearmeHttp.get("/partner/nearme/v1/usage-history");
}

function getUsageHistory(reservationCd) {
  return nearmeHttp.get(`/partner/nearme/v1/usage-history/${reservationCd}`);
}

function getCurrentlyBookerInfo() {
  return nearmeHttp.get("/partner/nearme/v1/reservation/customer");
}

function getBookerInfo(reservationCd) {
  return nearmeHttp.get(
    `partner/nearme/v1/reservation/customer/${reservationCd}`
  );
}

function updateBookerInfo(
  reservationCd,
  { engFirstName, engLastName, name, phoneNumber, email }
) {
  return nearmeHttp.post(`/partner/nearme/v1/usage-history/${reservationCd}`, {
    engFirstName,
    engLastName,
    name,
    phoneNumber,
    email,
  });
}

function getPartnerClubList({ page, partnershipCd, size }) {
  return nearmeHttp.get("/partner/v1/clubs", {
    params: {
      page,
      partnershipCd,
      size,
    },
  });
}

function getReservationCount() {
  return nearmeHttp.get("/partner/nearme/v1/reservations/count");
}

function getAirportInfoList({ partnershipCd }) {
  return nearmeHttp.get("/nearme/partner/v1/departures", {
    params: {
      partnershipCd: partnershipCd,
    },
  });
}

function getRecommendationPathList({ partnershipCd }) {
  return nearmeHttp.get("/nearme/partner/v1/recommendation/paths", {
    params: {
      partnershipCd: partnershipCd,
    },
  });
}

function createReservationTemporary({
  arriveAddress,
  arriveLat,
  arriveLng,
  arriveName,
  arriveEngName,
  carrierLarge,
  carrierMedium,
  carrierSmall,
  departureAddress,
  departureLat,
  departureLng,
  departureName,
  departureEngName,
  engFirstName,
  engLastName,
  halfBag,
  name,
  oneWayDate,
  oneWayTime,
  otherWithin100,
  otherWithin150,
  otherWithin200,
  passengers,
  phoneNumber,
  roundTripDate,
  roundTripTime,
  serviceName,
  standBag,
  tourBag,
  recommendationPathId,
  reserve,
  searchName,
  email,
}) {
  return nearmeHttp.post("/partner/nearme/v1/reservation/temporary", {
    arriveAddress,
    arriveLat,
    arriveLng,
    arriveName,
    arriveEngName,
    carrierLarge,
    carrierMedium,
    carrierSmall,
    departureAddress,
    departureLat,
    departureLng,
    departureName,
    departureEngName,
    engFirstName,
    engLastName,
    halfBag,
    name,
    oneWayDate,
    oneWayTime,
    otherWithin100,
    otherWithin150,
    otherWithin200,
    passengers,
    phoneNumber,
    roundTripDate,
    roundTripTime,
    serviceName,
    standBag,
    tourBag,
    recommendationPathId,
    reserve,
    searchName,
    email,
  });
}

function getReservationTemporary(serviceName) {
  return nearmeHttp.get("/partner/nearme/v1/reservation/temporary", {
    params: {
      serviceName: serviceName,
    },
  });
}

function createInitPaymentInfo(
  marketCd,
  {
    amount,
    cpid,
    currency,
    digital,
    impUid,
    merchantUid,
    paidAt,
    pgText,
    recommendId,
    reservationId,
    taxFree,
    uid,
    userCode,
  }
) {
  return nearmeHttp.post(`/v1/portone/init-payment/${marketCd}`, {
    amount,
    cpid,
    currency,
    digital,
    impUid,
    merchantUid,
    paidAt,
    pgText,
    recommendId,
    reservationId,
    taxFree,
    uid,
    userCode,
  });
}

function getAirportReservationRequest({
  arriveAddress,
  arriveLat,
  arriveLng,
  arriveName,
  bags,
  exchangeYn,
  guestId,
  oneWayAt,
  passengers,
  roundTripAt,
  roundTripYn,
  meetServiceYn,
  terminalCd,
  uid,
  engFirstName,
  engLastName,
  phoneNumber,
  searchName,
  email,
}) {
  return nearmeHttp.get("/nearme/partner/v1/ride-request/airport", {
    params: {
      arriveAddress: arriveAddress,
      arriveLat: arriveLat,
      arriveLng: arriveLng,
      arriveName: arriveName,
      bags: bags,
      exchangeYn: exchangeYn,
      guestId: guestId,
      meetServiceYn: meetServiceYn,
      oneWayAt: oneWayAt,
      passengers: passengers,
      roundTripAt: roundTripAt,
      roundTripYn: roundTripYn,
      terminalCd: terminalCd,
      uid: uid,
      engFirstName: engFirstName,
      engLastName: engLastName,
      phoneNumber: phoneNumber,
      searchName: searchName,
      email: email,
    },
  });
}

function getClubReservationRequest({
  arriveAddress,
  arriveLat,
  arriveLng,
  arriveName,
  bags,
  guestId,
  departureAddress,
  departureLat,
  departureLng,
  departureName,
  oneWayAt,
  passengers,
  roundTripAt,
  roundTripYn,
  meetServiceYn,
  uid,
  engFirstName,
  engLastName,
  phoneNumber,
  searchName,
  email,
}) {
  return nearmeHttp.get("/nearme/partner/v1/ride-request/golf", {
    params: {
      arriveAddress: arriveAddress,
      arriveLat: arriveLat,
      arriveLng: arriveLng,
      arriveName: arriveName,
      bags: bags,
      guestId: guestId,
      departureAddress: departureAddress,
      departureLat: departureLat,
      departureLng: departureLng,
      departureName: departureName,
      meetServiceYn: meetServiceYn,
      oneWayAt: oneWayAt,
      passengers: passengers,
      roundTripAt: roundTripAt,
      roundTripYn: roundTripYn,
      uid: uid,
      engFirstName: engFirstName,
      engLastName: engLastName,
      phoneNumber: phoneNumber,
      searchName: searchName,
      email: email,
    },
  });
}

function createReservationId() {
  return nearmeHttp.post("/nearme/partner/v1/reservation-id");
}

function updateReservation(
  reservationId,
  {
    arriveAddress,
    arriveLat,
    arriveLng,
    arriveName,
    carrierLarge,
    carrierMedium,
    carrierSmall,
    couponDiscountAmount,
    couponNumber,
    discountAmount,
    departureAddress,
    departureLat,
    departureLng,
    departureName,
    engFirstName,
    engLastName,
    fleetId,
    halfBag,
    meetServiceYn,
    name,
    numFlight,
    oneWayBaseAmount,
    oneWayDate,
    oneWayEarlyDiscount,
    oneWayFinalAmount,
    oneWayGroupDiscount,
    oneWayId,
    oneWayShareDiscount,
    oneWayTime,
    otherWithin100,
    otherWithin150,
    otherWithin200,
    passengers,
    phoneNumber,
    recommendationPathId,
    rideCartId,
    roundTripBaseAmount,
    roundTripDate,
    roundTripEarlyDiscount,
    roundTripFinalAmount,
    roundTripGroupDiscount,
    roundTripId,
    roundTripShareDiscount,
    roundTripTime,
    standBag,
    searchName,
    serviceFee,
    timeFlight,
    dateFlight,
    tourBag,
    userPaymentAmount,
    totalBags,
    webAppCd,
    email,
  }
) {
  return nearmeHttp.post(`/partner/nearme/v1/reservation/${reservationId}`, {
    arriveAddress,
    arriveLat,
    arriveLng,
    arriveName,
    carrierLarge,
    carrierMedium,
    carrierSmall,
    couponDiscountAmount,
    couponNumber,
    discountAmount,
    departureAddress,
    departureLat,
    departureLng,
    departureName,
    engFirstName,
    engLastName,
    fleetId,
    halfBag,
    meetServiceYn,
    name,
    numFlight,
    oneWayBaseAmount,
    oneWayDate,
    oneWayEarlyDiscount,
    oneWayFinalAmount,
    oneWayGroupDiscount,
    oneWayId,
    oneWayShareDiscount,
    oneWayTime,
    otherWithin100,
    otherWithin150,
    otherWithin200,
    passengers,
    phoneNumber,
    recommendationPathId,
    rideCartId,
    roundTripBaseAmount,
    roundTripDate,
    roundTripEarlyDiscount,
    roundTripFinalAmount,
    roundTripGroupDiscount,
    roundTripId,
    roundTripShareDiscount,
    roundTripTime,
    standBag,
    searchName,
    serviceFee,
    timeFlight,
    dateFlight,
    tourBag,
    userPaymentAmount,
    totalBags,
    webAppCd,
    email,
  });
}

function requestReservation(reservationId) {
  return nearmeHttp.post(`/partner/nearme/v1/open-request/${reservationId}`);
}

function doPaymentSchedule(marketCd, uid, merchantUid, reservationId) {
  return nearmeHttp.post(
    `/portone/v1/doPaymentSchedule/${marketCd}/${uid}/${merchantUid}/${reservationId}`
  );
}

function doPayments(
  marketCd,
  {
    amount,
    cpid,
    currency,
    digital,
    impUid,
    merchantUid,
    paidAt,
    payMethod,
    pgText,
    receiptUrl,
    recommendId,
    reservationId,
    taxFree,
    uid,
    userCode,
  }
) {
  return nearmeHttp.post(
    `/v1/portone/doPayments/${marketCd}/${uid}/${impUid}`,
    {
      amount,
      cpid,
      currency,
      digital,
      impUid,
      merchantUid,
      paidAt,
      payMethod,
      pgText,
      receiptUrl,
      recommendId,
      reservationId,
      taxFree,
      uid,
      userCode,
    }
  );
}

function cancelReservation(reservationId, cancelAmount) {
  return nearmeHttp.post(`/partner/nearme/v1/cancel-request/${reservationId}`, {
    cancelAmount,
  });
}

function getGuestNearMeAccount({
  oneWayDate,
  oneWayTime,
  roundTripDate,
  roundTripTime,
}) {
  return nearmeHttp.get("/nearme/partner/v1/account", {
    params: {
      oneWayDate,
      oneWayTime,
      roundTripDate,
      roundTripTime,
    },
  });
}

function getTermList(termTypeCdArr) {
  return nearmeHttp.get("auth/terms", {
    params: {
      termTypeCdArr,
    },
    paramsSerializer(paramObj) {
      const params = new URLSearchParams();
      for (const key in paramObj) {
        if (paramObj[key]) {
          params.append(key, paramObj[key]);
        }
      }

      return params.toString();
    },
  });
}

function checkOverlap({
  oneWayDate,
  oneWayTime,
  roundTripDate,
  roundTripTime,
}) {
  return nearmeHttp.get("/partner/nearme/v1/check-overlap", {
    params: {
      oneWayDate,
      oneWayTime,
      roundTripDate,
      roundTripTime,
    },
  });
}

function createMerchantUid(marketCd) {
  return nearmeHttp.get(`/v1/portone/v1/payments/MerchantUid/${marketCd}`);
}

function updatePaymentState(reservationId) {
  return nearmeHttp.post(`/partner/nearme/v1/payment-state/${reservationId}`);
}

function getHotelList({ nationCd, partnershipCd }) {
  return nearmeHttp.get("/partner/v1/hotel", {
    params: {
      nationCd,
      partnershipCd,
    },
  });
}

function immediatelyPayments(reservationId, merchantUid) {
  return nearmeHttp.post(`/v1/portone/schedule/${reservationId}/${merchantUid}/payments`);
}


export default {
  getUsageHistoryList,
  getUsageHistory,
  getCurrentlyBookerInfo,
  updateBookerInfo,
  getBookerInfo,
  getPartnerClubList,
  getReservationCount,
  getAirportInfoList,
  getRecommendationPathList,
  createReservationTemporary,
  getReservationTemporary,
  createInitPaymentInfo,
  getAirportReservationRequest,
  getClubReservationRequest,
  createReservationId,
  updateReservation,
  requestReservation,
  doPaymentSchedule,
  doPayments,
  cancelReservation,
  getGuestNearMeAccount,
  getTermList,
  checkOverlap,
  createMerchantUid,
  updatePaymentState,
  getHotelList,
  immediatelyPayments,
};
