<template>
  <div class="ly_body_body">
    <div class="ly_container">
      <div class="bl_airport">
        <ul class="el_reset bl_airport_list">
          <terminal-list-item
            title="나리타 공항"
            code="NRT"
            description="제 3터미널은 선택불가 (제 2터미널 이용)"
            :items="naritaTerminalList"
            @select="selectedTerminal"
            @open-bottom-modal="openTerminalInfo"
          />
          <terminal-list-item
            title="하네다 공항"
            code="HND"
            :items="hanedaTerminalList"
            @select="selectedTerminal"
            @open-bottom-modal="openTerminalInfo"
          />
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { nearmeApi } from "@/api";
import TerminalListItem from "@/views/nearme/search/components/TerminalListItem.vue";

export default {
  components: { TerminalListItem },
  name: "TerminalList",
  data() {
    return {
      airportList: null,
      naritaTerminalList: [],
      hanedaTerminalList: [],
    };
  },
  async created() {
    await this.setData();
  },
  methods: {
    async setData() {
      const { data } = await nearmeApi.getAirportInfoList({
        partnershipCd: "5801",
      });
      this.airportList = data;

      this.naritaTerminalList = this.airportList?.filter((el) => {
        return el.airportGrpCd === 15000;
      });
      this.hanedaTerminalList = this.airportList?.filter((el) => {
        return el.airportGrpCd === 15100;
      });
    },
    selectedTerminal(terminal) {
      this.$emit("select", terminal);
    },
    async openTerminalInfo(terminal) {
      this.terminalInfo(terminal);
    },
  },
};
</script>