import http from "@/util/http";

/**
 * boardCd
 * 10001: 자유게시판
 * 10002: 골린이 Q&A
 */
function createBoard({
  addrId,
  boardCd,
  ccode,
  content,
  fileIds,
  tags,
  contentLinks,
}) {
  return http.post(`/board`, {
    addrId,
    boardCd,
    ccode,
    content,
    fileIds,
    tags,
    contentLinks,
  });
}

function getBoard(boardId) {
  return http.get(`/board/${boardId}`);
}

function updateBoard(
  boardId,
  { addrId, boardCd, ccode, content, fileIds, tags, contentLinks }
) {
  return http.put(`/board/${boardId}`, {
    addrId,
    boardCd,
    ccode,
    content,
    fileIds,
    tags,
    contentLinks,
  });
}

function deleteBoard(boardId) {
  return http.delete(`/board/${boardId}`);
}

function getBoardCommentList(boardId, { lastId, page, size }) {
  return http.get(`/board/${boardId}/comment`, {
    params: {
      lastId,
      page,
      size,
    },
  });
}

function createBoardComment(boardId, { comment }) {
  return http.post(`/board/${boardId}/comment`, { comment });
}

function updateBoardLike(boardId, { likeYn }) {
  return http.put(`/board/${boardId}/like`, null, {
    params: { likeYn },
  });
}

/**
 * reportCd
 * 10101: 스팸/광고
 * 10102: 욕설/비속어
 * 10103: 폭력
 * 10104: 혐오
 * 10105: 거짓 정보
 * 10106: 기타
 */
function createBoardReport(boardId, { reason, reportCd }) {
  return http.post(`/board/${boardId}/report`, { reason, reportCd });
}

function getAddrList({ keyword, page, size }) {
  return http.get(`/board/addr`, {
    params: {
      keyword,
      page,
      size,
    },
  });
}

function uploadBoardImage(file) {
  const formData = new FormData();
  formData.append("file", file);
  return http.post(`/board/image`, formData);
}

function getPlaceList({ keyword }) {
  return http.get(`/board/places`, {
    params: {
      keyword,
    },
  });
}

function getPlaceAddrList({ keyword, page, size }) {
  return http.get(`/board/place/addrs`, {
    params: {
      keyword,
      page,
      size,
    },
  });
}

function getPlaceclubList({ keyword, page, size }) {
  return http.get(`/board/place/clubs`, {
    params: {
      keyword,
      page,
      size,
    },
  });
}

function getBoardSearchAll(keyword) {
  return http.get(`/board/search/all`, {
    params: {
      keyword,
    },
  });
}

function getBoardSearchHashtag({ keyword, page, size }) {
  return http.get(`/board/search/hashtag`, {
    params: {
      keyword,
      page,
      size,
    },
  });
}

function getBoardSearchUser({ keyword, page, size }) {
  return http.get(`/board/search/user`, {
    params: {
      keyword,
      page,
      size,
    },
  });
}

/**
 * boardCd
 * 10001: 자유게시판
 * 10002: 골린이 Q&A
 *
 * orderBy
 * date: 최신순
 * like: 인기순
 * comment: 댓글많은순
 */
function getBoards({
  addrId,
  boardCd,
  orderBy,
  comment,
  lastId,
  page,
  like,
  size,
}, controller) {
  return http.get(`/boards`, {
    params: {
      addrId,
      boardCd,
      orderBy,
      comment,
      lastId,
      page,
      like,
      size,
    },
    signal: controller.signal
  });
}

function getBoardsByClub({ ccode, lastId, page, size }) {
  return http.get(`/boards/club`, {
    params: {
      ccode,
      lastId,
      page,
      size,
    },
  });
}

function getBoardsByMyDangol({ lastId, page, size }) {
  return http.get(`/boards/dangol`, {
    params: {
      lastId,
      page,
      size,
    },
  });
}

function getBoardsByHashtag({ hashtag, lastId, page, size }) {
  return http.get(`/boards/hashtag`, {
    params: {
      hashtag,
      lastId,
      page,
      size,
    },
  });
}

function getBoardComment(commentId) {
  return http.get(`/comment/${commentId}`);
}

function deleteBoardComment(commentId) {
  return http.delete(`/comment/${commentId}`);
}

function updateBoardCommentLike(commentId, { likeYn }) {
  return http.put(`/comment/${commentId}/like`, null, {
    params: {
      likeYn,
    },
  });
}

function createBoardCommentReply(commentId, { reply, targetUid }) {
  return http.post(`/comment/${commentId}/reply`, { reply, targetUid });
}

function getBoardCommentReply(commentId, { lastId, page, size }) {
  return http.get(`/comment/${commentId}/reply`, {
    params: {
      lastId,
      page,
      size,
    },
  });
}

function updateBoardCommentReplyLike(replyId, { likeYn }) {
  return http.put(`/reply/${replyId}/like`, null, {
    params: {
      likeYn,
    },
  });
}

function deleteBoardCommentReply(replyId) {
  return http.delete(`/reply/${replyId}`);
}

function getFollowList({ page, size }) {
  return http.get(`/follow`, {
    params: {
      page,
      size,
    },
  });
}

function createFollow(toUid) {
  return http.post(`/follow`, null, {
    params: {
      toUid,
    },
  });
}

function deleteFollow(toUid) {
  return http.delete(`/follow`, {
    params: {
      toUid,
    },
  });
}

function getFollowProfile(uid) {
  return http.get(`/follow/profile/${uid}`);
}

function getFollowProfileBoardList(uid, { lastId, page, size }) {
  return http.get(`/follow/profile/${uid}/boards`, {
    params: {
      lastId,
      page,
      size,
    },
  });
}

function getPromotionList({ page, size }) {
  return http.get(`/board/promotion/list`, {
    params: {
      page,
      size,
    },
  });
}

function getPromotion(promotionId) {
  return http.get(`/board/promotion/${promotionId}`);
}

function createPromotionJoin(promotionId) {
  return http.post(`/board/promotion/${promotionId}/join`);
}

function createPromotionAnswer(promotionId, answerList) {
  return http.post(`/board/promotion/${promotionId}/answer`, { answerList });
}

function getPromotionAnswer(promotionId, joinNumber) {
  return http.get(`/board/promotion/${promotionId}/answer/${joinNumber}`);
}

function getUserEntryInfo() {
  return http.get(`/board/promotion/user_entry_info`);
}

function getTMapCoupon() {
  return http.post(`/board/promotion/tmap_coupon`);
}

export default {
  createBoard,
  getBoard,
  updateBoard,
  deleteBoard,
  getBoardCommentList,
  createBoardComment,
  updateBoardLike,
  createBoardReport,
  getAddrList,
  uploadBoardImage,
  getPlaceList,
  getPlaceAddrList,
  getPlaceclubList,
  getBoardSearchAll,
  getBoardSearchHashtag,
  getBoardSearchUser,
  getBoards,
  getBoardsByClub,
  getBoardsByMyDangol,
  getBoardsByHashtag,
  getBoardComment,
  deleteBoardComment,
  updateBoardCommentLike,
  createBoardCommentReply,
  getBoardCommentReply,
  updateBoardCommentReplyLike,
  deleteBoardCommentReply,
  getFollowList,
  createFollow,
  deleteFollow,
  getFollowProfile,
  getFollowProfileBoardList,
  getPromotionList,
  getPromotion,
  createPromotionJoin,
  createPromotionAnswer,
  getPromotionAnswer,
  getUserEntryInfo,
  getTMapCoupon,
};
