<template>
  <li class="bl_descList_item">
    <div class="bl_desc">
      <div class="bl_desc_ttl" v-if="title">
        {{ title }}
      </div>
      <div class="bl_desc_cont" v-if="text" v-html="text"></div>
      <div class="bl_desc_cont" v-for="point in points" :key="point">
        {{ data[point] }}
      </div>
    </div>
  </li>
</template>

<script>
import { convertNewLine } from "@/util/string";

export default {
  name: "JeomsinDepth",
  props: ["data"],
  computed: {
    title() {
      return this.data.title;
    },
    text() {
      return convertNewLine(this.data.text);
    },
    points() {
      return Object.keys(this.data).filter((key) => key.includes("point"));
    },
  },
};
</script>
