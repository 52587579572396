<template>
  <div class="bl_progress" v-if="nums.length">
    <div class="bl_progress_ttl">{{ title }}</div>
    <ul class="el_reset bl_progress_contList">
      <li v-for="num in nums" :key="num" class="bl_progress_contList_item">
        <div class="bl_progress_cont">
          <div class="bl_progress_cont_cont">
            <svg class="bl_progress_cont_cont_bar">
              <circle
                class="bl_progress_cont_cont_bar_ing"
                cx="35"
                cy="35"
                r="34"
                :style="{
                  'stroke-dashoffset': `${(2 * 3.14 * 34) * (1 - (data[num] / 100))}`,
                }"
              />
            </svg>
            <div class="bl_progress_cont_cont_num">{{ data[num] }}</div>
          </div>
          <div class="bl_progress_cont_ttl">{{ dataTitle[num] }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "JeomsinProgress",
  props: ["data"],
  data() {
    return {
      dataTitle: {
        num1: "자립심",
        num2: "까칠함",
        num3: "잘난척",
        num4: "공격성",
        num5: "사교성",
        num6: "판단력",
      },
    };
  },
  computed: {
    title() {
      return this.data.title;
    },
    nums() {
      return Object.keys(this.data).filter((key) => key.includes("num"));
    },
  },
};
</script>
