<template>
  <div class="ly_wrapper">
    <div class="ly_page">
      <div class="ly_page_inner">
        <div class="ly_page_inner2">
          <header class="ly_header">
            <div class="ly_container">
              <div class="bl_header_wrapper">
                <div class="bl_header">
                  <a class="el_reset">
                    <h1 class="el_reset bl_header_bi">APL Golf</h1>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <main class="ly_body">
            <div class="ly_container">
              <div class="bl_cont01_wrapper">
                <div class="bl_cont01"></div>
              </div>
              <div class="bl_cont02_wrapper">
                <div class="bl_cont02">
                  <div class="bl_nick_wrapper">
                    <div class="bl_nick">
                      <div class="bl_nick_txt">{{ nickname }}</div>
                      <button
                        class="el_form bl_nick_btn"
                        @click="handleClickNicknameEdit"
                      ></button>
                    </div>
                  </div>
                  <div class="bl_ttl_wrapper">
                    <div class="bl_ttl"></div>
                  </div>
                  <div class="bl_roundList_wrapper">
                    <ul class="el_reset bl_roundList">
                      <li class="bl_roundList_item">
                        <div class="cp_inp">
                          <number-input
                            class="el_form cp_inp_inp"
                            placeholder="00"
                            v-model="roundCount"
                            :max-length="3"
                          />
                          <div class="cp_inp_box"></div>
                        </div>
                      </li>
                      <li class="bl_roundList_item">
                        <div class="cp_inp">
                          <number-input
                            class="el_form cp_inp_inp"
                            placeholder="00"
                            v-model="score"
                            :max-length="3"
                          />
                          <div class="cp_inp_box"></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="bl_visitList_wrapper">
                    <ul class="el_reset bl_visitList">
                      <li class="bl_visitList_item">
                        <div class="cp_inp">
                          <!-- 포커스(C): is_acFocus -->
                          <div
                            class="cp_cusInp"
                            :class="{ is_acFocus: focusTarget === 'first' }"
                          >
                            <div class="cp_cusInp_obj">
                              <input
                                class="el_form cp_cusInp_obj_inp"
                                placeholder="골프장명"
                                type="text"
                                @focus="handleFocusEvt('first')"
                                @blur="handleBlurEvt"
                                :value="firstClub"
                                @input="(e) => (firstClub = e.target.value)"
                              />
                              <div class="cp_cusInp_obj_box"></div>
                            </div>
                            <div class="cp_cusInp_lay">
                              <div class="cp_cusInp_lay_inner">
                                <div class="cp_cusInp_lay_inner2">
                                  <div class="cp_cusInp_lay_inner3">
                                    <div class="cp_cusInp_lay_inner4">
                                      <div class="cp_cusInp_lay_body">
                                        <ul
                                          class="el_reset cp_cusInp_lay_body_optList"
                                        >
                                          <li
                                            v-for="club in firstSearchGolfClub"
                                            :key="club.ccode"
                                            class="cp_cusInp_lay_body_optList_item"
                                            @mousedown="selectFirstClub(club)"
                                          >
                                            <div class="cp_cusInp_lay_body_opt">
                                              <input
                                                class="el_form cp_cusInp_lay_body_opt_inp"
                                                type="radio"
                                              />
                                              <div
                                                class="cp_cusInp_lay_body_opt_chk"
                                              ></div>
                                              <div
                                                class="cp_cusInp_lay_body_opt_txt"
                                              >
                                                {{ club.clubName }}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="bl_visitList_item">
                        <div class="cp_inp">
                          <!-- 포커스(C): is_acFocus -->
                          <div
                            class="cp_cusInp"
                            :class="{ is_acFocus: focusTarget === 'second' }"
                          >
                            <div class="cp_cusInp_obj">
                              <input
                                class="el_form cp_cusInp_obj_inp"
                                placeholder="골프장명"
                                type="text"
                                @focus="handleFocusEvt('second')"
                                @blur="handleBlurEvt"
                                :value="secondClub"
                                @input="(e) => (secondClub = e.target.value)"
                              />
                              <div class="cp_cusInp_obj_box"></div>
                            </div>
                            <div class="cp_cusInp_lay">
                              <div class="cp_cusInp_lay_inner">
                                <div class="cp_cusInp_lay_inner2">
                                  <div class="cp_cusInp_lay_inner3">
                                    <div class="cp_cusInp_lay_inner4">
                                      <div class="cp_cusInp_lay_body">
                                        <ul
                                          class="el_reset cp_cusInp_lay_body_optList"
                                        >
                                          <li
                                            v-for="club in secondSearchGolfClub"
                                            :key="club.ccode"
                                            class="cp_cusInp_lay_body_optList_item"
                                            @mousedown="selectSecondClub(club)"
                                          >
                                            <div class="cp_cusInp_lay_body_opt">
                                              <input
                                                class="el_form cp_cusInp_lay_body_opt_inp"
                                                type="radio"
                                              />
                                              <div
                                                class="cp_cusInp_lay_body_opt_chk"
                                              ></div>
                                              <div
                                                class="cp_cusInp_lay_body_opt_txt"
                                              >
                                                {{ club.clubName }}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="bl_visitList_item">
                        <div class="cp_inp">
                          <!-- 포커스(C): is_acFocus -->
                          <div
                            class="cp_cusInp"
                            :class="{ is_acFocus: focusTarget === 'third' }"
                          >
                            <div class="cp_cusInp_obj">
                              <input
                                class="el_form cp_cusInp_obj_inp"
                                placeholder="골프장명"
                                type="text"
                                @focus="handleFocusEvt('third')"
                                @blur="handleBlurEvt"
                                :value="thirdClub"
                                @input="(e) => (thirdClub = e.target.value)"
                              />
                              <div class="cp_cusInp_obj_box"></div>
                            </div>
                            <div class="cp_cusInp_lay">
                              <div class="cp_cusInp_lay_inner">
                                <div class="cp_cusInp_lay_inner2">
                                  <div class="cp_cusInp_lay_inner3">
                                    <div class="cp_cusInp_lay_inner4">
                                      <div class="cp_cusInp_lay_body">
                                        <ul
                                          class="el_reset cp_cusInp_lay_body_optList"
                                        >
                                          <li
                                            v-for="club in thirdSearchGolfClub"
                                            :key="club.ccode"
                                            class="cp_cusInp_lay_body_optList_item"
                                            @mousedown="selectThirdClub(club)"
                                          >
                                            <div class="cp_cusInp_lay_body_opt">
                                              <input
                                                class="el_form cp_cusInp_lay_body_opt_inp"
                                                type="radio"
                                              />
                                              <div
                                                class="cp_cusInp_lay_body_opt_chk"
                                              ></div>
                                              <div
                                                class="cp_cusInp_lay_body_opt_txt"
                                              >
                                                {{ club.clubName }}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="bl_btn_wrapper">
                    <div class="bl_btn">
                      <button
                        class="el_form bl_btn_btn"
                        @click="submit"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bl_cont03_wrapper">
                <div class="bl_cont03">
                  <div class="bl_target_wrapper">
                    <div class="bl_target">
                      <!-- 슬라이드 Start -->
                      <vue-slick-carousel
                        v-bind="slideSettings"
                        v-if="newYearNews"
                        ref="carousel"
                        @beforeChange="onSlideChange"
                      >
                        <div
                          class="bl_target_cont"
                          v-for="card in newYearNews"
                          :key="card.uid"
                        >
                          <div class="bl_target_cont_nick">
                            {{ card.nickname }}
                            <em class="el_reset bl_target_cont_nick_unit"
                              >님</em
                            >
                          </div>
                          <div class="bl_target_cont_labe">
                            {{ card.score }}
                          </div>
                          <div class="bl_target_cont_round">
                            {{ card.roundCount }}
                          </div>
                          <ul class="el_reset bl_target_cont_visitList">
                            <li class="bl_target_cont_visitList_item">
                              {{ card.firstClubName }}
                            </li>
                            <li class="bl_target_cont_visitList_item">
                              {{ card.secondClubName }}
                            </li>
                            <li class="bl_target_cont_visitList_item">
                              {{ card.thirdClubName }}
                            </li>
                          </ul>
                        </div>
                      </vue-slick-carousel>
                      <!-- 슬라이드 End -->
                      <div class="bl_target_dot">
                        <ul class="el_reset bl_target_dot_dotList">
                          <li
                            v-for="(dot, i) in newYearNews"
                            :key="dot.uid"
                            class="bl_target_dot_dotList_item"
                            :class="{ is_ac: i === slideIdx }"
                          >
                            <button
                              @click="onClickDot(i)"
                              class="el_form bl_target_dot_dot"
                            ></button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/js/promotion/common";
import { introApi, userApi } from "@/api";
import NumberInput from "@/components/NumberInput";
import Hangul from "hangul-js";
import { showMyProfileInfo } from "@/util/webview";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "RoundResolution",
  data() {
    return {
      isComplete: true,
      golfClubList: [],
      totalCount: 0,
      nickname: "",
      roundCount: "",
      score: "",
      firstClub: "",
      secondClub: "",
      thirdClub: "",
      focusTarget: "",
      newYearNews: null,
      slideSettings: {
        arrows: false,
        dots: false,
        centerMode: true,
        centerPadding: "4.5rem",
        infinite: false,
        speed: 500,
        variableWidth: true,
      },
      slideIdx: 0,
    };
  },
  components: {
    NumberInput,
    VueSlickCarousel,
  },
  async created() {
    this.rem();
    await this.setGolfClubList();
    await this.setData();
    await this.getUserInfo();
    await this.getNewYearNews();
    window.tabActivated = () => {
      this.getUserInfo();
    };
  },
  methods: {
    showMyProfileInfo,
    rem() {
      let clientWidth = document.documentElement.clientWidth;
      let breakPoint = 720;
      let rem = document.documentElement.clientWidth / breakPoint;
      if (clientWidth < breakPoint) {
        document
          .querySelector(":root")
          .setAttribute("style", "font-size:" + rem * 10 + "px;");
      } else {
        document
          .querySelector(":root")
          .setAttribute("style", "font-size: 10px;");
      }
    },
    async setGolfClubList() {
      const storageClubList = this.getStorage("clubList");
      if (storageClubList) {
        this.golfClubList = storageClubList;
        return;
      }
      const { data: clubList } = await introApi.getClubList();
      // 국내 골프장만 필터링(2023.09.21)
      const domesticClubList = 
      clubList
        .filter(club => club.ccode.startsWith("82"))
        .sort((a, b) =>
          a.clubName > b.clubName ? 1 : a.clubName < b.clubName ? -1 : 0
        );
      
      this.setStorage("clubList", domesticClubList, 1);
      this.golfClubList = domesticClubList;
    },
    async setData() {
      const { data } = await userApi.getPromotionNewYear();
      if (!data) {
        this.isComplete = false;
        document.querySelector("body").classList.remove("is_moComplete");
        return;
      }
      document.querySelector("body").classList.add("is_moComplete");
      const {
        roundCount,
        score,
        firstClubName,
        secondClubName,
        thirdClubName,
      } = data;
      this.roundCount = roundCount;
      this.score = score;
      this.firstClub = firstClubName || "";
      this.secondClub = secondClubName || "";
      this.thirdClub = thirdClubName || "";
    },
    async submit() {
      if (this.isComplete) {
        return;
      }
      if (this.missingRequirements) {
        alert("빈 칸을 모두 입력해주세요.");
        return;
      }
      await userApi.createPromotionNewYear({
        score: this.score,
        roundCount: this.roundCount,
        firstCcode: this.firstCcode,
        secondCcode: this.secondCcode,
        thirdCcode: this.thirdCcode,
      });
      alert("목표 설정 완료!\n포인트가 지급되었습니다.");
      await this.setData();
      await this.getNewYearNews();
    },
    async getUserInfo() {
      const {
        data: { nickname },
      } = await introApi.getUserInfo();
      this.nickname = nickname;
    },
    async getNewYearNews() {
      const {
        data: { list },
      } = await userApi.getPromotionNewYearNewList({ size: 5 });
      this.newYearNews = list;
    },
    setStorage(key, value, days) {
      localStorage.setItem(key, JSON.stringify(value));
      if (days) {
        localStorage.setItem(
          `${key}_expiresIn`,
          Date.now() + 24 * 60 * 60 * 1000 * days
        );
      }
    },
    getStorage(key) {
      try {
        const expiresIn = localStorage.getItem(`${key}_expiresIn`);
        if (expiresIn < Date.now()) {
          return null;
        }
        return JSON.parse(localStorage.getItem(key));
      } catch {
        return null;
      }
    },
    handleClickNicknameEdit() {
      this.showMyProfileInfo();
    },
    getSearchGolfClubList(value) {
      const searchValue = value?.trim().toLowerCase();
      return this.golfClubList
        .filter(
          (club) =>
            club.ccode !== this.firstCcode &&
            club.ccode !== this.secondCcode &&
            club.ccode !== this.thirdCcode
        )
        .filter(
          (club) =>
            Hangul.search(club?.clubName.toLowerCase(), searchValue) > -1 ||
            Hangul.search(
              club?.clubName.replace(/ /g, "").toLowerCase(),
              searchValue
            ) > -1
        )
        .slice(0, 10);
    },
    handleFocusEvt(target) {
      this.focusTarget = target;
    },
    handleBlurEvt() {
      this.focusTarget = "";
      if (!this.firstCcode) {
        this.firstClub = "";
      }
      if (!this.secondCcode) {
        this.secondClub = "";
      }
      if (!this.thirdCcode) {
        this.thirdClub = "";
      }
    },
    selectFirstClub(club) {
      this.firstClub = club.clubName;
      this.handleBlurEvt();
    },
    selectSecondClub(club) {
      this.secondClub = club.clubName;
      this.handleBlurEvt();
    },
    selectThirdClub(club) {
      this.thirdClub = club.clubName;
      this.handleBlurEvt();
    },
    onSlideChange(perv, next) {
      this.slideIdx = next;
    },
    onClickDot(idx) {
      this.$refs.carousel.goTo(idx);
    },
  },
  computed: {
    firstCcode() {
      return this.golfClubList.find((club) => club.clubName === this.firstClub)
        ?.ccode;
    },
    secondCcode() {
      return this.golfClubList.find((club) => club.clubName === this.secondClub)
        ?.ccode;
    },
    thirdCcode() {
      return this.golfClubList.find((club) => club.clubName === this.thirdClub)
        ?.ccode;
    },
    firstSearchGolfClub() {
      return this.getSearchGolfClubList(this.firstClub);
    },
    secondSearchGolfClub() {
      return this.getSearchGolfClubList(this.secondClub);
    },
    thirdSearchGolfClub() {
      return this.getSearchGolfClubList(this.thirdClub);
    },
    missingRequirements() {
      return (
        !this.roundCount ||
        !this.score ||
        !this.firstCcode ||
        !this.secondCcode ||
        !this.thirdCcode
      );
    },
  },
};
</script>

<style scoped>
.id_event .ly_page .ly_body .bl_target {
  padding: 0;
}
.id_event .ly_page .ly_body .bl_target_cont {
  /*width: 63rem !important;*/
  display: block !important;
}
>>> .slick-track.slick-center {
  margin-left: -3.9rem;
}
>>> .slick-slide {
  width: 63rem !important;
}
</style>
