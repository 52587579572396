<template>
  <div class="policy">
    <h1>보이스캐디 APL 멤버십 서비스 이용약관</h1>
    <h2>제 1조 (목적)</h2>
    <p>
      본 서비스 약관은 회원이 브이씨소프트㈜(이하 "회사")가 제공하는 보이스캐디
      APL 멤버십(이하 “서비스”)을 이용함에 있어 필요한 회원과 회사간의 권리,
      의무 및 책임사항, 서비스 이용에 따른 이용조건 및 절차 등 기타 제반사항을
      규정함을 목적으로 합니다.
    </p>
    <h2>제 2조 (용어의 정의)</h2>
    <ol>
      <li>
        ① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <ol>
          <li>
            1. 보이스캐디 APL 멤버십(이하 “서비스”) : “회사”가 제공하는 “서비스”
            페이지를 통해 소정의 절차에 따라 본 약관에 ‘동의’라는 의사표시와
            함께 본 서비스를 신청한 “회원”에게 “단체보험”과 “라운드 이용권”을
            제공하는 서비스를 말합니다.
          </li>
          <li>
            2. 회원 : 본 “서비스”를 이용하는 것에 동의하고 “이용료”를 지불한
            사람을 말합니다.
          </li>
          <li>
            3. 이용료 : “회원”이 “회사”가 제공하는 “서비스”를 이용하기 위하여
            “회사”에 매월 지불하는 금액을 말합니다.
          </li>
          <li>
            4. 라운드 이용권 : “회사”가 “서비스”를 이용하는 “회원”에게 매월
            제공하는 APL골프 라운드 이용권(1일)을 말합니다.이용권의 유효기간은
            제공받은 날로부터 30일로 유효기간내에 사용하지 않은 이용권은
            소멸됩니다.
          </li>
          <li>
            5. 홀인원서비스 : 홀인원서비스는 핀크럭스와 제휴하여 제공하는
            서비스로 필드 골프장, 스크린 골프장에서 홀인원 시 회원에게 발생한
            비용을 서비스 지원 한도액 내에서 지급하여 드리는 서비스를 말합니다.
          </li>
          <li>
            6. 단체보험 : “회사”가 본 이용약관 및 단체보험규약에 동의하고 가입한
            “회원”으로 하여 보험가입을 위한 단체(상법 및 보험업법상의 단체보험의
            단체)를 구성하며,보험가입에 동의한 각 “회원”을 피보험자로 하여
            단체보험계약을 체결하고 “회원”에게 제공하는 보험을 말합니다.
          </li>
          <li>
            7. 해지 : “회사”또는 “회원”이 이용계약을 해약하는 것을 말합니다.
          </li>
        </ol>
      </li>
      <li>
        ② 본 약관에서 사용하는 용어의 정의는 전 항에서 정하는 것을 제외하고는
        관계 법령 및 서비스 별 안내에서 정하는 바에 의합니다
      </li>
    </ol>
    <h2>제 3조 (약관의 효력 및 개정)</h2>
    <ol>
      <li>
        ① 회사가 “서비스” 화면에 본 약관을 게시하여 “회원”에게 공지함으로써
        효력이 발생합니다.
      </li>
      <li>
        ② 회사는 “약관의 규제에 관한 법률”, "정보통신망 이용촉진 및 정보보호
        등에 관한 법률" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수
        있습니다.
      </li>
      <li>
        ③ 회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의
        적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 14일 전부터
        적용일자까지 공지합니다.
      </li>
      <li>
        ④ 전 항에 따른개정약관의 적용일 이후에 “회원”이 “서비스”를 이용하는
        경우에는 개정약관에 동의한 것으로 간주합니다. ‘회원’은 변경된 약관에
        동의하지 않을 경우 이용계약을 해지할 수 있습니다.
      </li>
    </ol>
    <h2>제 4조 (약관 외 준칙)</h2>
    <p>
      이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 온라인
      디지털콘텐츠산업발전법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의
      규제에 관한 법률, 관련 부처에서 정하는 디지털콘텐츠사용자보호지침, 기타
      관계법령 또는 상관례에 따릅니다
    </p>
    <h2>제 5조 (이용계약의 성립)</h2>
    <ol>
      <li>
        “서비스”를 이용하고자 하는 “회원”이 “서비스” 페이지를 통해서 “서비스”를
        신청하고, 본 약관 및 개인정보수집 및 이용 등에 대한 동의함 버튼을
        선택하여 동의 의사를 전송하면 “서비스” 및 약관과 개인정보수집 이용에
        동의하는 것으로 간주됩니다.
      </li>
      <li>
        “회원”이 “서비스” 이용을 신청하면 회사가 이를 승낙함으로써 서비스
        이용계약이 성립합니다. 약관을 읽지 않음으로 인한 피해의 책임은 전적으로
        “회원”에게 있습니다.
      </li>
    </ol>
    <h2>제 6조 (이용 신청 및 승낙)</h2>
    <ol>
      <li>
        ① “회사”가 제공하는 “서비스” 이용신청은 회사가 온라인으로 제공하는
        회원가입 신청 양식에 따라 필요한 각각의 항목을 “서비스”를 이용하는 자가
        직접 기재하여 이 약관에 동의한다는 의사 표시를 하는 방법으로
        이루어집니다. 다만, 일부 서비스의 이용 신청에 대해서는 추가로 사용자
        인증 절차를 필요로 합니다.
      </li>
      <li>
        ② 회사는 전 조의 규정에 따른 유효한 이용 신청이 있는 경우 이용자의
        서비스 이용 신청을 승낙합니다.
      </li>
      <li>
        ③ “회원”은 회원자격을 타인에게 양도하거나 대여 또는 담보의 목적으로
        이용할 수 없습니다.
      </li>
      <li>
        ④ “회사”가 이용자의 이용 신청을 승낙함과 동시에 이용자는 “회사”가
        제공하는 “서비스”를 이용할 수 있습니다.
      </li>
      <li>
        ⑤ 본 조 제1항 및 제2항에 따라 가입한 회원 중 회원 가입 때 개인정보를
        잘못 기재했거나, 회사에 제공한 개인정보를 변경할 때에는 “서비스” 페이지
        내 ‘정보 수정’을 통해 개인정보를 변경 신청해야 합니다. “회원”이
        개인정보를 변경하지 않아 발생하는 손해에 대해 “회사”는 책임을 지지
        않습니다.
      </li>
    </ol>
    <h2>제 7조 (단체보험의 가입)</h2>
    <ol>
      <li>
        ① “회사”는 “회원”에게 제공할 단체보험의 계약자로 핀크럭스를 지정하여
        보험사와 단체계약을 체결합니다.
      </li>
      <li>
        ② “회원”은 “회사”가 요구하는 사용자 인증절차를 마치고 본 약관에 대하여
        동의 절차를 거쳐 가입 신청을 합니다.
      </li>
      <li>
        ③ “회원”은 핀크럭스를 계약자로 “회원”을 피보험자로 하는 단체보험계약을
        체결함에 있어 보험회사와 핀크럭스간 협정에 단체보험을 체결하는 것에 대해
        동의합니다.
      </li>
      <li>
        ④ 보험계약에 따른 세부내용은 보험사가 제공하는 보험약관에 따르며
        「보이스캐디 APL 멤버십 서비스 이용약관」에서 정하지 않은 사항은
        핀크럭스가 보험사와 정한 약정에 따릅니다.
      </li>
      <li>⑤ 보험계약과 관련한 모든 권리, 의무는 핀크럭스가 행사합니다.</li>
      <li>
        ⑥ 단체보험의 보험료는 전액 핀크럭스가 부담하며, 보험금 지급사유 발생시
        “회원”에게 보험금을 지급합니다.
      </li>
    </ol>
    <h2>제 8조 (서비스 운용)</h2>
    <ol>
      <li>
        ① “서비스”의 신청, 변경, 해지 및 사용관련 문의는 APL골프 고객센터를 통해
        가능합니다.
      </li>
      <li>
        ② “회원”은 서비스 신청 시 이용약관 및 개인정보 수집, 제공에 동의해야
        합니다. 서비스를 이용 및 해지하고자 하는 “회원”은 “회사”에서 요청하는
        정보를 정확하게 제공해야 합니다.
      </li>
      <li>
        ③ “서비스” 이용 중 휴대폰번호 정보 변경 시 “서비스” 페이지 내 ‘정보
        수정’에서 수정할 수 있으며 APL골프 고객센터로 요청할 수 있습니다.단,변경
        당일에는 서비스 이용이 제한될 수 있습니다.
      </li>
      <li>
        ④ 본 “서비스”에 대한 민원은 “회사”의 책임 하에 있으며, “회원” 민원 발생
        시 회사는 적극적으로 해결하도록 합니다.
      </li>
    </ol>
    <h2>제 9조 (서비스 이용료 및 납입)</h2>
    <ol>
      <li>
        ① 회원은 “서비스” 이용의 대가로 회사가 정한 일정액의
        “이용료”를“서비스”를 구성하는 콘텐츠의 이용 여부와는 상관없이 “서비스”
        가입 상태가 유지되고 있는 동안 매월 “회사”에게 지불해야 합니다.
      </li>
      - 이용료 : 월정액2,000원(부가세 포함)
      <li>
        ② “서비스” 이용료는 회사의 사정에 따라 변경될 수 있으며, 이 경우 변경된
        내용은 서비스 화면 내 공지 또는 기타의 방법으로 사전에 안내해 드립니다.
      </li>
      <li>
        ③ “서비스” 이용료는 가입즉시 청구되며, 매월 가입일 기준으로 청구됩니다
        (회원이 서비스 해지 등의 방법으로 해당 서비스의 이용을 중단하고 정기
        결제의 취소를 요청하지 않는 한 매월 정기결제가 이루어집니다)
      </li>
      <li>
        ④ “회원”은 제1항의 이용료를 납부한 후 “회사”가 제공하는 “서비스”를
        이용하는 것을 원칙으로 합니다.
      </li>
      <li>
        ⑤ 이용료의 상세한 내용은 “서비스” 이용계약 체결 시 초기화면에서
        공지하거나 기타 회사가 적절하다고 판단하는 방법으로 공지합니다.
      </li>
      <li>⑥ 요금을 납입할 자는 해당 “서비스"를 신청한 회원 본인으로 합니다.</li>
      <li>
        ⑦ “회사”가 제공하는 “서비스”의 이용료 결제 방법은 카드 결제입니다. 또한
        결제수단을 운영하는 사업자가 별도로 있는 경우 “회원”은 해당 결제수단을
        이용하기 전에 해당 결제수단의 사업자가 제시하는 절차를 이행하여야
        합니다.
      </li>
      <li>
        ⑧ “회원”은 매월 서비스 이용료를 연체하지 않고 납입하여야 정상적으로
        서비스를 이용할 수 있으며, 서비스 이용료 연체로 인하여 서비스가 자동
        해지될 수도 있습니다.
      </li>
      <li>
        ⑨ “회사”는 “서비스” 이용 금액에 대하여 별도의 영수증 또는 세금계산서를
        발행하지 않습니다.
      </li>
    </ol>
    <h2>제 10조 (이용계약의 해지)</h2>
    <ol>
      <li>
        ① “회원”은 언제든지 “서비스” 페이지,서면, 이메일(E-mail), 전화, 기타
        회사가 정하는 방법으로 이용계약의 해지를 요청할 수 있습니다.
      </li>
      <li>
        ② 회원 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며,
        이용계약이 종료되면 “회사”는 “회원”에게 부가적으로 제공한 각종 혜택을
        회수할 수 있습니다.
      </li>
      <li>
        ③ “회사”는 “회원”이 서비스 이용료를 연체를 할 경우 제공하는 서비스를
        중단하고 해지할 수 있습니다.
      </li>
      <li>
        ④ “회사”는 “회원”이 다음 각 호에 해당하는 행위를 하였을 경우 임의로
        이용계약을 해지하거나 해당 회원의 서비스 이용을 중단 시킬 수 있습니다.
        <ol>
          <li>1. 타인의 명의를 도용하여 서비스를 가입한 자</li>
          <li>
            2. “서비스”를 이용하여 법령과 이 약관이 금지하거나 공공의 질서와
            선량한 풍속을 거스르는 행위를 하는 경우
          </li>
          <li>3. 회사의 지적재산권을 침해하는 경우</li>
          <li>4. 기타 본 약관이나 관련법규에 위배된다고 판단되는 경우</li>
        </ol>
      </li>
      <li>
        ⑤ “서비스”를 해지한 회원은 부당 이용 방지를 위해 90일간 재가입이
        불가합니다.
      </li>
    </ol>
    <h2>제 11조 (서비스의 변경 및 중단)</h2>
    <ol>
      <li>
        ① "회사“는 시스템 등 장치의 보수점검, 교체 및 고장, 통신의 두절 및
        천재지변 등의 사유가 발생한 경우에는 “서비스”의 제공을 일시적으로 중단할
        수 있습니다.
      </li>
      <li>
        ② "회사"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여
        회원 또는 제3자가 입은 손해에 대하여 배상하지 아니합니다. 단 "회사"에
        고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.
      </li>
      <li>
        ③ “회사”는 아래 각 호에 해당하는 사유가 추가로 발생한 경우에 본 서비스의
        전부 또는 일부를 제한하거나 중단할 수 있습니다.
        <ol>
          <li>1. 서비스의 설비점검, 보수 또는 공사로 인하여 부득이한 경우</li>
          <li>
            2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
            중지했을 경우
          </li>
          <li>
            3. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등
            광고서비스 이용에 지장이 있는 때
          </li>
          <li>
            4. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이
            부적당하다고 인정하는 경우
          </li>
        </ol>
      </li>
      <li>
        ④ "회사"는 “서비스”의 품질 또는 기술사양의 변경, 기타 불가피한 여건이나
        사정 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 등의 내용을
        변경할 수 있고 변경 내용이 “회원”에게 중대하거나 불리한 경우 이를 최소
        30일전 “서비스”상에 공지합니다. 단, "회사"는 불가피한 여건이나 사정이
        있는 경우, 위 공지를 하지 않을 수 있습니다.
      </li>
      <li>
        ⑤ 서비스는 회사 및 제휴사의 사정에 따라 변경, 중단될 수 있으며, 이
        경우중단일자 30일전까지 중단일자,중단사유,보상조건 등을 애플리케이션
        초기화면 또는 그 연결화면을 통해 사전에 공지하고 회원에게 통지합니다.
      </li>
    </ol>
    <h2>제 12조 (서비스 내용 및 범위)</h2>
    <ol>
      <li>
        ① 서비스는 “라운드 이용권”, “홀인원 서비스”, “단체보험”을 제공하며,
        회사의 사정에 의해 제공하는 서비스가 추가 또는 중단될 수 있습니다.
      </li>
      <li>
        ② "서비스"의 내용은 아래와 같습니다.
        <ol>
          <li>
            1. "라운드 이용권(1일)"
            <ol>
              <li>
                1) “회원”에게 APL골프 거리측정 기능을 사용할 수 있는 “라운드
                이용권(1일)”을 제공합니다.
              </li>
              <li>
                2)“라운드 이용권(1일)”은 매월 1회 제공되며,기재된 유효기간 경과
                시에는 유효기간 연장 및 사용이 불가능합니다.
              </li>
              <li>
                3) 미사용“라운드 이용권(1일)”은 이용권을 수령한 일시로부터 30일
                유효기간 이내에 사용할 수 있으며 유효기간 이내에 사용하지 않은
                “라운드 이용권(1일)”은 소멸됩니다.
              </li>
              <li>
                4) 사용한 “라운드 이용권(1일)”은 사용한 일시로부터 24시간 동안
                사용 가능합니다.
              </li>
            </ol>
          </li>
          <li>
            2. "홀인원 서비스"
            <ol>
              <li>
                1) “회원”에게는 가입 후 서비스 유지기간 동안 제휴사인
                핀크럭스가“홀인원 서비스”를 제공합니다.
              </li>
              <li>
                2) “홀인원 서비스”는 멤버십 가입일의 다음 날00:00부터 해지일
                또는 서비스종료일의 24:00까지 적용 됩니다.
              </li>
              <li>
                3) 연간 서비스 지원 한도액 (각 1회에 한함)
                <ol>
                  <li>- 필드 골프장 : 100만원</li>
                  <li>- 스크린 골프장 : 20만원</li>
                </ol>
              </li>
              <li>
                4) “회원”이 홀인원을 달성하면 연간 서비스 지원 한도액 내에서
                발생한 비용을 보상해드립니다. 발생한 비용은 “회원”이 지출 명세를
                제출하여 증빙할 수 있습니다. 지출 명세서에 간이 영수증은
                불가하며 실물의 본인 명의 개인 카드로 결제한 카드 영수증만
                해당됩니다. 단, 법인카드 및 가족카드로 결제한 카드 영수증은
                불가합니다.
              </li>
              <li>
                5) “회원”에게 발생한 비용은 각각 아래의 비용을 의미합니다.
                <ol>
                  <li>
                    - 필드 골프장에서 골프경기 중 홀인원을 행한 경우
                    <ol>
                      <li>
                        ㄱ. 증정용 기념품 구입비용. 단, 상품권 등 물품전표 및
                        선불카드는 구입비용에서 제외합니다.
                      </li>
                      <li>ㄴ. 축하회식 비용</li>
                      <li>ㄷ. 골프장에 대한 기념식수 비용</li>
                      <li>ㄹ. 동반 캐디에 대한 축의금</li>
                    </ol>
                  </li>
                  <li>
                    - 스크린 골프장에서 골프경기 중 홀인원을 행한
                    경우(골프존파크만 해당)
                    <ol>
                      <li>
                        ㄱ. 증정용 기념품 구입비용. 단, 상품권 등 물품전표 및
                        선불카드는 구입비용에서 제외합니다.
                      </li>
                      <li>
                        ㄴ. 만찬비용(스크린 홀인원 당일 개최된 축하식사비용)
                      </li>
                      <li>
                        ㄷ. 스크린골프 비용(“홀인원”이 발생한 골프경기의
                        “스크린골프장” 내 라운드 비용)
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                6) 필드 골프장에서 발생한 아래의 사유로 발생한 비용은 보장하지
                않습니다.
                <ol>
                  <li>
                    ㄱ. 경기결과의 조작, 비용의 허위청구 등 부정한 방법으로
                    발생한손해
                  </li>
                  <li>
                    ㄴ. 기준 타수(PAR)가 3개인홀이 3개이상인 1개의코스에서
                    “회원”이 홀인원을 행한 경우발생한 비용(코스란, 36타수이상의
                    9홀)
                  </li>
                  <li>
                    ㄷ. 한 개의코스를 2회이용한골프경기에서 “회원”이 홀인원을
                    행한 경우 발생한 비용
                  </li>
                  <li>
                    ㄹ. 멀리건을 사용한 홀 또는 깔때기 홀에서 홀인원을 행한 경우
                    발생한 비용
                  </li>
                  <li>ㅁ. 남성이 레이디티에서 홀인원을 행한 경우 발생한비용</li>
                  <li>
                    ㅂ. 캐디가 동반되지 않은 골프경기에서 홀인원을 한 경우
                    발생한 비용
                  </li>
                  <li>
                    ㅅ. “회원”이 골프장의 경영자 또는 사용인(임시 고용인을
                    포함합니다)인 경우에는 그 “회원”이 경영하는 또는 고용되어
                    있는 골프장에서 행한 홀인원
                  </li>
                </ol>
              </li>
              <li>
                7) 스크린 골프장에서 발생한 아래의 사유로 발생한 비용은 보장하지
                않습니다.
                <ol>
                  <li>
                    ㄱ. 경기결과의 조작, 비용의 허위청구 등 부정한 방법으로
                    발생한 손해
                  </li>
                  <li>
                    ㄴ. 기준 타수(PAR)가 3개인홀이 4개초과인코스에서 “회원”이
                    홀인원을 행한 경우 발생한비용
                  </li>
                  <li>
                    ㄷ. 멀리건을 사용한 홀 또는 깔때기 홀에서 홀인원을 행한 경우
                    발생한 비용
                  </li>
                  <li>
                    ㄹ. 남성이 레이디티에서 홀인원을 행한 경우 발생한 비용
                  </li>
                  <li>
                    ㅁ. 가상골프장(가상CC), 해외골프장(해외CC),
                    포섬모드(2명의선수가한조를이루어경기하는방식), 연습모드,
                    훈련모드에서 행한 홀인원
                  </li>
                  <li>
                    ㅂ. 홀스킵(홀넘김)이
                    2회이상발생한라운드에서홀인원을행한경우발생한비용
                  </li>
                  <li>
                    ㅅ. 스크린 골프장에서 로그인을 하지 않거나 18홀을 정규로
                    라운드 하지 않은 경기에서 홀인원을 행한 경우 발생한 비용
                  </li>
                  <li>
                    ㅇ. 아래에 기재된 난이도 미만의 골프경기에서 홀인원을 행한
                    경우 발생한 비용
                    <ol>
                      <li>
                        : 프로미만 난이도(아마추어, 루키), 그린
                        난이도별3개미만인게임에서행해진홀인원에대해서는보상하지않습니다.
                      </li>
                    </ol>
                  </li>
                  <li>
                    ㅈ. “회원”이 스크린 골프장의 경영자 또는 사용인(임시
                    고용인을 포함합니다)인 경우에 그 “회원”이 경영 또는 고용되어
                    있는 스크린 골프장에서 행한 홀인원
                  </li>
                </ol>
              </li>
              <li>
                8) “홀인원 서비스” 보장에 대한 문의는 보이스캐디 APL 멤버십 보상
                전담센터 1899-1986을 통해 가능합니다.
              </li>
            </ol>
          </li>
          <li>
            3. “단체보험”
            <ol>
              <li>
                1) “회원”에게는 가입 후 서비스 유지기간 동안 제휴사인
                핀크럭스가“단체보험”을 무료로 제공합니다.
              </li>
              <li>
                2)“단체보험”은 멤버십 가입일의 다음 날00:00부터 해지일 또는
                서비스종료일의 24:00까지 적용 됩니다.
              </li>
              <li>
                3)담보명 및 보험가입금액
                <ol>
                  <li>- 레저활동 중 상해 후유장해 : 100만원</li>
                </ol>
              </li>
              <li>
                4) “회원”이 서비스 유지기간 중에 레저활동(주민등록상에 기재된
                주택을 이탈하여 업무이외의 활동)을 하는 동안 발생한 상해로
                「장해분류표」(레저활동중상해약관【별표1】참조)에서 정한 각 장해
                지급률에 해당하는 장해상태가 되었을 때: 후유장해보험금(장해
                분류표에서 정한 지급률을 보험 가입금액에 곱하여 산출한금액)을
                지급합니다.
              </li>
              <li>
                5) 보험금을 지급하지 않는 사유
                <ol>
                  <li>
                    - 임금근로자, 자영업자 또는 학생의 직업, 직무 또는 학업에
                    종사하는 활동 중(출퇴근, 등하교포함) 발생한상해
                  </li>
                  <li>
                    - 주택 내에서 발생한 상해
                    <ol>
                      <li>
                        : ‘주택’이라함은 아파트, 연립주택, 다세대주택 및
                        단독주택을 말하며, ‘주택 내’라 함은 아파트의 경우
                        아파트건물의 입구 안쪽공간, 기타주택의 경우는 주택의
                        부속물인 대문의 안쪽공간을 말합니다.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                6) 보험금 청구 시 연락처
                <ol>
                  <li>
                    - 보험 보상 문의는 보이스캐디 APL 멤버십 보상 전담센터
                    1899-1986을 통해 가능합니다.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>
    <h2>제 13조 (이용료에 대한 이의 신청)</h2>
    <ol>
      <li>
        ① “회원”은 “이용료"에 대하여 이의를 제기할 수 있습니다. 단, 이용료에
        관한 이의는 그 사유가 발생을 안 날로부터 1개월, 그 사유가 발생한
        날로부터 3개월 이내에 제기하여야 합니다.
      </li>
      <li>
        ② 회사는 이용료 청구 등에 오류가 있는 경우 아래의 조치를 합니다.
        <ol>
          <li>
            1. 회원이 과다 납입한 요금에 대해서 회사는 그 요금을 환불하여야
            합니다. 단, 반환에 필요한 수수료는 과다 납입한 회원에게 부담시킬 수
            있습니다.
          </li>
          <li>
            2. 과소 청구한 요금에 대해서 회사는 미수금을 재청구하고, 미수금이
            완납될 때까지 회원의 서비스 이용을 제한 또는 중단할 수 있습니다.
          </li>
        </ol>
      </li>
    </ol>
    <h2>제 14조 (이용료의 환불)</h2>
    <ol>
      <li>
        ① “서비스”는 일정한 기간 동안 특정한 조건이 달성되었을 경우 지급하는
        “홀인원 상품”을 포함하고 있어결제 시 홀인원 이벤트에 참가하는 참가권이
        개시되며 환불이 불가합니다.단,회사가 운영하는 시스템으로 인해 중복결제가
        발생한 경우 그 중복결제 된 부분에 한하여 환불이 가능합니다.
      </li>
    </ol>
    <h2>제 15조 (회사의 의무)</h2>
    <ol>
      <li>
        ① “회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지
        않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여
        노력합니다.
      </li>
      <li>
        ② “회사”는 “회원”이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
        포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
        준수합니다.
      </li>
      <li>
        ③ “회사”는 서비스이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이
        정당하다고 인정할 경우에는 이를 처리하여야 합니다. “회원” 제기한
        의견이나 불만사항에 대해서는 문의하기를 활용하거나 전화, LMS,
        이메일(E-mail) 등을 통하여 “회원”에게 처리과정 및 결과를 전달합니다.
      </li>
      <li>
        ④ 제3자로부터 게시물의 게시중단 및 삭제 등의 요청이 있는 경우 “회사”는
        "정보통신망법 등 관련법령"에 따른 조치를 취할 의무가 있습니다.
      </li>
    </ol>
    <h2>제 16조 (회원의 의무)</h2>
    <ol>
      <li>
        ① 회원은 서비스 이용을 위해 개인정보를 정확하게 회사에 제공하여야 하고,
        제공된 정보가 서비스 제공을 위한 목적으로 회사, 제휴사에 제공 및
        이용되는 것에 동의합니다.
      </li>
      <li>
        ② 회원은 서비스 이용 중 위 1항의 개인정보가 변경될 시 “서비스” 고객센터
        및 문의하기를 통해 회사에 해당 내용을 통지해야 합니다. 통지 지연으로
        인해 회원 또는 제3자에게 발생된 사태나 손해에 대하여 회사는 책임지지
        않습니다.
      </li>
      <li>
        ③ 회원은 본 약관, 관계 법령에서 규정한 사항 및 서비스 이용에 따른 주의
        사항을 준수하여야 합니다.
      </li>
      <li>
        ④ 회원은 서비스의 이용 권한, 기타 이용 계약 상 지위를 타인에게 양도 및
        증여할 수 없으며 이를 담보로 제공할 수 없습니다.
      </li>
      <li>
        ⑤ 회원은 서비스 이용과 관련하여 자신의 개인정보를 제3자에게 이용하게
        해서는 안되며 회원 정보 관리, 사용 상의 과실 및 제3자의 사용 등에 의한
        손해에 대하여 회사는 책임지지 않습니다.
      </li>
      <li>
        ⑥ 회원은 개인정보가 도난을 당하거나 제3자에게 사용되고 있음을 인지한
        경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야
        합니다.
      </li>
      <li>
        ⑦ 회원은 서비스 관련 주요 정보를 MMS, LMS, SMS, 우편 또는 E-mail로 받는
        것에 동의합니다.
      </li>
      <li>
        ⑧ 회원은 “서비스” 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
        <ol>
          <li>
            1. 해당 “서비스”에서 부정한 방법을 생성하여 “서비스”를 이용하는 행위
          </li>
          <li>
            2. “서비스” 이용 관련 제반 신청행위 또는 변경행위 시 허위내용
            등록행위
          </li>
          <li>
            3. “서비스” 내 게시된 각종 정보의 무단 변경, 삭제 등 훼손 행위
          </li>
          <li>4. 다른 “회원”의 명예를 손상시키거나 개인정보를 수집하는 행위</li>
          <li>
            5. “회사”의 동의 없이 영리 목적의 광고 정보를 전송하거나, “회사”가
            허용한 정보 이외의 다른 정보(컴퓨터 프로그램 및 광고 등)를
            송신하거나 게시하는 행위
          </li>
          <li>
            6. 일체의 가공행위를 통해 서비스를 분해, 모방 또는 변형하는 행위
          </li>
          <li>7. 회사, 기타 제3자의 저작권 등 지적재산권에 대한 침해 행위</li>
          <li>8. 기타 불법적이거나 부당한 행위</li>
        </ol>
      </li>
    </ol>
    <h2>제 17조 (저작권의 귀속 및 이용제한)</h2>
    <ol>
      <li>
        ① “회사”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “회사”에
        귀속합니다.
      </li>
      <li>
        ② “회원”은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제,
        송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나
        제3자에게 이용하게 하여서는 안됩니다.
      </li>
    </ol>
    <h2>제 18조 (서비스 관련 분쟁해결)</h2>
    <ol>
      <li>
        ① “회사”는 서비스 이용과 관련하여 “회원”으로부터 제출되는 불만사항 및
        의견을 최대한 신속하게 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
        “회원”에게 그 사유와 처리일정을 조속히 통보합니다.
      </li>
      <li>
        ② “회사”와 “회원”간에 발생한 분쟁은 전자거래기본법에 의하여 설치된
        전자거래분쟁 조정위원회의 조정을 거칩니다.
      </li>
    </ol>
    <h2>제 19조 (서비스 종료)</h2>
    <ol>
      <li>
        ① “회사”는 “서비스”를 종료하고자 할 경우에는 종료하고자 하는 날로부터
        1개월 이전부터 “서비스”내에 공지합니다.
      </li>
      <li>
        ② 본 조 제1항에 따른 “서비스” 종료일 이후에는 “회원”은 “회사”로부터
        서비스를 제공받지 못합니다.
      </li>
      <li>
        ③ 본 조 제1항에 따른 서비스 종료 통지가 있은 날(“통지일”) 이후 “회원”은
        “회사” 또는 “제휴사”를 통해서 “서비스”에 가입이 제한될 수 있습니다.
        “통지일”로부터 현재까지 제공된 혜택에 대해서 “회사”가 별도 지정하는
        날(“서비스 종료일”)까지 본 약관이 정하는 바에 따라 혜택을 이용해야
        합니다. “서비스 종료일” 까지 사용하지 않은 혜택 자동으로 소멸 됩니다.
      </li>
    </ol>
    <h2>제 20조 (손해배상)</h2>
    <ol>
      <li>
        ① “회사”는 제공하는 서비스 이용과 관련하여 “회원”의 손해에 관하여
        회사에게 귀책사유가 있는 경우를 제외하고는책임을 지지 않습니다
      </li>
      <li>
        ② “회사”가 타 개별서비스 제공자와 제휴 협정을 맺고 “회원”에게
        개별서비스를 제공함에 있어 “회원”이 개별서비스 이용약관 등에 별도의
        동의를 한 뒤 개별 서비스 이용 중에 발생한 손해에 대해서는 “회사”는
        책임을 지지 않습니다.
      </li>
      <li>
        ③ “회원”이 본 약관의 규정을 위반함으로 인하여 “회사”에 손해가 발생하게
        되는 경우, 이 약관을 위반한 “회원”은 “회사”에 발생하는 모든 손해를
        배상하여야 합니다.
      </li>
      <li>
        ④ ”회원”이 서비스를 이용하는 과정에서 행한 불법행위나 이 약관 위반행위로
        인하여 회사가 해당 회원 이외의 제3자로부터 손해배상 청구 또는 소송을
        비롯한 각종 이의제기를 받는 경우 해당 회원은 자신의 책임과 비용으로
        회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 해당이용자는 그로
        인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
      </li>
      <li>
        ⑤ 회사는 제휴사 및 회원이 가입한 이동통신사의 과실로 인한 서비스 제공
        지연이나 불완전한 서비스의 제공, 회원의 귀책 사유로 인한 서비스 제공
        장애와 통지 내역 미확인에 대하여는 책임을 지지 않습니다.
      </li>
      <li>
        ⑥ 회사는 회원이 입력한 정보의 정확성, 진위성, 유효성에 대한 확인의
        책임이 없으며, 회사의 의무를 다했음에도 불구하고 발생된 피해에 대해서는
        책임을 지지 않습니다.
      </li>
    </ol>
    <h2>제 21조 (면책조항)</h2>
    <ol>
      <li>
        ① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를
        제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제 됩니다.
      </li>
      <li>
        ② “회사”는 “회원”의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을
        지지 않습니다.
      </li>
      <li>
        ③ “회사”는 “회원”이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등
        내용에 관하여는 책임을 지지 않습니다.
      </li>
      <li>
        ④ “회사”는 “회원”이 서비스를 이용하여 기대하는 손익이나 서비스를 통하여
        얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
      </li>
      <li>
        ⑤ “회사”는 “회원” 상호간 또는 “회원”과 제3자 상호간에 서비스를 매개로
        발생한 분쟁에 대해서는 개입할 의무가 없으며, 이로 인한 손해를 배상할
        책임도 없습니다.
      </li>
    </ol>
    <h2>제 22조 (개인정보보호 의무)</h2>
    <p>
      “회사”는 관련법령이 정하는 바에 따라서 “회원”의 개인정보를 보호하기 위하여
      노력합니다. “회원”의 개인정보보호에 관해서는 관련법령 및 회사가 정하는
      개인정보처리방침에서 정한 바에 의하며, “회원”이 상시 확인할 수 있도록
      “서비스”내 게시를 통해 공지하고 있습니다.
    </p>
    <h2>제 23조 (준거법 및 합의관할)</h2>
    <ol>
      <li>
        ① 본 약관에서 정하지 않은 사항과 본 관의 해석에 관하여는 대한민국 법 및
        상관례에 따릅니다.
      </li>
      <li>
        ② 서비스 및 본 약관과 관련한 제반 분쟁 및 소송은 “회사”의 관할소재지
        지방법원을 제1심 관할법원으로 합니다.
      </li>
    </ol>
    <h2>[부 칙]</h2>
    <ol>
      <li>제1조 (시행일) 본 약관은 2023년 03월 20일부터 시행합니다.</li>
    </ol>
  </div>
</template>
<script>
export default {
  name: "Membership",
};
</script>
<style scoped>
@import "~@/assets/css/default/reset.css";
</style>
