<template>
  <bottom-modal-layout class="is_moTerminal" :close="close">
    <template v-slot:header>
      <div class="bl_ttl">{{ name }}</div>
      <div class="bl_right is_moClose" @click="hide(null)"></div>
    </template>
    <template v-slot:main>
      <div class="bl_cont">
        <!-- 이미지(S): background-image -->
        <div
          class="bl_cont_img"
          :style="{ 'background-image': 'url(' + imageUrl + ')' }"
        ></div>
        <div class="bl_cont_txt" v-html="explanation" />
      </div>
    </template>
    <template v-slot:footer>
      <footer class="ly_footer" v-if="videoPath">
        <div class="bl_btn">
          <ul class="el_reset bl_btn_list">
            <li class="bl_btn_item">
              <!-- 비활성화(C) : is_acDisabled -->
              <nm-button @click="openVideoPlayer">영상보기</nm-button>
            </li>
          </ul>
        </div>
      </footer>
    </template>
  </bottom-modal-layout>
</template>
<script>
import BottomModalLayout from "@/components/layout/nearme/BottomModalLayout.vue";
import NmButton from "@/components/nearme/NmButton.vue";
import { preventBackButton } from "@/util/webview";

export default {
  components: { BottomModalLayout, NmButton },
  name: "TerminalInfoModal",
  data() {
    return {
      resolve: () => {},
      terminal: null,
      close: false,
      message: {
        NRT1: "국제선 도착 로비에서 <strong class='el_reset'>N2 또는 S2 출구</strong>로 나온 후,<br>횡단보도를 2번 건너 탑승합니다.",
        NRT2: "국제선 도착 로비에서 <strong class='el_reset'>1층 중앙 출구</strong>로 나온 후,<br>횡단보도를 2번 건너 탑승합니다.",
        HND1: "제 1터미널 도착 로비 (1층)에서 출구로 나와서<br><strong class='el_reset'>0번 하이어 플랫폼</strong>에서 탑승합니다.",
        HND2: "제 2터미널 도착 로비 (1층)에서 출구로 나와서​<br><strong class='el_reset'>1번 하이어 플랫폼</strong>에서 탑승합니다.",
        HND3: "제 3터미널 1층의 C출구로 나온 후, 우측의 <strong class='el_reset'>​택시 승차장</strong>에서 탑승합니다.",
        HND4: "Hotel Villa Fontaine 입구로 이동합니다. 엘리베이터를 타고 1층으로 이동 후,<br><strong class='el_reset'>​택시 승차장</strong>에서 탑승합니다.",
      },
    };
  },
  computed: {
    name() {
      return this.terminal?.airportName;
    },
    imageUrl() {
      return encodeURI(this.terminal?.imagePath);
    },
    explanation() {
      if (this.terminal?.airportCd === "NRT1") {
        return this.message.NRT1;
      } else if (this.terminal?.airportCd === "NRT2") {
        return this.message.NRT2;
      } else if (this.terminal?.airportCd === "HND1") {
        return this.message.HND1;
      } else if (this.terminal?.airportCd === "HND2") {
        return this.message.HND2;
      } else if (this.terminal?.airportCd === "HND3") {
        return this.message.HND3;
      } else {
        return this.message.HND4;
      }
    },
    videoPath() {
      return this.terminal?.videoPath;
    },
  },
  methods: {
    show({ content, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.terminal = content;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      this.close = true;
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
    openVideoPlayer() {
      // TODO: 영상 재생
      this.videoPlayer(this.terminal?.videoPath);
    },
  },
};
</script>