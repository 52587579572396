<template>
  <default-layout>
    <template v-slot:main>
      <div class="ly_body_body">
        <div class="bl_info is_acWay">
          <div class="bl_info_inner">
            <ul class="el_reset bl_info_list">
              <li class="bl_info_item is_moWay">
                <div class="bl_info_obj">
                  <div class="bl_obj_inner">
                    <div class="bl_obj_header">
                      <div class="bl_header_txt">
                        <div class="bl_txt_obj">{{ oneWayDepartureDate }}</div>
                        <div class="bl_txt_obj">{{ oneWayDepartureTime }}</div>
                      </div>
                    </div>
                    <div class="bl_obj_body">
                      <div
                          v-if="oneWayTerminalImageUrl"
                          class="bl_body_img"
                          :style="{
                          'background-image':
                            'url(' + oneWayTerminalImageUrl + ')',
                        }"
                      ></div>
                      <nm-static-map
                          v-else
                          :lat="oneWayDepartureLat"
                          :lng="oneWayDepartureLng"
                          marker-label="출발"
                      />
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_label">
                              <div class="bl_label_obj">출발</div>
                            </div>
                            <div class="bl_obj_txt">
                              <div class="bl_txt_ttl">
                                {{ oneWayDepartureName }}
                              </div>
                              <div class="bl_txt_txt">
                                {{ oneWayDepartureAddress }}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="bl_body_item is_moDest">
                          <div class="bl_body_obj">
                            <div class="bl_obj_label">
                              <div class="bl_label_obj">도착</div>
                            </div>
                            <div class="bl_obj_txt">
                              <div class="bl_txt_ttl">
                                {{ oneWayArrivalName }}
                              </div>
                              <div class="bl_txt_txt">
                                {{ oneWayArrivalAddress }}
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="ly_container">
          <div class="bl_detail">
            <ul class="el_reset bl_detail_list">
              <li class="bl_detail_item">
                <div class="bl_detail_obj">
                  <div class="bl_obj_header">
                    <div class="bl_header_ttl">탑승 정보</div>
                  </div>
                  <div class="bl_obj_body">
                    <ul class="el_reset bl_body_list">
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">탑승인원</div>
                          <div class="bl_obj_txt">{{ passengers }}명</div>
                        </div>
                      </li>
                      <li class="bl_body_item is_moCarrier">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">캐리어</div>
                          <div class="bl_obj_txt">{{ tripLuggageInfo }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item is_moBag">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">골프백</div>
                          <div class="bl_obj_txt">{{ golfLuggageInfo }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="bl_detail_item">
                <div class="bl_detail_obj">
                  <div class="bl_obj_header">
                    <div class="bl_header_ttl">예약자 정보</div>
                    <div v-if="!isBoardingCompleted && !isCancelReservation" class="bl_header_btn" @click="goToEditBookerInfo">
                      정보 수정
                    </div>
                  </div>
                  <div class="bl_obj_body">
                    <ul class="el_reset bl_body_list">
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">한글이름</div>
                          <div class="bl_obj_txt">{{ name }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">영문이름</div>
                          <div class="bl_obj_txt">{{ englishName }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">연락처</div>
                          <div class="bl_obj_txt">{{ phoneNumber }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">이메일</div>
                          <div class="bl_obj_txt">{{ email }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li v-if="numFlight && timeFlight" class="bl_detail_item">
                <div class="bl_detail_obj">
                  <div class="bl_obj_header">
                    <div class="bl_header_ttl">항공편 정보</div>
                  </div>
                  <div class="bl_obj_body">
                    <ul class="el_reset bl_body_list">
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">항공편 번호</div>
                          <div class="bl_obj_txt">{{ numFlight }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">항공편 시간</div>
                          <div class="bl_obj_txt">{{ timeFlight }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li v-if="teeOffDateTime" class="bl_detail_item">
                <div class="bl_detail_obj">
                  <div class="bl_obj_header">
                    <div class="bl_header_ttl">티오프 정보</div>
                  </div>
                  <div class="bl_obj_body">
                    <ul class="el_reset bl_body_list">
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">티오프 시간</div>
                          <div class="bl_obj_txt">{{ teeOffDateTime }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="bl_detail_item">
                <div class="bl_detail_obj">
                  <div class="bl_obj_header">
                    <div class="bl_header_ttl">배차 정보</div>
                  </div>
                  <div class="bl_obj_body">
                    <ul class="el_reset bl_body_list">
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">기사명</div>
                          <div class="bl_obj_txt">{{ oneWayVehicleDriverName }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">차량 번호</div>
                          <div class="bl_obj_txt">{{ oneWayVehicleNum }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">전화번호</div>
                          <div class="bl_obj_txt">{{ oneWayVehiclePhoneNumber }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="bl_detail_item">
                <div class="bl_detail_obj">
                  <div class="bl_obj_header">
                    <div class="bl_header_ttl">예약 정보</div>
                  </div>
                  <div class="bl_obj_body">
                    <ul class="el_reset bl_body_list">
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">예약 일시</div>
                          <div class="bl_obj_txt">{{ reservationDate }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">예약 번호</div>
                          <div class="bl_obj_txt">{{ printReservationCd }}</div>
                        </div>
                      </li>
                      <li v-if="!isCancelReservation" class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">예약 상태</div>
                          <div class="bl_obj_txt">{{ statusTxt }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">결제 금액</div>
                          <div class="bl_obj_txt">{{ amount | comma }} KRW</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">결제 상태</div>
                          <div class="bl_obj_txt">
                            <span :class="{ bl_txt_fail: isPaymentFail }">{{ paymentStateTxt }}</span
                            ><br/>
                          </div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">취소 사유</div>
                          <div class="bl_obj_txt">
                            <span class="bl_txt_fail">
                              {{ cancelMsg }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">취소 일시</div>
                          <div class="bl_obj_txt">{{ cancelDateTime }}</div>
                        </div>
                      </li>
                      <li class="bl_body_item">
                        <div class="bl_body_obj">
                          <div class="bl_obj_ttl">취소 수수료</div>
                          <div class="bl_obj_txt">
                            <span class="bl_txt_refund">
                              {{ cancelFee }}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                      v-if="activeReservationCancelBtn && !isCancelReservation"
                      class="bl_obj_footer"
                  >
                    <div class="bl_footer_btn" @click="cancelReservation">
                      <div class="bl_btn_cancel">예약 취소하기</div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="isPaymentFail" class="ly_body_footer">
        <div class="ly_container">
          <div class="bl_btn">
            <nm-button class="is_moPrim" @click="doPaymentSchedule">
              결제하기
            </nm-button>
          </div>
        </div>
      </div>
    </template>
  </default-layout>
</template>
<script>
import {mapGetters} from "vuex";
import {comma} from "@/util/string";
import {japanVehicleApi} from "@/api";
import {closeWebviewLayer} from "@/util/webview";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import dateMixin from "@/mixins/dateMixin";
import NmButton from "@/components/nearme/NmButton.vue";
import NmStaticMap from "@/components/modules/nearme/NmStaticMap.vue";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export default {
  name: "JapaneseVehicleUsageDetailView",
  components: {DefaultLayout, NmButton, NmStaticMap},
  mixins: [dateMixin],
  data() {
    return {
      usageHistory: null,
      merchantUid: "1111",
      impUid: "",
      isFetching: false,
      isPaymentProcess: false,
    };
  },
  async created() {
    await this.setData();
  },
  computed: {
    ...mapGetters(["userUid"]),
    reservationId() {
      return this.usageHistory?.reservationId;
    },
    oneWayDepartureDate() { // 픽업 날짜
      return this.convertDateTime(this.usageHistory?.pickUpDateTime, "YYYY.MM.DD");
    },
    oneWayDepartureTime() { // 픽업 시간
      return this.convertTime(this.usageHistory?.pickUpDateTime);
    },
    oneWayDepartureName() { // 출발지 이름
      return this.usageHistory?.pickUpLocation;
    },
    oneWayDepartureAddress() { // 출발지 주소
      return this.usageHistory?.pickUpAddress;
    },
    oneWayArrivalName() { // 도착지 이름
      return this.usageHistory?.dropOffLocation;
    },
    oneWayArrivalAddress() { // 도착지 주소
      return this.usageHistory?.dropOffAddress;
    },
    oneWayDepartureLat() {
      return this.usageHistory?.pickUpLatitude;
    },
    oneWayDepartureLng() {
      return this.usageHistory?.pickUpLongitude;
    },
    oneWayTerminalImageUrl() {
      if (this.usageHistory?.pickUpTerminalImage) {
        return encodeURI(this.usageHistory?.pickUpTerminalImage);
      }
      return null;
    },
    passengers() {
      return this.usageHistory?.passengerCount;
    },
    tripLuggageInfo() {
      const carryOnCarrierCount = this.usageHistory?.carryOnCarrier;
      const checkedCarrierCount = this.usageHistory?.checkedCarrier;

      let result = "";

      if (carryOnCarrierCount > 0) {
        result += `기내용 수하물 ${carryOnCarrierCount}개`;
      }
      if (checkedCarrierCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `위탁 수하물 ${checkedCarrierCount}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    golfLuggageInfo() {
      const golfBagCount = this.usageHistory?.golfBag;

      let result = "";

      if (golfBagCount > 0) {
        result += `${golfBagCount}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    name() {
      return this.usageHistory?.reservationName;
    },
    englishName() {
      return `${this.usageHistory?.engFirstName} ${this.usageHistory.engLastName}`;
    },
    phoneNumber() {
      return this.usageHistory?.reservationPhoneNumber;
    },
    email() {
      return this.usageHistory?.email;
    },
    numFlight() {
      return this.usageHistory?.flightNumber;
    },
    timeFlight() {
      return this.usageHistory?.flightArrivalTime ? `${this.convertDateTime(this.usageHistory?.flightArrivalTime, "YYYY.MM.DD")} ${this.convertTime(this.usageHistory?.flightArrivalTime)}` : "";
    },
    teeOffDateTime() {
      return this.usageHistory?.teeOffDateTime ? `${this.convertDateTime(this.usageHistory?.teeOffDateTime, "YYYY.MM.DD")} ${this.convertTime(this.usageHistory?.teeOffDateTime)}` : '';
    },
    oneWayVehicleDriverName() {
      return this.usageHistory?.vehicleDriverName === null ? "-" : this.usageHistory?.vehicleDriverName;
    },
    oneWayVehicleNum() {
      return this.usageHistory?.vehicleNumber === null ? "-" : this.usageHistory.vehicleNumber;
    },
    oneWayVehiclePhoneNumber() {
      return this.usageHistory?.vehicleDriverPhoneNumber === null ? "-" : this.usageHistory.vehicleDriverPhoneNumber;
    },
    reservationDate() {
      return this.convertDateTime(this.usageHistory?.regAt, "YYYY.MM.DD");
    },
    printReservationCd() {
      return this.usageHistory?.reservationCd.slice(-6);
    },
    reservationCd() {
      return this.usageHistory?.reservationCd;
    },
    statusTxt() {
      switch (this.usageHistory?.reservationStateCd) {
        /*case "14002":
          return "예약 진행 중";*/
        case "14003":
        case "14004":
        case "14005":
          return "예약 완료";
        case "14006":
          return "예약 취소";
        case "14007":
          return "탑승 완료";
        default:
          return "";
      }
    },
    isBoardingCompleted() {
      return this.usageHistory?.reservationStateCd === "14007";
    },
    activeReservationCancelBtn() {
      const departureDateTime = this.convertDateTime(this.usageHistory?.oneWayTime, "YYYY-MM-DD HH:mm:ss");
      const today = dayjs().format("YYYY-MM-DD HH:mm:ss").toString();

      const isPast = this.convertTimestamp(dayjs(departureDateTime)) < this.convertTimestamp(today);
      return (
        this.usageHistory?.reservationStateCd !== "14006" && this.usageHistory?.reservationStateCd !== "14007" && !isPast
      );
    },
    amount() {
      return this.usageHistory?.finalAmount;
    },
    isPaymentFail() {
      return this.usageHistory?.paymentStateCd === "12215";
    },
    paymentStateTxt() {
      if (this.usageHistory?.reservationCancelCd === "12701") {
        return "결제 실패";
      }

      return "결제 완료";
    },
    isCancelReservation() {
      return (
        this.usageHistory?.reservationStateCd &&
        this.usageHistory?.reservationStateCd === "14006"
      );
    },
    cancelMsg() {
      if (!this.usageHistory?.reservationCancelCd) {
        return '-';
      }

      if (this.usageHistory?.reservationCancelCd === '12702') {
        // 배차 실패로 들어온 경우는 '-' 로 표시
        return '-';
      } else {
        return this.usageHistory?.reservationCancelMsg;
      }
    },
    cancelDateTime() {
      if (this.usageHistory?.reservationCancelCd) {
        return this.convertDateTime(this.usageHistory?.cancelTime, "YYYY.MM.DD");
      } else {
        return "-"
      }
    },
    cancelAmount() {
      return comma(this.usageHistory?.cancelAmount);
    },
    cancelFee() {
      if (this.usageHistory?.cancelFee === 0) {
        const pickUpDate = dayjs.unix(this.usageHistory?.pickUpDateTime);
        const beforTime = pickUpDate.subtract(48, 'hour')
        const messge = this.convertDateTime(beforTime.unix(), 'YYYY-MM-DD HH:mm');
        return `무료 (${messge} 전 예약 취소 시)`;
      } else {
        return `${comma(this.usageHistory?.cancelFee)} KRW`;
      }
    },
  },
  methods: {
    async setData() {
      const {reservationId} = this.$route.params;
      if (!reservationId) {
        alert("잘못된 접근입니다.");
        closeWebviewLayer();
        return;
      }

      const {code, message, data} = await japanVehicleApi.getReservationsDetail(reservationId);
      console.log("result: ", code, message, data)
      this.usageHistory = data;
    },
    goToEditBookerInfo() {
      // 예약자 정보 수정 화면으로 이동
      this.$router.push({
        name: "JapaneseVehicleEditBookerInfo",
        params: {
          reservationId: this.reservationId,
        },
      });
    },
    async cancelReservation() {
      if (this.isFetching) {
        return;
      }

      const pickUpDateTime = dayjs.unix(this.usageHistory?.pickUpDateTime)
      const now = dayjs();

      const PaymentMethod = {
        NAVER_PAY: '3601',
        DANAL_PAY: '3602',
        VOUCHER: '3603'
      };

      let isCancel = false;
      if (now.isSameOrAfter(pickUpDateTime.subtract(48, 'h'), 'minute')) {
        /* 취소 수수료 발생 얼럿 활성 */
        let msg;
        if (this.usageHistory?.paymentTypeCd !== PaymentMethod.VOUCHER) {
          msg = `취소 수수료가 발생합니다\n${this.usageHistory?.finalAmount}KRW`;
        } else {
          msg = '예약 시 사용한 바우처는 복구가 불가능합니다.';
        }
        isCancel = confirm(`${msg}\n그래도 예약을 취소하시겠습니까?`);
      } else {
        /* 예약 취소 얼럿 활성 */
        isCancel = confirm("예약을 취소하시겠습니까?");
      }

      try {
        this.isFetching = true;
        if (isCancel) {
          await japanVehicleApi.cancelVehicleReservation(this.reservationId);
          await this.setData();
        }
      } finally {
        this.isFetching = false;
      }
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>
