import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import modal from "@/store/modules/modal";
import clubMap from "@/store/modules/clubMap";
import loading from "@/store/modules/loading";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    modal,
    clubMap,
    loading,
  },
});
