<template>
  <div class="ly_page">
    <div class="ly_page_inner">
      <main class="ly_body">
        <div class="bl_me_wrapper">
          <div class="bl_me">
            <h4 class="bl_me_ttl">내 초대 코드</h4>
            <div class="bl_me_code">
              <button class="bl_me_code_btn">
                <div
                  class="bl_me_code_btn_txt"
                  @click="logEvent('invitation_code_copy', clipboardCopy)"
                >
                  {{ inviteCode }}
                </div>
              </button>
            </div>
            <div class="bl_me_desc">
              친구가 내 초대 코드를 입력하면 친구와 나 모두에게 포인트가
              적립됩니다.
            </div>
            <ul class="bl_me_btnUnit">
              <li class="bl_me_btn">
                <button
                  class="el_btnDefault is_moKakao"
                  ref="kakaoShareBtn"
                  @click="logEvent('invitation_code_kakao_share')"
                >
                  <div class="el_btnDefault_txt">카카오톡으로 공유하기</div>
                </button>
              </li>
              <li class="bl_me_btn">
                <button
                  class="el_btnDefault"
                  @click="
                    logEvent(
                      'invitation_friend_list_click',
                      newWebviewLayerV2({ name: 'InviteStatus' })
                    )
                  "
                >
                  <div class="el_btnDefault_txt">친구가입 현황보기</div>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="bl_invite_wrapper" v-if="isDeservePoints">
          <div class="bl_invite">
            <h4 class="bl_invite_ttl">초대 코드 등록</h4>
            <div v-if="isCert" class="bl_invite_register">
              <div class="bl_invite_register_desc">
                가입 시 친구의 초대 코드를 등록하지 못했나요?<br />
                지금 등록하고 포인트를 받으세요.
              </div>
              <div class="bl_invite_register_input">
                <div
                  class="el_inputDefault"
                  :class="{ is_acValid: onValidate }"
                >
                  <input
                    :value="inviteCodeInput"
                    @input="onInviteCodeInput"
                    class="el_inputDefault_input"
                    :placeholder="`초대 코드 ${inviteCodeMaxLength}자리 입력`"
                    type="text"
                    :maxlength="inviteCodeMaxLength"
                  />
                  <div class="el_inputDefault_valid">
                    {{ validateMsg }}
                  </div>
                </div>
              </div>
              <div class="bl_invite_register_btn">
                <button
                  class="el_btnDefault is_moConfirm"
                  :disabled="missingRequirements"
                  @click="
                    logEvent('invitation_code_register', submitInviteCode)
                  "
                >
                  <div class="el_btnDefault_txt">초대 코드 등록</div>
                </button>
              </div>
            </div>
            <div v-else class="bl_invite_certify">
              <div class="bl_invite_certify_desc">
                <div class="bl_invite_certify_desc_txt">
                  휴대폰번호 인증 후<br />
                  초대 코드 등록을 진행할 수 있습니다.
                </div>
              </div>
              <div class="bl_invite_certify_btn">
                <button
                  class="el_btnDefault is_moConfirm"
                  @click="logEvent('invitation_verify_click', goCertificate)"
                >
                  <div class="el_btnDefault_txt">인증하러 가기</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer class="ly_footer">
        <div class="bl_caution_wrapper">
          <div class="bl_caution">
            <h4 class="bl_caution_ttl">꼭 읽어주세요!</h4>
            <ul class="bl_caution_descUnit">
              <li class="bl_caution_desc">
                초대 코드는 띄어쓰기 없이 정확히 입력해주세요.
              </li>
              <li class="bl_caution_desc">
                초대 받은 친구는 초대 코드를 최초 1회만 등록할 수 있습니다.
              </li>
              <li class="bl_caution_desc">
                내가 초대할 수 있는 친구의 수는 제한이 없습니다.
              </li>
              <li class="bl_caution_desc">
                친구가 초대 코드 등록을 완료하는 시점에 포인트가 지급됩니다.
              </li>
              <li class="bl_caution_desc">
                비정상적인 방법으로 참여할 경우 포인트가 지급되지 않거나 회수될
                수 있습니다.
              </li>
              <li class="bl_caution_desc">
                혜택을 위해 동일인이 중복 가입한 것으로 판단될 경우 포인트가
                지급되지 않을 수 있습니다.
              </li>
              <li class="bl_caution_desc">
                친구 초대 이벤트는 내부 사정에 따라 사전 고지 없이 변경 혹은
                중단될 수 있습니다.
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { introApi, userApi } from "@/api";

const INVITE_CODE_MAX_LENGTH = 8;

import { initKakao, initShareKakao } from "@/util/kakao";
import {
  newWebviewLayerV2,
  pointAccumulationComplete,
  saveText,
  showMyInfo,
} from "@/util/webview";

export default {
  name: "Invite",
  async created() {
    const { inviteCode } = this.$route.query;
    this.inviteCodeInput = inviteCode || "";
    await this.setData();
    await this.getUserInfo();
    await initKakao();
    await initShareKakao(this.$refs.kakaoShareBtn, {
      nickname: this.nickname,
      inviteCode: this.inviteCode,
    });
  },
  data() {
    return {
      nickname: "",
      inviteCode: "",
      inviteCodeInput: "",
      inviteCodeMaxLength: INVITE_CODE_MAX_LENGTH,
      isCert: true,
      isDeservePoints: true,
      onValidate: false,
      validateMsg: "",
      isFetching: false,
    };
  },
  methods: {
    newWebviewLayerV2,
    async setData() {
      const {
        data: { inviteCode, myInviteCode, phoneCertYn, niceCertDt },
      } = await userApi.getInviteCode();
      this.isDeservePoints = !inviteCode;
      this.inviteCode = myInviteCode;
      this.isCert = this.useNiceCertVersion
        ? !!niceCertDt
        : phoneCertYn === "Y" || !!niceCertDt;
    },
    async getUserInfo() {
      const {
        data: { nickname },
      } = await introApi.getUserInfo();
      this.nickname = nickname;
    },
    clipboardCopy() {
      saveText({ textStr: this.inviteCode });
    },
    onInviteCodeInput(e) {
      this.inviteCodeInput = e.target.value.slice(0, this.inviteCodeMaxLength);
      e.target.value = this.inviteCodeInput;
      this.onValidate = false;
    },
    goCertificate() {
      showMyInfo();
    },
    async submitInviteCode() {
      if (this.missingRequirements) {
        return;
      }
      if (this.inviteCode === this.inviteCodeInput) {
        this.onValidate = true;
        this.validateMsg = "친구 코드를 등록해 주세요";
        return;
      }
      try {
        if (this.isFetching) {
          return;
        }
        this.isFetching = true;
        const data = await userApi.submitInviteCode(this.inviteCodeInput);
        if (data?.code !== "200") {
          this.onValidate = true;
          this.validateMsg = data.message;
          return;
        }
        await pointAccumulationComplete();
        alert("포인트 지급이 완료되었습니다.");
        await this.setData();
      } catch (e) {
        alert(e.message);
      } finally {
        this.isFetching = false;
      }
    },
  },
  computed: {
    missingRequirements() {
      return !this.inviteCodeInput.length || this.onValidate === true;
    },
  },
  watch: {
    inviteCodeInput(val) {
      this.inviteCodeInput = val.toUpperCase().replace(/[^A-Z0-9]/g, "");
    },
  },
};
</script>
