<template>
  <div class="wrapper">
    <main class="main">
      <div class="main-main">
        <div class="event ck-content" ref="content" v-html="content">
          <div class="event-text ck-content"></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { userApi } from "@/api";
import dateMixin from "@/mixins/dateMixin";
import {
  closeWebviewLayer,
  newWebviewLayerV2,
  saveImage,
  saveText,
} from "@/util/webview";
import { HTTP_EXPRESSION } from "@/util/string";

export default {
  name: "EventView",
  mixins: [dateMixin],
  async created() {
    this.eventId = this.$route.params.eventId;
    if (!/\d/.test(this.eventId)) {
      this.$router.push({ name: "EventList" });
    }
    await this.setData();
  },
  data() {
    return {
      eventId: "",
      subject: "",
      content: "",
      startDt: 0,
      endDt: 0,
    };
  },
  methods: {
    async setData() {
      try {
        const {
          data: { content, startDt, endDt, subject, externalUrl },
        } = await userApi.getEvent(this.eventId);
        this.subject = subject;
        this.content = content;
        this.startDt = startDt;
        this.endDt = endDt;
        if (externalUrl) {
          const httpRegex = new RegExp(HTTP_EXPRESSION);
          if (httpRegex.test(externalUrl)) {
            const eventLinkTag = document.createElement("a");
            eventLinkTag.href = externalUrl;
            eventLinkTag.click();
            closeWebviewLayer();
          } else {
            this.$router.replace({ path: externalUrl });
          }
          return;
        }
        await this.$nextTick();
        this.$refs.content.querySelectorAll("a").forEach((tag) => {
          this.handleCustomATag(tag);
        });
      } catch (e) {
        if (!e?.code) {
          return;
        }
        switch (e.code) {
          case "5241":
            closeWebviewLayer();
            return;
          default:
            return;
        }
      }
    },
    handleCustomATag(tag) {
      const href = tag.href;
      const [scheme, ...rest] = href.split(":");
      const value = rest ? rest.join(":") : "";
      switch (scheme) {
        case "download":
        case "clipboard":
        case "weblayer":
          tag.addEventListener("click", this.bindAEvent);
          tag.setAttribute("data-scheme", scheme);
          tag.setAttribute("data-value", value);
          tag.removeAttribute("href");
          break;
        default:
          return;
      }
    },
    bindAEvent(e) {
      const aTag = e.target.closest("a") || e.target;
      const scheme = aTag.getAttribute("data-scheme");
      const value = aTag.getAttribute("data-value");

      switch (scheme) {
        case "download":
          saveImage({ imageUrl: value });
          break;
        case "clipboard":
          saveText({ textStr: decodeURI(value) });
          break;
        case "weblayer":
          newWebviewLayerV2({
            path: value,
          });
          break;
        default:
          return;
      }
    },
  },
};
</script>

<style scoped>
.event-text {
  word-break: break-word;
}
.ck-content::v-deep figure {
  margin: 0 auto;
}
.ck-content::v-deep img {
  width: 100%;
  display: block;
}
.ck-content::v-deep p {
  color: #767676;
  font: normal normal 400 14px / 1.43 "Noto Sans KR";
  padding: 0 20px;
}
.ck-content::v-deep figure + p {
  padding-top: 20px;
}
.ck-content::v-deep p + figure {
  padding-top: 20px;
}
.ck-content::v-deep p:first-child {
  padding-top: 20px;
}
.ck-content::v-deep p:last-child {
  padding-bottom: 20px;
}
</style>
