<template>
  <transition name="fade" appear>
    <div class="ly_layer ly_video">
      <header class="ly_header">
        <div class="bl_right is_moClose" @click="hide(null)"></div>
      </header>
      <div class="ly_layer_inner">
        <div class="ly_layer_inner2">
          <div class="ly_layer_inner3">
            <div v-if="!close" class="ly_layer_inner4">
              <main class="ly_body">
                <video
                  id="video"
                  class="video-js"
                  controls
                  preload="auto"
                  data-setup="{}"
                >
                  <source :src="videoPath" type="video/mp4" />
                </video>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { preventBackButton } from "@/util/webview";

export default {
  name: "VideoPlayer",
  data() {
    return {
      resolve: () => {},
      close: false,
      content: null,
      props: null,
    };
  },
  computed: {
    videoPath() {
      return this.content;
    },
  },
  methods: {
    show({ content, props, resolve }) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      this.content = content;
      this.props = props;
      this.resolve = resolve;
      this.$nextTick(() => {
        this.$refs.buttons?.focus();
      });
    },
    async hide(resolveValue) {
      await preventBackButton({
        isBackPrevent: false,
      });
      this.$nextTick(() => {
        this.resolve(resolveValue);
      });
    },
  },
};
</script>
<style scoped>
#video {
  width: 100%;
  height: 100%;
}
</style>