<template>
  <div class="history_wrapper">
    <default-layout>
      <template v-slot:main>
        <div class="ly_body_body">
          <div
            class="bl_info"
            :class="[{ is_acWay: !isRoundTrip }, { is_acRound: isRoundTrip }]"
          >
            <div class="bl_info_inner">
              <ul class="el_reset bl_info_list">
                <li class="bl_info_item is_moWay">
                  <div class="bl_info_obj">
                    <div class="bl_obj_inner">
                      <div class="bl_obj_header">
                        <div class="bl_header_txt">
                          <div class="bl_txt_obj">
                            {{ oneWayDepartureDate }}
                          </div>
                          <div class="bl_txt_obj">
                            {{ oneWayDepartureTime }}
                          </div>
                        </div>
                      </div>
                      <div class="bl_obj_body">
                        <div
                          v-if="oneWayTerminalImageUrl"
                          class="bl_body_img"
                          :style="{
                            'background-image':
                              'url(' + oneWayTerminalImageUrl + ')',
                          }"
                        ></div>
                        <nm-static-map
                          v-else
                          :lat="oneWayDepartureLat"
                          :lng="oneWayDepartureLng"
                          marker-label="출발"
                        />
                        <ul class="el_reset bl_body_list">
                          <li class="bl_body_item">
                            <div class="bl_body_obj">
                              <div class="bl_obj_label">
                                <div class="bl_label_obj">출발</div>
                              </div>
                              <div class="bl_obj_txt">
                                <div class="bl_txt_ttl">
                                  {{ oneWayDepartureName }}
                                </div>
                                <div class="bl_txt_txt">
                                  {{ oneWayDepartureAddress }}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl_body_item is_moDest">
                            <div class="bl_body_obj">
                              <div class="bl_obj_label">
                                <div class="bl_label_obj">도착</div>
                              </div>
                              <div class="bl_obj_txt">
                                <div class="bl_txt_ttl">
                                  {{ oneWayArrivalName }}
                                </div>
                                <div class="bl_txt_txt">
                                  {{ oneWayArrivalAddress }}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bl_info_item is_moRound">
                  <div class="bl_info_obj">
                    <div class="bl_obj_inner">
                      <div class="bl_obj_header">
                        <div class="bl_header_txt">
                          <div class="bl_txt_obj">
                            {{ roundTripDepartureDate }}
                          </div>
                          <div class="bl_txt_obj">
                            {{ roundTripDepartureTime }}
                          </div>
                        </div>
                      </div>
                      <div class="bl_obj_body">
                        <div
                          v-if="roundTripTerminalImageUrl"
                          class="bl_body_img"
                          :style="{
                            'background-image':
                              'url(' + roundTripTerminalImageUrl + ')',
                          }"
                        ></div>
                        <nm-static-map
                          v-else
                          :lat="roundTripDepartureLat"
                          :lng="roundTripDepartureLng"
                          marker-label="출발"
                        />
                        <ul class="el_reset bl_body_list">
                          <li class="bl_body_item">
                            <div class="bl_body_obj">
                              <div class="bl_obj_label">
                                <div class="bl_label_obj">출발</div>
                              </div>
                              <div class="bl_obj_txt">
                                <div class="bl_txt_ttl">
                                  {{ roundTripDepartureName }}
                                </div>
                                <div class="bl_txt_txt">
                                  {{ roundTripDepartureAddress }}
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="bl_body_item is_moDest">
                            <div class="bl_body_obj">
                              <div class="bl_obj_label">
                                <div class="bl_label_obj">도착</div>
                              </div>
                              <div class="bl_obj_txt">
                                <div class="bl_txt_ttl">
                                  {{ roundTripArrivalName }}
                                </div>
                                <div class="bl_txt_txt">
                                  {{ roundTripArrivalAddress }}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="ly_container">
            <div class="bl_detail">
              <ul class="el_reset bl_detail_list">
                <li class="bl_detail_item">
                  <div class="bl_detail_obj">
                    <div class="bl_obj_header">
                      <div class="bl_header_ttl">탑승 정보</div>
                    </div>
                    <div class="bl_obj_body">
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">탑승인원</div>
                            <div class="bl_obj_txt">{{ passengers }}명</div>
                          </div>
                        </li>
                        <li class="bl_body_item is_moCarrier">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">캐리어</div>
                            <div class="bl_obj_txt">{{ tripLuggageInfo }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item is_moBag">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">골프백</div>
                            <div class="bl_obj_txt">{{ golfLuggageInfo }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item is_moBag">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">기타</div>
                            <div class="bl_obj_txt">{{ etcLuggageInfo }}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="bl_detail_item">
                  <div class="bl_detail_obj">
                    <div class="bl_obj_header">
                      <div class="bl_header_ttl">예약자 정보</div>
                      <div
                        v-if="!isBoarding && !activeCancelState"
                        class="bl_header_btn"
                        @click="goToEditBookerInfo"
                      >
                        정보 수정
                      </div>
                    </div>
                    <div class="bl_obj_body">
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">한글이름</div>
                            <div class="bl_obj_txt">{{ name }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">영문이름</div>
                            <div class="bl_obj_txt">{{ englishName }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">연락처</div>
                            <div class="bl_obj_txt">{{ phoneNumber }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">이메일</div>
                            <div class="bl_obj_txt">{{ email }}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li v-if="numFlight && timeFlight" class="bl_detail_item">
                  <div class="bl_detail_obj">
                    <div class="bl_obj_header">
                      <div class="bl_header_ttl">항공편 정보</div>
                    </div>
                    <div class="bl_obj_body">
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">항공편 번호</div>
                            <div class="bl_obj_txt">{{ numFlight }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">항공편 도착 시간</div>
                            <div class="bl_obj_txt">{{ timeFlight }}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li v-if="hasOneWayVehicle" class="bl_detail_item">
                  <div class="bl_detail_obj">
                    <div class="bl_obj_header">
                      <div v-if="isRoundTrip" class="bl_header_ttl">
                        배차 정보 - 가는 날
                      </div>
                      <div v-else class="bl_header_ttl">배차 정보</div>
                    </div>
                    <div class="bl_obj_body">
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">기사명</div>
                            <div class="bl_obj_txt">
                              {{ oneWayVehicleDriverName }}
                            </div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">차량 번호</div>
                            <div class="bl_obj_txt">{{ oneWayVehicleNum }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">전화번호</div>
                            <div class="bl_obj_txt">
                              {{ oneWayVehiclePhoneNumber }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li
                  v-if="isRoundTrip && hasRoundTripVehicle"
                  class="bl_detail_item"
                >
                  <div class="bl_detail_obj">
                    <div class="bl_obj_header">
                      <div class="bl_header_ttl">배차 정보 - 오는 날</div>
                    </div>
                    <div class="bl_obj_body">
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">기사명</div>
                            <div class="bl_obj_txt">
                              {{ roundTripVehicleDriverName }}
                            </div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">차량 번호</div>
                            <div class="bl_obj_txt">
                              {{ roundTripVehicleNum }}
                            </div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">전화번호</div>
                            <div class="bl_obj_txt">
                              {{ roundTripVehiclePhoneNumber }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li class="bl_detail_item">
                  <div class="bl_detail_obj">
                    <div class="bl_obj_header">
                      <div class="bl_header_ttl">예약 정보</div>
                    </div>
                    <div class="bl_obj_body">
                      <ul class="el_reset bl_body_list">
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">예약 일시</div>
                            <div class="bl_obj_txt">{{ reservationDate }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">APL 접수</div>
                            <div class="bl_obj_txt">{{ reservationCd }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div v-if="isRoundTrip" class="bl_obj_ttl">
                              가는 날 - 예약 번호
                            </div>
                            <div v-else class="bl_obj_ttl">예약 번호</div>
                            <div class="bl_obj_txt">{{ oneWayNearMeId }}</div>
                          </div>
                        </li>
                        <li v-if="roundTripNearMeId" class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">오는 날 - 예약 번호</div>
                            <div class="bl_obj_txt">
                              {{ roundTripNearMeId }}
                            </div>
                          </div>
                        </li>
                        <li v-if="!activeCancelState" class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">예약 상태</div>
                            <div class="bl_obj_txt">{{ statusTxt }}</div>
                          </div>
                        </li>
                        <li class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">결제 금액</div>
                            <div class="bl_obj_txt">
                              {{ amount | comma }} KRW
                            </div>
                          </div>
                        </li>
                        <li
                          v-if="activePaymentState && !activeCancelState"
                          class="bl_body_item"
                        >
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">결제 상태</div>
                            <div class="bl_obj_txt">
                              <span :class="{ bl_txt_fail: isPaymentFail }">{{
                                paymentStateTxt
                              }}</span
                              ><br />
                            </div>
                          </div>
                        </li>
                        <li v-if="activeCancelState" class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">취소 사유</div>
                            <div class="bl_obj_txt">
                              <span class="bl_txt_fail">
                                {{ cancelMsg }}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li v-if="activeCancelState" class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">취소 일시</div>
                            <div class="bl_obj_txt">{{ cancelDateTime }}</div>
                          </div>
                        </li>
                        <li v-if="activeCancelState" class="bl_body_item">
                          <div class="bl_body_obj">
                            <div class="bl_obj_ttl">취소 수수료</div>
                            <div class="bl_obj_txt">
                              <span class="bl_txt_refund">
                                {{ cancelFee }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="activeReservationCancelBtn && !activeCancelState"
                      class="bl_obj_footer"
                    >
                      <div class="bl_footer_btn" @click="cancelReservation">
                        <div class="bl_btn_cancel">예약 취소하기</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="isPaymentFail" class="ly_body_footer">
          <div class="ly_container">
            <div class="bl_btn">
              <nm-button class="is_moPrim" @click="doPaymentSchedule">
                결제하기
              </nm-button>
            </div>
          </div>
        </div>
      </template>
    </default-layout>
    <div v-if="isPaymentProcess" class="dim"></div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { comma } from "@/util/string";
import { nearmeApi } from "@/api";
import { preventBackButton, closeWebviewLayer } from "@/util/webview";
import DefaultLayout from "@/components/layout/nearme/DefaultLayout.vue";
import dateMixin from "@/mixins/dateMixin";
import NmButton from "@/components/nearme/NmButton.vue";
import NmStaticMap from "@/components/modules/nearme/NmStaticMap.vue";
import dayjs from "dayjs";

export default {
  name: "UsageHistoryView",
  components: { DefaultLayout, NmButton, NmStaticMap },
  mixins: [dateMixin],
  data() {
    return {
      usageHistory: null,
      serviceType: null,
      merchantUid: "1111",
      impUid: "",
      isFetching: false,
      isPaymentProcess: false,
    };
  },
  async created() {
    const { service_type } = this.$route.query;
    this.serviceType = service_type;

    await this.setData();
  },
  computed: {
    ...mapGetters(["userUid"]),
    reservationId() {
      return this.usageHistory?.reservationId;
    },
    isRoundTrip() {
      return this.usageHistory?.roundTripTime ? true : false;
    },
    oneWayDepartureName() {
      return this.usageHistory?.departureName;
    },
    oneWayDepartureAddress() {
      return this.usageHistory?.departureAddress;
    },
    oneWayDepartureDate() {
      return this.convertDateTime(this.usageHistory?.oneWayTime, "YYYY.MM.DD");
    },
    oneWayDepartureTime() {
      return this.convertTime(this.usageHistory?.oneWayTime);
    },
    oneWayDepartureLat() {
      return this.usageHistory?.departureLat;
    },
    oneWayDepartureLng() {
      return this.usageHistory?.departureLng;
    },
    oneWayArrivalName() {
      return this.usageHistory?.arriveName;
    },
    oneWayArrivalAddress() {
      return this.usageHistory?.arriveAddress;
    },
    oneWayTerminalImageUrl() {
      if (this.usageHistory?.departureTerminalImage) {
        return encodeURI(this.usageHistory?.departureTerminalImage);
      }
      return null;
    },
    roundTripTerminalImageUrl() {
      if (this.usageHistory?.arriveTerminalImage) {
        return encodeURI(this.usageHistory?.arriveTerminalImage);
      }
      return null;
    },
    roundTripDepartureName() {
      return this.usageHistory?.arriveName;
    },
    roundTripDepartureAddress() {
      return this.usageHistory?.arriveAddress;
    },
    roundTripDepartureLat() {
      return this.usageHistory?.arriveLat;
    },
    roundTripDepartureLng() {
      return this.usageHistory?.arriveLng;
    },
    roundTripArrivalName() {
      return this.usageHistory?.departureName;
    },
    roundTripArrivalAddress() {
      return this.usageHistory?.departureAddress;
    },
    roundTripDepartureDate() {
      return this.convertDateTime(
        this.usageHistory?.roundTripTime,
        "YYYY.MM.DD"
      );
    },
    roundTripDepartureTime() {
      return this.convertTime(this.usageHistory?.roundTripTime);
    },
    passengers() {
      return this.usageHistory?.passengers;
    },
    tripLuggageInfo() {
      const smallSizeCount = this.usageHistory?.carrierSmall;
      const mediumSizeCount = this.usageHistory?.carrierMedium;
      const largeSizeCount = this.usageHistory?.carrierLarge;

      let result = "";

      if (smallSizeCount > 0) {
        result += `기내용 ${smallSizeCount}개`;
      }
      if (mediumSizeCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `중형 ${mediumSizeCount}개`;
      }
      if (largeSizeCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `대형 ${largeSizeCount}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    golfLuggageInfo() {
      const tourBagCount = this.usageHistory?.tourBag;
      const standBagCount = this.usageHistory?.standBag;
      const halfBagCount = this.usageHistory?.halfBag;

      let result = "";

      if (tourBagCount > 0) {
        result += `투어백 ${tourBagCount}개`;
      }
      if (standBagCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `스탠드백 ${standBagCount}개`;
      }
      if (halfBagCount > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `하프백 ${halfBagCount}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    etcLuggageInfo() {
      const within100Count = this.usageHistory?.otherWithIn100;
      const within150Count = this.usageHistory?.otherWithIn150;
      const within200Count = this.usageHistory?.otherWithIn200;

      let result = "";

      if (within100Count > 0) {
        result += `세 변 100cm 이내 ${within100Count}개`;
      }
      if (within150Count > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `세 변 150cm 이내 ${within150Count}개`;
      }
      if (within200Count > 0) {
        if (result !== "") {
          result += ", ";
        }
        result += `세 변 200cm 이내 ${within200Count}개`;
      }
      if (result === "") {
        result = "없음";
      }

      return result;
    },
    name() {
      return this.usageHistory?.reservationName;
    },
    englishName() {
      return this.usageHistory?.reservationEngName;
    },
    phoneNumber() {
      return this.usageHistory?.reservationPhoneNumber;
    },
    email() {
      return this.usageHistory?.email;
    },
    numFlight() {
      return this.usageHistory?.numFlight;
    },
    timeFlight() {
      return this.usageHistory?.timeFlight;
    },
    hasOneWayVehicle() {
      return (
        this.oneWayVehicleDriverName &&
        this.oneWayVehicleNum &&
        this.oneWayVehiclePhoneNumber
      );
    },
    oneWayVehicleDriverName() {
      return this.usageHistory?.oneWayVehicleDriverName;
    },
    oneWayVehicleNum() {
      return this.usageHistory?.oneWayVehicleNum;
    },
    oneWayVehiclePhoneNumber() {
      return this.usageHistory?.oneWayVehiclePhoneNumber;
    },
    hasRoundTripVehicle() {
      return (
        this.roundTripVehicleDriverName &&
        this.roundTripVehicleNum &&
        this.roundTripVehiclePhoneNumber
      );
    },
    roundTripVehicleDriverName() {
      return this.usageHistory?.roundTripVehicleDriverName;
    },
    roundTripVehicleNum() {
      return this.usageHistory?.roundTripVehicleNum;
    },
    roundTripVehiclePhoneNumber() {
      return this.usageHistory?.roundTripVehiclePhoneNumber;
    },
    activeReservationState() {
      return this.usageHistory?.reservationStateCd !== "14006";
    },
    reservationDate() {
      return this.convertDateTime(
        this.usageHistory?.reservationAt,
        "YYYY.MM.DD"
      );
    },
    reservationCd() {
      return this.usageHistory?.reservationCd;
    },
    oneWayNearMeId() {
      return this.usageHistory?.oneWayNearMeId;
    },
    roundTripNearMeId() {
      return this.usageHistory?.roundTripNearMeId;
    },
    statusTxt() {
      switch (this.usageHistory?.reservationStateCd) {
        case "14002":
          return "예약 진행 중";
        case "14003":
        case "14004":
        case "14005":
          return "예약 완료";
        case "14006":
          return "예약 취소";
        case "14007":
          return "탑승 완료";
        default:
          return "";
      }
    },
    isBoarding() {
      return this.usageHistory?.reservationStateCd === "14007";
    },
    activePaymentState() {
      return (
        this.usageHistory?.paymentStateCd === "12214" ||
        this.usageHistory?.paymentStateCd === "12215"
      );
    },
    activeReservationCancelBtn() {
      const departureDateTime = this.convertDateTime(
        this.usageHistory?.oneWayTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      const today = dayjs().format("YYYY-MM-DD HH:mm:ss").toString();

      const isPast =
        this.convertTimestamp(dayjs(departureDateTime)) <
        this.convertTimestamp(today);
      return (
        this.usageHistory?.reservationStateCd !== "14006" &&
        this.usageHistory?.reservationStateCd !== "14007" &&
        !isPast
      );
    },
    amount() {
      return this.usageHistory?.amount;
    },
    isPaymentFail() {
      return this.usageHistory?.paymentStateCd === "12215";
    },
    paymentStateTxt() {
      if (this.usageHistory?.reservationCancelCd === "12701") {
        return "결제 실패";
      }

      return "결제 완료";
    },
    activeCancelState() {
      return (
        this.usageHistory?.reservationCancelCd &&
        this.usageHistory?.reservationCancelCd !== "12701"
      );
    },
    cancelMsg() {
      return (
        this.usageHistory?.reservationCancelCd &&
        this.usageHistory?.reservationCancelMsg
      );
    },
    cancelDateTime() {
      return this.convertDateTime(this.usageHistory?.cancelTime, "YYYY.MM.DD");
    },
    cancelAmount() {
      return comma(this.usageHistory?.cancelAmount);
    },
    cancelFee() {
      // 관리자 취소 시 null로 넘어옴
      if (this.usageHistory?.cancelFee === null) {
        return "없음";
      }

      if (this.usageHistory?.cancelFee === 0) {
        return "없음";
      } else if (this.usageHistory?.cancelFee === this.usageHistory?.amount) {
        return `${comma(this.usageHistory?.cancelFee)} KRW`;
      } else {
        return `${comma(this.usageHistory?.cancelFee)} KRW (탑승 24시간 전)`;
      }
    },
  },
  methods: {
    ...mapMutations(["setIsLoading", "setLoadingMessage"]),
    async setData() {
      const { reservation_cd } = this.$route.params;
      if (!reservation_cd) {
        alert("잘못된 접근입니다.");
        closeWebviewLayer();
        return;
      }
      const { data } = await nearmeApi.getUsageHistory(reservation_cd);
      this.usageHistory = data;

      // TODO: 결제 실패 때에만 호출
      if (this.activePaymentState) {
        // 포트원 스크립트 추가
        await this.initPortone();
      }
    },
    initPortone() {
      this.script = document.createElement("script");
      this.script.addEventListener("load", async () => {
        window.IMP.init(process.env.VUE_APP_PORTONE_DANAL_USER_CODE);
      });
      this.script.setAttribute("src", "https://cdn.iamport.kr/v1/iamport.js");
      document.head.appendChild(this.script);
    },
    goToEditBookerInfo() {
      this.$router.push({
        name: "EditBookerInfo",
        params: {
          reservation_cd: this.reservationCd,
        },
      });
    },
    async doPaymentSchedule() {
      if (this.isPaymentProcess) {
        return;
      }
      if (this.isFetching) {
        return;
      }
      try {
        await preventBackButton({
          isBackPrevent: true,
        });
        this.isPaymentProcess = true;
        this.isFetching = true;
        await this.requestPayment();
      } catch (error) {
        await preventBackButton({
          isBackPrevent: false,
        });
        this.isPaymentProcess = false;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      } finally {
        this.isFetching = false;
      }
    },
    async requestPayment() {
      const { data } = await nearmeApi.createMerchantUid("5801");
      window.IMP.request_pay(
        {
          pg: `${process.env.VUE_APP_PORTONE_DANAL_PG_TEXT}.${process.env.VUE_APP_PORTONE_DANAL_CPID}`,
          pay_method: "card",
          merchant_uid: data,
          name: "NearMe 일본교통예약",
          amount: 0,
          customer_uid: this.userUid,
          buyer_email: "",
          buyer_name: this.name,
          buyer_tel: this.phoneNumber,
        },
        this.getBillingKeyCallback
      );
    },
    async getBillingKeyCallback(result) {
      try {
        if (result.success) {
          this.setIsLoading(true);
          this.setLoadingMessage("결제 중 화면을 유지해주세요.");
          this.impUid = result.imp_uid;
          const { code } = await nearmeApi.doPaymentSchedule(
            "5801",
            this.userUid,
            this.merchantUid,
            this.usageHistory?.reservationId
          );

          if (code === "200") {
            // 예약 업데이트 update
            await nearmeApi.updatePaymentState(
              this.usageHistory?.reservationId
            );
            this.isPaymentProcess = false;
            this.setIsLoading(false);
            this.setLoadingMessage("");
            this.reservationSuccess(null);
            await this.setData();
          }
        } else {
          this.paymentFail(result.error_msg, result.error_code);
        }
      } finally {
        this.isPaymentProcess = false;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      }
    },

    async cancelReservation() {
      let cancelAmount = 0;
      if (this.isFetching) {
        return;
      }

      const departureDateTime = this.convertDateTime(
        this.usageHistory?.oneWayTime,
        "YYYY-MM-DD HH:mm:ss"
      );

      const today = dayjs().format("YYYY-MM-DD HH:mm:ss").toString();
      const reservationDateTime = this.convertDateTime(
          this.usageHistory?.reservationAt,
          "YYYY-MM-DD HH:mm:ss"
      );

      if (
          this.convertTimestamp(
              dayjs(reservationDateTime)
                  .add(1, "m")
                  .format("YYYY-MM-DD HH:mm:ss")
          ) > dayjs().unix()
      ) {
        await this.alert("결제 진행 중에는\n예약을 취소할 수 없습니다.");
        return;
      }

      let isCancel = false;
      if (this.usageHistory?.reservationStateCd === "14002") {
        cancelAmount = 0;
        isCancel = await this.confirm("예약을 취소하시겠습니까?");
      } else {
        if (
          this.convertTimestamp(
            dayjs(departureDateTime)
              .subtract(24, "h")
              .format("YYYY-MM-DD HH:mm:ss")
          ) <= this.convertTimestamp(today)
        ) {
          cancelAmount = this.usageHistory?.amount;
          isCancel = confirm(
            `취소 수수료가 발생합니다.\n${cancelAmount} KRW\n그래도 예약을 취소하시겠습니까?`
          );
        } else if (
          this.convertTimestamp(
            dayjs(departureDateTime)
              .subtract(48, "h")
              .format("YYYY-MM-DD HH:mm:ss")
          ) < this.convertTimestamp(today)
        ) {
          cancelAmount = Math.round(this.usageHistory?.amount * 0.3);
          isCancel = confirm(
            `취소 수수료가 발생합니다.\n${cancelAmount} KRW (탑승 24 시간 전)\n그래도 예약을 취소하시겠습니까?`
          );
        } else {
          cancelAmount = 0;
          isCancel = confirm("예약을 취소하시겠습니까?");
        }
      }

      try {
        if (isCancel) {
          this.isFetching = true;
          this.setIsLoading(true);
          this.setLoadingMessage("예약 취소 중 화면을 유지해주세요.");
          await nearmeApi.cancelReservation(this.reservationId, cancelAmount);
          await this.setData();
        }
      } finally {
        this.isFetching = false;
        this.setIsLoading(false);
        this.setLoadingMessage("");
      }
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>
<style scoped>
.history_wrapper {
  top: 0rem;
  left: 0rem;
  position: relative;
  width: 100%;
  height: 100%;
}
.dim {
  top: 0rem;
  left: 0rem;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1010;
}
</style>