<template>
  <div class="lyPage" ref="scrollList">
    <!-- 픽스 상태 : is-stateFix -->
    <header class="lyHeader">
      <section class="stNavigation">
        <nav class="navigation">
          <ul class="navigation-list">
            <!-- 온 상태 : is-stateOn -->
            <li
              v-for="boardCategory in boardCategories"
              :key="boardCategory.value"
              class="navigation-item"
              :class="{ 'is-stateOn': boardCategory.value === boardCd }"
              @click="onCategoryClick(boardCategory)"
            >
              {{ boardCategory.text }}
            </li>
          </ul>
        </nav>
      </section>
    </header>
    <main class="lyBody">
      <section class="stFilter">
        <div
          class="sort"
          @click="logEvent('community_filter_click', onOrderByClick)"
        >
          {{ orderByText }}
        </div>
        <div
          class="class"
          @click="logEvent('community_location_click', onLocationClick)"
        >
          {{ addrText }}
        </div>
      </section>
      <community-none-result v-if="boardList && !boardList.length">
        <template #text> 게시물이 없어요!<br /> </template>
      </community-none-result>
      <section v-else class="stPost">
        <div class="cmPost">
          <ul class="cmPost-list">
            <community-post
              v-for="board in boardList"
              :key="board.boardId"
              :board="board"
              @deletePost="onDeletePost"
              @openPostLayer="onOpenPostLayer"
            />
          </ul>
        </div>
      </section>
    </main>
    <footer class="lyFooter">
      <section @click="openFloatingLayer" class="stWrite">
        <i class="write"> 글쓰기 </i>
      </section>
    </footer>
  </div>
</template>

<script>
import CommunityPost from "@/views/community/components/CommunityPost";
import CommunityNoneResult from "@/views/community/components/CommunityNoneResult";
import { boardApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import dateMixin from "@/mixins/dateMixin";
import { $openLocation, $openSelect, $openFloatingButton } from "@/util/modal";
import { removeLastWord } from "@/util/string";
import { newWebviewLayerV2 } from "@/util/webview";

export default {
  name: "CommunityList",
  mixins: [scrollMixin, dateMixin],
  components: {
    CommunityPost,
    CommunityNoneResult,
  },
  data() {
    return {
      boardList: null,
      searchParams: {},
      totalCount: 0,
      pageIndex: 1,
      pageSize: 10,
      orderBy: "date",
      orderByList: [
        {
          text: "최신순",
          value: "date",
        },
        {
          text: "인기순",
          value: "like",
        },
        {
          text: "댓글 많은순",
          value: "comment",
        },
      ],
      boardCd: null,
      boardCategories: [
        {
          text: "전체",
          value: null,
        },
        {
          text: "매거진",
          value: 10006,
        },
        {
          text: "라운드 리뷰",
          value: 10005,
        },
        {
          text: "자유게시판",
          value: 10001,
        },
      ],
      location: null,
      lastOpenLayerName: null,
      lastOpenBoardId: null,
      abortController: null,
      magazineCode: 10006,
    };
  },
  async created() {
    window.showBoardMagazine = async () => {
      // 홈 > 전체보기 > 매거진 탭 진입 시 기존 호출한 api abort 처리
      if (this.isFetching) {
        this.abortController.abort();
        this.isFetching = false;
        this.abortController = null;
      }
      this.boardCd = this.magazineCode;
      await this.search();
      return JSON.stringify({ isShowFinishing: true });
    };

    const { boardCd, orderBy, addrId, address } = this.$route.query;
    if (boardCd || orderBy || addrId) {
      this.boardCd = Number(boardCd) || null;
      this.orderBy = orderBy;
      if (addrId && address) {
        this.location = {
          addrId,
          address,
        };
      }
      this.searchParams = {
        size: this.pageSize,
        boardCd: this.boardCd,
        orderBy: this.orderBy,
        addrId: this.addrId,
      };
      await this.setData();
    } else {
      await this.search();
    }
    window.communityTabActivated = () => {
      if (this.lastOpenLayerName === "CommunityRegistration") {
        this.search();
      }
      if (this.lastOpenBoardId) {
        this.updateBoard();
      }
    };
  },
  mounted() {
    this.addScrollEvent();
  },
  methods: {
    newWebviewLayerV2,
    async setData() {
      if (this.isFetching) return;
      try {
        this.abortController = new AbortController();
        this.isFetching = true;
        const {
          data: { content, last },
        } = await boardApi.getBoards(
          {
            ...this.searchParams,
            comment: this.commentCnt,
            like: this.likeCnt,
            lastId: this.lastId,
            page: this.pageIndex,
          },
          this.abortController
        );
        this.boardList = this.boardList || [];
        this.boardList = [...this.boardList, ...content];
        const lastPost = content[content.length - 1];
        this.commentCnt = lastPost?.commentCnt;
        this.likeCnt = lastPost?.likeCnt;
        this.lastId = lastPost?.boardId;
        this.last = last;
      } finally {
        this.isFetching = false;
      }
    },
    async search() {
      this.removeScrollEvent();
      this.pageIndex = 1;
      this.boardList = null;
      this.commentCnt = undefined;
      this.likeCnt = undefined;
      this.lastId = undefined;
      this.searchParams = {
        size: this.pageSize,
        boardCd: this.boardCd,
        orderBy: this.orderBy,
        addrId: this.addrId,
      };
      await this.$router
        .replace({
          query: { ...this.searchParams, address: this.location?.address },
        })
        .catch(() => {});
      await this.setData();
      this.addScrollEvent();
    },
    async updateBoard() {
      const {
        data: { content },
      } = await boardApi.getBoards({
        size: 1,
        orderBy: "date",
        lastId: this.lastOpenBoardId + 1,
      });
      const [board] = content;
      if (board) {
        this.boardList = this.boardList.map((item) =>
          item.boardId === board.boardId ? board : item
        );
      }
    },
    async onCategoryClick(boardCategory) {
      let eventName = "";
      switch (boardCategory.value) {
        case null:
          eventName = "community_all_click";
          break;
        case 10001:
          eventName = "community_free_click";
          break;
        case 10002:
          eventName = "community_qna_cllick";
          break;
        case 10005:
          eventName = "community_roundreview_click";
          break;
        case 10006:
          eventName = "community_magazine_click";
          break;
        default:
      }
      if (eventName) {
        this.logEvent(eventName);
      }
      if (this.boardCd === boardCategory.value) {
        return;
      }
      this.boardCd = boardCategory.value;
      await this.search();
    },
    async onOrderByClick() {
      const orderBy = await $openSelect({
        options: this.orderByList,
        selectedOption: this.orderBy,
      });
      if (orderBy) {
        let eventName = "";
        switch (orderBy) {
          case "date":
            eventName = "community_filter_newest_click";
            break;
          case "like":
            eventName = "community_filter_likes_click";
            break;
          case "comment":
            eventName = "community_filter_comments_click";
            break;
          default:
        }
        if (eventName) {
          this.logEvent(eventName);
        }
        this.orderBy = orderBy;
        await this.search();
      }
    },
    async onLocationClick() {
      if (this.location) {
        this.location = null;
        await this.search();
        return;
      }
      const location = await $openLocation();
      if (location) {
        this.logEvent("community_location_list_click");
        this.location = location;
        await this.search();
      }
    },
    async onDeletePost() {
      this.removeScrollEvent();
      await this.search();
      this.addScrollEvent();
    },
    onOpenPostLayer(boardId) {
      this.lastOpenLayerName = null;
      this.lastOpenBoardId = boardId;
    },
    async onWriteClick() {
      this.lastOpenLayerName = "CommunityRegistration";
      this.lastOpenBoardId = null;
      await newWebviewLayerV2({ name: "CommunityRegistration" });
    },
    async openFloatingLayer() {
      const { lastOpenLayerName, lastOpenBoardId } =
        await $openFloatingButton();
      this.lastOpenLayerName = lastOpenLayerName;
      this.lastOpenBoardId = lastOpenBoardId;
    },
  },
  computed: {
    orderByText() {
      return this.orderByList.find(({ value }) => value === this.orderBy)?.text;
    },
    addrId() {
      return this.location?.addrId;
    },
    addrText() {
      if (!this.location) {
        return "전체(지역)";
      }
      const siGunGu = removeLastWord(this.location.address);
      return siGunGu;
    },
  },
};
</script>

<style scoped>
.navigation {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.navigation::-webkit-scrollbar {
  display: none;
}
.id-communityNonePost .lyBody {
  height: calc(100% - 80px);
}
.id-communityNonePost .lyFooter {
  bottom: 20px;
  position: fixed;
  left: auto;
  width: auto;
  z-index: 301;
}
</style>
