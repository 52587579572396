import http from "@/util/http";

function getUserInfo() {
  return http.get(`/intro/user/info`);
}

function getClubList() {
  return http.get(`/intro/download/club/list`);
}

export default {
  getUserInfo,
  getClubList,
};
