<template>
  <div class="wrapper">
    <div class="page" ref="scrollContainer">
      <header
        class="header"
        :style="{
          height: headerHeight,
          position: isHeaderFixed ? 'fixed' : 'static',
          top: isDownScroll ? `-${headerHeight}` : 0,
        }"
      >
        <div class="commonSearch">
          <!-- 삭제버튼 활성 : is-activeDelete -->
          <div class="search is-activeDelete">
            <div
              class="search-input"
              @click="logEvent('restaurant_search_click')"
            >
              <byte-input
                placeholder="골프장/식당"
                :max-byte="50"
                v-model="searchValue"
                @keydown.native.enter="search"
                @input.native="onSearchValueInput"
              />
              <i class="search-icon"> 검색 </i>
              <i
                class="search-delete"
                v-if="searchValue"
                @click.stop="logEvent('restaurant_search_remove', resetSearch)"
              >
                삭제
              </i>
            </div>
          </div>
        </div>
        <div class="recent">
          <div class="recent-header">
            <div class="recent-title">최근 검색어</div>
            <div
              class="recent-delete"
              @click="
                logEvent(
                  'restaurant_recentsearch_allremove',
                  removeAllSearchWords
                )
              "
            >
              전체삭제
            </div>
          </div>
          <!-- 검색어 결과 활성 : is-active -->
          <div class="recent-main" :class="{ 'is-active': searchWords.length }">
            <div class="recent-noResult">최근 검색어가 없습니다.</div>
            <div class="recent-keyword">
              <div class="keyword">
                <ul class="keyword-list">
                  <li
                    v-for="word in searchWords"
                    :key="word"
                    @click="setSearchValue(word)"
                  >
                    <div class="keyword-object">
                      <div class="keyword-item">{{ word }}</div>
                      <i
                        class="keyword-delete"
                        @click.stop="
                          logEvent(
                            'restaurant_recentsearch_remove',
                            removeSearchWord(word)
                          )
                        "
                      >
                        삭제
                      </i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main class="main">
        <div
          class="main-main"
          :style="{
            height: searchWords.length
              ? `calc(100% - ${headerPadding})`
              : `calc(100% - ${headerPadding})`,
            'padding-top': headerPadding,
          }"
        >
          <!-- 리스트 목록 활성 : is-active -->
          <div
            class="board"
            :class="{
              'is-active': restaurantList && restaurantList.length,
              'is-typeList': viewTypeList,
            }"
            ref="scrollList"
          >
            <div class="board-header">
              <div class="board-type">
                <div class="board-info">
                  <i class="info-icon"></i> TMAP 데이터 제공
                </div>
                <i class="board-icon" @click="toggleType"> 타입 </i>
              </div>
            </div>
            <div class="board-main">
              <div class="board-noResult">
                <div class="board-notice">검색된 내역이 없어요.</div>
                <div class="board-caption">다시 검색 해주세요.</div>
              </div>
              <ul class="board-list">
                <li
                  v-for="restaurant in restaurantList"
                  :key="restaurant.foodId"
                  @click="
                    logEvent(
                      'restaurant_detail_open',
                      selectRestaurant(restaurant)
                    )
                  "
                >
                  <div class="place">
                    <div class="place-header">
                      <div class="thumb">
                        <div
                          class="thumb-img"
                          :style="{
                            'background-image': `url('${restaurant.logoUrl}')`,
                          }"
                        ></div>
                      </div>
                    </div>
                    <div class="place-info">
                      <div class="info">
                        <div class="info-name">{{ restaurant.name }}</div>
                        <div class="info-address">
                          {{ restaurant.addr }}
                        </div>
                      </div>
                      <div
                        class="hash"
                        v-if="restaurant.clubList && restaurant.clubList.length"
                      >
                        <ul class="hash-list">
                          <li v-for="tag in restaurant.clubList" :key="tag">
                            <div class="hash-item">#{{ tag }}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="layer" v-if="searchLayer && searchValue">
      <div class="layerAuto">
        <div class="outer">
          <div class="inner">
            <div class="box">
              <div class="box-main">
                <div class="select">
                  <ul class="select-list">
                    <li
                      v-for="club in searchGolfClubList"
                      :key="club.ccode"
                      @click="setSearchValue(club.clubName)"
                    >
                      <div
                        class="select-item"
                        v-html="club.highlightHtml"
                      ></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trendApi, introApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import Hangul from "hangul-js";
import ByteInput from "@/components/ByteInput";
import { handleOpenWeb } from "@/util/webview";
import { comma } from "@/util/string";

export default {
  name: "RestaurantList",
  mixins: [scrollMixin],
  components: { ByteInput },
  data() {
    return {
      restaurantList: null,
      selectedRestaurant: null,
      viewTypeList: false,
      restaurantLayer: false,
      timeoutId: null,
      ccode: "",
      golfClubList: null,
      searchLayer: false,
      totalCount: 0,
      pageIndex: 1,
      pageSize: 20,
      searchValue: "",
      searchParams: {},
      searchWords: [],
      isHeaderFixed: false,
      isDownScroll: false,
      lastScrollTop: 0,
      scrollDiff: 0,
    };
  },
  async created() {
    this.ccode = this.$route.query.ccode;
    const searchWords = localStorage.getItem("searchWords");
    if (searchWords) {
      this.searchWords = JSON.parse(searchWords);
    }
    await this.setGolfClubList();
    const targetGolfClub = this.golfClubList.find(
      (club) => club.ccode === this.ccode
    );
    this.searchValue = targetGolfClub?.clubName || "";
    await this.search();
  },
  mounted() {
    this.addScrollHeaderEvent();
  },
  beforeDestroy() {
    window.clearInterval(this.intervalId);
  },
  methods: {
    async setData() {
      if (this.isFetching) return;
      try {
        this.isFetching = true;
        this.searchLayer = false;
        const {
          data: { list, total },
        } = await trendApi.getRestaurantList({
          ...this.searchParams,
          page: this.pageIndex,
        });
        if (this.pageIndex === 1) {
          this.restaurantList = [];
        }
        this.restaurantList = this.restaurantList || [];
        this.restaurantList = [...this.restaurantList, ...list];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    onSearchValueInput(e) {
      this.searchLayer = true;
      if (!this.golfClubList) {
        return;
      }
      this.searchValue = e.target.value;
    },
    search(e) {
      e?.target?.blur?.();
      this.pageIndex = 1;
      this.searchParams = {
        ccode: this.searchValueCcode,
        size: this.pageSize,
        text: this.searchValue,
      };
      this.setSearchWords();
      this.setData();
      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop = 0;
      });
    },
    async setGolfClubList() {
      const storageClubList = this.getStorage("clubList");
      if (storageClubList) {
        this.golfClubList = storageClubList;
        return;
      }
      const { data: clubList } = await introApi.getClubList();
      // 국내 골프장만 필터링(2023.09.21)
      const domesticClubList = 
      clubList
        .filter(club => club.ccode.startsWith("82"))
        .sort((a, b) =>
          a.clubName > b.clubName ? 1 : a.clubName < b.clubName ? -1 : 0
        );
      
      this.setStorage("clubList", domesticClubList, 1);
      this.golfClubList = domesticClubList;
    },
    setSearchValue(value) {
      this.searchValue = value;
      this.search();
    },
    setSearchWords() {
      if (!this.searchValue) {
        return;
      }
      const searchWords = [
        ...new Set([this.searchValue, ...this.searchWords]),
      ].slice(0, 10);
      this.searchWords = searchWords;
      localStorage.setItem("searchWords", JSON.stringify(searchWords));
    },
    removeSearchWord(word) {
      const searchWords = this.searchWords
        .map((w) => w)
        .filter((w) => w !== word);
      this.searchWords = searchWords;
      localStorage.setItem("searchWords", JSON.stringify(searchWords));
    },
    removeAllSearchWords() {
      this.searchWords = [];
      localStorage.setItem("searchWords", JSON.stringify([]));
    },
    toggleType() {
      this.viewTypeList = !this.viewTypeList;
      const eventName = this.viewTypeList
        ? "restaurant_list_small_click"
        : "restaurant_list_big_click";
      this.logEvent(eventName);
    },
    selectRestaurant(restaurant) {
      if (!restaurant) {
        return;
      }
      handleOpenWeb({ url: restaurant.detailLink });
    },
    getTags(tags) {
      return tags && tags.split(" ").filter((s) => s);
    },
    setStorage(key, value, days) {
      localStorage.setItem(key, JSON.stringify(value));
      if (days) {
        localStorage.setItem(
          `${key}_expiresIn`,
          Date.now() + 24 * 60 * 60 * 1000 * days
        );
      }
    },
    getStorage(key) {
      try {
        const expiresIn = localStorage.getItem(`${key}_expiresIn`);
        if (expiresIn < Date.now()) {
          return null;
        }
        return JSON.parse(localStorage.getItem(key));
      } catch {
        return null;
      }
    },
    addScrollHeaderEvent() {
      this.$refs.scrollContainer.addEventListener(
        "scroll",
        this.scrollHeaderEvtHandler
      );
    },
    removeScrollHeaderEvent() {
      this.$refs.scrollContainer.removeEventListener(
        "scroll",
        this.scrollHeaderEvtHandler
      );
    },
    scrollHeaderEvtHandler() {
      const scrollTop = this.$refs.scrollContainer.scrollTop;
      const scrollDiff = scrollTop - this.lastScrollTop;

      if (parseInt(this.headerHeight) < scrollTop) {
        this.isHeaderFixed = true;
      } else {
        this.isHeaderFixed = false;
      }
      if (
        (scrollDiff > 0 && this.scrollDiff < 0) ||
        (scrollDiff < 0 && this.scrollDiff > 0)
      ) {
        this.scrollDiff = 0;
      }

      this.scrollDiff += scrollDiff;
      this.lastScrollTop = scrollTop;
      const threshold = Math.abs(this.scrollDiff) > 50;
      if (!threshold) {
        return;
      }

      this.isDownScroll = scrollDiff > 0 ? threshold : !threshold;
    },
    async resetSearch() {
      this.searchValue = "";
      await this.search();
    },
  },
  computed: {
    searchGolfClubList() {
      const searchValue = this.searchValue.trim().toLowerCase();
      return this.golfClubList
        .filter(
          (club) =>
            Hangul.search(club.clubName.toLowerCase(), searchValue) > -1 ||
            Hangul.search(
              club.clubName.replace(/ /g, "").toLowerCase(),
              searchValue
            ) > -1
        )
        .slice(0, 10)
        .map((club) => {
          const highlightIndex = Math.max(
            club.clubName.toLowerCase().indexOf(searchValue),
            club.clubName.replace(/ /g, "").toLowerCase().indexOf(searchValue)
          );
          if (highlightIndex > -1) {
            const highlightLastIndex =
              club.clubName.toLowerCase().indexOf(searchValue.substr(-1)) + 1;
            return {
              ...club,
              highlightHtml: `${club.clubName.substring(
                0,
                highlightIndex
              )}<strong>${club.clubName.substring(
                highlightIndex,
                highlightLastIndex
              )}</strong>${club.clubName.substring(highlightLastIndex)}`,
            };
          }
          return {
            ...club,
            highlightHtml: club.clubName,
          };
        });
    },
    searchValueCcode() {
      return (
        this.golfClubList.find((club) => club.clubName === this.searchValue)
          ?.ccode || null
      );
    },
    headerHeight() {
      return this.searchWords.length ? "165px" : "149px";
    },
    headerPadding() {
      return this.isHeaderFixed ? this.headerHeight : "0px";
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>

<style scoped>
header {
  transition: 0.3s;
}
.commonSearch {
  padding-top: 16px;
}
.id-trendRestaurant .layerAuto {
  visibility: visible;
}
.board-info {
  display: flex;
  align-items: center;
  padding-left: 11px;
  gap: 5px;
  font-size: 13px;
  font-family: "Noto Sans KR";
  color: #666666;
}
.info-icon {
  display: block;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABYlBMVEVHcEwAZP8AZ//jLtHKMt8Ap++UN/r/Jr8Ab/0B27UFlenCMuO9M+b/AL8AnuAAyMSoNfD3J8UbpeS/AP9/O/66MugClee6M+fZLtkAaf8AgvIAh/H0KsnZL9icNPeKN/8A47AA27YAffcAc/0Ad/izNOzWMNr9J8EAb/3iLtPuK8wA1LwAmuQEpd+fN/RVael6R/WBOP4Aaf8DffUKt9P/AP8MutEGt9MCevcTm+IMuMsAZv8Cj+8LqOICj+wArtYDudEBwskBwMsDkukBp9wGt9UBmOYFoN9VbecDnuQFysAuldU0kNkAZ/8Aj+kAi+3rLM0AlOb1KscA0rwAmeMAstKwNO0AyMMBvcsAcv0AdvoAhvD9J8HRMdzbL9YAuM8Abf8AevcA1rkAgvIAzMEAot0BqtgAwcgArtUAzr8A37MAptsAxMahNvUAav8Af/QAffYA2ra4NOkbnNhgYO0uieAX9TliAAAATXRSTlMAlkz//QT+/BJIFP7+BP6I/kgZBEhI4odI3JXd+IZI/Pb23NyW+PmGlviG+OyV+f39+pYxMQQLdzwi9paNRnbuybTZW9qizcHNVPZI93SX/O4AAAG3SURBVEjH1dTXUsJAFIBhG6AoscQuig2VDoIw9t5FBUOxRkWKIiSAvr9bjoIXwmHGccb//svuzp5NQ8Nf1M0bpPWxxmi9vOO2cgB0Op3RaLRYLDMzLS3tHWazucvp1GpNJo/H7TpZOrLZJkjjpE9wcxMOn583393d3hJQjEa7cjkG3krx2PPzxcXjY6HwQqoB3O/x11js6QlEoRYgn0+nv4uqwJWS41RQQgQjVYDWlZHlCsEXqQLeSpFIpeDb+hm4S5kMiMqDlO8hbKSA30PRHHW+p1JURMqCbssGYJZktVpHaQOsg2T2S8hLW5udEAADtLAgigaDKIqL+8lkFoi8EfDp/RreT8N1aBe+xG5P7WHUOCQBxHrAj5he/Z7EBDmIQ48Zd689JBFCxI4P9T62QyEmhKRDgwJrp6eMCMIq7gWunIGwL+LA8BkRhEj9PUhwDQIPQKBBggpC0EAhgi6CBdOKkmCLoMGDysWIHgtAoMEVCCyYu2JCSaDBJROqggeXlKjqJBLM398z8TDZigR5EFiwHASBBY1BEFN4wEU9IJgnpC5ARX2AkKE6QfAfA2/TZ7g/5S/3AUPM3seQ7z9oAAAAAElFTkSuQmCC");
  width: 16px;
  height: 16px;
  background-size: contain;
}
.id-trendRestaurant .main-main .info {
  position: relative;
  margin-top: 15px;
  padding-bottom: 16px;
}
.id-trendRestaurant .main-main .hash {
  margin-top: -15px;
  color: #666666;
}
.id-trendRestaurant .main-main .info-name {
  font-size: 16px;
  padding-top: 0;
}
.board.is-typeList .place-info {
  flex: 1 1 auto;
}
.visit-count {
  position: absolute;
  right: 20px;
  top: 2px;
  display: inline-block;
  font-family: "Noto Sans KR";
  font-size: 12px;
  color: #ffffff;
  padding: 0px 5px;
  border-radius: 4px;
  height: 20px;
  background-color: #007aff;
}
</style>
