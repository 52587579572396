<template>
  <div class="cmPost-meta">
    <template v-if="ogMetaData.video">
      <div class="cmPost-metaWrapper">
        <div
          class="cmPost-metaThumbnail"
          @click="logEvent('community_meta_video_click')"
        >
          <div class="cmPost-metaItem">
            <iframe :src="ogMetaData.video.secure_url" frameborder="0"></iframe>
          </div>
        </div>
        <div class="cmPost-metaDescription">
          <a :href="linkUrl" @click="logEvent('community_meta_link_click')">
            <p class="cmPost-metaTitle">{{ ogMetaData.title }}</p>
            <p class="cmPost-metaSubtitle">{{ linkUrl }}</p>
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <a :href="linkUrl" @click="logEvent('community_meta_link_click')">
        <div class="cmPost-metaWrapper-small">
          <div class="cmPost-metaThumbnail-small">
            <div class="cmPost-metaItem-small">
              <div
                v-if="ogMetaData.image"
                class="cmPost-metaObject-small"
                :style="{
                  'background-image': `url('${ogMetaData.image.url}')`,
                }"
              ></div>
              <div v-else class="cmPost-metaObject-small none"></div>
            </div>
          </div>
          <div class="cmPost-metaDescription-small">
            <p class="cmPost-metaTitle-small">{{ ogMetaData.title }}</p>
            <p class="cmPost-metaSubtitle-small">{{ linkUrl }}</p>
          </div>
        </div>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: "OpenGraphMeta",
  props: ["ogMetaData"],
  computed: {
    linkUrl() {
      return this.ogMetaData.url || this.board.linkMeta?.[0]?.url || "";
    },
  },
};
</script>
