var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ly_wrapper"},[_c('div',{staticClass:"ly_page"},[_c('div',{staticClass:"ly_page_inner"},[_c('div',{staticClass:"ly_page_inner2"},[_c('main',{staticClass:"ly_body"},[_c('div',{staticClass:"bl_header_wrapper"},[_c('div',{staticClass:"bl_header"},[_c('div',{staticClass:"bl_header_ttl"},[_vm._v("Q"+_vm._s(_vm.questionNo)+".")]),(_vm.answerLength > 1)?_c('div',{staticClass:"bl_header_state"},[_c('strong',{staticClass:"el_reset bl_header_state_accent"},[_vm._v(_vm._s(_vm.questionNo))]),_vm._v(" / "+_vm._s(_vm.answerLength)+" ")]):_vm._e()])]),_c('div',{staticClass:"bl_ttl_wrapper"},[_c('div',{staticClass:"bl_ttl"},[_c('div',{staticClass:"bl_ttl_txt"},[_vm._v(" "+_vm._s(_vm.currentQuestion.title)+" ")]),(_vm.currentQuestion.correctCount > 1)?_c('div',{staticClass:"bl_ttl_multi"},[_vm._v(" 답변 "+_vm._s(_vm.currentQuestion.correctCount)+"개 ")]):_vm._e(),(_vm.currentQuestion.imageUrl)?_c('div',{staticClass:"bl_ttl_img"},[_c('div',{staticClass:"bl_ttl_img_val",style:({
                    'background-image': ("url('" + (_vm.currentQuestion.imageUrl) + "')"),
                  })})]):_vm._e()])]),(_vm.isShortAnswerQuestion)?_c('div',{staticClass:"bl_problemList_wrapper"},[_c('ul',{staticClass:"el_reset bl_problemList"},[_c('li',{staticClass:"bl_problemList_item is_moFull"},[_c('div',{staticClass:"bl_problem"},[_c('div',{staticClass:"cp_area is_moRes"},[_c('textarea',{staticClass:"el_form cp_area_inp",attrs:{"placeholder":"내용을 입력하세요.","disabled":""},domProps:{"innerHTML":_vm._s(_vm.currentQuestion.myAnswer)}}),_c('div',{staticClass:"cp_area_box"})])])])])]):_c('div',{staticClass:"bl_problemList_wrapper"},[_c('ul',{staticClass:"el_reset bl_problemList"},_vm._l((_vm.currentQuestion.exampleList),function(example){return _c('li',{key:example.exampleNumber,staticClass:"bl_problemList_item",class:{
                  is_moFull: _vm.currentQuestion.questionTypeCd === '4602',
                }},[_c('div',{staticClass:"bl_problem"},[_c('div',{staticClass:"cp_chk is_moRes",class:{
                      is_moImg: _vm.currentQuestion.questionTypeCd === '4601',
                      is_moTxt: _vm.currentQuestion.questionTypeCd === '4602',
                    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.questionMultipleAnswer),expression:"questionMultipleAnswer"}],staticClass:"el_form cp_chk_inp",attrs:{"type":"checkbox"},domProps:{"value":example.exampleNumber,"checked":Array.isArray(_vm.questionMultipleAnswer)?_vm._i(_vm.questionMultipleAnswer,example.exampleNumber)>-1:(_vm.questionMultipleAnswer)},on:{"change":function($event){var $$a=_vm.questionMultipleAnswer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=example.exampleNumber,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.questionMultipleAnswer=$$a.concat([$$v]))}else{$$i>-1&&(_vm.questionMultipleAnswer=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.questionMultipleAnswer=$$c}}}}),_c('div',{staticClass:"cp_chk_bg"}),_c('div',{staticClass:"cp_chk_box"}),_c('div',{staticClass:"cp_chk_area"},[_c('div',{staticClass:"cp_chk_area_pro"},[_c('div',{staticClass:"cp_chk_area_pro_val",style:({ width: ((example.choicePercent) + "%") })})]),_c('div',{staticClass:"cp_chk_area_img",style:({
                          'background-image': ("url('" + (example.imageUrl) + "')"),
                        })}),_c('div',{staticClass:"cp_chk_area_cont"},[_c('div',{staticClass:"cp_chk_area_cont_txt"},[_vm._v(" "+_vm._s(example.info)+" ")]),_c('div',{staticClass:"cp_chk_area_cont_chk"}),_c('div',{staticClass:"cp_chk_area_cont_per"},[_c('div',{staticClass:"cp_chk_area_cont_per_val"},[_vm._v(" "+_vm._s(example.choicePercent)+"% ")])])])])])])])}),0)])]),_c('footer',{staticClass:"ly_footer"},[_c('div',{staticClass:"bl_btnList_wrapper"},[_c('ul',{staticClass:"el_reset bl_btnList"},[_c('li',{staticClass:"bl_btnList_item",class:{ is_moPrev: _vm.questionNo < _vm.answerLength }},[_c('div',{staticClass:"bl_btn"},[_c('div',{staticClass:"cp_btn"},[_c('button',{staticClass:"el_form cp_btn_btn",on:{"click":_vm.closeWebviewLayer}},[_vm._v(" 목록으로 ")])])])]),(_vm.questionNo < _vm.answerLength)?_c('li',{staticClass:"bl_btnList_item"},[_c('div',{staticClass:"bl_btn"},[_c('div',{staticClass:"cp_btn is_moPrim"},[_c('button',{staticClass:"el_form cp_btn_btn",on:{"click":_vm.nextAnswer}},[_vm._v(" 다음결과 ")])])])]):_vm._e()])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }